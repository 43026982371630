<mat-form-field appearance="legacy" class="full-width">
    <mat-progress-bar *ngIf="loading" mode="indeterminate" class="over-head"></mat-progress-bar>
    <mat-label>{{label}}</mat-label>
    <input type="text"
           placeholder="{{placeholder}}"
           matInput
           [(ngModel)]="value"
           (ngModelChange)="onChange($event); valueChange.emit($event)"
           (blur)="onTouch()"
           #account
           [matAutocomplete]="auto" [required]="required" (keyup.enter)="onEnterSearch(account.value)">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let i of displayItems" [value]="i">
            <ng-container
                    *ngIf="getTemplate() else memberTemplate"
                    [ngTemplateOutlet]="getTemplate()"
                    [ngTemplateOutletContext]="{ item: i }">
            </ng-container>
            <ng-template #memberTemplate>
                {{i}}
            </ng-template>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
import {Apollo} from 'apollo-angular';
import {Component, OnInit} from '@angular/core';

import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiRest} from '@services/adfi-rest';
import { MatDialog } from '@angular/material/dialog';
import {ApprovedFormComponent} from '@pages/adfi-form/approved-form/approved-form.component';
import {AdfiService} from '@services/adfi-service';
import {Pages, Permission} from '@Interfaces/index';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {LoadingService} from '@services/loading.service';

export interface DialogConfirm {
    title: number;
    row: any;
}

@Component({
    selector: 'app-adfi-form',
    templateUrl: './adfi-form.component.html',
    styleUrls: ['./adfi-form.component.css']
})
export class AdfiFormComponent implements OnInit {

    PAGES = Pages;
    PERMISSION = Permission;

    constructor(private apollo: Apollo, private adfiGraphqlService: AdfiGraphqlService,
                public rest: AdfiService,
                private alert: AdfiGrowlService,
                private  loading: LoadingService,
                private dialog: MatDialog,
                private service: AdfiService, public adfibService: AdfiService) {
    }

    ngOnInit() {
    }

    approvedData(rowData: any, approved: number) {
        const dialogRef = this.dialog.open(ApprovedFormComponent, {
            width: '720px',
            data: {title: approved, row: rowData}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.saveData(rowData, result.approved, result.obs);
            }
        });
    }

    saveData(rowData: any, approved: number, obs?) {
        this.loading.show();
        this.apollo.mutate({
            mutation: this.adfiGraphqlService.updateAdfiForm(),
            variables: {
                input: {
                    id: rowData.id,
                    approved,
                    observation: obs,
                    employee: `/api/employees/${this.adfibService.user.id}`
                }
            }
        }).subscribe(value => {
            this.loading.hide();
            this.alert.success(approved === 1 ? 'Apropado' : 'Desaprobado', !rowData.approved ? 'Se ha aprobado Correctamente' : 'Se ha desaprobado Correctamente');
            rowData.approved = approved;
            if (approved === 1) {
                this.sendConfirmMail(rowData, approved);
            } else if (approved === 2) {
                this.service.post(AdfiRest.sendCustomMail, {
                    document: rowData.document,
                    title: 'Respuesta Solicitud Capacitación Plataforma ADFI',
                    content: `Su solicitud de asistencia a la capacitación de la plataforma ADFI fue rechazada por que: ${obs}`,
                    subject: 'Respuesta Solicitud Capacitación Plataforma ADFI'
                }).subscribe(value1 => {
                    this.alert.success(`Correo enviado`, `Se ha enviado el correo de denegacíon a ${rowData.names} ${rowData.lastnames}`);
                }, error => {
                    this.alert.error(`Error al enviar el correo`, `Error al enviar el correo a ${rowData.names} ${rowData.lastnames}, por favor comuníquese con el encargado.`);
                });
            }
        }, error => {
            this.loading.hide();
            this.alert.error(!rowData.approved ? 'Error al Aprobar' : 'Error al Desaprobar', !rowData.approved ? 'No ha sido posible cambiar el estado ha aprobado' : 'No ha sido posible cambiar el estado ha desaprobado');
        });
    }

    sendConfirmMail(rowData, approved) {
        this.loading.show();
        this.service.get(AdfiRest.sendApprovedTrainer, {
            code: rowData.document
        }).subscribe(value1 => {
            this.alert.success(`Correo  enviado`, `Se ha enviado el correo de aprobación a ${rowData.names} ${rowData.lastnames}`);
            rowData.approved = approved;
            this.loading.hide();
        }, error => {
            this.loading.hide();
            this.alert.error(`Error al enviar el correo`, `Error al enviar el correo a  ${rowData.names} ${rowData.lastnames}, por favor comuníquese con el encargado.`);
        });
    }

    resendSchedule(rowData) {
        this.loading.show();
        this.service.post('/resend/schedule/mail', {
            document: rowData.document
        }).subscribe(value1 => {
            this.alert.success(`Correo  enviado`, `Se ha enviado el reenviado el correo a ${rowData.names} ${rowData.lastnames}`);
            this.loading.hide();
        }, error => {
            this.alert.error(`Error al enviar el correo`, `Error al reenviar el correo  a ${rowData.names} ${rowData.lastnames}, por favor comuníquese con el encargado.`);
            this.loading.hide();
        });
    }

    exportData() {
        this.loading.show();
        /*this.rest.post(`${URL_REPORT}/reportes/public/index.php/xlsx/adfi/forms`, {}, 'arraybuffer', false).toPromise().then((r) => {
            AdfiRest.exportFile(r, `ReporteCapacitacion.xlsx`);
        }, (e) => {
            this.loading.hide();
        });*/
        this.alert.error('Support', 'Informe aún no implementado');
        this.loading.hide();
    }

    resendMail(row: any) {
        if (row.confirmCode.includes('SUCCESS') || row.confirmCode.includes('RESEND')) {
            this.resendSchedule(row);
        } else {
            this.sendConfirmMail(row, row.approved);
        }
    }
}

<app-list (loadedConfig)="title = prefix + '-' +$event.name" entityName="RubroPresupuesto" module="Presupuesto" [customQuery]="[{column: 'presupuesto', value: getType()}]"
          [customTitle]="title" [defaultQuery]="[{column: 'centroCosto', value: ''}]">
  <div class="footer">
    <button mat-raised-button color="primary" *ngIf="btnAbrirPresupuesto" (click)="open()">
      <mat-icon>done</mat-icon> Abrir Presupuesto
    </button>
    <button mat-raised-button color="primary" class="mr-3 ml-2" (click)="print()" [disabled]="totalItems <= 0"> Imprimir Movimientos
    </button>
  </div>
</app-list>


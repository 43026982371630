<div class="container pb-5">
  <div class="row">
      <div class="col-lg-9 col-12">
          <mat-form-field class="full-width" matTooltip="Documentos con ✔ indican que ya fueron cerrados">
              <mat-label>Documento Contable</mat-label>
              <mat-select placeholder="Seleccionar" (selectionChange)="selectDocument($event.value)">
                  <mat-select-trigger>
                    <ng-container *ngIf="documentComp">
                      <mat-icon *ngIf="documentComp.state === 'C'" color="primary">check_circle_outline</mat-icon>&nbsp;<b>N° </b>{{ documentComp.numberDoc }}
                    </ng-container>
                  </mat-select-trigger>
                  <mat-option *ngFor="let doc of accountingDocuments" [value]="doc">
                      <b>N° </b>{{ doc.numberDoc }}
                      <ng-container *ngIf="doc.proof">
                          <b>Comprobante: </b>
                          {{ doc.proof.name | titlecase}}
                      </ng-container>
                      <ng-container *ngIf="doc.type">
                          <b>Tipo: </b>
                          {{ doc.type.name | titlecase}}
                      </ng-container>
                      <mat-icon *ngIf="doc.state === 'C'">check_circle_outline</mat-icon>
                      <b *ngIf="doc.state === 'D'">anulado</b>
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="col-lg-3 col-12">
          <div class="row justify-content-end">
            <button mat-raised-button [disabled]="!periodOpen" (click)="newDocument()" color="primary" matTooltip="Crear Documento Contable">
              <mat-icon>create</mat-icon> Crear Documento</button>
          </div>
      </div>
  </div>
  <mat-card class="col-12 mb-2" *ngIf="documentComp">
    <mat-card-title>Documento Seleccionado: </mat-card-title>
    <mat-card-content>
      <div class="row pt-2">
        <div class="col-12 col-md-4"><strong> Número Documento: </strong>{{ documentComp.numberDoc }}</div>
        <div class="col-12 col-md-4"><strong> Fecha: </strong> {{ documentComp.date | date: 'mediumDate':null:'es-CO'}}</div>
        <div class="col-12 col-md-4"><strong> Diferencia: </strong>{{ documentComp.difference | currency }}</div>
        <div class="col-12 col-md-4 pt-2"><strong> Comprobante: </strong> {{ documentComp.proof.name }}</div>
        <div class="col-12 col-md-4 pt-2"><strong> Tipo: </strong>{{ documentComp.type.name }}</div>
        <div *ngIf="documentComp.state == 'D'" class="col-12 col-md-4 pt-2"><strong> Fecha anulación: </strong>{{ cancelDate | date: 'mediumDate':null:'es-CO' }}</div>
        <div *ngIf="documentComp.state != 'D'" class="col-12 col-md-4 pt-2">
          <div class="row justify-content-end">
            <button mat-icon-button matTooltip="Editar Documento" (click)="editDocument()" [disabled]="this.documentComp.state === 'C' || this.documentComp.state === 'D' || !periodOpen"><mat-icon>edit</mat-icon></button>
            <button matTooltip="Eliminar Documento"
                    mat-icon-button
                    (click)="removeDocument()"
                    color="warn" [disabled]="(this.documentComp.state === 'C' || !periodOpen || this.documentComp.state === 'D') || !canDelete "><mat-icon>delete</mat-icon></button>
            <button matTooltip="Abrir Documento" *ngIf="canOpen && this.documentComp.state === 'C'" color="primary"
                    [disabled]="!movementTable || movementTable.loadingData"
                    (click)="closeDocument('G')" mat-icon-button>
              <mat-icon>menu_book</mat-icon>
            </button>
            <button matTooltip="Cerrar Documento" (click)="closeDocument('C')"
                    [disabled]="!canClose || (!movementTable || movementTable.loadingData) || totals.debit + totals.credit === 0 || documentComp.difference !== 0 || documentComp.state === 'C' || documentComp.state === 'D'"
                    color="primary" mat-icon-button>
              <mat-icon>check_circle</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Anular Documento" (click)="cancelDocument()" *ngIf="canOpen && this.documentComp.state === 'C' && canCancel"><mat-icon>block</mat-icon></button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="row pt-3" *ngIf="this.documentComp">
    <div class="col-12">
        <app-list #movements entityName="Adjustment" module="Adjustments" [defaultQuery]="[
        { column: 'cencos', value: this.documentComp.cencos._id },
        { column: 'numDay', value: this.documentComp.numDay },
        { column: 'numPeriod', value: this.documentComp.period },
        { column: 'numDoc', value: this.documentComp.numberDoc },
        { column: 'module', value: getModule }
        ]" [dataEntity]="{enableAdd: false}" (loadedData)="postLoadAdjustments()">
            <ng-template tableColumn="third" let-row="row">
                <ng-container *ngIf="row.third">
                    {{row.third.nit + ' - ' + (row.third.names | titlecase) + ' ' + (row.third.firstSurname | titlecase)}}
                </ng-container>
                <ng-container *ngIf="!row.third">Error - Tercero Eliminado</ng-container>
            </ng-template>
            <ng-template tableColumn="actions" let-row="row">
                <button mat-icon-button matTooltip="Editar movimiento" [disabled]="movements.loadingData || row.approved === 'S' || !periodOpen || documentComp.state === 'C'" (click)="editMovement(row)"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button matTooltip="Eliminar movimiento" [disabled]="movements.loadingData || row.approved === 'S' || !periodOpen || documentComp.state === 'C'"
                        (click)="removeMovement(row)"
                        color="warn"><mat-icon>delete</mat-icon></button>
            </ng-template>
            <ng-template tableColumn="footer_third">
                <strong>Total</strong>
            </ng-template>
            <ng-template tableColumn="footer_debit">
                <strong> {{ totals.debit | currency}}</strong>
            </ng-template>
            <ng-template tableColumn="footer_credit">
                <strong> {{ totals.credit | currency}}</strong>
            </ng-template>
        </app-list>
    </div>
  </div>
  <ng-container *ngIf="documentComp">
    <div class="row pt-3 pb-5">
      <div class="col-12">
        <div class="row justify-content-end">
          <button mat-raised-button class="mr-2" (click)="showTrialBalance()" color="primary" [disabled]="(!movementTable || movementTable.loadingData) || documentComp.difference !== 0 || totals.debit + totals.credit === 0"
                  matTooltip="Mostrar afectación contable previa al cierre del documento">
            <mat-icon>receipt_long</mat-icon>
             {{documentComp.state === 'C' ? 'Ver ' : 'Previsualizar ' }}Afectación Contable
          </button>
          <button mat-raised-button class="mr-2" (click)="newMovement()" color="primary" [disabled]="documentComp.state === 'D' || documentComp.state === 'C' || !periodOpen"
                  matTooltip="Crear Movimiento Contable">
            <mat-icon>create</mat-icon>Nuevo Movimiento
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AdjustComponent} from './adjust/adjust.component';
import {NavRoute} from 'src/app/utils/menu';
import {Entities, Pages, PeriodState, Permission} from '@Interfaces/index';
import {AdminComponent} from '@pages/adjustment/admin/admin.component';
import {CheckingBalanceComponent} from '@pages/adjustment/checking-balance/checking-balance.component';


export const RoutesAdjustment: NavRoute[] = [
  {
    path: Pages.ACCOUNTING_CHECKERS,
    component: AdjustComponent,
    permission: Permission.VIEW,
    entity: Entities.ADJUSTMENT,
    periodSate: PeriodState.NO_STATUS
  },
  {
    path: Pages.ACCOUNTING_CHECKERS_ADMIN,
    component: AdminComponent,
    entity: Entities.ADJUSTMENT,
    permission: Permission.VIEW_ADMIN,
    periodSate: PeriodState.NO_STATUS
  },
  {
    path: Pages.CHECKING_BALANCE,
    component: CheckingBalanceComponent,
    entity: Entities.CHECKING_BALANCE,
    periodSate: PeriodState.NO_STATUS
  }
];

@NgModule({
  imports: [RouterModule.forChild(RoutesAdjustment)],
  exports: [RouterModule]
})
export class AdjustmentRoutingModule { }

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {Action, DataEntity, ENUM_ACTIONS, Property, TICK} from '@Interfaces/index';
import { AdfiGraphqlService } from '@services/adfi-graphql.service';
import { AdfiGrowlService } from '@services/adfi-growl.service';
import { AdfiService } from '@services/adfi-service';
import { LoadingService } from '@services/loading.service';
import { Subscription, timer } from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { AddUnspscAccountsDialogComponent } from '@Components/dialogs/paa/add-unspsc-accounts-dialog/add-unspsc-accounts-dialog.component';
import {AdfiUtil} from '@Components/util/adfi-util';
import {ListComponent} from '@Components/ui/list/list.component';
import {FormComponent} from '@Components/ui/form/form.component';
import {UIActionsHandler} from '@Components/util/UIActionsHandler';
import {Pages} from '@Interfaces/index';

@Component({
    selector: 'app-plan-anual-adquisicion',
    templateUrl: './plan-anual-adquisicion.component.html',
    styleUrls: ['./plan-anual-adquisicion.component.scss'],
})
export class PlanAnualAdquisicionComponent implements OnInit, AfterViewInit, OnDestroy {
    counter: {
        counterStart: number;
        counterEnd: number;
        countDown: Subscription | null;
        countStart: Subscription | null;
    } = {
        counterStart: 1,
        counterEnd: 0,
        countDown: null,
        countStart: null,
    };
    period: number;
    paa: any;
    fPaa: FormComponent;
    public vigencia: any;
    public estadoVigencia: any;
    @ViewChild('tem_paa', { read: ViewContainerRef }) vPaa: ViewContainerRef;
    constructor(
        private injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver,
        private loadingService: LoadingService,
        private graphqlService: AdfiGraphqlService,
        private adfiService: AdfiService,
        private adfiGrowlService: AdfiGrowlService,
        private dialog: MatDialog,
        private graphService: AdfiGraphqlService
    ) {}

    ngOnInit(): void {
        this.loadingService.show();
    }

    getColorTime() {
        const days = (this.counter.counterEnd / 86400);
        if (days < 7) {
            return 'red';
        } else if (days < 15) {
            return 'orange';
        }
        return 'green';
    }

    loadPaa() {
        const dataForm = {
            entityName: 'PlanAnualAdquisicion',
            module: 'PAA',
            group: 'E_PA',
            customActionBtnText: 'Guardar',
            customTitle: '',
            displayBtnCancel: false,
            displayBtnSave: this.counter.counterEnd > 0,
            disableEdit: this.paa.state !== 'I' || this.counter.counterEnd < 0,
            action: ENUM_ACTIONS.EDIT,
            item : this.paa.id,
            displayTitle: false
        } as DataEntity;
        if (!this.vPaa) { return; }
        this.vPaa.clear();
        const inj: Injector = Injector.create({
            providers: [{
                provide: MatDialogRef, useValue: {}
            }, {
                provide: MAT_DIALOG_DATA, useValue: dataForm
            }],
            parent: this.injector
        });
        const resolver = this.componentFactoryResolver.resolveComponentFactory(FormComponent);
        this.fPaa = this.vPaa.createComponent(resolver, 0, inj).instance;
        this.fPaa.postSave.subscribe( () => {});
    }

    ngAfterViewInit(): void {
        this.loadingService.show();
        this.adfiService.getTimeControl(
            'PlanAnualAdquisicion',
            (data: any) => {
                if (data){
                    this.setAvailableDates(data);
                    this.vigencia = this.adfiService.getUserPeriod();
                    if(this.vigencia.estado == 'C') {
                        this.period = this.vigencia.year;
                        this.checkPAA(this.vigencia.year);
                    }else {
                        this.period = data.period;
                        this.checkPAA(data.period);
                    }
                }else {
                    this.loadingService.hide();
                }
            },
            (error: any) => {
                this.loadingService.hide();
                this.adfiGrowlService.errorMessage(error);
            }
        );
    }

    public addUnspscAccount(row: any, property: Property, list: ListComponent) {
        const ref = this.dialog.open(AddUnspscAccountsDialogComponent, {
            data: { accounts: AdfiUtil.getDataOneToMany(row, property, true)},
            height: '80vh',
            minWidth: '85%'
        });
        ref.afterClosed().subscribe((selectedAccounts: any[]) => {
            if (!selectedAccounts) {
                return;
            }
            this.loadingService.show();
            this.graphqlService.createEntity(
                'Action',
                {
                    nameClass: 'AddMultipleAccountPAA',
                    message: JSON.stringify({
                        accounts: selectedAccounts,
                        acquisition: row._id
                    })
                },
                'message actions',
                {
                    next: (r) => {
                        this.loadingService.hide();
                        this.adfiGrowlService.success(JSON.parse(r.actions), JSON.parse(r.message));
                        list.reload();
                    },
                    error: (error) => {
                        this.loadingService.hide();
                        this.adfiGrowlService.errorMessage(error);
                    },
                }
            );
        });
    }

    /**
     *  This functions checks if the PAA is created for this period, if it
     *  doesn't then it sends the signal to create it in the backend.
     */
    private checkPAA(period: number): void {
        this.loadingService.show();
        this.graphqlService.getFirstEntity(
            'planAnualAdquisicions',
            'id vigencia lastUpdateDate state paaFile fileNamePaa',
            `vigencia: ${period}, cencosFilter_cencos: ""`,
            {
                next: (response: any) => {
                    // If the response is undefined, it means there is no PAA
                    // for this period.
                    if (!response ) {
                        if(this.vigencia.estado !== 'C') {
                            this.graphqlService.createEntity(
                                'PlanAnualAdquisicion',
                                {
                                    lastUpdateDate: new Date(),
                                },
                                'id vigencia lastUpdateDate state paaFile fileNamePaa',
                                {
                                    next: (r) => {
                                        this.paa = r;
                                        this.loadPaa();
                                        this.loadingService.hide();
                                    },
                                    error: (error) => {
                                        this.loadingService.hide();
                                        this.adfiGrowlService.errorMessage(error);
                                    },
                                }
                            );
                        }else {
                            this.loadingService.hide();
                        }

                       
                    } else {
                        this.paa = response;
                        this.loadPaa();
                        this.loadingService.hide();
                    }
                },
                error: (error) => {
                    this.adfiGrowlService.errorMessage(error);
                    this.loadingService.hide();
                }
            }
        );
    }

    private setAvailableDates(data: any) {
        if (data) {
            this.counter.counterStart = this.adfiService.getCountDown(
                data.startDateRegister
            );
            if (this.counter.counterStart > 0) {
                this.counter.countStart = timer(0, TICK).subscribe(() => {
                    --this.counter.counterStart;
                    if (this.counter.counterStart <= 0) {
                        this.counter.countStart.unsubscribe();
                        this.endTime(data);
                    }
                });
            } else {
                this.endTime(data);
                if (this.counter.counterEnd <= 0) {
              const valid = (dates) => {
                  if (dates.length) {
                      const param = JSON.parse(dates[0]);
                      const end = new Date(param.endDate);
                      this.counter.counterEnd = (end.getTime() / 1000) - ((new Date()).getTime() / 1000);
                      if (this.counter.counterEnd > 0) {
                          this.counter.countDown = timer(0, TICK).subscribe(() => --this.counter.counterEnd);
                      }
                  }
              };
              this.graphService.loadAlerts('ExeptionAvalibleDate', 'getExceptionRegisterFor', `"3", "InitialBudget", "${this.adfiService.user.centroCosto._id}", "${new Date().getMonth() + 1}"`, valid);
            }
            }
        }
    }

    private endTime(period: any) {
        this.counter.counterEnd = this.adfiService.getCountDown(
            period.endDateRegister
        );
        this.counter.countDown = timer(0, TICK).subscribe(
            () => --this.counter.counterEnd
        );
        if (this.counter.counterEnd < 0) {
            this.counter.countDown.unsubscribe();
            this.loadingService.hide();
        }
    }

    getDataMenu(row: any, property: Property) {
        return AdfiUtil.getDataOneToMany(row, property, true);
    }

    transformValue(obj, property: Property, index: number) {
        return AdfiUtil.evaluateColumnsToDisplay(obj, [property.display[index]], property.customFormatDisplay, property.separators, property, 0);
    }

    ngOnDestroy(): void {
        if (this.counter.countStart) {
            this.counter.countStart.unsubscribe();
        }
        if (this.counter.countDown) {
            this.counter.countDown.unsubscribe();
        }
    }

    downloadFile(type: 'pdf' | 'xlsx') {
        UIActionsHandler.handleFilesActions(this.adfiService, null, 'PAA\\PlanAnualAdquisicion',
            JSON.stringify({id: this.paa._id}),
            'Plan Anual de Adquisiones',
            {properties: {fileType: type, fileAction: 'report'}} as Action, null);
    }
    downloadPaa() {
        AdfiUtil.downloadMediaObject(this.paa, { fieldName: 'paaFile', options: {bucket: 'files_paa'}},
            this.period);
    }

    openVideoPAA(){
        const URL = '/nav/help/' + Pages.VIDEOS + '?video=PAA';
        window.open(URL);   
    }

    uploadFile() {
        const dialog = this.dialog.open(FormComponent, {
            data: {
                entityName: 'PlanAnualAdquisicion',
                module: 'PAA',
                group: 'U_PA',
                item: this.paa.id,
                customTitle: 'Subir Plan Anual de Adquisiciones',
                customActionBtnText: 'Subir',
                action: ENUM_ACTIONS.EDIT,
            } as DataEntity,
            minWidth: '50%'
        });
        const subs = dialog.afterClosed().subscribe((event) => {
            if (event) {
                subs.unsubscribe();
                window.location.reload();
            }
        });
    }

    openVerification() {
        const ref = this.dialog.open(ListComponent, {
            minHeight: '30vh',
            minWidth: '50%',
            maxHeight: '90vh',
        });
        ref.componentInstance.group = 'VA';
        ref.componentInstance.module = 'Presupuesto';
        ref.componentInstance.entityName = 'VCurrentItemsBudget';
        ref.componentInstance.defaultQuery = [{column: 'center', value: ''}, {column: 'itemType', value: 'E'}, {column: 'vigencia', value: this.period}];
        ref.componentInstance.dataEntity = {
            enableAdd: false,
            customTitle: 'Validación Plan Anual de Adquisiciones'
        } as DataEntity;
    }
}

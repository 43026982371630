<div class="container">
  <div class="row" *ngIf="data">
    <div class="col-12">
      <div class="row justify-content-center">
        <h3>{{tittle}}</h3>
      </div>
    </div>
    <form class="col-12" [formGroup]="documentForm" (ngSubmit)="onSubmit(form)" #form="ngForm">
      <mat-form-field appearance="legacy" class="col-12 col-md-6">
        <mat-label>Tipo</mat-label>
        <mat-select placeholder="Seleccionar" formControlName="nature" required>
          <mat-option *ngFor="let doc of nature" [value]="doc.value">
            {{ doc.label}}
          </mat-option>
        </mat-select>
        <mat-error>
          El tipo de movimiento es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy" class="col-12 col-md-6">
        <mat-progress-bar *ngIf="loadingAccount" mode="indeterminate" class="over-head"></mat-progress-bar>
        <mat-label>Cuenta</mat-label>
        <input type="text"
               placeholder="Digitar o Seleccionar"
               matInput
               formControlName="account"
               #account
               [matAutocomplete]="auto" required (keyup.enter)="onEnterSearch($event.target, documentForm.get('account').value)">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onEnterSearch(account, $event.option.value)">
          <mat-option *ngFor="let doc of accountsObs | async" [value]="doc">
            <ng-container *ngTemplateOutlet="memberTemplate; context:{o: displaySearchCode(doc) }"></ng-container>
            <ng-template #memberTemplate let-acc="o">
              <b [matTooltip]="doc.description.toUpperCase()">{{ acc[0] }}</b>{{ acc[1] }} - {{ doc.description | titlecase}}
            </ng-template>
          </mat-option>
        </mat-autocomplete>
        <mat-slide-toggle matSuffix color="primary" class="act-select" (change)="onEnterSearch($event.checked, documentForm.get('account').value)" matTooltip="Mostrar cuentas de Reclasificación"></mat-slide-toggle>
        <button matSuffix class="act-select" mat-stroked-button
                type="button" color="primary" matTooltip="Si digitó la cuenta presione &crarr; (enter) par buscarla o puede seleccionarla con este botón" (click)="selectAccount()">
          <mat-icon>reorder</mat-icon>
        </button>
        <mat-error>
          La cuenta es <strong>requerida</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy" class="col-12">
        <mat-label>Tercero</mat-label>
        <input matInput formControlName="third" readonly required class="cursor-pointer" (click)="selectThird()">
        <button matSuffix class="mr-1 act-select" type="button" mat-stroked-button color="primary" matTooltip="Seleccionar tercero" (click)="selectThird()">
          <mat-icon>reorder</mat-icon>
        </button>
        <button matSuffix class="act-select" type="button" mat-stroked-button matTooltip="Cancelar seleccion de tercero" (click)="clearThird()" color="warn">
          <mat-icon>refresh</mat-icon>
        </button>
        <mat-error>
          El tercero es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy" class="col-12">
        <mat-label>Valor</mat-label>
        <mat-label matPrefix>$</mat-label>
        <input matInput min="0.001" formControlName="value" appCurrencyDirective (valueChange)="value = $event" required/>
        <mat-error>
          El valor es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy" class="col-12">
        <mat-label>Descripción</mat-label>
        <textarea matInput #description formControlName="description" required maxlength="250"></textarea>
        <mat-hint align="end">{{description.value?.length || 0}} / {{description.maxLength}}</mat-hint>
        <mat-error>
          La descripción es <strong>requerida</strong>
        </mat-error>
      </mat-form-field>
      <div class="col-12 mt-3">
        <div class="row justify-content-end">
          <button mat-raised-button mat-dialog-close>Cerrar</button>
          <button mat-raised-button type="submit" color="primary" [disabled]="this.loadingAccount || documentForm.invalid">Guardar</button>
        </div>
      </div>
    </form>
  </div>
</div>

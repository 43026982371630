import {Component, OnInit} from '@angular/core';
import {Attribute, Column, ColumnGroup, Type} from '@Components/table/table.component';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {AdfiService} from '@services/adfi-service';
import {Entities, PeriodState, Permission} from '@Interfaces/index';
import {AccountmanSignatureComponent} from '@Components/ui/accountman-signature/accountman-signature.component';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-budget-reserve-ledger',
  templateUrl: './budget-reserve-ledger.component.html',
  styleUrls: ['./budget-reserve-ledger.component.scss']
})
export class BudgetReserveLedgerComponent implements OnInit {

  columnsHeaderGroupAll: ColumnGroup[] = [
    {
      column: 'V_MINIPRESU',
      name: 'Rubro',
      type: Type.Number
    },
    {
      column: 'V_NOMBRE',
      name: '',
      hideGroupName: true,
      type: Type.String
    }
  ];

  columnsHeaderGroupAllConsolidate: ColumnGroup[] = [
    {
      column: 'ID_CC',
      name: 'Centro de Costo',
      type: Type.Number
    },
    {
      column: 'TOWN',
      name: '',
      hideGroupName: true,
      type: Type.String
    },
    {
      column: 'DE_DESCRIPCION',
      name: '',
      hideGroupName: true,
      type: Type.String
    },
      ...this.columnsHeaderGroupAll
  ];

  columnsReserve: Column[] = [
    {
      column: 'D_CREACION',
      name: 'Fecha',
      group: 'ID',
      type: Type.Date,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'DESCRIPTION',
      name: 'Objeto',
      group: 'ID',
      type: Type.String,
      attr: null
    },
    {
      column: 'THIRD',
      name: 'Beneficiario',
      group: 'ID',
      type: Type.String,
      attr: null
    },
    {
      column: 'VALUE_ORIGIN',
      name: 'Valor Total',
      group: 'ID',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'VALUE_PAID',
      name: 'Valor Pagado',
      group: 'ID',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'VALUE_RESERVE',
      name: 'Valor Reserva',
      group: 'ID',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'BALANCE_RESERVE',
      name: 'Valor Por Obligar',
      group: 'ID',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'PRORD_N_NUMORD',
      name: 'N° Orden de Pago',
      type: Type.Number,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'CREACION_OR',
      name: 'Fecha',
      type: Type.Date,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'PRORD_N_VLR_NETO',
      name: 'Valor neto',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'PRORD_VLR_IVA',
      name: 'Valor iva',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'N_VALOR',
      name: 'Valor Total',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'GIRO',
      name: 'Valor Giro',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'DESCU',
      name: 'Valor Descuento',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'VALUE_RETE_ICA',
      name: 'Ica',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'VALUE_RETE_FUENTE',
      name: 'Retefuente',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'VALUE_RETE_IVA',
      name: 'IVA',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'BALANCE_OP',
      name: 'Valor Por Pagar',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'N_NUMBER_PAYMENT',
      name: 'N° Medio de Pago',
      type: Type.Number,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'COCTA2_D_CREACION',
      name: 'Fecha de Pago',
      type: Type.Date,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'COCTA2_N_SALDO',
      name: 'Valor Pagado',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    }
  ];

  columnsCXP: Column[] = [
    {
      column: 'D_CREACION',
      name: 'Fecha',
      type: Type.Date,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'DESCRIPTION',
      name: 'Objeto',
      type: Type.String,
      attr: null
    },
    {
      column: 'THIRD',
      name: 'Beneficiario',
      type: Type.String,
      attr: null
    },
    {
      column: 'PRORD_N_VLR_NETO',
      name: 'Valor neto',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'PRORD_VLR_IVA',
      name: 'Valor iva',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'N_VALOR',
      name: 'Valor Total',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'GIRO',
      name: 'Valor Giro',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'DESCU',
      name: 'Valor Descuento',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'VALUE_RETE_ICA',
      name: 'Reteica',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'VALUE_RETE_FUENTE',
      name: 'Retefuente',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'VALUE_RETE_IVA',
      name: 'Reteiva',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'BALANCE_OP',
      name: 'Valor Por Pagar',
      group: 'ID',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'N_NUMBER_PAYMENT',
      name: 'N° Medio de Pago',
      type: Type.Number,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'COCTA2_D_CREACION',
      name: 'Fecha de Pago',
      type: Type.Date,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'COCTA2_N_SALDO',
      name: 'Valor Pagado',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    }
  ];

  data: any;

  permissionViewAll: boolean;
  generateMassive = false;
  typeBook: string;

  constructor(private loading: LoadingService,
              private adfiGrowlService: AdfiGrowlService,
              private service: AdfiService, private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.data.subscribe(data => {
      this.typeBook = data.type;
      this.loadData();
      if (this.typeBook === 'ReserveBook'){
        this.permissionViewAll = this.service.validPermission(Entities.RESERVE, Permission.VIEW_ALL, PeriodState.NO_STATUS);
      } else {
        this.permissionViewAll = this.service.validPermission(Entities.PAYMENT_ORDER_WP, Permission.VIEW_ALL, PeriodState.NO_STATUS);
      }
    });
  }

  loadData(changeState?) {
    if (changeState !== undefined) {
      this.generateMassive = changeState;
    }
    const next = (d) => {
      if (d && d.fileName) {
        this.data = JSON.parse(d.fileName);
        this.loading.hide();
      } else {
        this.adfiGrowlService.error('Información',
            'Se ha presentado un error al generar la consulta, por favor contacte a soporte técnico');
        this.loading.hide();
      }
    };
    this.service.getJson(this.typeBook, JSON.stringify({massive: this.generateMassive, type: 'json'}), 'fileName', next);
  }

  export(type= 'xlsx') {
    this.service.download(this.typeBook,  JSON.stringify({massive: this.generateMassive, type}),
        'fileName', 'application/' + type, ((this.typeBook === 'ReserveBook' ? 'Libro_reservas_' : 'Libro_cuentas_x_pagar_') +
        (this.generateMassive ? 'consolidado' : this.service.user.centroCosto.deDescripcion.replace(/\s/g, '_') )).toUpperCase() + '.' + type);
  }

}

<div class="container">
    <mat-card>
        <div class="row">
            <mat-form-field class="col-12 col-md-6">
                <mat-label>Capacitación</mat-label>
                <mat-select placeholder="Seleccionar" (selectionChange)="selectTraining($event.value)" [(value)]="selectedTraining">
                    <mat-option *ngFor="let training of trainingList" [value]="training">{{ training._id }}
                        {{ training.titleTraining }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-12 col-md-6">
                <mat-label>Horarios</mat-label>
                <mat-select placeholder="Seleccionar" (selectionChange)="selectSchedule($event.value)">
                    <mat-option *ngFor="let schedule of scheduleList" [value]="schedule">{{ schedule._id }}.
                        {{ schedule.startTown.nombre }} - {{schedule.endTown.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-12 col-md-6">
                <mat-label>Llamado de asistencia</mat-label>
                <mat-select placeholder="Seleccionar" (selectionChange)="selectCall($event.value)" [value]="selectedCall">
                    <mat-option *ngFor="let call of trainingCallList" [value]="call">{{ call.consecutive }}.
                        {{ call.dateCall | date:'dd/M/yy, h:mm a'}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="col-auto" *ngIf="selectedSchedule">
                <button mat-raised-button (click)="newCall()" color="primary">Agregar Llamado de Asistencia</button>
            </div>
        </div>
    </mat-card>

    <mat-card *ngIf="selectedCall">
        <div class="d-flex">
            <app-list #list class="w-100" entityName="Assistance" module="Trainings"
                      [customTitle]="'Listado llamado de asistencia '+ selectedCall.consecutive + ' '+
                       (selectedCall.dateCall | date:'dd/M/yy, h:mm a')" 
                      [defaultQuery]="[{column: 'callAsistance', value: selectedCall._id}]">
                <ng-template tableColumn="observation" let-row="row">
                    <ng-container *ngIf="row.observation else act">
                        {{row.observation}}
                        <button mat-icon-button
                                matTooltip="Editar Observación"
                                color="primary"
                                (click)="editObservation(row, list)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </ng-container>
                    <ng-template #act>
                        <div class="d-flex justify-content-center w-100">
                            <button mat-icon-button
                                    matTooltip="Especificar Razón"
                                    color="primary"
                                    (click)="editObservation(row, list)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </ng-template>
                    <ng-template tableColumn="reply" let-row="row">
                        <div class="d-flex w-100 justify-content-around">
                            <button *ngIf="row.reply === undefined || row.reply === null || row.reply === true" color="primary" mat-icon-button aria-label="Está presente" (click)="replyAssistance(row, true,list)">
                                <mat-icon>check_circle</mat-icon>
                            </button>

                            <button *ngIf="row.reply === undefined || row.reply === null || row.reply === false" color="warn" mat-icon-button aria-label="Está presente" (click)="replyAssistance(row, false, list)">
                                <mat-icon>highlight_off</mat-icon>
                            </button>

                        </div>
                    </ng-template>
                </ng-template>
            </app-list>
        </div>
    </mat-card>
</div>

<div role="group" class="month-input-container" [attr.aria-labelledby]="formField?.getLabelId()" [formGroup]="form" *ngIf="style === 'Dropdown' else sel">
    <mat-select formControlName="month" (selectionChange)="value = $event.value">
        <mat-option *ngFor="let m of months" [value]="m.month">{{m.name | titlecase}}</mat-option>
    </mat-select>
</div>
<ng-template #sel>
    <ng-container *ngIf="useFormControl else bas">
        <div role="group" class="month-input-container" [attr.aria-labelledby]="formField?.getLabelId()" [formGroup]="form">
            <input matInput [min]="startDate" [max]="endDate" [matDatepicker]="dp" #input
                   (keydown)="false" readonly [required]="required" formControlName="month" (click)="open()">
            <mat-datepicker #dp color="primary" startView="year" [calendarHeaderComponent]="exampleHeader"
                            (monthSelected)="monthSelected($event)">
            </mat-datepicker>
        </div>
    </ng-container>
    <ng-template #bas>
        <input class="size-zero" [min]="startDate" [max]="endDate" [matDatepicker]="dp" [value]="value" (dateChange)="dp.close();dateChange.emit($event.value)">
        <mat-datepicker #dp color="primary" startView="year" [calendarHeaderComponent]="exampleHeader"
                        (monthSelected)="monthSelected($event)">
        </mat-datepicker>
    </ng-template>
</ng-template>

import {AbstractControlOptions, AsyncValidatorFn, FormControl as FControl, ValidatorFn} from '@angular/forms';
import { EventEmitter, Output, Directive } from '@angular/core';
import {MatDatepickerInput} from "@angular/material/datepicker";

@Directive()
export class FormControl extends FControl {
    @Output() assignedNatives = new EventEmitter();
    picker: MatDatepickerInput<any>;
    nativeElement: any;
    constructor(formState?: any,
                validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
                asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super(formState, validatorOrOpts, asyncValidator);
    }
}

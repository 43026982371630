import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip/tooltip';

@Component({
  selector: 'line-menu',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineComponent {

  @HostBinding('class.own-menu-container') ownClass = true;

  @Input() content;
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() tooltip: string;
  @Input() showValue: boolean;
  @Input() tooltipPosition: TooltipPosition = 'before';
  optionsItems: any[];
  @Input() set options(options: any[]) {
    this.checks = {};
    this.optionsItems = options;
    const opt = Array.isArray(this.currentValue);
    if (this.currentValue) {
      for (let i = 0; i < this.optionsItems.length; i++) {
        if (opt) {
          for (const optKey of this.currentValue) {
            if (optKey === this.optionsItems[i]) {
              this.checks[i] = true;
            }
          }
        }
      }
    }
  }
  @Input() hamburger = false;
  private currentValue;
  @Input() set value(nv) {
    this.handleAction(undefined, nv);
  }
  get value() {
    return this.currentValue;
  }
  checked = false;
  checks: {};
  menuItemID = Math.random().toString(36).substring(2);
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @Input() toString: (item) => string = (item) => item;

  updateChecked(btn) {
    this.checked = btn.checked;
  }

  handleAction(location, item) {
    if (this.multiple) {
      if (item && Array.isArray(item)) {
        this.checks = {};
        this.currentValue = item;
      } else {
        this.currentValue = this.currentValue ?? [];
        this.checks = this.checks ?? {};
        const exist = this.currentValue.indexOf(item);
        if (exist === -1) {
          this.currentValue.push(item);
          this.checks[location] = true;
        } else {
          this.currentValue.splice(exist, 1);
          delete this.checks[location];
        }
      }
    } else {
      this.checks = {};
      this.currentValue = item;
    }
    if (location !== undefined) {
      this.selectionChange.next(this.currentValue);
    }
    this.valueChange.next(this.currentValue);
  }
}

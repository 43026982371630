<div class="container pb-5">
    <div class="row pt-2">
        <mat-card class="col-12 mb-2">
            <mat-card-title>Reportes de Mesa de Ayuda</mat-card-title>
            <mat-card-content>
                <mat-card>
                    <mat-card-title class="text-center">
                        Fechas seleccionadas
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row pt-2">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="row justify-content-center">
                                            <h3>Inicial: </h3><br>
                                            <h4 class="ml-2">
                                                <ng-container *ngIf="selectedDates">
                                                    {{ selectedDates.startDate | date: 'mediumDate':null:'es-CO'}}
                                                </ng-container>
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="row justify-content-center">
                                            <h3>Final: </h3> <br>
                                            <h4 class="ml-2">
                                                <ng-container *ngIf="selectedDates">
                                                    {{ selectedDates.endDate | date: 'mediumDate':null:'es-CO'}}
                                                </ng-container>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row d-flex justify-content-center">
                                    <button mat-raised-button color="primary"
                                            matTooltip="Seleccinar fechas" (click)="this.selectDates()">
                                        <mat-icon>calendar_today</mat-icon> Seleccionar fechas
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <div class="row">
                        <div class="col-12 col-md-4 mat-elevation-z5 offset-md-1 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color" (click)="exportReport('HelpDeskGlpi', 'Incidencias')">
                            <div class="flex-row">
                                <mat-icon class="icon" svgIcon="help-desk" inline="true" aria-hidden="false" aria-label="Descargar Informe Mesa Ayuda"></mat-icon>
                            </div>
                            <div class="flex-row">
                                <h4>Exportar a Excel Informe de Incidencias</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mat-elevation-z5 offset-md-2 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color" (click)="exportReport()">
                            <div class="flex-row">
                                <mat-icon class="icon" svgIcon="calls" inline="true" aria-hidden="false" aria-label="Descargar Llamadas De Mesa de Ayuda"></mat-icon>
                            </div>
                            <div class="flex-row">
                                <h4>Exportar a Excel Informe de Llamadas</h4>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </mat-card-content>
        </mat-card>
    </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavComponent } from './nav.component';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {HelpDeskModule} from '@Components/help-desk/help-desk.module';
import {UiModule} from '@Components/ui/ui.module';
import { MenuComponent } from './menu/menu.component';

@NgModule({
    declarations: [NavComponent, MenuComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatSidenavModule,
        FlexLayoutModule,
        MatListModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatExpansionModule,
        MatTooltipModule,
        HelpDeskModule,
        UiModule,
        MatFormFieldModule,
        MatSelectModule
    ],
})
export class NavModule { }

import {Component, Inject} from '@angular/core';
import {DialogData} from '@pages/public/training-form/training-form.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-data',
    templateUrl: './confirm-data.component.html',
    styleUrls: ['./confirm-data.component.css']
})
export class ConfirmDataComponent {

    allComplete = false;


    constructor(
        public dialogRef: MatDialogRef<ConfirmDataComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public dialog: MatDialog) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {

        const dialogRef = this.dialog.open(DialogConfirmEmailComponent, {
            data: {email: this.data.email}
        });
        dialogRef.afterClosed().subscribe(result => {
            this.dialogRef.close(result);
        });

    }

}

@Component({
    selector: 'app-dialog-confirm-email',
    templateUrl: 'dialog-confirm-email.html',
})
export class DialogConfirmEmailComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmEmailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

}

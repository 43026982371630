import { Component, OnInit } from '@angular/core';
import {AdfiService} from '@services/adfi-service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiRest} from '@services/adfi-rest';
import {AdfiUtil} from '@Components/util/adfi-util';
import {LoadingService} from '@services/loading.service';
import { DialogVideosComponent } from '@pages/help/dialog-videos/dialog-videos.component';

@Component({
  selector: 'app-trainings-videos',
  templateUrl: './trainings-videos.component.html',
  styleUrls: ['./trainings-videos.component.scss']
})
export class TrainingsVideosComponent implements OnInit {

  sections: any[];
  isHiddenStates: { [key: string]: boolean } = {}
    
  constructor(private service: AdfiService, private dialog: MatDialog, private sanitizer: DomSanitizer,
              private graphql: AdfiGraphqlService, private loading: LoadingService) {}

  ngOnInit(): void {
    this.loadSections();
    const urlParams = new URLSearchParams(window.location.search);
    const flag = urlParams.get('video');
    if(flag !== null){
      this.openVideo(flag);
    }
  }

  private async loadSections() {
    const params = '';
    const all = await this.graphql.countEntity('trainings', params);
    console.log(all);
    this.sections = [{label:'Jornadas de Trabajo Conjunto', image: "assets/images/settings.svg", videos:[{imageKey: "1bTi9n2P1FB0_9HkBW4n4yySaFf-nXy1g",videoKeyDrive: "1o4TjaM_B6CVLJG2mmaGsClN40dH3h18Z",}]}]
  }

  openViewVideo(section) {
    const dialogRef = this.dialog.open(DialogVideosComponent, {
      width: '67%',
      height: '80vh',
      data: { title: section.label, idVideo: section.videoKeyDrive }
    });
  }

  openVideo(video) {
    if(video =='PAA'){
      const dialogRef = this.dialog.open(DialogVideosComponent, {
        width: '67%',
        height: '80vh',
        data: { title: "Plan Anual de Adquisiciones", idVideo: "1kqpGQCaJjS3-cAdJta_6kYol-2LkkW81" }
      });
    }else {
      const dialogRef = this.dialog.open(DialogVideosComponent, {
        width: '67%',
        height: '80vh',
        data: { title: "Presupuesto Inicial", idVideo: "1EfPm3PI7yj9hmrMy1Q7rcP3IyccBV1YI" }
      });
    }
  }

  hiddeContent(section: any): void {
    const element = document.getElementById(section.moduleKey);
    const isHidden = element.classList.toggle('hidden');
    this.isHiddenStates[section.moduleKey] = isHidden;
  }
  
}

<mat-card>
    <mat-card-title>
        <div class="d-flex justify-content-between">
            <span style="padding-left: 16px">
                {{title}}
            </span>
            <div class="d-flex justify-content-end">

            <ng-content select=".actionsTable">
            </ng-content>
            <button mat-icon-button style="padding-right: 16px"
                    matTooltip="Actualizar"
                    (click)="reload.emit()"
                    [disabled]="this.loadingData || this.externalCondition">
                <mat-icon>update</mat-icon></button>
            </div>
        </div>
    </mat-card-title>
    <mat-card-content class="container-table-adf">
        <mat-progress-bar class="col-12" *ngIf="loadingData" mode="indeterminate"></mat-progress-bar>
        <div class="over-column" [style.left]="currentColSelected.left" [style.width]="currentColSelected.width"></div>
        <table mat-table [dataSource]="data">
            <ng-container *ngFor="let column of columnsConf; let colIndex = index">
                <ng-container matColumnDef="{{column.column}}">
                    <th mat-header-cell *matHeaderCellDef class="mat-header-cell {{ column.attr && column.attr.classHeader ? column.attr.classHeader: 'text-center'}}" [innerHTML]="column.dynamicName ? column.dynamicName(context, this) : column.name"></th>
                    <td mat-cell *matCellDef="let row"
                        (mouseenter)="checkColumnOver($event)"
                        (mouseleave)="currentColSelected.width = '0'"
                        [attr.rowspan]="row[CELL_ATTR] && row[CELL_ATTR][column.column] ? row[CELL_ATTR][column.column][ROWSPAN] : 1"
                        matTooltip="{{column.attr && column.attr.tooltip ? transformColumn(row, column) : ''}}"
                        class="{{row[CELL_ATTR] && row[CELL_ATTR][column.column] && row[CELL_ATTR][column.column][HIDE_CELL] ? 'd-none' : '' }} mat-cell {{column.attr && column.attr.classCell ? column.attr.classCell : ''}}">
                        {{transformColumn(row, column)}}
                    </td>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayCol; sticky: true"></tr>
            <tr mat-row class="mat-row {{row && row[ROW_DYNAMIC_CLASS] ? row[ROW_DYNAMIC_CLASS] : ''}}" *matRowDef="let row; columns: displayCol"></tr>
            <!-- Group header -->
            <ng-container matColumnDef="grouped">
                <td mat-cell colspan="999" class="mat-cell row-bold"  *matCellDef="let groupBy">{{groupBy.value}}</td>
            </ng-container>
            <tr mat-row class="mat-row" *matRowDef="let row; columns: ['grouped']; when: isGroup">
        </table>
        <ng-content select=".footer"></ng-content>
    </mat-card-content>
</mat-card>

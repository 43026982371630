import {Directive, ElementRef, HostListener, OnInit, Optional, Renderer2} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[appNumberDirective]'
})
export class NumberDirective implements OnInit {

    regex = /^-?\d+$/;
    constructor(private el: ElementRef, private renderer: Renderer2, @Optional() private control: NgControl) {
    }

    ngOnInit() {
        if (this.el.nativeElement.value && !this.regex.test(this.el.nativeElement.value)) {
            this.cleanData(this.el.nativeElement.value);
        }
        if (this.control) {
            this.control.valueChanges.subscribe((e) => {
                if (e && !this.regex.test(e)) {
                    this.cleanData(e);
                }
            });
        }
    }

    private cleanData(text) {
        if (this.control) {
            this.control.control.setValue(text.replace(/\D/g, ''));
        } else {
            this.renderer.setProperty(this.el.nativeElement, 'value', text.replace(/\D/g, ''));
        }
    }

    @HostListener('keypress', ['$event']) onInput(event: any) {
        const regex = /[0-9]/;
        let key = event.keyCode || event.which;
        key = String.fromCharCode(key);
        if (!regex.test(key)) {
            event.returnValue = false;
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        const text = event.clipboardData.getData('text/plain');
        if (!this.regex.test(text)) {
            event.returnValue = false;
            event.preventDefault();
            this.cleanData(text);
        }
    }
}

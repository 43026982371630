import {Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss']
})
export class SelectGroupComponent {

  group: string;

  constructor(public dialogRef: MatDialogRef<SelectGroupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.groups && data.groups.length > 0) {
      this.group = data.groups[0];
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

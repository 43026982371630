import { URL_SERVER } from '../config/constants';

export class AdfiRest {

  // Gets get

  public static get authenticate() {
    return URL_SERVER + '/login_check';
  }

  public static get logout(): string {
    return '/api/logout';
  }

  public static get userDetails() {
    return URL_SERVER + '/api/user-details';
  }

  public static get exportVCgn() {
    return URL_SERVER + '/export/cgn';
  }

  public static get exportVCgnInicial() {
    return URL_SERVER + '/export/cgn-inicial';
  }

  public static get exportVDatosProcesados() {
    return URL_SERVER + '/export/datos-procesados';
  }

  public static get getPrIniCntrl() {
    return URL_SERVER + '/api/adfib/pr_ini_cntrl';
  }

  public static get presupuestoIngresos() {
    return URL_SERVER + '/api/adfib/presupuesto/inigresos';
  }

  public static get presupuestoEgresos() {
    return URL_SERVER + '/api/adfib/presupuesto/egresos';
  }

  public static get mediaObject() {
    return URL_SERVER + '/api/media_objects';
  }

  public static get exportPresupuestoInicial() {
    return URL_SERVER + '/api/adfib/export/presupuesto-inicial';
  }

  public static exportMovimientosBancarios(cuenta: any) {
    return URL_SERVER + `/api/adfib/export/movimientos-bancarios/${cuenta._id}`;
  }

  public static exportPartidasConciliacion(cuenta: any) {
    return URL_SERVER + `/api/adfib/export/partidas-conciliacion/${cuenta._id}`;
  }

  public static exportCenCosUrl(period:any):string{
    return URL_SERVER + `/api/get/cencos/${period}`;
  }

  public static exportBudgetExecutionByCategoryUrl(id_cc:any, period:any, level:any):string{
    return URL_SERVER + `/api/get/income-expense-by-category/${id_cc}/${period}/${level}`;
  }

  public static exportBudgetExecutionFuncInve(id_cc:any, period:any):string{
    return URL_SERVER + `/api/get/active-pasive-3lvl/${id_cc}/${period}`;
  }

  public static exportCenCosInfoUrl(id_cc:any):string{
    return URL_SERVER + `/api/get/cencos-info/${id_cc}`;
  }

  static exportGeneralIncomesAndExpensesURL(year: number): string {
    return URL_SERVER + `/api/get/general-info-income-expense/${year}`;
  }

  static exportActivePassiveURL(period: number, n_id_cc: number): string {
    return URL_SERVER + `/api/get/active-pasive/${n_id_cc}/${period}`;
  }
  
  static exportAvailabilitiesURL(period: number, n_id_cc: number): string {
    return URL_SERVER + `/api/get/availabilities/${n_id_cc}/${period}`;
  }

  static exportHelpsURL(period: number): string {
    return URL_SERVER + `/api/get/helps/${period}`;
  }

  static exportMainItemsURL(period: number, n_id_cc: string): string {
    return URL_SERVER + `/api/get/mainItems/${n_id_cc}/${period}`;
  }

  static exportBankStatementValue(period: number, month: number, account:string): string {
    return URL_SERVER + `/api/get/statement/${period}/${month}/${account}`;
  }

  // Functions static

  public static recover(userEmail: string) {
    return URL_SERVER + `/recover-password/${userEmail}`;
  }

  public static cancelRecover(user: string) {
    return URL_SERVER + `/cancel-recover-password/${user}`;
  }

  public static saldoPreEit(anConseestru: string, avTipopres: 'I' | 'E') {
    return URL_SERVER + `/api/adfib/presupuesto/saldo-pre-eit/${anConseestru}/${avTipopres}`;
  }

  public static sumValorPresupuesto(tipoPres: 'I' | 'E') {
    return `/api/adfib/sum-presupuesto/${tipoPres}`;
  }

  public static sumValuePAA() {
    return `/api/adfib/sum-paa`;
  }

  public static validateAcquisitionDetails() {
    return `/api/adfib/validate-acquisition-detail`;
  }

  public static reportConciliacion(conciliacion, todos) {
    return URL_SERVER + '/api/adfib/export/conciliacion/' + conciliacion + '?todos=' + todos;
  }

  public static reportTraslado(acuerdo: any) {
    return URL_SERVER + '/api/adfib/export/traslado/' + acuerdo._id;
  }

  public static reportModificacion(acuerdo: any) {
    return URL_SERVER + '/api/adfib/export/modificacion/' + acuerdo._id;
  }
  public static reportRubros(tipoPres: 'I' | 'E') {
    return URL_SERVER + `/api/adfib/export/rubros/todos/${tipoPres}`;
  }
  public static reportDetalleRubro(rubro: any ) {
    return URL_SERVER + '/api/adfib/export/rubro/info/' + rubro._id;
  }
  public static reportRubrosTodos(tipoPres: 'I' | 'E') {
    return URL_SERVER + `/api/adfib/export/rubros/${tipoPres}`;
  }
  public static downloadMediaObject(mediaObject: number) {
    return URL_SERVER + `/api/adfib/download/media-object/${mediaObject}`;
  }

  public static reportDisponibilidad(disponibilidad: any ) {
    return URL_SERVER + '/api/adfib/export/disponibilidad/' + disponibilidad._id;
  }
  public static reportRegistro(registro: any) {
    return URL_SERVER + '/api/adfib/export/detalles/registro/' + registro._id;
  }
  public static reportOrdenPago( ordenPago: any) {
    return URL_SERVER + `/api/adfib/export/detalles/orden/pago/${ordenPago._id}`;
  }

  public static reportInfoActivos() {
    return URL_SERVER + `api/adfib/export/info/activos`;
  }

  public static reportXlsx() {
    return URL_SERVER + '/api/adfib/export/xls/report.xlsx';
  }

  public static getCallsHelpDesk() {
    return '/api/read/files/calls';
  }

  public static addAssetsArray() {
    const aux = `/api/carga/activos/array`
    return aux;
  }

  public static get searchTown() {
    return URL_SERVER + '/search-town';
  }

  public static get getCentersByTown() {
    return URL_SERVER + '/search-center-by-town';
  }

  public static get registerTraining() {
    return URL_SERVER + '/add/adfi/form';
  }

  public static get sendCustomMail() {
    return '/send/custom/mail';
  }

  public static get sendApprovedTrainer() {
    return '/send/adfi/mail';
  }

  public static get getConfigData() {
    return '/api/adfi/entity';
  }

  

    // utils

  /**
   * Exports csv
   */
  public static exportCsv(response, nameFile: string = 'file.csv') {
    const dataType = 'text/csv' || response.type;
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([response], { type: dataType }));
    downloadLink.setAttribute('download', nameFile);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }

  /**
   * Exports csv
   */
  public static exportFile(response, nameFile?: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([response]));
    document.body.appendChild(downloadLink);
    if (nameFile) {
      downloadLink.setAttribute('download', nameFile);
    }
    downloadLink.click();
    downloadLink.remove();
  }
}

import { Component, OnInit } from '@angular/core';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {DataEntity, ENUM_ACTIONS} from '@Interfaces/index';
import {FormComponent} from '@Components/ui/form/form.component';
import {MatDialog} from '@angular/material/dialog';
import {AdfiUtil} from '@Components/util/adfi-util';
import {FormGroup, Validators} from '@angular/forms';
import {FormControl} from '@Components/ui/util/FormControl';
import {MyTel} from '@Components/ui/tel-input/tel-input.component';
import {LoadingService} from '@services/loading.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {

  color = 'rgba(63,81,181, 0.45)';
  role: any;
  form: FormGroup;
  constructor(
      private graphqlService: AdfiGraphqlService,
      private growl: AdfiGrowlService,
      public adfiService: AdfiService,
      public loading: LoadingService,
      private alert: AdfiGrowlService,
      private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadRole();
    const [ area, isp, exchange, subscriber] =
        this.adfiService.user.phone ? this.adfiService.user.phone.split(',') : [null, null, null, null];
    this.form = new FormGroup({
      name: new FormControl(this.adfiService.user.name, [Validators.required]),
      lastname: new FormControl(this.adfiService.user.apellidos, [Validators.required]),
      document: new FormControl({ value: this.adfiService.user.document, disabled: true}),
      mail: new FormControl(this.adfiService.user.email),
      genre: new FormControl(this.adfiService.user.genre),
      phone: new FormControl(area ? new MyTel(area, isp, exchange, subscriber) : undefined)
    });

  }

  private loadRole(){
    const next = (f) => {
      this.role = f;
    };
    const error = (e) => {
      this.alert.errorMessage(e, 'Error al el estado actual del usuario');
    };
    this.graphqlService.getFirstEntity('roleByUsers', 'id fileDocument fileAppointment dateAppointment',
        `employee_id: ${this.adfiService.user.id}, not_null:{endDate:false}, cencos: ${this.adfiService.user.centroCosto._id}, role_id: ${this.adfiService.user.role._id}`,
        {next, error});
  }

  uploadDocument(group: string) {
    const dialogAdd = this.matDialog.open(FormComponent, {data: {
      entityName: 'RoleByUser',
      module: '',
      customTitle: 'Editar Archivo',
      item: this.role.id,
      group,
      action: ENUM_ACTIONS.EDIT,
      } as DataEntity, width: '70%'}
    );
    dialogAdd.afterClosed().subscribe(value => {
      if (value) {
        this.loadRole();
      }
    });
  }

  downloadFile(fileAppointment: string) {
    AdfiUtil.downloadMediaObject(this.role, {fieldName: fileAppointment, options: {bucket: 'files_active_directory', bucketNoVigencia: true }}, this.adfiService.user.period);
  }

  save() {
    this.loading.show();
    const attrs = {
      id: '/api/employees/' + this.adfiService.user.id,
      name: this.form.controls.name.value,
      apellidos: this.form.controls.lastname.value,
      //documento: this.form.controls.document.value,
      email: this.form.controls.mail.value,
      telefono: this.form.controls.phone.value ? this.form.controls.phone.value.toJson() : null,
      genre: this.form.controls.genre.value
    };
    this.graphqlService.updateEntity('Employee', attrs, '', { next: async (ans) => {
        this.growl.success('Actualizado', 'Datos actualizados correctamente');
        await this.adfiService.loadUser();
      },
      error: (err) => {
        this.growl.errorMessage(err);
        this.loading.hide();
      }
    });
  }
}

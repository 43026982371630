import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NavRoute} from 'src/app/utils/menu';
import {Entities, Pages, PeriodState, Permission} from '@Interfaces/index';
import {InformacionComplementariaComponent} from '@pages/cierre-vigencia/informacion-complementaria/informacion-complementaria.component';
import {FormRecursosBalanceComponent} from '@pages/cierre-vigencia/form-recursos-balance/form-recursos-balance.component';
import {EndPeriodAccountsComponent} from '@pages/cierre-vigencia/end-period-accounts/end-period-accounts.component';


export const RoutesClosePeriod: NavRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: Pages.COMPLEMENTARY_INFO
  },
  {
    path: Pages.COMPLEMENTARY_INFO,
    component: InformacionComplementariaComponent,
    entity: Entities.CLOSING_FORM,
    permission: Permission.ADD,
    periodSate: PeriodState.NO_STATUS
  },
  {
    path: Pages.END_PERIOD_ACCOUNTS,
    nav: { title: 'Cuentas bancarias resumen', icon: 'money'},
    component: EndPeriodAccountsComponent,
    entity: Entities.END_PERIOD_ACCOUNTS,
    permission: Permission.VIEW_ALL,
    periodSate: PeriodState.CLOSE
  },
  {
    path: Pages.FORM_BALANCE_ROESOURCES,
    nav: { title: 'Formulario Recursos de Balance', icon: 'article' },
    component: FormRecursosBalanceComponent,
    entity: Entities.BALANCE_RESOURCE,
    permission: Permission.ADD,
    periodSate: PeriodState.CLOSE
  }
];

@NgModule({
  imports: [RouterModule.forChild(RoutesClosePeriod)],
  exports: [RouterModule]
})
export class CierreVigenciaRoutingModule { }

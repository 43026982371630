import {ChangeDetectorRef, Component, Inject, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subject} from "rxjs";
import {MatCalendar} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from "@angular/material/core";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-calendar-header',
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .mat-datepicker-content .title {
      display: block;
      font-size: 120%;
      font-weight: 300;
      margin-block-start: 10%;
      margin-block-end: 10%;
      line-height: 110% !important
    }
    .mat-datepicker-content-touch .title {
      display: block;
      font-size: 160%;
      font-weight: 300;
      margin-block-start: 2.33em;
      margin-block-end: 2.33em;
      line-height: 110% !important
    }
  `],
  template: `<div class="col-12 text-center">
    <span class="title">Selecciona un Mes</span>
  </div>`
})
export class CalendarHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
      private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
      @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get currentDate(): D {
    return this._calendar.activeDate;
  }
}

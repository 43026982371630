import {Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-accountman-signature',
  templateUrl: './accountman-signature.component.html',
  styleUrls: ['./accountman-signature.component.scss']
})
export class AccountmanSignatureComponent {
  addSing = false;

  constructor( public dialogRef: MatDialogRef<AccountmanSignatureComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close({});
  }


  changeSelect($event: MatSelectChange) {
    if (!$event.value) {
      this.data = {};
    }
  }
}

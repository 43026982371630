import {Apollo, gql} from 'apollo-angular';
import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    QueryList,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {AdfiService} from '@services/adfi-service';
import {
    Action,
    DataEntity,
    ENUM_ACTIONS, LocationAction,
    Options,
    Permission,
    Property,
    PropertyTypes,
    TypeAction,
    TypeFilters
} from '@Interfaces/index';


import {map} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {DatePipe} from '@angular/common';
import {FormComponent} from '@Components/ui/form/form.component';
import {AdfiUtil} from '@Components/util/adfi-util';
import {CurrencyPipe} from '../../util/currency.pipe';
import {SelectionModel} from '@angular/cdk/collections';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {ColumnFilter, Filter} from '../util/Filter';
import * as moment from 'moment';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {LoadingService} from '@services/loading.service';
import {UIHelper} from '@Components/util/UIHelper';
import {DisplayItemComponent} from '@Components/ui/display-item/display-item.component';
import * as JSZip from 'jszip';
import {SelectGroupComponent} from '@Components/ui/select-group/select-group.component';
import {TableColumnDirective} from '@Components/ui/util/Utilities';
import {MonthPickerComponent} from '@Components/month-selector/month-picker/month-picker.component';
import {DateRangePickerComponent} from '@Components/date-range-selection/date-range-picker/date-range-picker.component';
import {NavRouteService} from '@services/nav-routing-service';
import {ValidatorFn} from '@angular/forms';
import {FormControl} from '@Components/ui/util/FormControl';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Router} from '@angular/router';
import {UIActionsHandler} from '@Components/util/UIActionsHandler';
import { DialogAssetsComponent } from '../dialog-assets/dialog-assets.component';


export enum Order {
    DESC = 'DESC',
    ASC = 'ASC'
}

export interface ColumnSort {
    name: string;
    active: boolean;
    order: Order;
}

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent extends Filter implements OnInit, AfterViewInit {

    @Input() url = '';
    @Input() locale = 'es-CO';
    @Input() format = 'mediumDate';
    @Input() selectable = false;
    @Input() noActions = false;
    @Input() customData;
    @Input() initialData;
    @Input() withPaginator = true;
    @Input() customRowClass = '';
    @Input() customTitle: string;
    @Input() exportableDocs = true;
    @Output() formShow = new EventEmitter<MatDialogRef<FormComponent>>();
    selection = new SelectionModel<any>(true, []);
    @ContentChildren(TableColumnDirective) customColumn: QueryList<TableColumnDirective>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Output() selectedEntity: EventEmitter<any> = new EventEmitter();
    @Output() loadedData: EventEmitter<any[]> = new EventEmitter();
    @Output() preLoadAdd = new EventEmitter<{column: Property, validators: ValidatorFn[], control: FormControl}>();
    @Output() reloadData: EventEmitter<boolean> = new EventEmitter();
    OPTION = Options;
    TYPES = PropertyTypes;
    ORDER = Order;
    FILTER = TypeFilters;

    data: any[] = [];
    displayColumns: string[];

    loadingData = true;
    page = 0;
    endCursor = '';
    startCursor = '';
    resultsLength = 10;

    sorts: ColumnSort[] = [];
    canAdd = false;
    canEdit = false;
    canDelete = false;
    canView = false;
    canPrint = false;

    downloadedFiles: number;
    total: number;
    generatingZip: boolean;
    percentageGenZip: string;

    displayAlerts = true;
    listTotals = {};

    constructor(@Optional() private dialogRef: MatDialogRef<ListComponent>,
                private apollo: Apollo,
                adfiService: AdfiService,
                private datePipe: DatePipe,
                public currency: CurrencyPipe,
                public dialog: MatDialog,
                public adfiGrowlService: AdfiGrowlService,
                private graphqlService: AdfiGraphqlService,
                private load: LoadingService,
                private navRouteService: NavRouteService,
                private navRouter: Router,
                private gaService: GoogleAnalyticsService) {
        super(adfiService);
    }

    ngOnInit() {
        this.loadListConfig();
    }

    ngAfterViewInit() {
        if (this.withPaginator) {
            this.paginator._intl.itemsPerPageLabel = 'Filas por página';
            this.paginator.page.subscribe((page: PageEvent) => {
                this.data = [];
                if (this.resultsLength !== page.pageSize) {
                    this.resultsLength = page.pageSize;
                    this.reload();
                } else if (this.page < page.pageIndex) {
                    this.loadData(this.endCursor, undefined, this.sorts.filter(s => s.active));
                } else {
                    this.loadData(null, this.startCursor, this.sorts.filter(s => s.active));
                }
                this.page = page.pageIndex;
            });
        }
    }

    reload() {
        this.displayAlerts = false;
        this.page = 0;
        if (this.paginator) {
            this.paginator.pageIndex = 0;
        }
        setTimeout(() => {
            this.loadFilterFromConfig();
            this.loadData(null, null, this.sorts.filter(s => s.active));
            this.displayAlerts = true;
        }, 200);
        this.reloadData.emit(true);
    }

    getColumnValue(row: any, property: Property) {
        return AdfiUtil.getColumnValue(row, property);
    }

    getTemplate(id: string): TemplateRef<any> {
        const c = this.customColumn ? this.customColumn.find((template: any) =>
            template.columnName === id) : null;
        return c ? c.template : null;
    }

    checkPermissions() {
        const periodState = this.configTable.entityProperty ? this.configTable.entityProperty.periodState : undefined;
        const permissions = this.configTable.entityProperty ? this.configTable.entityProperty.permissions : [];
        this.canAdd = permissions.indexOf(Permission.ADD) !== -1 &&
            this.adfiService.validPermission(this.configTable.entity, Permission.ADD, periodState);
        this.canEdit = permissions.indexOf(Permission.EDIT) !== -1 &&
            this.adfiService.validPermission(this.configTable.entity, Permission.EDIT, periodState);
        this.canDelete = permissions.indexOf(Permission.DELETE) !== -1 &&
            this.adfiService.validPermission(this.configTable.entity, Permission.DELETE, periodState);
        this.canView = this.adfiService.validPermission(this.configTable.entity, Permission.VIEW, periodState);
        this.canPrint = this.adfiService.validPermission(this.configTable.entity, Permission.PDF, periodState );
    }

    findColumnSort(fieldName: string) {
        return this.sorts && this.sorts.find(c => (c.name.indexOf('.') > 0 ? c.name.substr(0, c.name.indexOf('.')) : c.name) === fieldName);
    }

    activateSort(c: ColumnSort) {
        if (c.active) {
            if (c.order === Order.DESC) {
                c.order = Order.ASC;
            } else {
                c.active = false;
            }
        } else {
            c.order = Order.DESC;
            c.active = true;
        }
    }

    private loadListConfig() {
        this.loadingData = true;
        if (this.dataEntity) {
            if (this.dataEntity.customTitle) {
                this.customTitle = this.dataEntity.customTitle;
            }
            this.exportableDocs = this.dataEntity.exportableDocs !== undefined ? this.dataEntity.exportableDocs : true;
            if (this.dataEntity.defaultFilters && (!this._defaultQuery || !this._defaultQuery.length)) {
                this.defaultQuery = this.dataEntity.defaultFilters;
            }
            if (this.dataEntity.customFilters && (!this._customQuery || !this._customQuery.length)) {
                this.customQuery = this.dataEntity.customFilters;
            }
        }
        this.loadedConfig.subscribe(() => {
            if (this.initialData && this.configTable && this.configTable.name) {
                // @ts-ignore
                this.customTitle = this.customTitle ? this.customTitle.formatUnicorn(this.initialData) : this.configTable.name
                    // @ts-ignore
                    .formatUnicorn(this.initialData);
            }
            this.resultsLength = this.configTable && this.configTable.table &&
                                this.configTable.table.options &&
                                this.configTable.table.options[this.OPTION.ITEMS_PER_PAGE] ?
                this.configTable.table.options[this.OPTION.ITEMS_PER_PAGE] : this.resultsLength;
            if (this.configTable.filters.orderFilter) {
                if (Array.isArray(this.configTable.filters.orderFilter.properties)) {
                    this.sorts = this.configTable.filters.orderFilter.properties.map<ColumnSort>(p => {
                        return {name: p, active: false, order: Order.DESC} as ColumnSort;
                    });
                } else {
                    this.sorts = [];
                    for (const p of Object.keys(this.configTable.filters.orderFilter.properties)) {
                        const v = this.configTable.filters.orderFilter.properties[p];
                        const valid = v && v !== '';
                        this.sorts.push({
                            name: p,
                            active: valid,
                            order: valid ? this.configTable.filters.orderFilter.properties[p] : Order.DESC
                        } as ColumnSort);
                    }
                }
            }
            this.displayColumns = this.columns.filter(f => (!f.options[Options.FORCE_QUERY] || !f.options[Options.HIDDEN_LIST]))
                .map((prop: Property) => prop.fieldName);
            if (this.dataEntity && this.dataEntity.multiSelect !== undefined) {
                this.displayColumns = ['select'].concat(this.displayColumns);
            } else if (this.configTable.table.options && this.configTable.table.options[Options.ACTIONS_TABLE]
                && !this.noActions && (!this.dataEntity || !this.dataEntity.hiddenActionsTable)) {
                this.displayColumns.push(Options.ACTIONS_TABLE);
            }
            this.checkSortFilters();
            this.loadData(null, null, this.sorts.filter(s => s.active));
        }, (e) => {
            this.adfiGrowlService.errorMessage(e, 'Error obteniendo información');
            this.loadingData = false;
            this.dialogRef?.close();
        });
        this.loadConfig(true);
    }

    private getSum(value: Property) {
        const next = (d) => {
            this.listTotals[value.fieldName] = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        const className = this.module !== '' ? `${this.module}\\${this.entityName}` : `${this.entityName}`;
        this.graphqlService.createEntity('Summation', {
            nameClass: className,
            filters: JSON.stringify(this.getAllFilters()),
            actions: value.fieldName,
        }, 'total', {next, error});
    }

    private loadData(after?, before?, sort?) {
        this.loadingData = true;
        this.selection.clear();
        if (this.dataEntity && this.dataEntity.rows){
            this.data = this.dataEntity.rows;
            setTimeout(() => this.loadingData = false, 400);
            setTimeout(() => this.loadedData.emit(this.data), 500);
        } else {
            const ok = (list: any) => {
                subs.unsubscribe();
                if (!list || list.errors) {
                    this.loadingData = false;
                    this.adfiGrowlService.errorMessage({graphQLErrors: list?.errors}, 'Error al obtener la información del Sistema');
                    return;
                }
                if (this.withPaginator) {
                    this.paginator.length = list ? list.totalCount : 0;
                    if (this.configTable.table.options[Options.VIRTUAL]) {
                        this.data = list.edges.map((obj) => JSON.parse(obj.node.data));
                    } else {
                        this.data = list.edges.map((obj) => obj.node);
                    }
                } else {
                    this.data = list;
                }
                if (this.dataEntity && this.dataEntity.multiSelect && this.dataEntity.multiSelect.length) {
                    this.dataEntity.multiSelect.forEach(row => {
                        const f = this.data.find(d => d.id === row.id);
                        if (f) {
                            this.selection.select(f);
                        }
                    });
                }
                setTimeout(() => this.loadingData = false, 400);
                setTimeout(() => this.loadedData.emit(this.data), 500);
                if (this.withPaginator) {
                    this.endCursor = list.pageInfo.endCursor;
                    this.startCursor = list.pageInfo.startCursor;
                }
            };
            const err = (e) => {
                subs.unsubscribe();
                this.loadingData = false;
                this.adfiGrowlService.errorMessage(e, 'Error al obtener la información del Sistema');
            };
            const subs = this.apollo.query({
                query: this.getQuery(after, before, sort)
            }).pipe(
                map((p) => {
                    const key = this.configTable.table.options[Options.VIRTUAL] ? 'genericEntities' : this.configTable.plural;
                    return p.data && p.data[key] ? p.data[key] : p;
                }
                )
            ).subscribe(ok, err);
            this.columns.forEach(value => {
                if (value.options && value.options.total) {
                    this.getSum(value);
                }
            });
        }
        this.checkPermissions();
    }

    private getQuery(after?, before?, sort?: ColumnSort[]) {
        const pages = this.withPaginator ? 'first:' + this.resultsLength + (after ? `, after:"${after}"` : '') + (before ? `, before:"${before}"` : '') : '';
        const sorts = sort && sort.length ? `, order: {${sort.map((s, i) =>
            `${s.name.replace(/[.]/g, '_')}: "${s.order}"${(i + 1) === sort.length ? '' : ','}`)} }` : '';
        if (this.configTable.table.options[Options.VIRTUAL]){
            const objFil = this.generateFilters(true);
            // tslint:disable-next-line:no-shadowed-variable
            const filters = `, entity: "${this.entityName}", module: "${this.module}"${Object.keys(objFil).length ? ', data: """' + JSON.stringify(objFil) + '"""' : ''}`;
            // tslint:disable-next-line:no-shadowed-variable
            const header = pages + filters + sorts;
            return gql`query getList{
                genericEntities ${header ? `(${header})` : ''} {
                    ${pages ? 'edges{ node{' : ''}
                    id
                    data
            ${pages ? '} cursor } totalCount pageInfo{startCursor endCursor}' : ''}
        }
        }`;
        }
        const filters = this.generateFilters();
        const header = pages + filters + sorts;
        return gql`query getList{
            ${this.configTable.table.options[Options.VIRTUAL] ? 'genericEntities' : this.configTable.plural} ${header ? `(${header})` : ''} {
            ${pages ? 'edges{ node{' : ''}
                    id
                    ${this.configTable.table.options && this.configTable.table.options.notId ? '' : '_id'}
                    ${this.configTable.table.options &&
                    this.configTable.table.options[Options.CAN_DELETE] &&
                    this.columns.find(prop => (prop.fieldName === this.configTable.table.options[Options.CAN_DELETE])) === undefined
                    ? this.configTable.table.options[Options.CAN_DELETE] : ''}
                    ${this.configTable.table.options &&
                    this.configTable.table.options[Options.CAN_EDIT] &&
                    this.columns.find(prop => (prop.fieldName === this.configTable.table.options[Options.CAN_EDIT])) === undefined
                    ? this.configTable.table.options[Options.CAN_EDIT] : ''}
                    ${AdfiUtil.getBasicFieldsGraphql(this.columns)}
                    ${AdfiUtil.getFieldManyToOne(this.columns)}
                    ${AdfiUtil.getFieldOneToMany(this.columns)}
                    ${AdfiUtil.getFieldManyToMany(this.columns)}
                    ${AdfiUtil.getFieldOneToOne(this.columns)}
            ${pages ? '} cursor } totalCount pageInfo{startCursor endCursor}' : ''}
        }
        }`;
    }

    addItem() {
        if (this.dataEntity && this.dataEntity.groupsOnAdd !== undefined) {
            const dialogRef = this.dialog.open(SelectGroupComponent, {
                data: {
                    groups: this.dataEntity.groupsOnAdd
                },
                maxHeight: '90vh',
                minWidth: '40%'
            });
            dialogRef.afterClosed().subscribe((data) => {
                if (data) {
                    this.addItemFromGroup(data);
                }
            });
        } else {
            this.addItemFromGroup();
        }
    }

    openFormAdd(dataEntity: DataEntity = {}) {
        this.gaService.event('Abrir Dialogo Añadir', 'ADFI', this.entityName, this.adfiService.user.id);
        const dialogRef = UIHelper.createItemEntity(this.dialog, dataEntity);
        dialogRef.componentInstance.preLoad.subscribe((col) => {
            this.preLoadAdd.emit(col);
        });
        this.formShow.emit(dialogRef);
        if (this.configTable.table.options[this.OPTION.VIEW_AFTER_ADD]){
            dialogRef.componentInstance.postSave.subscribe((row) => {
                this.viewItem(row);
            });
        }
        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.reload();
            }
        });
    }

    suggestionWasClicked(clickedEntry?: any): void {
        this.gaService.event('Seleccionar Item', 'ADFI',
            this.entityName, this.adfiService.user.id);
        if (this.dataEntity && this.dataEntity.multiSelect !== undefined && clickedEntry) {
           this.selection.select(clickedEntry);
        } else if (clickedEntry) {
            this.selectedEntity.emit(clickedEntry);
        } else {
            this.selectedEntity.emit(this.selection.selected);
        }
    }

    addItemFromGroup(group?) {
        const entity: DataEntity = this.dataEntity ? {...this.dataEntity} : {} as DataEntity;
        entity.entityName = this.entityName;
        entity.module = this.module;
        entity.action = ENUM_ACTIONS.CREATE;
        entity.customData = this.customData;
        if (group && group.customData) {
            entity.customData = {...entity.customData, ...group.customData};
        }
        if (group && group.group) {
            entity.group = group.group;
        }
        this.openFormAdd(entity);
    }

    editItem(row: any) {
        let groupEdit = this.configTable.table?.options?.groupsOnEdit;
        groupEdit = !groupEdit ? this.dataEntity?.groupsOnEdit : groupEdit;
        if (groupEdit) {
            const dialogRef = this.dialog.open(SelectGroupComponent, {
                data: {
                    groups: groupEdit
                },
                maxHeight: '90vh',
                minWidth: '40%'
            });
            dialogRef.afterClosed().subscribe((data) => {
                if (data) {
                    this.editItemFromGroup(row, data.group);
                }
            });
        } else {
            this.editItemFromGroup(row);
        }
    }

    editItemFromGroup(row: any, pGroup = null) {
        this.gaService.event('Editar', 'ADFI', this.entityName, this.adfiService.user.id);
        const group = !pGroup ? (this.dataEntity ? this.dataEntity.editGroup : undefined) : pGroup;
        const dialogRef = UIHelper.editItem(this.dialog, row.id, this.entityName, this.module, this.customData, group);
        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.reload();
            }
        });
    }

    viewItem(row: any) {
        this.gaService.event('Ver Item', 'ADFI',
            this.entityName, this.adfiService.user.id);
        if (this.configTable && this.configTable.table && this.configTable.table.options
            && this.configTable.table.options[Options.VIEW_DETAIL_ITEM]) {
            const id = /[^/]*$/.exec(row.id)[0];
            this.navRouteService.navigate( (
                this.configTable.path ?
                    `list/${this.configTable.path}/` :
                    './'
            ) + id);
        } else {
            UIHelper.openDialog(DisplayItemComponent, this.dialog, {
                entityName: this.entityName,
                module: this.module,
                group: this.dataEntity ? this.dataEntity.displayGroup : undefined
            } as DataEntity, ENUM_ACTIONS.VIEW, row.id);
        }
    }

    getAllFilters() {
        const filters = this.generateFilters(true);
        if (this.configTable.filters.orderFilter) {
            const sortsList = this.sorts.filter(s => s.active);
            filters[TypeFilters.ORDER] = {class: this.configTable.filters.orderFilter.filterClass, value: {}};
            sortsList.forEach(value => {
                filters[TypeFilters.ORDER].value[value.name.replace(/[.]/g, '_')] = value.order;
            });
        }
        return filters;
    }

    downloadMediaObject(mediaObject, property: Property) {
        this.gaService.event('Decargar Archivo', 'ADFI',
            this.entityName, this.adfiService.user.id);
        AdfiUtil.downloadMediaObject(mediaObject, property, this.adfiService.user.period);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.data.forEach(row => this.selection.select(row));
    }

    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    exportReport(format: string) {
        const className = this.module !== '' ? `${this.module}\\${this.entityName}` : `${this.entityName}`;
        this.gaService.event('Generar Reporte Lista', 'ADFI',
            this.entityName + ' ' + format, this.adfiService.user.id);
        this.adfiService.download(
            className,
            JSON.stringify(this.getAllFilters()),
            'fileName',
            `application/${format}`,
             this.getFileName() + `.${format}`,
            null, null, this.group);
    }

    getFileName(){
        if (this.configTable.table.options.nameFileNoCencos) {
            return `${((this.dataEntity && this.dataEntity.customTitle ? this.dataEntity.customTitle : (this.configTable.table.options && this.configTable.table.options.name ? this.configTable.table.options.name : this.configTable.table.name)) + '_' + ((new Date().toJSON().slice(0, 10)))).replace(/\s/g, '_')}`;
        }
        return `${((this.dataEntity && this.dataEntity.customTitle ? this.dataEntity.customTitle : (this.configTable.table.options && this.configTable.table.options.name ? this.configTable.table.options.name : this.configTable.table.name)) + '_' + this.adfiService.user.centroCosto._id + '_' + this.adfiService.user.centroCosto.deDescripcion + '_' + ((new Date().toJSON().slice(0, 10)))).replace(/\s/g, '_')}`;
    }

    updateOptionFilters(fieldName, option, type: TypeFilters, index = -1) {
        if (this._filters.has(fieldName)) {
            switch (type) {
                case TypeFilters.RANGE:
                    const current = this._filters.get(fieldName).option;
                    if (current && current.length && current[0] === 'between' &&
                        this._filters.get(fieldName).data && current[0] !== option.value) {
                        this._filters.get(fieldName).data = this._filters.get(fieldName).data.slice(0, 1);
                    }
                    this._filters.get(fieldName).option = [option.value];
                    break;
                case TypeFilters.DATE:
                    if (!this._filters.get(fieldName).option) {
                        this._filters.get(fieldName).option = ['', ''];
                    }
                    this._filters.get(fieldName).option[index] = option.value;
                    break;
            }
        } else {
            switch (type) {
                case TypeFilters.RANGE:
                    this._filters.set(fieldName, {data: option.value === 'between' ? [] : '', option: option.value, type} as ColumnFilter);
                    break;
                case TypeFilters.DATE:
                    this._filters.set(fieldName, {
                        data: [],
                        option: [index === 0 ? option.value : '', index === 1 ? option.value : ''],
                        type
                    } as ColumnFilter);
                    break;
            }
        }
    }

    updateFilters(fieldName, data, index = -1) {
        if (this._filters.has(fieldName)) {
            if (index >= 0) {
                const filterV = this._filters.get(fieldName);
                let val = data.value;
                if (data.value instanceof moment) {
                    if (index > 0) {
                        if (!filterV.option || !filterV.option[index] || filterV.option[index] !== this.dateOptions2[index].key) {
                            data.value.set({hour: 23, minute: 59, second: 59});
                        } else {
                            data.value.set({hour: 0, minute: 0, second: 0});
                        }
                    }
                    val = data.value.toISOString(true);
                }
                if (!filterV.data) {
                    filterV.data = [];
                }
                if (filterV.data[index]) {
                    filterV.data[index] = val;
                } else {
                    filterV.data.splice(index, 1, val);
                }
            } else {
                this._filters.get(fieldName).data = data.value;
            }
        } else {
            console.log('error filter unKnow');
        }
    }

    updateDefaultFilters(column: string, value: any){
        if (!this._defaultQuery) {
            this._defaultQuery  = [];
        }
        this._defaultQuery.push({column, value});
    }

    deleteItem(row) {
        const next = () => {
            this.load.hide();
            this.adfiGrowlService.success('Eliminado Correctamente', 'Registro eliminado correctamente');
            this.reload();
        };
        const error = (value) => {
            this.load.hide();
            this.adfiGrowlService.errorMessage(value);
        };
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                action: ENUM_ACTIONS.DELETE,
                title: 'Eliminar Registro',
                content: '<p>¿Usted desea <strong>eliminar</strong> este registro?</p>'
            } as ConfirmDialogData,
            maxHeight: '90vh'
        });
        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.load.show();
                this.graphqlService.deleteEntity(AdfiUtil.capitalizeFirstLetter(this.configTable.entity), row.id, {next, error});
            }
        });
    }

    getDataOneToMany(row, property: Property) {
        return AdfiUtil.getDataOneToMany(row, property, true);
    }

    transformColumnValue(col, obj, property: Property, index) {
        return AdfiUtil.transformColumnValue(AdfiUtil.extractSubfields(obj, col),
            property.customFormatDisplay && property.customFormatDisplay[index] ? property.customFormatDisplay[index] : null,
            obj, property
        );
    }

    async download(fileAttr: Property) {
        this.gaService.event('Descarga Documentos ZIP', 'ADFI', this.entityName, this.adfiService.user.id);
        this.load.show();
        const filters = this.generateFilters() as any;
        let bucket = '';
        if (!fileAttr || !fileAttr.options[Options.BUCKET]) {
            this.adfiGrowlService.error('Error', 'Doesn\'t exist attribute for file in accounts or unknown bucket');
            return;
        } else {
            bucket = fileAttr.options[Options.BUCKET];
        }
        const all = await this.graphqlService.countEntity(this.configTable.plural, filters);
        let periodParam = '';
        if( fileAttr.options && fileAttr.options[Options.BUCKET_NO_VIGENCIA]) {
            periodParam = '';
        } else if(fileAttr.options && fileAttr.options[Options.BUCKET_NEXT_VIGENCIA]) {
            periodParam = `_${this.adfiService.user.period + 1}`;
        } else {
            periodParam = `_${this.adfiService.user.period}`;
        }

        const params = {responseType: 'blob', origin: location.origin};
        const next = async (pres) => {
            const zip = new JSZip();
            this.total = pres.length;
            this.downloadedFiles = 0;
            for (const p of pres) {
                if (p[fileAttr.fieldName]) {
                    // when the file is in a custom bucket we need to add the period to the url from item
                    if(fileAttr.options && fileAttr.options[Options.BUCKET_CUSTOM_VIGENCIA] && p[fileAttr.options[Options.BUCKET_CUSTOM_VIGENCIA]]){
                        periodParam = `_${p[fileAttr.options[Options.BUCKET_CUSTOM_VIGENCIA]]}`;
                    }
                    const url = `https://storage.googleapis.com/${bucket}${periodParam}/`;
                    // @ts-ignore
                    const binary = await this.adfiService.http.get<any>(`${url}${p[fileAttr.fieldName]}?ignoreCache=1`, params)
                        .toPromise();
                    const dir = AdfiUtil.getDirFile(fileAttr, p);
                    zip.file(`${dir}` + (typeof p[fileAttr.display[0]] === 'string' ? p[fileAttr.display[0]] : p[fileAttr.display[0]][0]) + '.pdf', binary, {binary: true});
                    this.downloadedFiles++;
                }
            }
            this.downloadedFiles = 0;
            this.generatingZip = true;
            zip.generateAsync({type: 'blob'}, (metadata)=> {
                this.percentageGenZip = metadata?.percent?.toFixed(0);
            }).then((blob) => {
                const dataType = 'application/x-zip-compressed';
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: dataType}));
                const filterParam = filters.match(/(?<=:).*?(?=,|$)/gm);
                downloadLink.setAttribute('download', (fileAttr.label.length <= 25 ?
                    fileAttr.label : fileAttr.label.substr(0, 22) + '...') + `(${filterParam ? filterParam : ''}).zip`);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                this.generatingZip = false;
                this.load.hide();
            }, e => {
                this.load.hide();
                this.adfiGrowlService.errorMessage(e);
            });
        };
        const error = (e) => {
            this.load.hide();
            this.adfiGrowlService.errorMessage(e);
        };
        const tree = {};
        const folders = fileAttr.options[Options.FOLDERS] ? fileAttr.options[Options.FOLDERS] : [];
        folders.forEach(folder => {
            const subfields = folder.split('.');
            AdfiUtil.addToTree(tree, subfields);
        });
        if (all && all > 0) {
            // generate query graphql for data to create folders
            const query = AdfiUtil.treeToStr(tree);
            this.graphqlService.getEntity(this.configTable.plural, `${query}  ${fileAttr.fieldName}  ${fileAttr.display[0]} ${fileAttr.options[Options.BUCKET_CUSTOM_VIGENCIA] ?? ''}`,
                all, filters, {next, error});
        } else {
            this.load.hide();
            this.adfiGrowlService.warning('Advertencia', 'Sin archivos para descargar');
        }
    }

    printItem(row) {
        this.gaService.event('Generar PDF', 'ADFI',
            this.entityName, this.adfiService.user.id);
        this.adfiService.download(this.module !== '' ? `${this.module}\\${this.entityName}` : `${this.entityName}`,
            JSON.stringify({id: row._id}), 'fileName', 'application/pdf',
            (this.customTitle ? this.customTitle : this.configTable.name)
                .replace(/\s/g, '_')  + '.pdf',  'printItem');
    }

    handleCustomActions(item: Action, monthComponent: MonthPickerComponent, rangePicker: DateRangePickerComponent, row?) {
        if ([TypeAction.GENERATE_FILE, TypeAction.DOWNLOAD_FILE].includes(item.type)) {
            if (item.type === TypeAction.DOWNLOAD_FILE) {
                this.downloadMediaObject(row, this.columns.find(c => c.fieldName === item.properties[Options.FIELD_NAME]));
            } else {
                UIActionsHandler.handleFilesActions(this.adfiService, row,
                    this.module !== '' ? `${this.module}\\${this.entityName}` : `${this.entityName}`,
                    JSON.stringify(row ? {id: row._id ?? row.ID } : this.getAllFilters()),
                    this.getFileName(),
                    item, this.group
                );
            }
        } else {
            UIActionsHandler.handleCustomActions(item, monthComponent, rangePicker, this.adfiGrowlService,
                this.dialog, this.load, this.dataEntity, this.graphqlService,
                this.getAllFilters(), this, row, this.navRouter, this.adfiService);
        }
    }

    showItem(item: any, property: Property) {
        UIHelper.openDialog(DisplayItemComponent, this.dialog, {
            entityName: property.column.entity,
            module: property.column.module,
            group: property.options[Options.DISPLAY_GROUP]
        } as DataEntity, ENUM_ACTIONS.VIEW, item.id);
    }

    getValueCellphone(itemElement: null|string) {
        return itemElement ? itemElement.split(',') : null;
    }

    checkSortFilters() {
        if (this._customQuery) {
            const sort = this._customQuery.find(cq => cq.column === 'sort');
            if (sort && Array.isArray(sort.value)) {
                for (const s of sort.value) {
                    const c = this.findColumnSort(s.column);
                    if (c && !c.active && [Order.ASC, Order.DESC].includes(s.value)) {
                        c.order = s.value;
                        c.active = true;
                    }
                }
            }
        }
    }
}

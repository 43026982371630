import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, Injectable, Input } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
} from '@angular/material/tree';
import { AdfiService } from '@services/adfi-service';
import { LoadingService } from '@services/loading.service';
import { BehaviorSubject } from 'rxjs';
import {AdfiGrowlService} from '@services/adfi-growl.service';

export class AccountInfoNode {
    id: string;
    account: string;
    description: string;
    children: AccountInfoNode[];
}

export class AccountInfoFlatNode {
    id: string;
    account: string;
    description: string;

    level: number;
    expandable: boolean;
}

@Injectable()
export class AccountDatabase {
    dataChange = new BehaviorSubject<AccountInfoNode[]>([]);

    get data(): AccountInfoNode[] {
        return this.dataChange.value;
    }

    constructor(
        private adfiService: AdfiService,
        private loading: LoadingService
    ) {}

    searchAccounts(description: string, type: 'acc' | 'id') {
        this.loading.show();
        this.adfiService.getJson(
            'unspscAccounts',
            JSON.stringify({
                description,
                type
            }),
            'fileName',
            (response: any) => {
                if (response.fileName) {
                    const respArr = JSON.parse(response.fileName);
                    this.dataChange.next(respArr);
                }
                this.loading.hide();
            }
        );
    }
}

@Component({
    selector: 'app-add-unspsc-accounts-dialog',
    templateUrl: './add-unspsc-accounts-dialog.component.html',
    styleUrls: ['./add-unspsc-accounts-dialog.component.scss'],
    providers: [AccountDatabase],
})
export class AddUnspscAccountsDialogComponent {
    flatNodeMap = new Map<AccountInfoFlatNode, AccountInfoNode>();
    nestedNodeMap = new Map<AccountInfoNode, AccountInfoFlatNode>();

    treeControl: FlatTreeControl<AccountInfoFlatNode>;
    treeFlattener: MatTreeFlattener<AccountInfoNode, AccountInfoFlatNode>;
    dataSource: MatTreeFlatDataSource<AccountInfoNode, AccountInfoFlatNode>;

    checklistSelection = new SelectionModel<AccountInfoFlatNode>(true);

    @Input() public searchDescription: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { accounts: any[] },
        private dialogRef: MatDialogRef<AddUnspscAccountsDialogComponent>,
        private adfiGrowlService: AdfiGrowlService,
        private database: AccountDatabase
    ) {
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );
        this.treeControl = new FlatTreeControl<AccountInfoFlatNode>(
            this.getLevel,
            this.isExpandable
        );
        this.dataSource = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );
        database.dataChange.subscribe((d) => {
            this.dataSource.data = d;
        });
        this.database.searchAccounts('', 'acc');
    }

    getLevel = (node: AccountInfoFlatNode) => node.level;
    isExpandable = (node: AccountInfoFlatNode) => node.expandable;
    getChildren = (node: AccountInfoNode): AccountInfoNode[] => node.children;
    hasChild = (_: number, nodeData: AccountInfoFlatNode) =>
        nodeData.expandable

    hasNoContent = (_: number, nodeData: AccountInfoFlatNode) =>
        nodeData.account === ''

    transformer = (node: AccountInfoNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.account === node.account
                ? existingNode
                : new AccountInfoFlatNode();

        flatNode.id = node.id;
        flatNode.account = node.account;
        flatNode.description = node.description;
        flatNode.level = level;
        flatNode.expandable = !!node.children?.length;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);

        return flatNode;
    }

    public searchAccountByDescription(type: 'acc' | 'id') {
        if (this.searchDescription && this.searchDescription.trim().length > 1) {
            this.database.searchAccounts(this.searchDescription, !type ? 'acc' : type);
        } else {
            this.adfiGrowlService.warning('Alerta', 'Por favor ingrese un valor a buscar de al menos 4 caracteres');
        }
    }

    accountToAdd() {
        return this.checklistSelection.selected.filter(
            (item) => !item.expandable
        );
    }

    updateAccounts() {
        let selection: any[] = this.accountToAdd();
        if (this.data && this.data.accounts){
            selection = [...selection, ...this.data.accounts.map(i => ({id: i.unspscAccount._id, account: i.unspscAccount.account}))];
        }
        this.dialogRef.close(selection.map(i => ({id: i.id, account: i.account})));
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: AccountInfoFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child);
            });
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: AccountInfoFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) =>
            this.checklistSelection.isSelected(child)
        );
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: AccountInfoFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.forEach((child) =>
            this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: AccountInfoFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: AccountInfoFlatNode): void {
        let parent: AccountInfoFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: AccountInfoFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child);
            });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: AccountInfoFlatNode): AccountInfoFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    remove(index: any) {
        this.data.accounts.splice(index, 1);
    }
}

<mat-icon *ngIf="!noShowIcon"  [class]="bell ? 'icon-notify notify' : 'icon-notify'" [matMenuTriggerFor]="menu" aria-label="Notificaciones"
          matTooltip="Notificaciones" matTooltipPosition="before"
          [matBadge]="total"
          matBadgeColor="warn"
          [matBadgeHidden]="total === 0">
    notifications
</mat-icon>
<mat-menu #menu="matMenu" xPosition="before" class="notify-list">
    <div *ngFor="let notification of notificationSaved | keyvalue" class="notification" [ngClass]="className(notification.value)">
        <ng-container *ngTemplateOutlet="notificationSave;context:{notification:notification.value}"></ng-container>
    </div>
    <div class="d-flex flex-column p-2" *ngIf="notificationSaved.size !== 0; else emptyNotify">
        <button *ngIf="notificationSaved.size < total" mat-stroked-button type="button" color="primary" routerLink="/nav/list/Y3RodjhBTT0%3D/Y3RodjhBTT0%3D">Ver todas</button>
    </div>
    <ng-template #emptyNotify>
        <div class="d-flex flex-column p-2">
            No hay notificaciones
        </div>
    </ng-template>
</mat-menu>

<ng-template #notificationSave let-notification="notification">
    <div (click)="$event.stopPropagation()" class="p-1 notification-save" [ngClass]="className(notification)">
        <div (click)="$event.stopPropagation()" class="title d-flex justify-content-between align-content-center">
            <div (click)="$event.stopPropagation()" class="d-flex align-items-center">{{notification.title}}</div>
            <button [disabled]="notification.loading" (click)="$event.stopPropagation()" mat-icon-button
                    (click)="delete(notification)"
                    style="color: #4188ef"
                    matTooltip="Marcar como visto"
                    matTooltipPosition="before">
                <mat-icon style="font-size: 20px">highlight_off</mat-icon>
            </button>
        </div>
        <div style="cursor: pointer" (click)="$event.stopPropagation()" [matTooltip]="notification.message"
             class="message line-clamp">{{notification.message}}</div>
        <mat-progress-bar  *ngIf="notification.loading" mode="indeterminate"></mat-progress-bar>
    </div>
</ng-template>

<ng-template cdk-portal #notifyTemplate="cdkPortal">
    <div class="notifications">
        <div *ngFor="let notification of notifications" class="notification" [ngClass]="className(notification)">
            <ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #notificationTpl let-notification="notification">
    <div class="title d-flex justify-content-between align-content-center">
        <div class="d-flex align-items-center">{{notification.title}}</div>
        <button mat-icon-button
                (click)="deleteNotifyLive(notification)"
                matTooltip="Marcar como visto"
                style="color: darkgray"
                matTooltipPosition="before">
            <mat-icon>highlight_off</mat-icon>
        </button>
    </div>
    <div class="px-2" [class]="getClassProgress(notification)">
        <mat-progress-bar mode="determinate"
                          [value]="(notification.timeoutDestroy*100)/(notification.timeout)"></mat-progress-bar>
    </div>
    <div class="message">{{notification.message}}</div>

</ng-template>

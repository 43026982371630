<mat-card>
    <ng-container *ngIf="training;else notraining">
        <div class="d-flex justify-content-center align-items-center flex-column">
            <h3>{{training?.titleTraining}}</h3>
            <p>Numero de Contacto Información Capacitación: {{training.contactNumber}}</p>
        </div>
        <ng-container *ngIf="!reservation && training.canRegister; else created">
            <template #tem_RegisterForm></template>
            <div class="d-flex justify-content-center pb-5">
                <button color="primary" *ngIf="registerForm?.entityForm" mat-raised-button
                        [disabled]="registerForm.entityForm.invalid" (click)="register()">{{textBtn}}</button>
            </div>
        </ng-container>
        <ng-template #created>
            <div class="p-3" *ngIf="reservation && reservation?.numberClickToken == 0; else confirmed">
                <div class="row">
                    <div class="col-md-3 d-none d-md-flex">
                        <img src="https://api.boyaca.adfi.com.co/assets/images/42461515145286634.png" alt="">
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="align-items-center">
                            <div>
                                Para completar el proceso solo te falta ingresar al correo:
                            </div>
                            <div>
                                <h4>{{reservation?.personalEmail}}</h4>
                            </div>
                            <div>
                                En el cual encontrará un correo con los pasos a seguir para terminar el proceso de
                                inscripción.
                            </div>
                        </div>
                        <br>
                        <div class="align-items-center">
                            <div>
                                <strong>Tenga en Cuenta las Siguientes Recomendaciones:</strong>
                            </div>
                        </div>
                        <div class="align-items-start">
                            <ul>
                                <li class="text-left"
                                    *ngFor="let rec of this.training.recomendations.edges">{{rec.node.textRecomendation}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #confirmed>
            <div class="p-5 row" *ngIf="reservation; else noRegister">
                <ng-container *ngIf="!reservation.canUploadAct; else upload_act">
                    <div class="col-12 col-md-6" >
                        <div class="text-justify">
                            El link que se le proporcionará para la sesión es para uso exclusivamente personal, no se
                            aceptarán correos diferentes a los inscritos en el formulario.
                        </div>
                        <p>El {{reservation.schedule.startDate | date:'dd-MM-yyyy' }}, antes de
                            las {{reservation.schedule.startDate | date:'shortTime' }} Ingrese a la capacitación en Google
                            Meet
                            a través del siguiente botón.</p>
                        <div class="d-flex justify-content-center">
                            <a mat-raised-button color="primary" target="_blank"
                               [href]="reservation.schedule.linkSession">Acceder</a>
                        </div>
                        <br>
                        <p class="text-center">O en el siguiente Link: <br><a target="_blank" class="text-primary"
                                                   [href]="reservation.schedule.linkSession">{{reservation.schedule.linkSession.changingThisBreaksApplicationSecurity}}</a>
                        <!--<div class="align-items-center mt-3">
                            <div>
                                <strong>Cronograma:</strong>
                            </div>
                        </div>-->
                        <div class="align-items-start">
                            <ul>
                                <li class="text-left p-1"
                                    *ngFor="let act of training.activities.edges">{{act.node.startDate |  date:'shortTime' }}
                                    - {{act.node.endDate |  date:'shortTime' }}
                                    <strong>{{act.node.nameActivity}}</strong>: {{act.node.description}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row justify-content-center">
                            <div class="col-4">
                                <img src="https://api.boyaca.adfi.com.co/assets/images/42461515145286634.png" alt="">
                            </div>
                        </div>
                        <div class="align-items-center">
                            <div>
                                <strong>Tenga en Cuenta las Siguientes Recomendaciones:</strong>
                            </div>
                        </div>
                        <div class="align-items-start">
                            <ul>
                                <li class="text-left"
                                    *ngFor="let rec of training.recomendations.edges">{{rec.node.textRecomendation}}</li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <ng-template #upload_act>
                    <div class="d-flex align-items-center w-100 flex-column">
                        <h2>👋 ¡Hola de nuevo!</h2>
                        <h4 class="mt-0">{{ reservation.name }} {{ reservation.lastname }}</h4>
                        <p class="text-justify">Es de nuestro agrado agradecer su asistencia al evento {{training?.titleTraining | capitalize}}.
                            Esperamos que en las próximas reuniones o eventos a suscitarse podamos confiar con su asistencia y participación.
                            Para terminar con el proceso por favor diligencie la encuesta de satisfacción para luego generar documento para que una vez firmado pueda proceder a subir el archivo.</p>
                        <div class="d-flex w-100 justify-content-around flex-column flex-md-row">

                            <button mat-stroked-button color="primary" class="mt-1" *ngIf="reservation && !reservation.surveyComplete" (click)="openSatisfactionSurvey()"><mat-icon aria-hidden="false" aria-label="Survey">document</mat-icon>Completar Encuesta de Satisfacción</button>
                            <button mat-stroked-button color="primary" class="mt-1" *ngIf="reservation && !reservation.fileSupport && reservation.surveyComplete" (click)="getDocumento()"><mat-icon aria-hidden="false" aria-label="Descargar Documento">file_download</mat-icon>Generar Documento</button>
                            <button mat-raised-button color="primary" class="mt-1" *ngIf="reservation && !reservation.fileSupport && reservation.surveyComplete" (click)="uploadFile()"><mat-icon aria-hidden="false" aria-label="Cargar Documento">upload</mat-icon>Subir Documento Firmado</button>
                        </div>
                        <div class="d-flex w-100" *ngIf="reservation && reservation.surveyComplete">
                            <app-list class="full-width" entityName="Question" module="Survey" group="U_Q"  [defaultQuery]="[{column: 'origin', value: 'U'}, {column: 'employee', value: adfiService.user.id}]"
                                      [customData]="{origin: 'U'}" [dataEntity]="{customTitle: '¿Preguntas adicionales? Registrelas a continuación', group: 'U_Q', editGroup: 'U_Q'}"></app-list>
                        </div>
                    </div>
                </ng-template>
            </div>
            <ng-template #noRegister>
                <div class="d-flex justify-content-center">
                    <mat-card class="w-75 mat-elevation-z4" style="padding: 3rem; border-radius: 2rem">
                        <mat-card-content class="d-flex flex-column justify-content-around align-items-center">
                            <img class="w-50 m-4" src="assets/images/adfib.png" alt="">
                            <h2 class="mt-4 text-center">El formulario para la inscripción a la capacitación termino en
                                la fecha:
                                {{training.desactivationDate | date: 'M/d/yy, h:mm a'}}</h2>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-template #notraining>
        <div class="d-flex justify-content-center">
            <mat-card class="w-75 mat-elevation-z4" style="padding: 3rem; border-radius: 2rem">
                <mat-card-content class="d-flex flex-column justify-content-around align-items-center">
                    <img class="w-50 m-4" src="assets/images/adfib.png" alt="">
                    <h2 class="mt-4 text-center">En este momento no se encuentra habilitado el proceso de capacitación</h2>
                </mat-card-content>
            </mat-card>
        </div>
    </ng-template>
</mat-card>

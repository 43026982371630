import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ENUM_ACTIONS} from '@Interfaces/index';

export interface ConfirmDialogData {
    title: string;
    content: string;
    action: ENUM_ACTIONS;
    hideActions?: boolean;
    warnings?: { fieldName: string, warning: string}[];
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {

    ACTIONS = ENUM_ACTIONS;

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    }

    save() {
        this.dialogRef.close(true);
    }

    resolveWarning(warning: any) {
        this.dialogRef.close(warning);
    }
}

import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';

@Component({
  selector: 'app-document-mov',
  templateUrl: './document-mov.component.html',
  styleUrls: ['./document-mov.component.css']
})
export class DocumentMovComponent implements OnInit {
  documentForm: FormGroup;
  @ViewChild('picker') picker: MatDatepicker<any>;
  @Output() afterSave = new EventEmitter<any>();
  @Input() tittle: string;
  typeDocuments: any[];
  proofDocuments: any[];
  loadingDocuments = false;
  loadingProofs = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public loading: LoadingService,
              private alert: AdfiGrowlService,
              private adfiGraphql: AdfiGraphqlService) {
  }

  ngOnInit() {
    this.documentForm = new FormGroup({
      date: new FormControl(this.data ? this.data.date : new Date(), Validators.required),
      proof: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      reason: new FormControl(this.data ? this.data.reason : '', Validators.required),
    });
    this.loadProofs();
  }

  async loadProofs() {
    this.loadingProofs = true;
    const next = (proofs: any[]) => {
      this.loadingProofs = false;
      this.documentForm.get('proof').enable();
      this.proofDocuments = proofs;
      if (this.data) {
        for (const p of proofs) {
          if (p._id === this.data.proof._id) {
            this.documentForm.get('proof').setValue(p);
            this.selectProof(p);
            break;
          }
        }
      }
    };
    const error = (e) => {
      this.loadingProofs = false;
      this.documentForm.get('proof').enable();
      this.alert.errorMessage(e, 'Error al cargar los comprobantes contables');
    };
    this.documentForm.get('proof').disable();
    const filter = `vAdjustments: true, order: { name : "ASC" }`;
    const count = await this.adfiGraphql.countEntity('proofAdjustments', filter);
    this.adfiGraphql.getEntity('proofAdjustments', 'name year', count, filter, {next, error});
  }

  async selectProof(proof: any) {
    this.loadingDocuments = true;
    this.documentForm.get('type').disable();
    const next = (types: any[]) => {
      this.typeDocuments = types;
      this.loadingDocuments = false;
      this.documentForm.get('type').enable();
      if (this.data) {
        for (const p of types) {
          if (p._id === this.data.type._id) {
            this.documentForm.get('type').setValue(p);
            break;
          }
        }
      }
    };
    const error = (e) => {
      this.loadingDocuments = false;
      this.documentForm.get('type').enable();
      this.alert.errorMessage(e, 'Error al cargar tipos de documentos');
    };
    const filter = `year: ${proof.year}, proof_year: ${proof.year}, proof_id: ${proof._id}`;
    const count  = await this.adfiGraphql.countEntity('documentTypeByProofAdjustments', filter);
    this.adfiGraphql.getEntityWithOutIds('documentTypeByProofAdjustments',
          'type {id _id name docDescription}',
          count,
          filter,
          {next, error},
          'type');
  }

  onSubmit() {
    const doc = this.documentForm.value;
    if (this.data) {
      doc.id = this.data.id;
    }
    this.afterSave.emit(doc);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavRoute } from 'src/app/utils/menu';
import { AltaBienesComponent } from './alta-bienes/alta-bienes.component';
import { BajaBienesComponent } from './baja-bienes/baja-bienes.component';
import {Entities, Pages} from '@Interfaces/index';
import {AccesoriosComponent} from '@pages/movimiento-bienes/accesorios/accesorios.component';
import { FormAssetsComponent } from './form-assets/form-assets.component';


export const MovimientoBienesRoutes: NavRoute[] = [
  {
    path: Pages.MOVEMENT_CONTROL,
    nav: { title: 'Control de propiedad, planta y equipo ', icon: 'remove' }, // menu-temp
    redirectTo: '/nav/home'
  }, {
    path: Pages.MOVEMENT_ACCESSORIES,
    nav: { title: 'Accesorios ', icon: 'mouse' },
    component: AccesoriosComponent
  },
  {
    path: Pages.MOVEMENT_ASSETS_FORM_ADD+'/:id',
    //nav: { title: 'Formulario de ingreso', icon: 'arrow_upward' },
    component: FormAssetsComponent,
    entity: Entities.ACTIVO_FIJO
  }
];

@NgModule({
  imports: [RouterModule.forChild(MovimientoBienesRoutes)],
  exports: [RouterModule]
})
export class MovimientoBienesRoutingModule { }

<div class="row pt-2">
    <mat-card class="col-12 mb-2">
        <mat-card-title> Generar Copia de la Plataforma</mat-card-title>
        <mat-card-content>
            <div class="row align-items-center">
                <div class="col-12 col-md-3">
                        <button mat-raised-button 
                            class="mr-2" color="primary" matTooltip="Exportar Reporte" (click)="download()">
                            <mat-icon>receipt_long</mat-icon> Exportar reporte
                        </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallbackPipe} from './callback.pipe';
import {CurrencyDirective} from './currency.directive';
import {NumberDirective} from '@Components/util/number.directive';
import {DecimalNumberDirective} from '@Components/util/decimal.number.directive';
import {CurrencyPipe} from '@Components/util/currency.pipe';
import {FormatTimePipe} from '@Components/util/format-time.pipe';
import { NativeElementInjectorDirective } from './native-element-injector.directive';
import { FilterDropdownPipe } from './filter-dropdown.pipe';
import { CustomDirective } from './custom.directive';


@NgModule({
    declarations: [CallbackPipe, CurrencyDirective, NumberDirective, DecimalNumberDirective, CurrencyPipe, FormatTimePipe, NativeElementInjectorDirective, FilterDropdownPipe, CustomDirective],
    exports: [CallbackPipe, CurrencyDirective, NumberDirective, DecimalNumberDirective, CurrencyPipe, FormatTimePipe, NativeElementInjectorDirective, FilterDropdownPipe, CustomDirective],
    imports: [
        CommonModule
    ],
})
export class UtilModule {
}

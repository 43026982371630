import {Component, OnInit, ViewChild} from '@angular/core';
import {ListComponent} from '@Components/ui/list/list.component';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {Entities, PeriodState, Permission} from '@Interfaces/index';
import {NavRouteService} from '@services/nav-routing-service';

@Component({
  selector: 'app-request-balance-resource',
  templateUrl: './request-reserve.component.html',
  styleUrls: ['./request-reserve.component.css']
})
export class RequestReserveComponent implements OnInit {

  constructor(private loading: LoadingService,
              private adfiGraphqlService: AdfiGraphqlService,
              private adfiService: AdfiService,
              private adfiGrowlService: AdfiGrowlService,
              public navRouteService: NavRouteService) {
  }

  ngOnInit() {
  }

  showBalanceResource(row) {

    const id = `/api/employees/${this.adfiService.user.id}`;
    this.loading.show();
    const saveOk = (response) => {
      this.loading.hide();
      if (response) {
        this.adfiService.user.centroCosto = response.centroCosto;
        this.adfiService.user.cencos = response.cencos;
        this.adfiGrowlService.success(`Centro costo: ${response.centroCosto._id}`, `Actualizado para el usuario ${this.adfiService.user.username}`);
      }
      this.navRouteService.navigate('presupuesto/solicitud-reservas');
    };

    const saveError = (error) => {
      this.loading.show();
      this.adfiGrowlService.errorMessage(error);
    };
    const query = this.adfiGraphqlService.updateEntity('Employee',
        {id, centroCosto: row.centroCosto.id}, 'centroCosto{ _id id deDescripcion} cencos', {next: saveOk, error: saveError});
  }


}

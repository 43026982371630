<div class="container pb-5">
    <div class="row pt-2">
        <mat-card class="col-12 mb-2">
            <mat-card-title>Libros Auxiliares Contables <span class="custom-title" *ngIf="!canSearchConsolidated"> - {{service.user.centroCosto._id + ' - ' + service.user.centroCosto.deDescripcion}}</span> </mat-card-title>
            <mat-card-content>
                <div class="row pt-2">
                    <div class="col-12 col-md-9">
                        <div class="row">
                            <div class="{{canSearchConsolidated ? 'col-md-3' : 'col-md-5'}} col-8">
                                <div class="row full-height align-items-center">
                                    <mat-form-field class="col-12">
                                        <mat-label>Filtrar por tipo</mat-label>
                                        <mat-select placeholder="Seleccionar" [(value)]="filters.types" multiple (selectionChange)="loadConfigData()">
                                            <mat-option *ngFor="let doc of parameters.types" [value]="doc.key">
                                                {{ doc.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="{{canSearchConsolidated ? 'col-md-5 col-6' : 'col-md-7 col-12'}}">
                                <div class="row full-height align-items-center">
                                    <mat-form-field class="col-12">
                                        <mat-label>Filtrar cuenta</mat-label>
                                        <mat-select placeholder="Seleccionar" [(value)]="filters.items" multiple>
                                            <mat-option *ngFor="let doc of parameters.items" [value]="doc.key">
                                                <b>{{doc.key}}</b> - {{doc.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-4 col-6" *ngIf="canSearchConsolidated">
                                <div class="row full-height align-items-center">
                                    <mat-form-field class="col-12" appearance="legacy">
                                        <mat-label>Filtro (Municipio - I.E.)</mat-label>
                                        <input matInput placeholder="Filtro (Municipio - I.E.)" [(ngModel)]="townOrIe" (ngModelChange)="loadConfigData();checkColumns()">
                                        <mat-icon matPrefix class="mr-2 cursor-pointer no-select" (click)="openCenterSearch()">travel_explore</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="row justify-content-around align-items-center">
                            <div class="col-10 pl-sm-3">
                                <h6 class="d-inline"> Fecha Inicial:</h6>
                                <ng-container *ngIf="selectedDates">
                                    {{ selectedDates.startDate | date: 'mediumDate':null:'es-co'}}
                                </ng-container> <br>
                                <h6 class="d-inline"> Fecha Final:</h6>
                                <ng-container *ngIf="selectedDates">
                                    {{ selectedDates.endDate | date: 'mediumDate':null:'es-co'}}
                                </ng-container>
                            </div>
                            <div class="col-2 d-flex align-items-center flex-column">
                                <button mat-mini-fab color="primary"
                                        matTooltip="Seleccionar fechas" (click)="selectDates()">
                                    <mat-icon>calendar_today</mat-icon>
                                </button>
                                <button mat-mini-fab color="primary"
                                        matTooltip="Reiniciar Filtros" (click)="resetFilters()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="row pt-3">
        <div class="col-12">
            <app-table [columns]="columnsAll" (reload)="this.loadData()" [items]="data" (loaded)="loading.hide()"
                       [externalCondition]="selectedDates === undefined"
                       [headerGroupBy]="columnsHeaderGroupAll"
                       [footerGroupBy]="columnsFooterGroupAll"
            >
                <div class="footer">
                    <div class="col-12 pt-3">
                        <div class="row justify-content-end">
                            <button mat-raised-button [disabled]="!this.selectedDates" (click)="export('xlsx')"
                                    class="mr-2" color="primary" matTooltip="Exportar a excel">
                                <mat-icon>receipt_long</mat-icon> Exportar a excel
                            </button>
                            <button mat-raised-button [disabled]="!this.selectedDates" (click)="export('pdf')"
                                    class="mr-2" color="primary" matTooltip="Exportar a pdf">
                                <mat-icon>description</mat-icon>Exportar a pdf
                            </button>
                        </div>
                    </div>
                </div>
            </app-table>
        </div>
    </div>
</div>

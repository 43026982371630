import {Component, OnInit, ViewChild} from '@angular/core';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';
import {MatDialog} from '@angular/material/dialog';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {LoadingService} from '@services/loading.service';
import * as JSZip from 'jszip';
import {DateRangePickerComponent} from '@Components/date-range-selection/date-range-picker/date-range-picker.component';
import { Entity, Options, Property, Table } from '@Interfaces/index';
import { AdfiRest } from '@services/adfi-rest';
import {AdfiUtil} from '@Components/util/adfi-util';

@Component({
    selector: 'app-comptrollership',
    templateUrl: './comptrollership.component.html',
    styleUrls: ['./comptrollership.component.css']
})
export class ComptrollershipComponent implements OnInit {

    allCenters: any[];
    color = 'rgba(63,81,181,0.65)';
    startCenter = 1;
    endCenter = 99999999999;
    downloadedFiles: number;
    total: number;
    private zip: any;
    generatingZip: boolean;
    @ViewChild('rp') dates: DateRangePickerComponent;

    percentage: string;
    
    private budgetData: Property;
    private configTableBudget: Entity;

    private policeData: Property;
    private configTablePolice: Entity;

    private configTableModification: Entity;
    private modificationData: Property;

    private configTableTraslate: Entity;
    private traslateData: Property;

    private configTableExtracts: Entity;
    private extractsData: Property;

    private configTableRuts: Entity;
    private rutsData: Property;

    private configTableRetentions: Entity;
    private retentionsData: Property;

    private configTableCc: Entity;
    private ccData: Property;

    private configTableAppointment: Entity;
    private appointmentData: Property;

    private closingDataData: Property;
    private configTableClosing: Entity;

    private paaData: Property;
    private configTablePaa: Entity;

    constructor(private matDialog: MatDialog,
                private adfiGrowlService: AdfiGrowlService,
                public service: AdfiService,
                private adfiGraphql: AdfiGraphqlService,
                public loading: LoadingService) {
    }

    async ngOnInit() {
        this.loadDataBudget();
        this.loadDataPAA();
        this.loadDataPolices();
        this.loadDataModifications();
        this.loadDataTraslate();
        this.loadDataExtract();
        this.loadDataRuts();
        this.loadDataRetentions();
        this.loadDataCc();
        this.loadDataClosing();
        const filter = 'id_not: { list: [1500101, 1500102, 1500103]} ';
        const count = await this.adfiGraphql.countEntity('prCentroCostos', filter);
        const next = (val) => {
            this.allCenters = val;
        };
        const error = (err) => {
            this.adfiGrowlService.info('Error al obtener el listado de Instituciones', err);
        };
        this.adfiGraphql.getEntity('prCentroCostos', 'id _id deDescripcion prcencosCodDane municipio{nombre}',
            count, filter, {next, error});
    }

    private loadDataPolices() {
        const next = (value: Entity) => {
            this.configTablePolice = value;
            this.policeData = value.properties.find(prop => prop.fieldName === 'policyFile');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'PolicyByCenter', module: 'ActiveDirectory'}).subscribe(next);
    }

    private loadDataModifications() {
        const next = (value: Entity) => {
            this.configTableModification = value;
            this.modificationData = value.properties.find(prop => prop.fieldName === 'actFile');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'Modificacion', module: 'Presupuesto'}).subscribe(next);
    }

    private loadDataTraslate() {
        const next = (value: Entity) => {
            this.configTableTraslate = value;
            this.traslateData = value.properties.find(prop => prop.fieldName === 'actFile');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'Traslado', module: 'Presupuesto'}).subscribe(next);
    }

    private loadDataExtract() {
        const next = (value: Entity) => {
            this.configTableExtracts = value;
            this.extractsData = value.properties.find(prop => prop.fieldName === 'extractFile');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'ExtractBankByAccount', module: 'Tesoreria'}).subscribe(next);
    }

    private loadDataRuts() {
        const next = (value: Entity) => {
            this.configTableRuts = value;
            this.rutsData = value.properties.find(prop => prop.fieldName === 'fileRut');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'PrCentroCosto', module: ''}).subscribe(next);
    }

    private loadDataRetentions() {
        const next = (value: Entity) => {
            this.configTableRetentions = value;
            this.retentionsData = value.properties.find(prop => prop.fieldName === 'generalFile');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'GeneralFile', module: 'Files'}).subscribe(next);
    }


    private loadDataBudget() {
        const next = (value: Entity) => {
            this.configTableBudget = value;
            this.budgetData = value.properties.find(prop => prop.fieldName === 'archivo');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'PresupuestoInicial', module: 'Presupuesto'}).subscribe(next);

    }

    private loadDataCc() {
        const next = (value: Entity) => {
            this.configTableCc = value;
            this.configTableAppointment = value;
            this.ccData = value.properties.find(prop => prop.fieldName === 'fileDocument');
            this.appointmentData = value.properties.find(prop => prop.fieldName === 'fileAppointment');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'RoleByUser', module: ''}).subscribe(next);
    }  

    private loadDataClosing() {
        const next = (value: Entity) => {
            this.configTableClosing = value;
            this.closingDataData = value.properties.find(prop => prop.fieldName === 'certificate');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'ClosingForm', module: 'ClosingForm', displayGroup: 'stats'}).subscribe(next);
    }

    private loadDataPAA() {
        const next = (value: Entity) => {
            this.configTablePaa = value;
            this.paaData = value.properties.find(prop => prop.fieldName === 'paaFile');
        };
        this.service.post<Entity>(AdfiRest.getConfigData,
            {entity: 'PlanAnualAdquisicion', module: 'PAA', displayGroup:'v_all'}).subscribe(next);

    }

    private getListCenter() {
        return this.allCenters.filter(value => (value._id >= this.startCenter && value._id <= this.endCenter));
    }
    private getRangeDates() {
        const start = this.dates.startDate;
        const end = this.dates.endDate;
        return {
            startDate: start.toISOString(),
            endDate: end.toISOString()
        };
    }

    generateBalance() {
        this.downloadedFiles = 0;
        this.download(
            ['Report\\ConsolidatedBalanceByCenter'],
            [{
                center: '1529902',
                ...this.getRangeDates()
            }],
            ['01'],
            ['']
        );
    }

    generateBudgetaryExecution() {
        this.downloadedFiles = 0;
        this.download(
            ['Report\\BudgetaryExecution'],
            [{
                center: '1529902',
                ...this.getRangeDates()
            }],
            ['02'], ['']);
    }

    generateIncomes() {
        this.downloadedFiles = 0;
        this.download(
            ['Report\\IncomeStatement'],
            [{
                center: '1529902',
                ...this.getRangeDates()
            }],
            ['08'], ['']);
    }

    generateExpenses() {
        this.downloadedFiles = 0;
        this.download(
            ['Report\\ExpensesStatement'],
            [{
                center: '1529902',
                ...this.getRangeDates()
            }],
            ['09'], ['']);
    }

    generateF04() {
        this.downloadedFiles = 0;
        this.download(
            ['Report\\F04'],
            [{
                center: '1529902'
            }],
            ['F04'], [''] ,['center'], [''], ['csv']);
    }

    generateF11() {
        this.downloadedFiles = 0;
        this.download(
            ['Report\\F11'],
            [{
                center: '1529902'
            }],
            ['F11'], [''] ,['center'], [''], ['csv']);
    }

    async generateBudgets(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.budgetData, this.configTableBudget, '', 'Presupuesto_inicial');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generatePolice(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        const dates = this.getRangeDates();
        const firstDayOfYear = this.dates.endDate.clone().startOf('year');
        const firstDayOfYearISOString = firstDayOfYear.toISOString();
        await this.downloadFiles(this.policeData, this.configTablePolice, `endDate: {after: "${firstDayOfYearISOString}"}`, 'Polizas');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generateModifications(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.modificationData, this.configTableModification, '', 'Modificaciones');
        await this.downloadFiles(this.traslateData, this.configTableTraslate, '', 'Modificaciones');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generateExtracts(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.extractsData, this.configTableExtracts, '', 'Extractos/');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generateRut(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.rutsData, this.configTableRuts, '', '', '_id');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generateRetentions(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.retentionsData, this.configTableRetentions, '', 'Retenciones/');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generateCC(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.ccData, this.configTableCc, 'only_apply: {actions: ["period"]}', '');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generateApointment(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.appointmentData, this.configTableAppointment, 'only_apply: {actions: ["period"]}', '');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generateClosingForms(generateZip =  true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.closingDataData, this.configTableClosing, '', '');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }    
    }
    
    async generatePAAs(generateZip = true) {
        if(generateZip) {
            this.downloadedFiles = 0;
        }
        await this.downloadFiles(this.paaData, this.configTablePaa, '', 'Plan_anual_adquisiciones_');
        if(generateZip) {
            this.generatingZip = true;
            this.generateZip();
        }
    }

    async generateAll() {
        this.downloadedFiles = 0;
        await this.generateBudgets(false);
        await this.generatePolice(false);
        await this.generateModifications(false);
        await this.generateExtracts(false);
        await this.generateRut(false);
        await this.generateRetentions(false);
        await this.generateCC(false);
        await this.generateApointment(false);
        await this.generateClosingForms(false);
        await this.generatePAAs(false);
        const dates = this.getRangeDates();
        this.download(
            ['Report\\ConsolidatedBalanceByCenter', 'Report\\BudgetaryExecution', 'Report\\IncomeStatement', 'Report\\ExpensesStatement', 'Report\\F04', 'Report\\F11'],
            [{
                center: '1529902',
                ...dates
            }, {
                center: '',
                ...dates
            }, {
                center: '',
                ...dates
            }, {
                center: '',
                ...dates
            },
            {
                center: '',
            },
            {
                center: '',
            }
            ],
            ['01', '02', '08', '09','F04', 'F11'],
            ['', '', '', '','', ''],
            ['', '', 'center', 'center','center', 'center'],
            ['', '', '', '','', ''],
            ['xlsx', 'xlsx', 'xlsx', 'xlsx','csv','csv']
            );
    }

    private async downloadFiles(fileAttr: Property, configTable: Entity, filters: string, folerName = '', cencosValue = 'cencos'): Promise<void> {
        const months = ['','Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return new Promise( async (resolve, reject) => {

        if (!this.zip) {
            this.zip = new JSZip();
        }
        this.loading.show();
        let bucket = '';
        if (!fileAttr || !fileAttr.options[Options.BUCKET]) {
            this.adfiGrowlService.error('Error', 'Doesn\'t exist attribute for file in accounts or unknown bucket');
            return;
        } else {
            bucket = fileAttr.options[Options.BUCKET];
        }
        const all = await this.adfiGraphql.countEntity(configTable.plural, filters);
        let periodParam = '';
        if( fileAttr.options && fileAttr.options[Options.BUCKET_NO_VIGENCIA]) {
            periodParam = '';
        } else if(fileAttr.options && fileAttr.options[Options.BUCKET_NEXT_VIGENCIA]) {
            periodParam = `_${this.service.user.period + 1}`;
        } else {
            periodParam = `_${this.service.user.period}`;
        }

        const params = {responseType: 'arraybuffer', origin: location.origin};
        const next = async (pres) => {
            const centerList = this.getListCenter();
            for (const p of pres) {
                if (p[fileAttr.fieldName]) {
                    // when the file is in a custom bucket we need to add the period to the url from item
                    if(fileAttr.options && fileAttr.options[Options.BUCKET_CUSTOM_VIGENCIA] && p[fileAttr.options[Options.BUCKET_CUSTOM_VIGENCIA]]){
                        periodParam = `_${p[fileAttr.options[Options.BUCKET_CUSTOM_VIGENCIA]]}`;
                    }
                    const url = `https://storage.googleapis.com/${bucket}${periodParam}/`;
                    try {
                        const center = centerList.find(c => c._id === p[cencosValue]);
                        if(center){
                            // @ts-ignore   
                            const binary = await this.service.http.get<any>(`${url}${p[fileAttr.fieldName]}?ignoreCache=1`, params)
                            .toPromise();
                                let month = '';
                                if(configTable.plural === 'extractBankByAccounts' || configTable.plural === 'generalFiles'){
                                    month = `${p.month}. ${months[p.month]}/`
                                }
                                this.zip.file(`${this.service.user.period}_${center.municipio.nombre}_${center.prcencosCodDane}_${center.deDescripcion}/${folerName}${month}`
                                + (typeof p[fileAttr.display[0]] === 'string' ? p[fileAttr.display[0]] : p[fileAttr.display[0]][0]) + '.pdf', binary, {binary: true});
                            this.downloadedFiles++;
                        }
                        }catch (e) {
                            this.adfiGrowlService.error('Error', 'Error downloading file: '+ p[fileAttr.fieldName]);
                        } 
                }
            }
            this.downloadedFiles = 0;
            resolve();
        };
        const error = (e) => {
            reject();
            this.loading.hide();
            this.adfiGrowlService.errorMessage(e);
        };
        const tree = {};
        const folders = fileAttr.options[Options.FOLDERS] ? fileAttr.options[Options.FOLDERS] : [];
        folders.forEach(folder => {
            const subfields = folder.split('.');
            AdfiUtil.addToTree(tree, subfields);
        });
        if (all && all > 0) {
            // generate query graphql for data to create folders
            const query = AdfiUtil.treeToStr(tree) + ' '+ cencosValue + (configTable.plural === 'extractBankByAccounts' || configTable.plural === 'generalFiles' ? ' month' : '');
            this.adfiGraphql.getEntity(configTable.plural, `${query}  ${fileAttr.fieldName}  ${fileAttr.display[0]} ${fileAttr.options[Options.BUCKET_CUSTOM_VIGENCIA] ?? ''}`,
                all, filters, {next, error});
        } else {
            resolve();
            this.loading.hide();
            this.adfiGrowlService.warning('Advertencia', 'Sin archivos para descargar');
        }
       });
    }

    download(nameClass: string[], filters: any[], fileName: string[], nameFolder = [''], filterCenter = ['center'], group = [], typeFiles = ['xlsx']) {
        this.loading.show();
        if (!this.zip) {
            this.zip = new JSZip();
        }
        const centerList = this.getListCenter();
        const totalFiles = centerList.length * nameClass.length;
        this.total = totalFiles;
        this.downloadedFiles = 0;
        let currentCenter = 0;
        let i = 0;
        const next = (d) => {
            if (d && d.fileName) {
                this.service.get(`/api/adfib/get/report/${d.fileName}`, undefined, 'arraybuffer').subscribe(
                    (data) => {
                        this.zip.file(`${this.service.user.period}_${centerList[currentCenter].municipio.nombre}_${centerList[currentCenter].prcencosCodDane}_${centerList[currentCenter].deDescripcion}/${nameFolder[i]}/${this.service.user.period}_${centerList[currentCenter].municipio.nombre}_${centerList[currentCenter].prcencosCodDane}_${centerList[currentCenter].deDescripcion}_${fileName[i]}.${typeFiles[i]}`, data, {binary: true});
                        this.downloadedFiles++;
                        if (this.downloadedFiles) {
                            if (this.downloadedFiles === totalFiles) {
                                this.downloadedFiles = 0;
                                this.generatingZip = true;
                                this.generateZip();
                            }
                        } else {
                            this.loading.hide();
                            this.adfiGrowlService.warning('Advertencia', 'Sin archivos para descargar');
                        }
                        if (i + 1 < nameClass.length) {
                            i++;
                            if(typeof filters[i][filterCenter[i]] === 'object') {
                                filters[i][filterCenter[i]] = {...filters[i][filterCenter[i]], value: centerList[currentCenter]._id};
                            } else {
                                filters[i][filterCenter[i]] = centerList[currentCenter]._id;
                            }
                            this.adfiGraphql.createEntity('Report',
                                {nameClass: nameClass[i], filters: JSON.stringify(filters[i]), type: ( typeFiles[i] === 'csv' ? 'text/csv; charset=utf-8' :`application/xlsx`), ...(group.length > i && group[i] ? {group: group[i]} : {})}, 'fileName', {next, error});
                        } else  if (currentCenter + 1 < centerList.length){
                            i = 0;
                            currentCenter++;
                            if(typeof filters[i][filterCenter[i]] === 'object') {
                                filters[i][filterCenter[i]] = {...filters[i][filterCenter[i]], value: centerList[currentCenter]._id};
                            } else {
                                filters[i][filterCenter[i]] = centerList[currentCenter]._id;
                            }
                            this.adfiGraphql.createEntity('Report',
                                {nameClass: nameClass[i], filters: JSON.stringify(filters[i]), type: ( typeFiles[i] === 'csv' ? 'text/csv; charset=utf-8' :`application/xlsx`), ...(group.length > i && group[i] ? {group: group[i]} : {})}, 'fileName', {next, error});
                        }
                    }, (e) => {
                        this.adfiGrowlService.errorMessage(e);
                        this.loading.hide();
                    }
                );
            } else {
                if(nameClass[i] !== 'Report\\F11'){
                    this.adfiGrowlService.error('Reporte', 'Se ha presentado un error al generar el reporte, por favor contacta a soporte técnico');
                    this.loading.hide();
                } else {
                    if (i + 1 < nameClass.length) {
                        i++;
                        if(typeof filters[i][filterCenter[i]] === 'object') {
                            filters[i][filterCenter[i]] = {...filters[i][filterCenter[i]], value: centerList[currentCenter]._id};
                        } else {
                            filters[i][filterCenter[i]] = centerList[currentCenter]._id;
                        }
                        this.adfiGraphql.createEntity('Report',
                            {nameClass: nameClass[i], filters: JSON.stringify(filters[i]), type: ( typeFiles[i] === 'csv' ? 'text/csv; charset=utf-8' :`application/xlsx`), ...(group.length > i && group[i] ? {group: group[i]} : {})}, 'fileName', {next, error});
                    } else  if (currentCenter + 1 < centerList.length){
                        i = 0;
                        currentCenter++;
                        if(typeof filters[i][filterCenter[i]] === 'object') {
                            filters[i][filterCenter[i]] = {...filters[i][filterCenter[i]], value: centerList[currentCenter]._id};
                        } else {
                            filters[i][filterCenter[i]] = centerList[currentCenter]._id;
                        }
                        this.adfiGraphql.createEntity('Report',
                            {nameClass: nameClass[i], filters: JSON.stringify(filters[i]), type: ( typeFiles[i] === 'csv' ? 'text/csv; charset=utf-8' :`application/xlsx`), ...(group.length > i && group[i] ? {group: group[i]} : {})}, 'fileName', {next, error});
                    } else {
                        this.downloadedFiles = 0;
                        this.generatingZip = true;
                        this.generateZip();
                    }
                }
            }
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
            this.loading.hide();
        };
        if(typeof filters[i][filterCenter[i]] === 'object') {
            filters[i][filterCenter[i]] = {...filters[i][filterCenter[i]], value: centerList[currentCenter]._id};
        } else {
            filters[i][filterCenter[i]] = centerList[currentCenter]._id;
        }
        this.adfiGraphql.createEntity('Report',
            {nameClass: nameClass[i], filters: JSON.stringify(filters[i]), type: `application/xlsx`, ...(group.length > i && group[i] ? {group: group[i]} : {})}, 'fileName', {next, error});
    }

    private generateZip() {
        this.zip.generateAsync({type: 'blob'}, (metadata)=> {
            this.percentage = metadata?.percent?.toFixed(0);
        }).then((blob) => {
            const dataType = 'application/x-zip-compressed';
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: dataType}));
            downloadLink.setAttribute('download', `ReporteContraloria.zip`);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            this.generatingZip = false;
            this.loading.hide();
        }, e => {
            this.loading.hide();
            this.adfiGrowlService.errorMessage(e);
        });
    }
}

import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache, ApolloLink } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import { NavModule } from "@Components/nav/nav.module";

import { AdfibGuard } from "./public/adfib.guard";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import {
    URL_GRAPHQL,
    PROVIDERS_DATE_FORMAT,
    APP_TOKEN,
} from "./config/constants";
import {
    MomentDateModule,
    MatMomentDateModule,
} from "@angular/material-moment-adapter";
import es from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import * as moment from "moment";
import { AccountsService } from "@services/accounts.service";
import { UiModule } from "@Components/ui/ui.module";
import { DetailModificationComponent } from "@Components/presupuesto/detail-modification/detail-modification.component";
import { PresupuestoModule } from "@pages/presupuesto/presupuesto.module";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { environment } from "src/environments/environment";
import { AddUnspscAccountsDialogComponent } from "./components/dialogs/paa/add-unspsc-accounts-dialog/add-unspsc-accounts-dialog.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule } from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatChipsModule} from "@angular/material/chips";
import {MatSelectModule} from "@angular/material/select";
import {MatCardModule} from "@angular/material/card";
import {MatProgressBarModule} from "@angular/material/progress-bar";

registerLocaleData(es, "es");
moment.locale("es-CO");

export function provideApollo(httpLink: HttpLink) {
    const basic = setContext((operation, context) => ({
        headers: {
            Accept: "charset=utf-8",
        },
    }));

    // Get the authentication token from local storage if it exists
    const token = document["serviwToken"] || localStorage.getItem(APP_TOKEN);
    const auth = setContext((operation, context) => ({
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }));

    const link = ApolloLink.from([
        basic,
        auth,
        httpLink.create({ uri: URL_GRAPHQL }),
    ]);
    const cache = new InMemoryCache();

    return {
        link,
        cache,
        defaultOptions: {
            /*watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },*/
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        },
    };
}

@NgModule({
    declarations: [
        AppComponent,
        DetailModificationComponent,
        AddUnspscAccountsDialogComponent,
    ],
    imports: [
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,

        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NavModule,
        HttpClientModule,
        MomentDateModule,
        MatMomentDateModule,
        HttpClientModule,
        // mat
        MatSnackBarModule,
        UiModule,
        NgxGoogleAnalyticsModule.forRoot(environment.ga),
        PresupuestoModule,
        MatDialogModule,
        MatChipsModule,
        MatSelectModule,
        MatCardModule,
        MatProgressBarModule,
    ],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: provideApollo,
            deps: [HttpLink],
        },
        AccountsService,
        AdfibGuard,
        MatSnackBar,
        ...PROVIDERS_DATE_FORMAT,
        {
            provide: LOCALE_ID,
            useValue: "es",
        },
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {}
}

import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Optional, Output, Renderer2} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[appCurrencyDirective]'
})
export class CurrencyDirective implements OnInit {

    constructor(private el: ElementRef, private renderer: Renderer2,
                @Optional() private control: NgControl) {
    }

    @Input() decimals = 2;
    @Input() sepMiles = '.';
    @Input() sepDecimals = ',';
    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

    nativeValue = '0';

    currencyChars = new RegExp(`[${this.sepMiles}]|[^\\d\\-,]`, 'g');
    showRegex = new RegExp(`(?<![${this.sepDecimals}].*)(\\d)(?=(?:\\d{3})+(?:[${this.sepDecimals}]|$))`, 'g');

    ngOnInit() {
        this.format(this.el.nativeElement.value);
        if (this.control) {
            this.control.valueChanges.subscribe((v) => {
                if (v) {
                    this.format(v);
                    this.valueChange.emit(parseFloat(this.nativeValue));
                }
            });
        }
    }

    @HostListener('keyup', ['$event']) onKeyUp(event: any) {
        const ci = event.target.selectionStart;
        const oldLen = event.target.value.length;
        this.format(event.target.value);
        const newLen = event.target.value.length;
        this.valueChange.emit(parseFloat(this.nativeValue));
        if (!(event.which === 8 || event.which === 46 || event.which === 37 || event.which === 39)){
            event.target.selectionStart = oldLen < newLen ? ci + 1 : ci;
            event.target.selectionEnd = oldLen < newLen ? ci + 1 : ci;
        }
        if (event.which === 8 || event.which === 46){
            event.target.selectionStart = oldLen > newLen ? ci - 1 : ci;
            event.target.selectionEnd = oldLen > newLen ? ci - 1 : ci;
        }
    }

    @HostListener('keydown', ['$event']) onInput(event: any) {
        const regex = /-|\d|,/;
        const key = event.key;
        const hasComma = key.includes(this.sepDecimals);
        const current = this.el.nativeElement.value;
        const posComma = current.lastIndexOf(this.sepDecimals);
        const hasMinus = current.startsWith('-');
        if (!(event.which === 8 || event.which === 46 || event.which === 37 || event.which === 39)) {
            if (!regex.test(key) ||
                (hasComma && (this.decimals === 0 || posComma !== -1 || current.length === 0)) ||
                (posComma !== -1 && current.length - posComma > this.decimals && event.target.selectionStart > posComma) ||
                (!hasMinus && (key === '-' && event.target.selectionStart !== 0)) ||
                (hasMinus && key === '-')
            ) {
                event.returnValue = false;
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        this.format(event.clipboardData.getData('text/plain'));
        this.valueChange.emit(parseFloat(this.nativeValue));
    }

    format(current: string) {
        current = this.replaceDecimals(current);
        let newValue = current.trim() ? current.replace(this.currencyChars, '') : '';
        this.nativeValue = newValue.replace(this.sepDecimals, '.');
        newValue = newValue.replace( this.showRegex, '$1' + this.sepMiles);
        this.renderer.setProperty(this.el.nativeElement, 'value', newValue);
    }

    replaceDecimals(currency: string) {
        const max = currency.length;
        for (let i = 1; i <= 3; i++) {
            if (currency[max - i] === '.') {
                return currency.substring(0, max - i) + this.sepDecimals + currency.substring(max - i + 1);
            }
        }
        return currency;
    }
}

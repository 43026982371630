import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NavRoute} from '../../utils/menu';
import {Entities, Pages, PeriodState, Permission} from '@Interfaces/index';
import {ReservationComponent} from '@pages/trainings/reservation/reservation.component';
import {CallAssistanceComponent} from '@pages/trainings/call-assistance/call-assistance.component';
import { TrainingsVideosComponent } from './trainings-videos/trainings-videos.component';


export const RoutesTrainings: NavRoute[] = [
    {
        path: Pages.TRAINING_RESERVATION,
        component: ReservationComponent,
        entity: Entities.RESERVATION,
        permission: Permission.ADD,
        periodSate: PeriodState.OPEN,
        nav: { title: 'Inscripción', icon: 'school' },
    },
    {
        path: Pages.TRAINING_CALL_ASSISTANCE,
        component: CallAssistanceComponent,
        entity: Entities.CALL_ASSISTANCE,
        permission: Permission.ADD,
        periodSate: PeriodState.OPEN,
        nav: { title: 'Llamar asistencia', icon: 'assignment_turned_in' },
    },
    {
        path: Pages.TRAINING_VIDEO,
        component: TrainingsVideosComponent,
        entity: Entities.VIDEOS,
        permission: Permission.VIEW,
        periodSate: PeriodState.NO_STATUS,
        nav: { title: 'Videos', icon: 'smart_display_outlined' },
    },
];
@NgModule({
    imports: [RouterModule.forChild(RoutesTrainings)],
    exports: [RouterModule]
})
export class TrainingsRoutingModule { }

import {Component, OnInit} from '@angular/core';
import {Pages, Permission, TipoPresupuesto} from '@Interfaces/index';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';
import {AdfiRest} from '@services/adfi-rest';
import {AdfiGrowlService} from '@services/adfi-growl.service';

@Component({
  selector: 'app-rubros-presupuesto',
  templateUrl: './rubros-presupuesto.component.html',
  styleUrls: ['./rubros-presupuesto.component.css']
})
export class RubrosPresupuestoComponent implements OnInit {

  public readonly type;
  public prefix = 'Ingresos';
  public title;
  btnAbrirPresupuesto: boolean;
  totalItems: number;

  constructor(
      private loading: LoadingService,
      private adfiService: AdfiService,
      private adfiGraphqlService: AdfiGraphqlService,
      private alert: AdfiGrowlService,
  ) {
    this.type = this.getType();
  }

  ngOnInit() {
    this.countRubros();
  }

  getType() {
    return TipoPresupuesto.INGRESO;
  }

  getModule() {
    return Pages.BUDGET_INCOME;
  }

  private countRubros() {
    this.loading.show();
    const next = (data) => {
      this.totalItems = data.total;
      this.btnAbrirPresupuesto = data.total === 0 && this.adfiService.validPermission(this.getModule(), Permission.ADD);
      this.loading.hide();
    };
    const error = () => {
      this.loading.hide();
    };
    this.adfiGraphqlService.createEntity('Summation', {nameClass: 'Presupuesto\\RubroPresupuesto'}, 'total', {next, error});
  }

  /**
   * Abrirs presupuesto
   */
  public open() {
    this.loading.show();
    const next = (data) => {
      this.btnAbrirPresupuesto = false;
      this.loading.hide();
      this.alert.success('Rubros', data.message);
    };
    const error = () => {
      this.loading.hide();
      this.alert.errorMessage(error);
    };
    this.adfiGraphqlService.createEntity('Action', { nameClass: 'CreateRubroPresupuesto' }, 'message', {next, error});
  }

  print() {
    this.loading.show();
    const link = AdfiRest.reportRubros(this.getType());
    this.adfiService.get(link, undefined, 'arraybuffer', false).subscribe(
        (data) => {
          AdfiService.downLoadFile(data, 'application/pdf', 'report.pdf');
          this.loading.hide();
        },
  () => {
          this.loading.hide();
          this.alert.error('Reporte',
              ' Se ha presentado un error al generar el reporte, por favor contacta a soporte técnico'
          );
        }
    );
   }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  sepMiles = '.';
  sepDecimals = ',';
  symbol = '$';
  currencyChars = new RegExp(`[.]`, 'g');
  showRegex = new RegExp(`(?<![${this.sepDecimals}].*)(\\d)(?=(?:\\d{3})+(?:[${this.sepDecimals}]|$))`, 'g');

  transform(value: number | string): string {
    if (!value && value !== 0) {
      return '';
    }
    const current = String(value);
    const newValue = current.trim() ?
        current.replace(this.currencyChars, this.sepDecimals)
            .replace(this.showRegex, '$1' + this.sepMiles)
        : '';
    const decimals = `${this.sepDecimals}00`;
    const length = newValue.length;
    let i = 1;
    for (; i <= 3; i++) {
      if (length - i < 0){
        i = 4;
        break;
      }
      if (newValue[length - i] === this.sepDecimals) {
        break;
      }
    }
    i = i === 4 ? 0 : i;
    return this.symbol + newValue + decimals.substr(i);
  }

}

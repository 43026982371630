<div class="container">
    <div class="d-flex justify-content-end pb-1">
        <button mat-raised-button color="primary" (click)="exportData()">Exportar</button>
    </div>
    <app-list entityName="AdfiForm">
        <ng-template tableColumn="approved" let-row="row">
            <ng-container [ngSwitch]="row.approved">
             <span *ngSwitchCase="0">
                 <ng-container *ngIf="adfibService.validPermission(PAGES.ADFI_FORM, PERMISSION.APPROVE)">
                     <button mat-icon-button
                             matTooltip="Aprobar"
                             color="primary"
                             (click)="approvedData(row, 1)">
                            <mat-icon>check</mat-icon>
                    </button>
                     <button class="m-2" mat-icon-button
                             (click)="approvedData(row, 2)"
                             color="warn"
                             matTooltip="Denegar">
                         <mat-icon>close</mat-icon>
                     </button>
                 </ng-container>
                 <ng-container *ngIf="!adfibService.validPermission(PAGES.ADFI_FORM, PERMISSION.APPROVE)">
                     Pendiente
                 </ng-container>
            </span>
                <span *ngSwitchCase="1">
                Aprobado
            </span>
                <span *ngSwitchCase="2">
                No Aprobado
            </span>
            </ng-container>

        </ng-template>

        <ng-template tableColumn="confirmCode" let-row="row">
            <div class="d-flex justify-content-center">
                <button class="m-2" mat-icon-button
                        color="primary"
                        (click)="resendMail(row)"
                        [matTooltip]="!(row.confirmCode.includes('SUCCESS') || row.confirmCode.includes('RESEND')) ? 'Reenviar Correo Validación de Correo':'Reenviar Horario'"
                        *ngIf="row.approved === 1">
                    <mat-icon *ngIf="!(row.confirmCode.includes('SUCCESS') || row.confirmCode.includes('RESEND'))">
                        send
                    </mat-icon>
                    <mat-icon *ngIf="row.confirmCode.includes('SUCCESS') || row.confirmCode.includes('RESEND')">
                        book_online
                    </mat-icon>
                </button>
            </div>
        </ng-template>
    </app-list>
</div>

<div class="mat-calendar-header">
    <mat-chip-list class="row justify-content-center" aria-label="Color selection">
        <mat-chip>{{dateLabel(calendar.selected.start)}}</mat-chip>
        <span class="sep-header-date">-</span>
        <mat-chip>{{dateLabel(calendar.selected.end)}}</mat-chip>
    </mat-chip-list>
    <div class="mat-calendar-controls">
        <button mat-button type="button" class="mat-calendar-period-button"
                (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel">
            <span>{{periodButtonText}}</span>
            <div class="mat-calendar-arrow"
                 [class.mat-calendar-invert]="calendar.currentView !== 'month'"></div>
        </button>

        <div class="mat-calendar-spacer"></div>

        <button mat-icon-button type="button" class="mat-calendar-previous-button"
                [disabled]="!previousEnabled()" (click)="previousClicked()"
                [attr.aria-label]="prevButtonLabel">
        </button>

        <button mat-icon-button type="button" class="mat-calendar-next-button"
                [disabled]="!nextEnabled()" (click)="nextClicked()"
                [attr.aria-label]="nextButtonLabel">
        </button>
    </div>
</div>

import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DialogConfirm} from '@pages/adfi-form/adfi-form.component';

@Component({
    selector: 'app-approved-form',
    templateUrl: './approved-form.component.html',
    styleUrls: ['./approved-form.component.css']
})
export class ApprovedFormComponent {

    obs: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogConfirm,
        public dialogRef: MatDialogRef<ApprovedFormComponent>,
       ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close({approved: this.data.title, obs: this.obs});
    }

}

import { Injectable } from "@angular/core";
import {
    Route,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    CanActivate,
    CanActivateChild,
} from "@angular/router";
import { Observable } from "rxjs";
import { AdfiService } from "@services/adfi-service";

interface SRoute<S> extends Route {
    data?: {
        home?: boolean;
        icon?: string;
    };
}
export type ServiwRoutes<S = any> = SRoute<S>[];

export interface CallsOfPromise {
    resolve?: (val: boolean) => void;
    reject?: (val: boolean) => void;
}

@Injectable()
export class AdfibGuard implements CanActivate {
    protected home: boolean;

    constructor(protected router: Router, protected service: AdfiService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.home = next.data ? next.data.home : false;
        const promise = async (
            resolve: (val: boolean) => void,
            reject: (val: boolean) => void
        ) => {
            const validToken = await this.checkLogin();
            if (!validToken) {
                await this.service.logout();
            }
            resolve(validToken);
            if (
                this.service.user.role._id == 440 &&
                state.url === "/nav/home"
            ) {
                this.router.navigate(["/nav/reviewer-statistics"]);
                resolve(false);
            } else {
                resolve(true);
            }
        };
        return new Promise<boolean>(promise);
    }

    protected checkLogin(): Promise<boolean> {
        // 1.
        const promise = (
            resolve: (val: boolean) => void,
            reject: (val: boolean) => void
        ) => {
            this.service.loadUser().then(
                (value) => {
                    resolve(value);
                },
                () => {
                    resolve(false);
                }
            );
        };
        return new Promise<boolean>(promise);
    }
}

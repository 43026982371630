<div class="row">
    <div class="col-12">
        <mat-card *ngIf="centroCosto">
            <mat-card-header>
                <mat-card-title>
                    {{ centroCosto.deDescripcion }}
                </mat-card-title>
                <mat-card-subtitle>{{centroCosto.municipio.nombre}} </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="row pl-3">
                    <div class="col-12 col-md-3">
                        <mat-card-subtitle>
                            <strong>DANE:</strong> {{centroCosto.prcencosCodDane}}
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-card-subtitle>
                            <strong>NIT:</strong> {{centroCosto.nit}}
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-card-subtitle>
                            <strong>TELÉFONO:</strong>  {{ centroCosto.telefono}}
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-card-subtitle>
                            <strong>CELULAR:</strong> {{centroCosto.celular}}
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-card-subtitle>
                            <strong>CORREO:</strong> {{centroCosto.correo}}
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-card-subtitle>
                            <strong>PÁGINA WEB:</strong> {{centroCosto.webPage}}
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-card-subtitle>
                            <strong>UBICACIÓN GPS:</strong> {{centroCosto.ubicacionGps}}
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-card-subtitle>
                            <strong>RUT:</strong>
                            <button mat-icon-button color="primary" matTooltip="Descargar RUT" *ngIf="centroCosto.fileRut" (click)="downloadFile('fileRut')"><mat-icon>download</mat-icon></button>
                            <button mat-icon-button color="primary" matTooltip="Editar Archivo RUT" (click)="editCenter('editRutFile', 'Editar Archivo RUT')"><mat-icon>edit</mat-icon></button>
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-card-subtitle>
                            <strong>MISIÓN:</strong>
                            {{centroCosto.mision}}
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-card-subtitle>
                            <strong>VISIÓN:</strong>
                            {{centroCosto.vision}}
                        </mat-card-subtitle>
                    </div>
                    <div class="w-100 text-right mr-3">
                        <button mat-raised-button color="primary" (click)="editCenter('optionalFields', 'Editar Centro de Costo')">
                            <mat-icon>edit</mat-icon>
                            Editar
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="row mt-4">
    <div class="col-12">
        <app-generic-list entity="ZjhkNTZoaVI1ZUF0UHpBPQ" module="YzhkNCt4U1g4OGMzTldJT1M4RUJmNWs9"></app-generic-list>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12">
        <app-list module="" entityName="Sede" [url]="PAGES.DIRECTORY_SEDES_CENCOS"
            [customData]="{centroCosto: centerCost}"
            [defaultQuery]="[{column: 'cencos', value: cencos}]"></app-list>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12">
        <app-list module="ActiveDirectory" entityName="PolicyByCenter" [defaultQuery]="[{column: 'period', value: period}, {column: 'center', value: cencos}]"></app-list>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AdfiGraphqlService } from '@services/adfi-graphql.service';
import { AdfiGrowlService } from '@services/adfi-growl.service';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-plataform-backup',
  templateUrl: './plataform-backup.component.html'
})
export class PlataformBackupComponent implements OnInit {

  constructor(
    private adfiGrowlService: AdfiGrowlService,
    private graph: AdfiGraphqlService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {
  }

  download() {
    const nameClass = 'plataform-backup';
    const filters = '';
    const type = '';
    const actions = '';
    const group = '';
    const customReturn = '';

    this.loading.show();
    const next = (d) => {
      this.loading.hide();
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
      this.loading.hide();
    };
    this.graph.createEntity('Report', { nameClass, filters, type, actions, group }, customReturn, { next, error });
  }

}

import { Component, OnInit } from '@angular/core';
import { AdfiService } from '@services/adfi-service';
import { Filter } from '../util/Filter';
import { AdfiGraphqlService } from '@services/adfi-graphql.service';
import { AdfiUtil } from '@Components/util/adfi-util';
import { Entities } from '@Interfaces/index';

interface ItemAssetState {
  id: string;
  purchaseValue: string;
  description: string;
  consecutive: number;
}

interface AssetState {
  name: string;
  module: string;
  entity: string;
  loading: boolean;
  group: string;
  messageEmpty: boolean;
  purchaseValue: string;
  list: ItemAssetState[];
}

@Component({
  selector: 'app-summary-asset-status',
  templateUrl: './summary-asset-status.component.html'
})
export class SummaryAssetStatusComponent implements OnInit {

  listAssetsState: AssetState[] = null;
  public canView: boolean;

  constructor(private graphqlService: AdfiGraphqlService,private adfiService: AdfiService) {
    this.canView = this.adfiService.validPermission(Entities.ACTIVO_FIJO, 'viewSummary');
  }

  ngOnInit(): void {
    this.adfiService.get('/api/get/assetState').subscribe((data :any[]) => {
      if (data) {
        const listAssetsState = [];
        data.forEach( alert => {
          const assetsState = {
            name: alert.name,
            entity: alert.entity,
            module: alert.module,
            purchaseValue: '0',
            loading: true,
            group: alert.group,
            messageEmpty: alert.messageEmpty,
            list: []
          };
          listAssetsState.push(assetsState);
          this.loadListPending(assetsState, alert.keyIdentifier, alert.keysDescription, alert.keysValue, alert.filters);
        });
        this.listAssetsState = listAssetsState;
      }
    });
  }

  private static createItemAssetState(id , purchaseValue, description, consecutive): ItemAssetState {
    return {
      id,
      purchaseValue,
      description,
      consecutive
    };
  }

    private async loadListPending(asset: AssetState,
                                  keyIdentifier: string = 'consecutive',
                                  keysDescription: string[],
                                  keyValue: string[],
                                  filters) {
      await this.getAssetState(asset, `id ${keyIdentifier} ${keysDescription.join(' ')} ${keyValue.join(' ')}`,
          filters, (data, config) => {
            asset.purchaseValue = this.calculateTotalFromList(data, keyValue);
            asset.list = data.map(item =>
              SummaryAssetStatusComponent.createItemAssetState(item.id,
                  keyValue.length === 1 ? item[keyValue[0]] : keyValue.reduce((v, k) => (+item[v] + +item[k]).toString()),
                  keysDescription.map( desc => AdfiUtil.getColumnValue(item, config.columns.find(k => k.fieldName === desc))).join(' - '),
                  AdfiUtil.getColumnValue(item, config.columns.find(k => k.fieldName === keyIdentifier))
              ));
              asset.loading = false;
      });
    }
  
    /**
     * Get full value of assets
     * @param list list of assets
     * @param key key to calculate total
     */
    private calculateTotalFromList(list: any[], key: string[]): string {
      let total = 0;
      list.forEach(item => {
        key.forEach(multiKey => {
          total += item[multiKey];
        });
      });
      return total.toFixed(2);
    }
  
    private async getAssetState(asset: AssetState, columns: string, filters, next: (data: any, config: Filter) => void) {
      const error = (err: any) => {
        console.log(err);
      };
      const f = new Filter(this.adfiService);
      f.entityName = asset.entity;
      f.module = asset.module;
      f.group = asset.group;
      f.defaultQuery = filters;
      f.loadedConfig.subscribe(async () => {
        const params: any = f.generateFilters();
        const count = await this.graphqlService.countEntity(f.configTable.plural, params);
        this.graphqlService.getEntity(f.configTable.plural, columns, count, params, {next: (data) => { next(data, f); }, error});
      });
      f.loadConfig();
    }

}

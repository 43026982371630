import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormComponent} from './form/form.component';
import {ListComponent} from './list/list.component';
import {CurrencyPipe} from '../util/currency.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectItemComponent} from './select-item/select-item.component';
import {UtilModule} from '@Components/util/util.module';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {DisplayItemComponent} from './display-item/display-item.component';
import {LoadingComponent} from './loading/loading.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {AlertComponent} from './alert/alert.component';
import {MatChipsModule} from '@angular/material/chips';
import {AdfiGrowlComponent } from './adfi-growl/adfi-growl.component';
import {PortalModule} from '@angular/cdk/portal';
import {MAT_DATETIME_FORMATS, MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';
import { SelectGroupComponent } from './select-group/select-group.component';
import { AccountmanSignatureComponent } from './accountman-signature/accountman-signature.component';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {TableColumnDirective} from '@Components/ui/util/Utilities';
import {MonthSelectorModule} from '@Components/month-selector/month-selector.module';
import {DateRangeSelectionModule} from '@Components/date-range-selection/date-range-selection.module';
import { LineComponent } from './menus/line/line.component';
import { CircleComponent } from './menus/circle/circle.component';
import { HalfCircleComponent } from './menus/half-circle/half-circle.component';
import {AutocompleteComponent, AutocompleteContentItemDirective} from './autocomplete/autocomplete.component';
import { TelInputComponent } from './tel-input/tel-input.component';
import { CustomComponentComponent } from './custom-component/custom-component.component';
import {RouterModule} from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import { PendingProcessComponent} from '@Components/ui/pending-process/pending-process.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DialogAssetsComponent } from './dialog-assets/dialog-assets.component';

@NgModule({
    declarations: [FormComponent, ListComponent, SelectItemComponent, ConfirmDialogComponent, DisplayItemComponent,
        LoadingComponent, AlertComponent, AdfiGrowlComponent, SelectGroupComponent, AccountmanSignatureComponent,
        TableColumnDirective, LineComponent, CircleComponent, HalfCircleComponent, AutocompleteComponent,
        AutocompleteContentItemDirective, TelInputComponent, CustomComponentComponent, CalendarComponent, PendingProcessComponent, DialogAssetsComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        UtilModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatDividerModule,
        MatListModule,
        MatExpansionModule,
        EditorModule,
        MatSlideToggleModule,
        NgxMatFileInputModule,
        MatMenuModule,
        MatChipsModule,
        MatBadgeModule,
        PortalModule,
        // use this if you want to use native javascript dates and INTL API if available
        // MatNativeDatetimeModule,
        MatMomentDatetimeModule,
        MatDatetimepickerModule,
        FormsModule,
        MonthSelectorModule,
        DateRangeSelectionModule,
        RouterModule,
        MatTabsModule
    ],
    exports: [
        FormComponent, ListComponent, DisplayItemComponent,
        SelectItemComponent, LoadingComponent, AdfiGrowlComponent, TableColumnDirective, LineComponent,
        CircleComponent, HalfCircleComponent, AutocompleteComponent, AutocompleteContentItemDirective, TelInputComponent, CustomComponentComponent, CalendarComponent,
        PendingProcessComponent,
    ],
    providers: [DatePipe, CurrencyPipe,  {
        provide: MAT_DATETIME_FORMATS,
        useValue: {
            parse: {
                dateInput: 'L',
                monthInput: 'MMMM',
                timeInput: 'LT',
                datetimeInput: 'L LT'
            },
            display: {
                dateInput: 'L',
                monthInput: 'MMMM',
                datetimeInput: 'L LT',
                timeInput: 'LT',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
                popupHeaderDateLabel: 'ddd, DD MMM'
            }
        }
    }],
    entryComponents: [ListComponent, FormComponent, SelectItemComponent, ConfirmDialogComponent,
        DisplayItemComponent, LoadingComponent, AdfiGrowlComponent, SelectGroupComponent, AccountmanSignatureComponent]
})
export class UiModule {
}

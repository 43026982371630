<mat-card-title class="d-flex full-width align-items-center">
  <h4>Plan Anual de Adquiciones </h4>
</mat-card-title>
<ng-container *ngIf="paa">
  <app-list #listPAA
    entityName="PAAAdquisicion"
    module="PAA"
    [group]="'LP'"
    [noActions]=true 
    [dataEntity]="{ enableAdd: false, group: 'AP', editGroup: 'EP'}"
    [defaultQuery]="[{column:'cencos', value: ''}, {column:'vigencia', value: period}]"
  >
    <ng-template tableColumn="unspscAccounts" let-row="row" let-property="property">
      <div class="d-flex align-items-center justify-content-center full-width full-height">
        <ng-container *ngIf="getDataMenu(row, property); let list">
          <ng-container *ngIf="list && list.length">
            <button mat-icon-button [matMenuTriggerFor]="many">
              <mat-icon color="primary">visibility</mat-icon>
            </button>
            <mat-menu #many="matMenu" yPosition="above">
            </mat-menu>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
    <div class="footer col-12 py-2">
      <div class="col-12">
        <div class="row justify-content-center">
          <button mat-raised-button color="primary" class="mr-3" *ngIf="(paa?.state == 'C')"
                  (click)="downloadPaa()">
            <mat-icon>file_download</mat-icon>
            Archivo Plan Anual Adquisiciones
          </button>
          <button mat-raised-button color="primary" (click)="downloadFile('pdf')">
            <mat-icon>picture_as_pdf</mat-icon>
            Descargar PDF
          </button>
          <button mat-raised-button color="primary" class="ml-3" (click)="downloadFile('xlsx')">
            <mat-icon>summarize</mat-icon>
            Descargar XLSX
          </button>
        </div>
      </div>
    </div>
    <div class="actionsTable pr-3">
      <button mat-raised-button color="primary" class="ml-3" (click)="openVerification()">
        Verificación
      </button>
    </div>
  </app-list>
</ng-container>

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_TOKEN, NAME_ENTITY, URL_SERVER} from '../config/constants';
import {Observable, PartialObserver} from 'rxjs';
import {Router} from '@angular/router';
import {AdfiRest} from '@services/adfi-rest';
import {first, map} from 'rxjs/operators';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {LoadingService} from '@services/loading.service';
import { PeriodState, Permission} from '@Interfaces/index';
import {AdfiUtil} from '@Components/util/adfi-util';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Injectable({
    providedIn: 'root'
})
export class AdfiService {

    constructor(public http: HttpClient, private router: Router,
                private graph: AdfiGraphqlService,
                private growl: AdfiGrowlService,
                private gaService: GoogleAnalyticsService,
                private loading: LoadingService) {
    }

    public user: {role: any, rolList: any[], username: string, helpDeskTel: boolean, name: string, apellidos: string, phone: string, genre: string, document: string, centroCosto: any, email: string, id: number, period: number, cencos: string};
    periods: any[];

    public static loadHeaders() {
        const token = AdfiService.extractToken();
        const headers: any = {'Content-Type': 'application/json'};

        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return new HttpHeaders(headers);
    }

    private static extractToken() {
        let token: string = document['serviwToken'];
        if (token) {
            localStorage.setItem(APP_TOKEN, token);
        } else {
            token = localStorage.getItem(APP_TOKEN);
        }
        return token;
    }

    /**
     * Downs load file
     */
    public static downLoadFile(data: any, type: string, file: string) {
        const blob = new Blob([data], { type: type.toString() });
        const url = window.URL.createObjectURL(blob);
        // saveAs(blob, file);
        window.open(url);
    }

    loadPeriods() {
        this.loading.show();
        const promise = async (resolve: (val: boolean) => void, reject: (val: boolean) => void) => {
            const next = (data) => {
                this.periods = data;
                resolve(true);
                this.loading.hide();
            };
            const error = (e) => {
                this.growl.errorMessage(e);
                resolve(false);
                this.loading.hide();
            };
            const all = await this.graph.countEntity(NAME_ENTITY.PeriodsPlatform.plural, null);
            this.graph.getEntity(NAME_ENTITY.PeriodsPlatform.plural, 'fechaApertura fechaCierre estado year', all, '', {next, error});
        };
        return new Promise<boolean>(promise);
    }

    public uploadFile<T>(url: string, data: FormData, responseType: any = 'json', urlRelative = true): Observable<T> {
        const token = AdfiService.extractToken();
        const headers: any = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return this.http.post<T>(`${urlRelative ? URL_SERVER : ''}${url}`,
            data, {responseType, headers: new HttpHeaders(headers)}).pipe(first());
    }

    public post<T>(url: string, data: any, responseType: any = 'json', urlRelative = true): Observable<T> {
        const headers = AdfiService.loadHeaders();
        return this.http.post<T>(`${urlRelative ? URL_SERVER : ''}${url}`, data, {responseType, headers}).pipe(first());
    }

    public get<T>(url: string, params?: any, responseType: any = 'json', urlRelative = true): Observable<T> {
        const headers = AdfiService.loadHeaders();
        responseType = responseType ? responseType : 'json';
        return this.http.get<T>(`${urlRelative ? URL_SERVER : ''}${url}`,
            {responseType, headers, params}).pipe(first());
    }

    public login(username: string, password: string) {
        return this.http.post<any>(AdfiRest.authenticate, { username, password })
            .pipe(map(async aut => {
                if (aut && aut.token) {
                    localStorage.setItem(APP_TOKEN, aut.token);
                    return true;
                } else {
                    localStorage.removeItem(APP_TOKEN);
                }
                return false;
            }));
    }

    public logout() {
        const logoutOk = () => {
            document['serviwToken'] = undefined;
            localStorage.removeItem(APP_TOKEN);
            AdfiUtil.removeFromStorage('SESSION');
            this.user = null;
            window.location.replace('login');
        };
        const logoutErr = async (err) => {
           await this.post('/session/reset', null).subscribe(logoutOk, (e) => {
               this.growl.errorMessage(e);
           });
        };
        if (!this.isLogged()) {
            window.location.replace('login');
        }
        return this.get(AdfiRest.logout).subscribe(logoutOk, logoutErr);
    }

    public validPermissionAllRoles(entity: string, action: string, periodState= PeriodState.OPEN) {
        periodState = !periodState ? PeriodState.OPEN : periodState;
        const currentPeriod = this.getUserPeriod();
        return this.user.rolList.find((value: any) =>
            (value.accessRights.find(v =>
                ((v.module === entity && ( !action || v.action === action)))
            ) !== undefined)
        ) !== undefined && (currentPeriod.estado === periodState || periodState === PeriodState.NO_STATUS
            || (action === Permission.LIST || action === Permission.VIEW || action === Permission.CHANGE_CENCOS || action === Permission.PDF
            || action === undefined));
    }

    public validPermission(entity: string, action: string, periodState= PeriodState.OPEN) {
        periodState = !periodState ? PeriodState.OPEN : periodState;
        const currentPeriod = this.getUserPeriod();
        return this.user.role.accessRights.find(v =>
            ((v.module === entity && ( !action || v.action === action)))
        ) !== undefined && (currentPeriod.estado === periodState || periodState === PeriodState.NO_STATUS
            || (action === Permission.LIST || action === Permission.VIEW || action === Permission.CHANGE_CENCOS || action === Permission.PDF
                || action === undefined));
    }

    public hasRole(idRole: number) {
        return this.user && this.user.rolList && this.user.rolList.length && this.user.rolList.find((value: any) => value._id === idRole) !== undefined;
    }

    public isLogged(): boolean {
        const user = AdfiService.extractToken();
        return user !== null && user !== undefined && user !== '' && user.trim() !== '';
    }

    public loadUser(): Promise<boolean> {
        this.loading.show();
        const promise = (resolve: (val: boolean) => void, reject: (val: boolean) => void) => {
            // 1.1.
            this.get('/api/user-details').subscribe(async (value: any) => {
                this.user = value;
                const oldSession = AdfiUtil.getFromStorage('SESSION');
                if (oldSession && oldSession !== value.session) {
                    this.growl.error('Sesión', 'Sesión finalizada ó invalida');
                    resolve(false);
                }
                AdfiUtil.saveInStorage('SESSION', value.session);
                this.gaService.gtag('set', 'GA_MEASUREMENT_ID', { user_id: this.user.id});
                this.gaService.set({ user_id: this.user.id});
                await this.loadPeriods();
                this.loading.hide();
                resolve(true);
                // setInterval(() => this.loadNotifications(), 300000);
            }, error => {
                if (window.location.pathname.startsWith('/nav')) {
                    AdfiUtil.saveInStorage('NEED_REDIRECT', window.location.pathname);
                }
                this.loading.hide();
                resolve(false);
            });
        };
        return new Promise<boolean>(promise);
    }

    public getUserPeriod() {
        if (this.periods) {
            return this.periods.find((period) => (period.year === this.user.period));
        }
        return {estado: undefined};
    }

    downloadPdf(data, type, fileName) {
        if ((window.navigator as any) && (window.navigator as any).msSaveOrOpenBlob) {
            const blob = new Blob([data], { type });
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
        } else {
            const file = new File([data], fileName, { type });
            const exportUrl = URL.createObjectURL(file);
            window.open(exportUrl, '_blank');
            setTimeout(() => {
                window.URL.revokeObjectURL(exportUrl);
            }, 300000);
        }
    }

    getPeriodByUser(receiver: PartialObserver<any>) {
        const next = (v) => {
            receiver.next({fechaApertura: v.fechaApertura ? new Date(v.fechaApertura) : null,
                fechaCierre: v.fechaCierre ? new Date(v.fechaCierre) : null, estado: v.estado});
        };
        if (this.periods) {
            const period = this.periods.find(v => v.year === this.user.period);
            if (period) {
                next(period);
                return;
            }
        }
        this.graph.getFirstEntity(NAME_ENTITY.PeriodsPlatform.plural,
            'fechaApertura fechaCierre estado',
            `year: ${this.user.period}`, {next, error: receiver.error});
    }

    download(nameClass: string, filters: string, customReturn: string, type: string, fileName: string, actions = null, onFinish: () => void = null, group = '') {
        this.loading.show();
        const next = (d) => {
            if (d && d.fileName) {
                this.get(`/api/adfib/get/report/${d.fileName}`, undefined, 'arraybuffer').subscribe(
                    (data) => {
                            if (type.endsWith('pdf')) {
                                this.downloadPdf(data, type, fileName);
                            } else {
                                AdfiRest.exportFile(data, fileName);
                            }
                            if (onFinish) {
                                onFinish();
                            }
                            this.loading.hide();
                    }, (e) => {
                        this.growl.errorMessage(e);
                        this.loading.hide();
                    }
                );
            } else {
                this.growl.error('Reporte', 'Se ha presentado un error al generar el reporte, por favor contacta a soporte técnico');
                this.loading.hide();
            }
        };
        const error = (e) => {
            if(type == 'application/zip'){
                this.growl.error('Reporte', 'El archivo es muy grande, pronto se le estará enviando el enlace de descarga al correo');
            }else {
                this.growl.errorMessage(e);
            }
            this.loading.hide();
                    };
        this.graph.createEntity('Report', {nameClass, filters, type, actions, group}, customReturn, {next, error});
    }

    generateFile(nameClass: string, filters: string, customReturn: string, type: string, fileName: string, actions = null, onFinish: () => void = null, group = ''): Observable<Blob> {
        return new Observable<Blob>((observer) => {
            this.loading.show();
            const next = (d) => {
                if (d && d.fileName) {
                    this.get(`/api/adfib/get/report/${d.fileName}`, undefined, 'arraybuffer').subscribe(
                        (data: Blob) => {
                            if (type.endsWith('pdf')) {
                                this.downloadPdf(data, type, fileName);
                            } else {
                                AdfiRest.exportFile(data, fileName);
                            }
                            if (onFinish) {
                                onFinish();
                            }
                            this.loading.hide();
                            observer.next(data); 
                            observer.complete();
                        }, (e) => {
                            this.growl.errorMessage(e);
                            this.loading.hide();
                            observer.error(e); 
                        }
                    );
                } else {
                    this.growl.error('Reporte', 'Se ha presentado un error al generar el reporte, por favor contacta a soporte técnico');
                    this.loading.hide();
                    observer.complete(); 
                }
            };
    
            const error = (e) => {
                this.growl.errorMessage(e);
                this.loading.hide();
                observer.error(e); // Notificar un error al Observable
            };
    
            this.graph.createEntity('Report', { nameClass, filters, type, actions, group }, customReturn, { next, error });
        });
    }

    getJson(nameClass: string, filters: string, customReturn: string,  next, actions = null) {
        this.loading.show();
        const error = (e) => {
            this.growl.errorMessage(e);
            this.loading.hide();
        };
        this.graph.createEntity('Report', {nameClass, filters, type: 'json', actions}, customReturn, {next, error});
    }

    getTimeControl( module, next, error) {
        const cd = new Date();
        const filter = `ctrModule: "${module}", startDate: {before: "${cd.toUTCString()}"}, endDate: {after: "${cd.toUTCString()}"}`;
        this.graph.getFirstEntity('prIniControls', 'period startDateRegister endDateRegister', filter, {next, error});
    }

    getCountDown(date) {
        const init = new Date();
        const end = new Date(date);
        return (end.getTime() / 1000) - (init.getTime() / 1000);
}

    public getCenCos(period:any) {
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportCenCosUrl(period),{headers});
    }

    public getBudgetExecution(id_cc: any, period:any, level:any){
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportBudgetExecutionByCategoryUrl(id_cc,period,level),{headers});
    }

    public getFuncInve(id_cc: any, period:any){
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportBudgetExecutionFuncInve(id_cc,period),{headers});
    }

    public getCenCosInfo(n_id_cc: number) {
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportCenCosInfoUrl(n_id_cc),{headers});
    }

    public getGeneralIncomesAndExpenses(year:number){
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportGeneralIncomesAndExpensesURL(year),{headers});
    }

    getActivePassive(period: number, n_id_cc: number) {
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportActivePassiveURL(period,n_id_cc),{headers});
    }
    
    getAvailabilities(period: number, n_id_cc: number) {
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportAvailabilitiesURL(period,n_id_cc),{headers});
    }

    getHelps(period: number) {
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportHelpsURL(period),{headers});
    }

    getMainItemsBudget(period: number, n_id_cc: string) {
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportMainItemsURL(period, n_id_cc),{headers});
    }

    getBankStatementValue(period: number, month: number, account:string) {
        const headers = AdfiService.loadHeaders();
        return this.http.get<any>(AdfiRest.exportBankStatementValue(period, month, account),{headers});
    }

}

<div class="container pb-5">
    <div class="row pt-2">
        <mat-card class="col-12 mb-2">
            <mat-card-title> Movimientos bancarios </mat-card-title>
            <mat-card-content>
                <div class="row pt-2">
                    <mat-form-field  class="col-12 col-md-7">
                        <mat-label>Seleccione la cuenta bancaria</mat-label>
                        <mat-select disabled="{{!bankAccounts}}" placeholder="Seleccionar" (selectionChange)="selectBankAccount($event.value)">
                            <mat-select-trigger>
                                <ng-container *ngIf="bankAccount">
                                    <b>N° </b>({{ bankAccount.numero }})
                                    <b> - </b>{{ bankAccount.nombre}}
                                    <b> - </b>$ {{ bankAccount.currentValue | number: '1.2-2'}}
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option *ngFor="let account of bankAccounts" [value]="account">{{ account.nombre }}
                                {{ account.numero }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-2">
                        <mat-label>Seleccione el tipo de movimiento</mat-label>
                        <mat-select disabled="{{bankAccount === undefined}}" placeholder="Seleccionar" (selectionChange)="selectType($event.value)">
                            <mat-select-trigger>
                                <ng-container *ngIf="type">
                                   {{ type.text }}
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option *ngFor="let type of types" [value]="type">{{ type.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-12 col-md-3">
                        <div class="row justify-content-around align-items-center">
                            <div class="col-10 pl-sm-3">
                                <h6 class="d-inline"> Fecha Inicial:</h6>
                                <ng-container *ngIf="selectedDates">
                                    {{ selectedDates.startDate | date: 'mediumDate':null:'es-co'}}
                                </ng-container> <br>
                                <h6 class="d-inline"> Fecha Final:</h6>
                                <ng-container *ngIf="selectedDates">
                                    {{ selectedDates.endDate | date: 'mediumDate':null:'es-co'}}
                                </ng-container>
                            </div>
                            <div class="col-2 d-flex align-items-center flex-column">
                                <button mat-mini-fab color="primary" disabled="{{bankAccount === undefined}}"
                                    matTooltip="Seleccionar fechas" (click)="selectDates()">
                                    <mat-icon>calendar_today</mat-icon>
                                </button>
                                <button mat-mini-fab color="primary" disabled="{{bankAccount === undefined}}"
                                    matTooltip="Reiniciar Filtros" (click)="resetFilters()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="row pt-3">
        <div class="col-12">
            <app-table [columns]="columnsAll" (reload)="this.loadData()" [items]="data" (loaded)="loading.hide()"
                [externalCondition]="bankAccount === undefined" [headerGroupBy]="columnsHeaderGroupAll"
                [footerGroupBy]="columnsFooterGroupAll">
                <div class="footer">
                    <div class="col-12 pt-3">
                        <div class="row justify-content-end">
                            <button mat-raised-button [disabled]="!this.bankAccount" (click)="export('xlsx')"
                                class="mr-2" color="primary" matTooltip="Exportar a excel">
                                <mat-icon>receipt_long</mat-icon> Exportar a excel
                            </button>
                            <button mat-raised-button [disabled]="!this.bankAccount" (click)="export('pdf')"
                                class="mr-2" color="primary" matTooltip="Exportar a pdf">
                                <mat-icon>description</mat-icon>Exportar a pdf
                            </button>
                        </div>
                    </div>
                </div>
            </app-table>
        </div>
    </div>
</div>
<div class="col-12" *ngIf="!loadingDates;else loadingList">
    <div class="day-list row" *ngIf="listDates.size; else noDates">
        <div class="child col-12" *ngFor="let day of listDates | keyvalue">
            <div class="date">
                <ul>
                    <li>
                      <a href="https://storage.googleapis.com/files_resolutions/291f1easw3465e9349531dcds4804f2b2075">
                        <h6>{{ day.value.date.format('dddd') | titlecase}}</h6>
                        <h1>{{ day.value.date.format('DD') }}</h1>
                        <h6>{{ day.value.date.format( 'MMMM') | titlecase }}</h6>
                      </a>
                    </li>
                </ul>
            </div>
            <div class="d-flex flex-column justify-content-center box">
                <div class="row header">
                    <span class="circle primary"></span>
                    <div class="col-12 text-center">
                        Actividades
                    </div>
                    <span class="circle primary"></span>
                </div>
                <div id="tabs2">
                    <mat-list headerPosition="below">
                        <mat-list-item class="text-date" *ngFor="let event of day.value.events; let index=index;">
                          <span *ngIf="day.value.events.length > 1" class="pr-2">{{index+1}} - </span> {{event.title}}
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
    </div>
    <div class="child col-12">
        <div class="date">
            <ul>
                <li>
                  <a>
                    <h6>Lunes</h6>
                    <h1>25</h1>
                    <h6>Noviembre</h6>
                  </a>
                </li>
            </ul>
        </div>
        <div class="d-flex flex-column justify-content-center box">
            <div class="row header">
                <span class="circle primary"></span>
                <div class="col-12 text-center">
                    Actividades
                </div>
                <span class="circle primary"></span>
            </div>
            <div id="tabs2">
                <mat-list headerPosition="below">
                    <mat-list-item class="text-date">
                      Fecha límite para registro de Presupuesto Inicial 2025 y Plan Anual de Adquisiciones 2025. (Período para el registro PI Y PAA 2025, entre 28 oct 2024 al 25 nov 2024.)
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>

    <ng-template #noDates>
        <div class="child col-12">
            <div class="date">
                <ul>
                    <li>
                      <a>
                        <h6>Lunes</h6>
                        <h1>25</h1>
                        <h6>Noviembre</h6>
                      </a>
                    </li>
                </ul>
            </div>
            <div class="d-flex flex-column justify-content-center box">
                <div class="row header">
                    <span class="circle primary"></span>
                    <div class="col-12 text-center">
                        Actividades
                    </div>
                    <span class="circle primary"></span>
                </div>
                <div id="tabs2">
                    <mat-list headerPosition="below">
                        <mat-list-item class="text-date">
                          Fecha límite para registro de Presupuesto Inicial 2025 y Plan Anual de Adquisiciones 2025. (Período para el registro PI Y PAA 2025, entre 28 oct 2024 al 25 nov 2024.)
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
        <!-- <div class="row box">
            <div class="col-12">
                 <div class="row header">
                    <span class="circle primary"></span>
                    <div class="col-12 text-center">
                        Sin fechas para la vigencia {{period}}
                    </div>
                    <span class="circle primary"></span>
                </div>
            </div>
        </div> -->
    </ng-template>
</div>
<ng-template #loadingList>
    <div class="col-12">
        <div class="row">
            <div class="col-2 items_load m-2" style="height: 100px"></div>
            <div class="col-9 items_load m-2 header" style="height: 100px">
                <span class="circle" style="background: #94999f"></span>
                <div class="row justify-content-center pt-4">
                    <div class="col-5 items_load" style="height: 20px; background-color: #94999f"></div>
                    <div class="col-12" style="height: 10px;"></div>
                    <div class="col-10 items_load" style="height: 25px; background-color: #94999f"></div>
                </div>
                <span class="circle" style="background: #94999f"></span>
            </div>
        </div>
    </div>
</ng-template>

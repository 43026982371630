import {FormGroup} from '@angular/forms';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import { Inject, Injectable } from '@angular/core';
export interface DynamicFlatNode {
  /** @Type integer @field notNull */
  year?: number;
  /** @field notNull */
  nature?: string;
  /** @field notNull */
  group?: string;
  /** @field notNull */
  subGroup?: string;
  /** @field notNull */
  major?: string;
  /** @field notNull */
  subAccount?: string;
  /** @field notNull */
  aux1?: string;
  /** @field notNull */
  aux2?: string;
  /** @field notNull */
  aux3?: string;
  minicode?: string;
  /** @field notNull */
  description?: string;
  /** @field notNull */
  type?: string;
  /** @field notNull */
  cencos?: string;
  isLoading: boolean;
  expandable: boolean;
  level: number;
}
export const LEVEL = 'level';
export const LOADING = 'isLoading';
export const EXPANDABLE = 'expandable';
export const TREE_ACTIONS = [
  {
    action: 'group',
    depend: 'subGroup',
    digits: 1,
    value: '0'
  },
  {
    action: 'subGroup',
    depend: 'major',
    digits: 1,
    value: '00'
  },
  {
    action: 'major',
    digits: 2,
    depend: 'subAccount',
    value: '00'
  },
  {
    action: 'subAccount',
    depend: 'aux1',
    digits: 2,
    value: '00'
  },
  {
    action: 'aux1',
    depend: 'aux2',
    digits: 2,
    value: '00'
  },
  {
    action: 'aux2',
    depend: 'aux3',
    digits: 2,
    value: '0000'
  }
];
@Injectable()
export class AccountsService {
  constructor(@Inject(AdfiGraphqlService) private adfiGraphql: AdfiGraphqlService) {}

  async initialData(next, error) {
    const filter = `${TREE_ACTIONS[0].depend}: "${TREE_ACTIONS[0].value}", order: { ${TREE_ACTIONS[0].action}: "ASC" }`;
    const count = await this.adfiGraphql.countEntity('accounts', filter);
    this.adfiGraphql.getEntity('accounts', `${TREE_ACTIONS[0].action !== 'minicode' ? TREE_ACTIONS[0].action : ''} minicode description type`,
        count, filter, {next, error}, false, undefined, (e) => {
          e[LEVEL] = 0;
          e[LOADING] = false;
          e[EXPANDABLE] = true;
          return e;
        });
  }

  async getChildren(node: DynamicFlatNode, form: FormGroup, nextStep, error) {
    form.get(TREE_ACTIONS[node.level].action).setValue(node[TREE_ACTIONS[node.level].action]);
    let query = '';
    let miniCode = '';
    let level;
    for (level = 0; level < TREE_ACTIONS.length && level <= node.level; level++) {
      miniCode += form.controls[TREE_ACTIONS[level].action].value;
      query += `${TREE_ACTIONS[level].action}: "${form.get(TREE_ACTIONS[level].action).value}", `;
    }
    let field;
    const otherFilteer = miniCode.startsWith('111005') || miniCode.startsWith('111006');
    if (level > 3 && otherFilteer) {
      field = 'minicode';
      query = `minicode: "${miniCode}", cencos: "${form.get('cencos').value}"`;
    } else {
      field = TREE_ACTIONS[level].action;
      query += `${TREE_ACTIONS[level].depend}: "${TREE_ACTIONS[level].value}"`;
    }
    const filter = `${query}, order: { ${field}: "ASC" }`;
    const count = await this.adfiGraphql.countEntity('accounts', filter);
    const next  = (d) => {
      if (level <= 3 || !otherFilteer) {
        d.shift();
      }
      nextStep(d);
    };
    this.adfiGraphql.getEntity('accounts', `${field !== 'minicode' ? field : ''} minicode description type`,
        count, filter, {next, error}, false, undefined, (e) => {
          e[LEVEL] = level;
          e[LOADING] = false;
          e[EXPANDABLE] = !otherFilteer && level < 5;
          return e;
        });
  }
}

<div class="container pb-5">
    <div class="row pt-2 justify-content-center">
        <mat-card class="col-12 col-md-10 mb-2">
            <mat-card-title class="mb-md-5 mb-3"></mat-card-title>
            <div class="row justify-content-center mb-5">
                <form class="col-10 col-lg-8" [formGroup]="form">
                    <section class="row">
                        <div class="col-md-4 col-12">
                            <div class="row">
                                <span class="col-12 mat-card-title">Datos del perfil de Usuario</span>
                            </div>
                            <div class="full-width d-flex justify-content-center align-items-center" style="height: calc(100% - 2.3em)">
                                <img class="col-5 col-md-8 col-lg-5 no-select" src="assets/images/cv.svg" alt="profile">
                            </div>
                        </div>
                        <div class="col-md-8 co-12">
                            <div class="row">
                                <mat-form-field appearance="legacy" class="col-12">
                                    <mat-label>Nombres</mat-label>
                                    <input matInput formControlName="name" required>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="col-12">
                                    <mat-label>Apellidos</mat-label>
                                    <input matInput formControlName="lastname" required>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="col-12">
                                    <mat-label>Sexo</mat-label>
                                    <mat-select formControlName="genre" required>
                                        <mat-option *ngFor="let opt of [{key: 'W', label: 'Mujer'}, {key: 'M', label: 'Hombre'}]" [value]="opt.key">
                                            {{opt.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-form-field appearance="legacy" class="col-md-4 col-12">
                            <mat-label>Celular</mat-label>
                            <app-tel-input formControlName="phone"></app-tel-input>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="col-md-8 col-12">
                            <mat-label>Documento</mat-label>
                            <input matInput formControlName="document" readonly="true">
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="col-12">
                            <mat-label>Correo de Contacto</mat-label>
                            <input matInput formControlName="mail">
                        </mat-form-field>
                    </section>
                </form>
                <section class="col-10 col-lg-8 mb-1" *ngIf="adfiService.user.role._id === 142 || adfiService.user.role._id === 162">
                    <div class="d-flex justify-content-around flex-wrap">
                        <div  class="p-3 mat-elevation-z5  mx-0 my-2 d-flex flex-column align-items-center col-md-4 col-7"
                              matRipple
                              [matRippleColor]="color">
                            <div class="d-flex flex-column icon-container align-items-center cursor-pointer w-100" style="flex: 1 1 auto; min-height: 50px;" (click)="uploadDocument('DI')">
                                <div class="flex-row">
                                    <img class="icon no-select" src="assets/images/Recurso%203.svg">
                                </div>
                            </div>
                            <div class="d-flex flex-column no-select">
                                <section class="mat-card-title cards-text cursor-pointer text-center no-select" (click)="uploadDocument('DI')">Documento de Identificación</section>
                                <button *ngIf="role?.fileDocument" mat-stroked-button color="primary" (click)="downloadFile('fileDocument')">Descargar Archivo Cargado</button>
                            </div>
                        </div>

                        <div class="p-3 mat-elevation-z5  mx-0 my-2 d-flex flex-column align-items-center col-md-4  col-7"
                             matRipple
                             [matRippleColor]="color">
                            <div class="d-flex flex-column align-items-center cursor-pointer w-100" style="flex: 1 1 auto; min-height: 50px;" (click)="uploadDocument('ADA')">
                                <div class="flex-row">
                                    <img class="icon no-select" src="assets/images/Recurso%208.svg">
                                </div>
                            </div>
                            <div class="d-flex flex-column no-select">
                                <section class="text-center mat-card-title cursor-pointer cards-text no-select" (click)="uploadDocument('ADA')" >Acto Administrativo de Nombramiento</section>
                                <button *ngIf="role?.fileAppointment" mat-stroked-button color="primary" (click)="downloadFile('fileAppointment')">Descargar Archivo Cargado</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="col-10 col-lg-8">
                    <div class="row justify-content-center">
                        <button [disabled]="form.invalid || !role?.fileAppointment || !role?.fileDocument" mat-raised-button type="button" color="primary" (click)="save()">
                            Actualizar
                        </button>
                    </div>
                </section>
            </div>
        </mat-card>
    </div>
</div>

<div class="container-help-desk">
    <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOpen"
    >
        <mat-card class="card-help-desk">
            <form class="form-help-desk" [formGroup]="helpDeskForm" #form="ngForm" (submit)="close(form)">

                <mat-form-field class="form-help-desk" >
                    <mat-label>Llamadas</mat-label>
                    <mat-select #callInput (selectionChange)="onSelectCall($event.value)" formControlName="callList">
                        <mat-option *ngFor="let call of callFiles"
                                    [value]="call" [disabled]="false"><p style="font-size: 11px">{{ call.name }}</p></mat-option>
                    </mat-select>

                    <mat-progress-bar *ngIf="loadingCalls" mode="indeterminate"
                                      class="over-head"></mat-progress-bar>
                    <mat-datepicker-toggle matSuffix (click)="loadCalls()">
                        <mat-icon matDatepickerToggleIcon>sync</mat-icon>
                    </mat-datepicker-toggle>
                </mat-form-field>

                <audio *ngIf="audioURL" controls preload="metadata" style="width:100%;" #audioOption (play)="playAudio()" autoplay>
                    <source [src]="audioURL"/>
                    <p>Your browser does not support HTML5 audio.</p>
                </audio>
                <mat-form-field class="form-help-desk" >
                    <mat-label>TIPO DE SOLICITANTE</mat-label>
                    <mat-select required (selectionChange)="onSelectTYpeUser($event)">
                        <mat-option value="1"><p style="font-size: 11px">RECTOR O AUXILIAR</p></mat-option>
                        <mat-option value="202"><p style="font-size: 11px">CONTADOR SEB</p></mat-option>
                    </mat-select>
                    <mat-progress-bar *ngIf="loadingEmployees" mode="indeterminate"
                                      class="over-head"></mat-progress-bar>
                </mat-form-field>
                <mat-form-field class="form-help-desk">
                    <mat-label>Municipio</mat-label>
                    <input matInput #townInput (keyup.enter)="searchTown()" [matAutocomplete]="autoTown"
                           formControlName="town">
                    <mat-progress-bar *ngIf="loadingTown" mode="indeterminate" class="over-head"></mat-progress-bar>
                    <mat-autocomplete #autoTown="matAutocomplete" (optionSelected)="onSelectTown($event.option.value)"
                                      [displayWith]="displayFnTown">
                        <mat-option *ngFor="let town of townList | async" [value]="town">
                            <p style="font-size: 11px">{{ town.nombre }}</p>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="form-help-desk">
                    <mat-label>Centro de Costo</mat-label>
                    <input matInput #centerInput (keyup.enter)="onEnterSearch()" [matAutocomplete]="auto" required
                           formControlName="centerCost">
                    <mat-progress-bar *ngIf="loadingCenter" mode="indeterminate" class="over-head"></mat-progress-bar>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectCenter($event.option.value)"
                                      [displayWith]="displayFnCenter">
                        <mat-option *ngFor="let center of centerCostList | async" [value]="center">
                            <p style="font-size: 11px">{{ center.deDescripcion }}</p>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <br>
                <mat-form-field class="form-help-desk" >
                    <mat-label>Solicitante</mat-label>
                    <mat-select required formControlName="employee" #employeeInput>
                        <mat-option *ngFor="let employee of employeesList | async"
                                    [value]="employee"><p style="font-size: 11px">{{ employee.name }} {{employee.apellidos}}</p></mat-option>
                    </mat-select>
                    <mat-progress-bar *ngIf="loadingEmployees" mode="indeterminate"
                                      class="over-head"></mat-progress-bar>
                </mat-form-field>


                <mat-form-field class="form-help-desk" appearance="fill">
                    <mat-label>Tipo</mat-label>
                    <input matInput #typeInput [matAutocomplete]="autoType" required
                           formControlName="type" style="font-size: 11px">
                    <mat-autocomplete #autoType="matAutocomplete" (optionSelected)="onSelectType($event.option.value)"
                                      [displayWith]="displayFnType">
                        <mat-option *ngFor="let incidence of categoriesFilter | async" [value]="incidence" class="multiple-line-text-option">
                            <p style="font-size: 11px">{{ incidence.typeIncidence }} : {{ incidence.name }} </p>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-datepicker-toggle matSuffix (click)="clearTypeIncidence()">
                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                    </mat-datepicker-toggle>
                </mat-form-field>
                <br>
                <mat-form-field class="form-help-desk">
                    <mat-label>Observación</mat-label>
                    <textarea #obs cdkTextareaAutosize #autosize="cdkTextareaAutosize" matInput placeholder="Observación.." required formControlName="observation" cdkAutosizeMinRows="10"
                              cdkAutosizeMaxRows="20" maxlength="2500"></textarea>
                    <mat-hint align="end">{{obs.value.length}} / 2500</mat-hint>
                </mat-form-field>
                <div class="col-12 mt-3">
                    <div class="row justify-content-end">
                        <button mat-raised-button type="button" (click)="clearInputs()">Cancelar</button>
                        <button mat-raised-button type="submit" color="primary"
                                [disabled]="loadingCenter || loadingEmployees || helpDeskForm.invalid">Guardar
                        </button>
                    </div>
                </div>
            </form>
        </mat-card>
    </ng-template>
    <button mat-mini-fab color="primary" aria-label="Example icon button with a delete icon" (click)="open()"
            type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <mat-icon>phone</mat-icon>
    </button>
</div>

<div class="d-flex flex-column flex-xl-row w-100">
    <div class="flex-grow-1">
        <h4 class="text-center">{{titleL}}</h4>
        <app-tree-presupuesto #incomes  [customSave]="save" [field]="field" [item]="item" tipoPresupuesto="I" entity="collectionQueryTreeModifications" [sumValue]="false"
                              strQuery="nombre  minipresu descompuesto agrupador valor estadoPresupuesto idAp modificationId detailModificationId detailModification{id} valueAvailable"
                              idGroup="idAp" [decimals]="2"
        ></app-tree-presupuesto>
    </div>
    <div class="flex-grow-1">
        <h4 class="text-center">{{titleR}}</h4>
        <app-tree-presupuesto #expenses  [customSave]="save" [field]="field" [item]="item" tipoPresupuesto="E" entity="collectionQueryTreeModifications" [sumValue]="false"
                              strQuery="nombre  minipresu descompuesto agrupador valor estadoPresupuesto idAp modificationId detailModificationId detailModification{id} valueAvailable"
                              idGroup="idAp" [decimals]="2"
        ></app-tree-presupuesto>
    </div>
</div>
<div class="d-flex justify-content-center w-100">
    <p class="text-success row-green w-50 text-center"
                       style="font-size: 1rem; padding: 1rem; border-radius: 0.5rem"><strong>
                        Historial 
                        </strong>
                        <a class="stretched-link text-success" style="text-decoration-line: underline; cursor: pointer"
                                                              (click)="showHistory=!showHistory">{{!showHistory ? 'Ver historial' : 'Ocultar historial'}}</a>
                    </p> <br>
    </div>
    <div *ngIf="(showHistory && field.column.entity === 'DetailModification')" class="w-100">
        <app-list entityName="HistoryModification" module="Presupuesto" group="M"
                                                   [dataEntity]="{enableAdd: false, customTitle: 'Historial'}"
                                                   [defaultQuery]="[{column:'modification', value: item._id }]"></app-list></div>
<div *ngIf="(showHistory && field.column.entity === 'DetalleTraslado')" class="w-100">
        <app-list entityName="HistoryModification" module="Presupuesto" group="T"
                                                   [dataEntity]="{enableAdd: false, customTitle: 'Historial'}"
                                                   [defaultQuery]="[{column:'transfer', value: item._id }]"></app-list>
</div>


import {Apollo} from 'apollo-angular';
import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';

import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {AdfiService} from '@services/adfi-service';
import {AdfiRest} from '@services/adfi-rest';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-help-desk',
    templateUrl: './help-desk.component.html',
    styleUrls: ['./help-desk.component.css']
})
export class HelpDeskComponent implements OnInit {

    @ViewChild('centerInput') centerAutocomplete: ElementRef<HTMLElement>;
    @ViewChild('townInput') townAutocomplete: ElementRef<HTMLElement>;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;
    @ViewChild('audioOption') audioPlayerRef: ElementRef;
    @ViewChild('callInput') callListInput: ElementRef<HTMLElement>;
    isOpen = false;
    helpDeskForm: FormGroup;
    townList: Observable<any[]>;
    centerCostList: Observable<any[]>;
    employeesList: Observable<any[]>;
    categories: Observable<any[]>;
    categoriesFilter: Observable<any[]>;
    valuesCategories: any[];
    callFiles: any[];
    loadingTown = false;
    loadingCenter = false;
    loadingEmployees = false;
    loadingCalls = false;
    initDate: Date;
    endDate: Date;
    audioURL;
    callID;
    numberCel = '';
    incomingCall = true;

    startDate = new Date(2020, 0, 1);
    private centercostId: string;

    constructor(private apollo: Apollo, private adfiGraphql: AdfiGraphqlService, private alert: AdfiGrowlService,
                private ngZone: NgZone, private adfiservice: AdfiService, private serviwRest: AdfiService,
                private graphql: Apollo, private loading: LoadingService) {
    }

    ngOnInit() {

    }

    loadCalls() {
        this.helpDeskForm.get('callList').setValue(null);
        this.audioURL = null;
        this.loadingCalls = true;
        this.helpDeskForm.get('callList').disable();
        this.helpDeskForm.get('town').disable();
        this.serviwRest.get(AdfiRest.getCallsHelpDesk()).toPromise().then((data: any[]) => {
            if (typeof data === 'string') {
                window.open(data, '_blank');
            } else {
                this.callFiles = data;
                this.loadingCalls = false;
                this.helpDeskForm.get('callList').enable();
            }
        });
    }

    loadCategoriesIncident() {
        this.categories = this.apollo.watchQuery({
            query: this.adfiGraphql.getCategoriesIncedents()
        }).valueChanges.pipe(map((p) => p.data['categoriesIncidents'].edges.map((obj) => obj.node))
        );

        this.categories.subscribe((cat) => {
            this.valuesCategories = cat;
        });

    }

    searchTown() {
        const name = this.helpDeskForm.get('town').value.toUpperCase();
        if (typeof name === 'string') {
            this.loadingTown = true;
            this.helpDeskForm.get('town').disable();
            let subs;
            this.townList = this.apollo.query({
                query: this.adfiGraphql.searchTown(name)
            }).pipe(
                map((p) => p.data['municipios'].edges.map((obj) => obj.node))
            );
            const ok = (proofs: any[]) => {
                this.loadingTown = false;
                this.helpDeskForm.get('town').enable();
                setTimeout(() => {
                    this.townAutocomplete.nativeElement.blur();
                    this.townAutocomplete.nativeElement.focus();
                });
                subs.unsubscribe();
            };
            const err = (e) => {
                subs.unsubscribe();
                this.loadingTown = false;
                this.helpDeskForm.get('proof').enable();
                this.alert.errorMessage(e, 'Error al buscar los municipios');
            };
            subs = this.townList.subscribe(ok, err);
        }
    }

    onEnterSearch(id?) {
        if (!this.loadingCenter) {
            this.loadingCenter = true;
            this.helpDeskForm.get('centerCost').disable();
            let subs;
            this.centerCostList = this.apollo.query({
                query: this.adfiGraphql.searchCenter(id)
            }).pipe(
                map((p) => p.data['prCentroCostos'].edges.map((obj) => obj.node))
            );
            const ok = (proofs: any[]) => {
                this.loadingCenter = false;
                this.helpDeskForm.get('centerCost').enable();
                setTimeout(() => {
                    this.centerAutocomplete.nativeElement.blur();
                    this.centerAutocomplete.nativeElement.focus();
                });
                subs.unsubscribe();
            };
            const err = (e) => {
                subs.unsubscribe();
                this.loadingCenter = false;
                this.helpDeskForm.get('centerCost').enable();
                this.alert.errorMessage(e, 'Error al buscar la institución');
            };
            subs = this.centerCostList.subscribe(ok, err);
        }
    }

    onSelectCenter(center) {
        this.helpDeskForm.get('centerCost').disable();
        this.loadingEmployees = true;
        let subs;
        this.helpDeskForm.get('employee').disable();
        this.centercostId = center.id;
        this.employeesList = this.apollo.query({
            query: this.adfiGraphql.getEmployeesByCenter(center._id)
        }).pipe(
            map((p) => p.data['employees'].edges.map((obj) => obj.node))
        );
        const ok = (proofs: any[]) => {
            subs.unsubscribe();
            this.loadingEmployees = false;
            this.helpDeskForm.get('employee').enable();
        };
        const err = (e) => {
            subs.unsubscribe();
            this.loadingEmployees = false;
            this.helpDeskForm.get('employee').enable();
            this.alert.errorMessage(e, 'Error al cargar los empleados ');
        };
        subs = this.employeesList.subscribe(ok, err);
    }


    onSelectTown(town: any) {
        this.onEnterSearch(town._id);
        this.helpDeskForm.get('town').disable();
    }

    displayFnType(typeIncidence: any): string {
        return typeIncidence && typeIncidence.name ? typeIncidence.name : '';
    }

    displayFnCenter(center: any): string {
        return center && center.deDescripcion ? center.deDescripcion : '';
    }

    displayFnTown(town: any): string {
        return town && town.nombre ? town.nombre : '';
    }

    clearInputs() {
        this.helpDeskForm.reset();
        this.audioURL = null;
        this.helpDeskForm.get('town').disable();
        // this.helpDeskForm.get('callList').setValue(null);
        this.helpDeskForm.get('callList').enable();
    }

    open() {
        this.isOpen = !this.isOpen;
        this.helpDeskForm = new FormGroup({
            town: new FormControl('', Validators.required),
            centerCost: new FormControl({value: '', disabled: true}, Validators.required),
            employee: new FormControl({value: '', disabled: true}, Validators.required),
            type: new FormControl('', Validators.required),
            observation: new FormControl('', Validators.required),
            dateCall: new FormControl(null, null),
            callList: new FormControl(null, Validators.required)
        });
        this.loadCategoriesIncident();

        this.categoriesFilter = this.helpDeskForm.get('type').valueChanges
            .pipe(
                startWith(''),
                map(value => this.onSearchTypeInput(value))
            );
        this.loadCalls();
    }

    close(form: FormGroupDirective) {
        this.loading.show();
        const call: any = {
            _id: this.callID,
            observation: this.helpDeskForm.get('observation').value,
            endDate: this.endDate,
            initDate: this.initDate,
            typeCall: this.incomingCall,
            auxiliaryCenter: this.helpDeskForm.get('employee').value,
        };
        const incidenceType = this.helpDeskForm.get('type').value;
        const cencos = this.helpDeskForm.get('centerCost').value;
        this.graphql.mutate({
                mutation: this.adfiGraphql.saveCall(call, cencos.id,
                    `/api/employees/${this.adfiservice.user.id}`, incidenceType.id, this.numberCel)
            }
        ).subscribe(() => {
            this.alert.info('Finalizado', 'Llamada agregada correctamente');
            // this.isOpen = !this.isOpen;
            this.helpDeskForm.get('town').enable();
            // this.helpDeskForm.get('centerCost').enable();
            this.clearInputs();
            form.resetForm();
            this.helpDeskForm.get('callList').setValue(null);
            this.helpDeskForm.get('callList').enable();
            this.loading.hide();
            const removeIndex = this.callFiles.map((item) => (item.id)).indexOf(this.callID);
            this.callFiles.splice(removeIndex, 1);
            this.audioURL = null;
        }, (err) => {
            this.loading.hide();
            this.alert.errorMessage(err, 'Error al guardar el registro');
        });
    }

    clearStartDate() {
        this.helpDeskForm.get('dateCall').setValue(null);
    }

    onSearchTypeInput(name) {
        if (typeof name !== 'string') {
            return [];
        }
        const filterName = name.toUpperCase();

        return this.valuesCategories ? this.valuesCategories.filter((option) => {
            return option.name.toUpperCase().includes(filterName) || option.typeIncidence.toUpperCase().includes(filterName);
        }) : [];
    }

    onSelectType(value: any) {
        this.helpDeskForm.get('type').disable();
    }

    clearTypeIncidence() {
        this.helpDeskForm.get('type').setValue('');
        this.helpDeskForm.get('type').enable();
    }

    getDateFromNameFile(name: string) {
        const arr = name.match(/[0-9]+/g);
        if (arr.length === 8) {
            if (`${arr[0]}`.length === 10) {
                this.initDate = new Date(
                    parseInt(arr[1], 10),
                    parseInt(arr[2], 10) - 1, // Mes enero =0, diciembre =11
                    parseInt(arr[3], 10),
                    parseInt(arr[4], 10),
                    parseInt(arr[5], 10),
                    parseInt(arr[6], 10)
                );
                this.numberCel = arr[0];
            } else {
                this.initDate = new Date(
                    parseInt(arr[0], 10),
                    parseInt(arr[1], 10) - 1, // Mes enero =0, diciembre =11
                    parseInt(arr[2], 10),
                    parseInt(arr[3], 10),
                    parseInt(arr[4], 10),
                    parseInt(arr[5], 10)
                );
            }
            this.incomingCall = name.includes('[0]');
        } else if (arr.length === 4) {
            this.initDate = new Date(parseInt(arr[2], 0));
            this.incomingCall = arr[1] === '0';
            this.numberCel = arr[0];
        } else {
            const telNumberArr = name.match(/(\([0-9]{3}\)) [0-9]{3}-[0-9]{4}/g);
            if (telNumberArr && telNumberArr !== []){
                let telNumber = telNumberArr[0];
                telNumber = telNumber.replace('(', '');
                telNumber = telNumber.replace(')', '');
                telNumber = telNumber.replace(' ', '');
                telNumber = telNumber.replace('-', '');
                this.numberCel = telNumber;
            }
            // en el caso de tener un numero privado la fecha esta en la posicion 1
            if (arr.length === 3) {
                this.initDate = new Date(arr[1]);
            } else {
                this.initDate = new Date();
            }
        }
    }

    playAudio() {
        const audio = this.audioPlayerRef.nativeElement;
        audio.onloadedmetadata = () => {
            const duration = this.audioPlayerRef.nativeElement.duration;
            this.endDate = new Date(this.initDate);
            this.endDate.setSeconds(this.endDate.getSeconds() + duration);
            this.helpDeskForm.get('town').enable();
        };

    }

    onSelectCall(call: any) {
        this.audioURL = `https://drive.google.com/uc?id=${call.id}&export=download`;
        this.callID = call.id;
        this.getDateFromNameFile(call.name);
        this.helpDeskForm.get('callList').disable();
        this.helpDeskForm.get('type').enable();
        setTimeout(() => {
            this.playAudio();
        }, 10);
    }

    onSelectTYpeUser($event: MatSelectChange) {
        if ($event.value !== '1') {
            let subs;
            this.helpDeskForm.get('employee').disable();
            this.helpDeskForm.get('town').disable();
            this.employeesList = this.apollo.query({
                query: this.adfiGraphql.getEmployeesRole($event.value)
            }).pipe(
                map((p) => p.data['employees'].edges.map((obj) => obj.node))
            );
            const ok = (proofs: any[]) => {
                subs.unsubscribe();
                this.loadingEmployees = false;
                this.helpDeskForm.get('employee').enable();
            };
            const err = (e) => {
                subs.unsubscribe();
                this.loadingEmployees = false;
                this.helpDeskForm.get('employee').enable();
                this.alert.errorMessage(e, 'Error al cargar los empleados ');
            };
            subs = this.employeesList.subscribe(ok, err);
        } else {
            this.helpDeskForm.get('town').enable();
        }
    }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Entities, Pages, Permission } from '@Interfaces/index';
import { NavRoute } from 'src/app/utils/menu';
import { BankTransactionsComponent } from './bank-transactions/bank-transactions.component';

export const TreasuryRoutes: NavRoute[] = [
    {
      path: Pages.BANK_TRANSACTIONS,
      component: BankTransactionsComponent,
      entity: Entities.BANK_TRANSACTIONS,
      permission: Permission.LIST
    }
];

@NgModule({
  imports: [RouterModule.forChild(TreasuryRoutes)],
  exports: [RouterModule]
})
export class TreasuryRoutingModule { }

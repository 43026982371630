import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  ElementRef,
  Optional,
  Inject, Self, AfterViewInit
} from '@angular/core';
import * as moment from 'moment';
import {CalendarHeaderComponent} from '@Components/month-selector/calendar-header/calendar-header.component';
import {MatDatepicker} from '@angular/material/datepicker';
import {ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators} from '@angular/forms';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM',
  },
  display: {
    dateInput: 'MMMM',
    monthYearLabel: 'MMMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM',
  },
};

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MonthPickerComponent,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
  }
})
export class MonthPickerComponent implements OnInit, ControlValueAccessor, MatFormFieldControl<moment.Moment>, OnDestroy, AfterViewInit {

  static nextId = 0;

  @Input() style: 'Dropdown' | 'Selector' = 'Selector';
  @Input() format = 'MMMM';
  @Input() locale = 'es';
  @Input() closeOnSelectMonth = true;
  @Input() customText = 'Seleccionar Mes';
  @Input() startDate = moment('01', 'MM');
  @Input() endDate = moment('12', 'MM');
  @Input() useFormControl: boolean;
  @Input() touchUI = true;
  @Input() showSuffix = false;
  @Output() monthChange = new EventEmitter<moment.Moment>();
  @Output() dateChange = new EventEmitter<moment.Moment>();

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  @Input()
  get disabled(): boolean {
    return this.disabledOwn;
  }
  set disabled(value: boolean) {
    this.disabledOwn = coerceBooleanProperty(value);
    this.disabledOwn ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }
  @Input()
  get placeholder(): string {
    return this.placeholderOwn;
  }
  set placeholder(value: string) {
    this.placeholderOwn = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this.requiredOwn;
  }
  set required(value: boolean) {
    this.requiredOwn = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @ViewChild(MatDatepicker) picker: MatDatepicker<moment.Moment>;
  @ViewChild('input') areaInput: HTMLInputElement;

  months: { name: string, month: moment.Moment }[] = [];

  exampleHeader = CalendarHeaderComponent;
  focused = false;
  controlType = 'month-input';
  id = `month-input-${MonthPickerComponent.nextId++}`;
  @Input() userAriaDescribedBy?: string;

  @Input()
  get value(): null | moment.Moment {
    if (this.form.valid) {
      const { month } = this.form.value;
      return month;
    }
    return null;
  }
  set value(month: any) {
    this.form.setValue({month});
    this.validate();
    this.onChange(this.value);
    this.stateChanges.next();
  }
  stateChanges = new Subject<void>();
  private placeholderOwn: string;
  private requiredOwn = false;
  private disabledOwn = false;
  form: FormGroup;

  constructor(
      formBuilder: FormBuilder,
      private focusMonitor: FocusMonitor,
      private elementRef: ElementRef<HTMLElement>,
      @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
      @Optional() @Self() public ngControl: NgControl) {
    this.form = formBuilder.group({
      month: [null, [Validators.required]]
    }
   );

    focusMonitor.monitor(elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.validate();
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  onChange = (_: any) => {};
  onTouched = () => {};

  validate() {
    if (this.formField){
      if (this.form.valid){
        this.formField._elementRef.nativeElement.classList.remove('mat-form-field-invalid');
      } else if (this.required) {
        this.formField._elementRef.nativeElement.classList.add('mat-form-field-invalid');
      }
    }
  }

  get empty() {
    const {month} = this.form.value;
    return !month;
  }
  get errorState(): boolean {
    return this.form.invalid && this.form.dirty;
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this.elementRef.nativeElement.querySelector('.month-input-container')!;
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick(event: MouseEvent): void {}
  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    if (this.style === 'Dropdown') {
      this.months = [];
      let start = moment('01', 'MM', this.locale);
      const end = moment('12', 'MM', this.locale);
      while (start <= end) {
        this.months.push( {name: start.format(this.format), month: start});
        start = moment(start).add(1, 'month');
      }
    }
  }

  ngAfterViewInit() {
    if (this.picker) {
      this.picker.touchUi = this.touchUI;
    }
  }

  monthSelected($event) {
    if (this.closeOnSelectMonth){
      this.picker.close();
      this.focused = false;
    }
    this.value = $event;
    this.monthChange.emit($event);
  }

  open() {
    if (this.picker){
      this.picker.touchUi = this.touchUI;
      this.picker.open();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }
}

import Timeout = NodeJS.Timeout;

export class AdfiGrowl {

    constructor(
        public id: string|number,
        public type: AdfiGrowlType,
        public title: string,
        public message: string,
        public timeout: number,
        public priority?: AdfiGrowlPriority,
        public timeoutDestroy?: number,
        public timerId?: Timeout,
        public loading?: boolean,
    ) { }

}

export enum AdfiGrowlType {
    success = 0,
    warning = 1,
    error = 2,
    info = 3
}

export enum AdfiGrowlPriority {
    VERY_IMPORTANT = 1,
    IMPORTANT = 2,
    NORMAL = 3,
    LOW = 4
}

<mat-card class="col-12 mb-2 justify-content-between">
  <mat-card-title>Certificado de Retención</mat-card-title>
  <mat-card-content>
    <div class="row pt-2">
      <ng-container class="col-12 col-md-4">
        <mat-form-field appearance="legacy" class="col-12 col-md-4">
          <mat-label>Terceros</mat-label>

          <input matInput readonly style="cursor:pointer;" placeholder="Selecciona una tercero" [(value)]="label">

          <button mat-icon-button matSuffix type="button" aria-label="Agregar" (click)="selectItem()">
            <mat-icon matTooltip="Agregar">playlist_add</mat-icon>
          </button>

          <button mat-icon-button matSuffix type="button" color="warn" aria-label="Cerrar" (click)="removeItem()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </ng-container>

      <mat-form-field class="col-12 col-md-2">
        <mat-label>Tipo de Certificado</mat-label>
        <mat-select [disabled]="!label" [(ngModel)]="type" placeholder="Seleccionar" (selectionChange)="clear()">
          <mat-option value="RF">Retención Renta</mat-option>
          <mat-option value="RI">Retención IVA</mat-option>
          <mat-option value="RC">Retención ICA</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-12 col-md-2">
        <mat-label>Vigencia</mat-label>
        <mat-select [disabled]="!label" [(ngModel)]="currentPeriod" placeholder="Seleccionar">
          <mat-option *ngFor="let period of periods" [value]="period.year">{{ period.year }}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="currentPeriod">
        <mat-form-field  appearance="legacy">
          <mat-label>Seccional</mat-label>
          <mat-select [disabled]="!label" [(ngModel)]="town" placeholder="Seleccionar">
            <mat-option *ngFor="let sectional of towns" [value]="sectional.town">{{ sectional.code }} - {{ sectional.town }}</mat-option>
          </mat-select>
          <mat-icon matSuffix class="icon-help" [matTooltip]='help' >help_outline</mat-icon>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="type && type !== 'RF' && town">
        <mat-form-field class="col-12 col-md-2">
          <mat-label>Seleccione el Rango</mat-label>
          <mat-select [(ngModel)]="range" placeholder="Seleccionar">
            <mat-option *ngIf="type && type === 'RC'" value="M">Mensual</mat-option>
            <mat-option value="B">Bimestral</mat-option>
            <mat-option value="C">Cuatrimestral</mat-option>
            <mat-option *ngIf="type && type === 'RC'" value="A">Anual</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="range && range !== 'A'">
          <mat-form-field class="col-12 col-md-2">
            <mat-label>Seleccione las fechas</mat-label>
            <mat-select *ngIf="range === 'M'" [(ngModel)]="dates" placeholder="Seleccionar">
              <mat-option *ngFor="let month of monthly" [value]="month">{{ month }}</mat-option>
            </mat-select>
            <mat-select *ngIf="range === 'B'" [(ngModel)]="dates" placeholder="Seleccionar">
              <mat-option *ngFor="let range of bimonthly" [value]="range">{{ range }}</mat-option>
            </mat-select>
            <mat-select *ngIf="range === 'C'" [(ngModel)]="dates" placeholder="Seleccionar">
              <mat-option *ngFor="let range of quaterly" [value]="range">{{ range }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>
      
      <div class="col-12 col-md-4">
        <button mat-raised-button color="primary" [disabled]="type === 'RF' ? (!label || !currentPeriod || !town) : (!label || !currentPeriod || !town || !range || (range === 'A'? false :!dates))" (click)="download()">Descargar
          Certificado</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<div class="w-100 d-flex flex-column">
    <h3 mat-dialog-title>Seleccione un tipo</h3>
    <div mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>Tipo</mat-label>
            <mat-select matInput [(value)]="group">
                <mat-option *ngFor="let group of data.groups" [value]="group">
                    {{group.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="w-100 d-flex justify-content-end">
        <button mat-raised-button (click)="onNoClick()">Cancelar</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="group">Continuar</button>
    </div>
</div>

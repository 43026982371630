import {Component, ElementRef, Inject, Input, OnDestroy, Optional, Self, ViewChild} from '@angular/core';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusMonitor} from '@angular/cdk/a11y';

export class MyTel {
  constructor(
      public area: string,
      public isp: string,
      public exchange: string,
      public subscriber: string
  ) {}
  isEmpty(): boolean {
    return !this.area || !this.isp || !this.exchange || !this.subscriber;
  }
  toJson() {
    return this.isEmpty() ? undefined : `${this.area},${this.isp},${this.exchange},${this.subscriber}`;
  }
}

@Component({
  selector: 'app-tel-input',
  templateUrl: './tel-input.component.html',
  styleUrls: ['./tel-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TelInputComponent }],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
  }
})
export class TelInputComponent implements ControlValueAccessor, MatFormFieldControl<MyTel>, OnDestroy {

  get empty() {
    const {
      value: { area, isp, exchange, subscriber }
    } = this.parts;

    return !area && !isp && !exchange && !subscriber;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): MyTel | null {
    if (this.parts.valid) {
      const {
        value: { area, isp, exchange, subscriber }
      } = this.parts;
      return new MyTel(area, isp, exchange, subscriber);
    }
    return null;
  }
  set value(tel: MyTel | null) {
    const { area, isp, exchange, subscriber } = tel || new MyTel('57', '', '', '');
    this.parts.setValue({ area, isp, exchange, subscriber });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.parts.dirty;
  }

  constructor(
      formBuilder: FormBuilder,
      private _focusMonitor: FocusMonitor,
      private _elementRef: ElementRef<HTMLElement>,
      @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
      @Optional() @Self() public ngControl: NgControl) {

    this.parts = formBuilder.group({
      area: [
        null,
        [Validators.required, Validators.pattern('[0-9]{1,4}'), Validators.minLength(1), Validators.maxLength(4)]
      ],
      isp: [
        null,
        [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern(/[0-9]{3}/)]
      ],
      exchange: [
        null,
        [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern(/[0-9]{3}/)]
      ],
      subscriber: [
        null,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/[0-9]{4}/)]
      ]
    });

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  static nextId = 0;

  static ngAcceptInputType_disabled: BooleanInput;
  static ngAcceptInputType_required: BooleanInput;
  @ViewChild('area') areaInput: HTMLInputElement;
  @ViewChild('isp') ispInput: HTMLInputElement;
  @ViewChild('exchange') exchangeInput: HTMLInputElement;
  @ViewChild('subscriber') subscriberInput: HTMLInputElement;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'tel-input';
  id = `tel-input-${TelInputComponent.nextId++}`;

  @Input('aria-describedby') userAriaDescribedBy: string;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  onChange = (_: any) => {};
  onTouched = () => {};

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement
          .querySelector('.tel-input-container')!;
    if (controlElement){
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick() {
    if (this.parts.controls.subscriber.valid) {
      this._focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.exchange.valid) {
      this._focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.isp.valid) {
      this._focusMonitor.focusVia(this.exchangeInput, 'program');
    } else if (this.parts.controls.area.valid) {
      this._focusMonitor.focusVia(this.ispInput, 'program');
    } else {
      this._focusMonitor.focusVia(this.areaInput, 'program');
    }
  }

  writeValue(tel: MyTel | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  verifyArea(event: KeyboardEvent) {
    const regex = /\d/;
    if (!(event.which === 8 || event.which === 46 || event.which === 37 || event.which === 39)) {
      if (!regex.test(event.key)) {
        event.returnValue = false;
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {ListComponent} from '@Components/ui/list/list.component';
import {ENUM_ACTIONS, Options, PeriodState, Permission} from '@Interfaces/index';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-end-period-accounts',
  templateUrl: './end-period-accounts.component.html',
  styleUrls: ['./end-period-accounts.component.css']
})
export class EndPeriodAccountsComponent implements OnInit {

  canApprove: boolean;
  @ViewChild('table') table: ListComponent;

  constructor(private loading: LoadingService,
              private adfiGraphqlService: AdfiGraphqlService,
              private adfiService: AdfiService,
              private dialog: MatDialog,
              private adfiGrowlService: AdfiGrowlService) { }

  ngOnInit() {
    this.canApprove = this.adfiService.validPermission('NewAccount', Permission.APPROVE, PeriodState.CLOSE);
  }

  acceptCreationBankAccount(row, table: ListComponent) {
    const next = () => {
      this.loading.hide();
      this.adfiGrowlService.success('Cuenta creada correctamente',
          'Se agrego la cuenta al centro de costo ' + row.centroCosto.deDescripcion);
      table.reload();
    };
    const error = (value) => {
      this.loading.hide();
      this.adfiGrowlService.errorMessage(value);
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        action: ENUM_ACTIONS.DELETE,
        title: '¿Seguro?',
        content: `<p>¿Esta seguro de aceptar la <strong>creación</strong> de la cuenta bancaria (${row.numero}) para la institución ${row.centroCosto.deDescripcion}?</p>`
      } as ConfirmDialogData,
      maxHeight: '90vh'
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.loading.show();
        this.adfiGraphqlService.updateEntity(table.configTable.entity, {id: row.id, formularioCierre: true},
            null,  {next, error});
      }
    }, (e) => {
        this.loading.hide();
        this.adfiGrowlService.errorMessage(e);
    });
  }

}

<mat-card>
    <mat-card-header  class="full-width">
        <mat-card-title class="d-flex justify-content-between full-width ">
            <section>
                Presupuesto Inicial
            </section>
        </mat-card-title>

    </mat-card-header>

    <mat-card-content>
        <div class="row ml-1">
            <div class="col-4">
                <h4 style="margin-block-end: 0">Fuente financiación</h4>
            </div>
        </div>
        <div class="row mt-1 ml-1" *ngIf="fuentes">
            <div class="col-4">
                <mat-form-field class="list-fuente full-width">
                    <mat-label> Seleccionar: </mat-label>
                    <mat-select placeholder="Seleccionar" (selectionChange)="selectPrFuente($event.value)">
                        <mat-option *ngFor="let item of fuentes" [value]="item._id">
                            {{ item._id }} - {{ item.prfinVNombre }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-auto" *ngIf="presupuestoInicial?.estado!='I'">
                <button mat-raised-button color="primary" (click)="getObservations()">Observaciones</button>
            </div>
            <div class="col-auto">
                <button mat-raised-button *ngIf="presupuestoInicial?.archivo && (presupuestoInicial.estado=='C' || presupuestoInicial.estado=='X')" color="primary" (click)="descargarArchivo()">
                    Descargar Archivo
                    <mat-icon>arrow_downward</mat-icon>
                </button>
            </div>
            <ng-container *ngIf="presupuestoInicial">
                <!-- col -->
                <div class="col-auto d-flex align-items-center">
                    <h2 class="d-inline pr-1" style="margin-block-start: 0">Estado: </h2>
                    <mat-chip-list style="margin-bottom: 0.83em;">
                        <mat-chip [attr.selected]="presupuestoInicial.estado=='C'">{{ estado }}</mat-chip>
                    </mat-chip-list>
                </div>
            </ng-container>
        </div>

        <div class="row">
            <div class="col-sm">
                <app-tree-presupuesto [tipoPresupuesto]="tipo.INGRESO" [value]="period" (changeItem)="reloadBudget($event)" [externalPermission]=false></app-tree-presupuesto>
            </div>
            <div class="col-sm">
                <app-tree-presupuesto [tipoPresupuesto]="tipo.EGRESO" [value]="period" (changeItem)="reloadBudget($event)" [externalPermission]=false></app-tree-presupuesto>
            </div>
              <div class="footer col-12 pt-2 ">
                <div class="row justify-content-end">
                    <button mat-raised-button color="primary" (click)="downloadPi()">
                        Descargar XLSX
                        <mat-icon>arrow_downward</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-card-content>

</mat-card>

<div class="w-100">
    <div class="offset-10 col-2">
        <mat-chip-list *ngIf="permissionViewAll" class="mat-chip-list-stacked" aria-label="Color selection" matTooltip="Realizar las consultas para la I.E. Actual o Todas">
            <mat-chip [style.background-color]="generateMassive? '#9ccc65':'#3f51b5'" selected (click)="loadData(!generateMassive)">{{generateMassive?'Todas las I.E.':'I.E. Actual'}}</mat-chip>
        </mat-chip-list>
    </div>
</div>
<div class="row pt-3">
    <div class="col-12">
        <app-table [title]="typeBook === 'ReserveBook' ? 'Libro de Reservas' : 'Libro de Cuentas por Pagar'" [columns]="typeBook === 'ReserveBook' ? columnsReserve : columnsCXP" (reload)="this.loadData()"
                   [items]="data"
                   [headerGroupBy]="generateMassive ? columnsHeaderGroupAllConsolidate : columnsHeaderGroupAll"
        ></app-table>
    </div>
</div>
<ng-container>
    <div class="row pt-3 pb-5">
        <div class="col-12">
            <div class="flex justify-content-start">
                <button mat-raised-button (click)="export()"
                        class="mr-2" color="primary" matTooltip="Exportar a excel">
                    <mat-icon>receipt_long</mat-icon> Exportar a excel
                </button>
            </div>
        </div>
    </div>
</ng-container>

import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import {DataEntity, Options, Property} from '@Interfaces/index';
import {CustomDirective} from '@Components/util/custom.directive';
import {DetailModificationComponent} from '@Components/presupuesto/detail-modification/detail-modification.component';
import {ICustomComponent} from '@Components/util/ICustomComponent';

@Component({
  selector: 'app-custom-component',
  templateUrl: './custom-component.component.html',
  styleUrls: ['./custom-component.component.scss']
})
export class CustomComponentComponent implements OnInit {

  @Input() field: Property;
  @Input() dataEntity: DataEntity;
  @Input() group: string;
  @Input() item: any;

  @ViewChild(CustomDirective, {static: true}) adHost!: CustomDirective;


  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.loadComponent();
  }

  private getComponent()
  {
    switch (this.field.options[Options.CUSTOM_COMPONENT]){
      case 'DetailModificationComponent':
        return DetailModificationComponent;
      default:
            return null;
    }
  }

  loadComponent() {

    const component = this.getComponent();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<ICustomComponent>(componentFactory);
    componentRef.instance.setConfig(this.field, this.dataEntity, this.group, this.item);
  }

}

<app-list #table entityName="AccountBalance" module="ClosingForm"
          [dataEntity]="{
          enableAdd: false,
          customTitle: 'Estado cuentas bancarias al cierre de vigencia'}"
            group="summary">
</app-list>
<app-list #news entityName="NewAccount" module="ClosingForm"
          noActions="true"
          [dataEntity]="{
          enableAdd: false,
          customTitle: 'Cuentas bancarias registradas como nuevas'}" group="nca">
    <ng-template tableColumn="formularioCierre" let-row="row">
        <ng-container *ngIf="canApprove else failPer">
            <ng-container *ngIf="!row.formularioCierre else ok">
                <button mat-icon-button matTooltip="Aprobar creación de cuenta" [disabled]="news.loadingData"
                        (click)="acceptCreationBankAccount(row, news)"
                    color="warn"><mat-icon>check_circle_outline</mat-icon></button>
            </ng-container>
            <ng-template tableColumn="ok">
                Cuenta agregada al sistema
            </ng-template>
        </ng-container>
        <ng-template tableColumn="failPer">
            {{!row.formularioCierre ? 'Pendiente de aprobación' : 'Cuenta agregada al sistema'}}
        </ng-template>
    </ng-template>
</app-list>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpDeskComponent } from './help-desk/help-desk.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import {OverlayModule} from '@angular/cdk/overlay';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [HelpDeskComponent],
  exports: [
    HelpDeskComponent
  ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        OverlayModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatDatepickerModule
    ]
})
export class HelpDeskModule { }

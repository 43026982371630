import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

    transform(value: number): string {

        const units = {
            dia: 24 * 60 * 60,
            hora: 60 * 60,
            minuto: 60,
            segundo: 1
        };

        const result = [];
        // tslint:disable-next-line:forin
        for (const name in units) {
            const p = Math.floor(value / units[name]);
            if (p === 1) {
                result.push(' ' + p + ' ' + name);
            }
            if (p >= 2) {
                result.push(' ' + p + ' ' + name + 's');
            }
            value %= units[name];
        }

        return result.join(' ');
    }

}

<div class="container pb-5">
    <mat-card class="col-12 mb-2">
        <mat-card-title class="text-center">Balance de Comprobación</mat-card-title>
        <mat-card-content>
            <div class="row pt-2 align-items-center">
                <div class="col-12 col-md-9">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <h5 class="d-inline"> Centro de costo:</h5>
                            {{service.user.centroCosto._id + ' - ' + service.user.centroCosto.deDescripcion}}
                        </div>
                        <div class="col-12 mt-1">
                            <div class="row">
                                <div class="col-md-3 col-6">
                                    <div class="row justify-content-start align-items-center">
                                        <h5 class="pl-3 d-inline">Nivel de detalle: </h5>
                                        <half-circle-menu tooltip="Nivel de detalle" class="mx-2" content="Nivel" [(value)]="level" (selectionChange)="saveDetailLevel($event)"
                                                          [options]="parameters.levels" tooltipPosition="above" showValue="true">
                                        </half-circle-menu>
                                    </div>
                                </div>
                                <div class="col-md-4 col-6">
                                    <div class="row d-flex justify-content-between align-items-center">
                                        <h5 class="d-inline"> Filtro Clase: </h5>
                                        <span class="text-left flex-grow-1  pl-2"  matTooltip="{{filters.groups | json}}" style="max-width: 150px; overflow: hidden; text-overflow: ellipsis"> {{filters.groups | json}}</span>
                                        <circle-menu class="flex-grow-1" tooltip="Filtro para Nivel 1" content="Filt. 1" [multiple]="true"
                                                     [(value)]="filters.groups" [options]="parameters.groups" (valueChange)="loadConfigData()">
                                        </circle-menu>
                                    </div>
                                </div>
                                <div class="col-md-5 col-6">
                                    <div class="row justify-content-between align-items-center">
                                        <h5 class="pl-3 d-inline"> Filtro Grupo: </h5>
                                        <span class="text-left pl-2 flex-grow-1"  matTooltip="{{filters.subgroups | json}}" style="max-width: 150px; overflow: hidden; text-overflow: ellipsis"> {{filters.subgroups | json}}</span>
                                        <line-menu class="mr-1 flex-grow-1" tooltip="Filtro para Nivel 2" content="Filt. 2" [multiple]="true" [(value)]="filters.subgroups"
                                                   [options]="parameters.subgroups" (valueChange)="loadConfigData()" tooltipPosition="above">
                                        </line-menu>
                                    </div>
                                </div>
                                <mat-form-field class="col-md-6 col-12" appearance="legacy">
                                    <mat-label>Filtro Cuenta</mat-label>
                                    <mat-select [(value)]="filters.major" multiple="true" (valueChange)="loadConfigData()">
                                        <mat-option *ngFor="let lvl of parameters.major" [value]="lvl" >{{lvl}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-md-6 col-12" appearance="legacy">
                                    <mat-label>Filtro Subcuenta</mat-label>
                                    <mat-select [(value)]="filters.sub_account" multiple="true">
                                        <mat-option *ngFor="let lvl of parameters.sub_account" [value]="lvl" >{{lvl}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <mat-chip-list class="row justify-content-center mb-1" aria-label="adjustment_show" *ngIf="modules">
                        <mat-chip class="warn-red text-center" matTooltip="La consulta incluirá todos los documentos de ajustes sin cierre que pertenezcan a la I.E.">La consulta incluirá documentos sin cierre</mat-chip>
                    </mat-chip-list>
                    <div class="row justify-content-around align-items-center pt-4">
                        <div class="col-8 pl-sm-3">
                            <h5 class="d-inline"> Fecha Inicial:</h5>
                            <ng-container *ngIf="selectedDates">
                                {{ selectedDates.startDate | date: 'mediumDate':null:'es-co'}}
                            </ng-container> <br>
                            <h5 class="d-inline"> Fecha Final:</h5>
                            <ng-container *ngIf="selectedDates">
                                {{ selectedDates.endDate | date: 'mediumDate':null:'es-co'}}
                            </ng-container>
                        </div>
                        <div class="col-4 d-flex justify-content-center">
                            <button mat-mini-fab color="primary"
                                    matTooltip="Seleccionar fechas" (click)="selectDates()">
                                <mat-icon>calendar_today</mat-icon>
                            </button>
                            <button mat-mini-fab color="primary" class="ml-2"
                                    matTooltip="Reiniciar Filtros" (click)="resetFilters()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-content>
            <app-table [context]="this" [dynamicRowClass]="checkParentClass" [columns]="columnsData" [items]="data" (reload)="loadData()">
                <div class="row footer mt-2 pt-2 mb-3">
                    <button mat-raised-button [disabled]="!this.selectedDates" (click)="export('xls')"
                            class="ml-3 mr-2" color="primary" matTooltip="Exportar a excel">
                        <mat-icon>receipt_long</mat-icon>
                        Exportar a excel
                    </button>
                    <button mat-raised-button [disabled]="!this.selectedDates" (click)="export('pdf')"
                            color="primary" matTooltip="Exportar a pdf">
                        <mat-icon>description</mat-icon>
                        Exportar a pdf
                    </button>
                </div>
            </app-table>
        </mat-card-content>
    </mat-card>
</div>


<div *ngIf="this.isValidPeriod">
    <div *ngIf="counterStart > 0 || counterEnd < 0; else open" class="d-flex flex-column justify-content-center align-items-center" style="height: 60vh">
        <mat-card class="w-75 mat-elevation-z4" style="padding: 3rem; border-radius: 2rem">
            <mat-card-content class="d-flex flex-column justify-content-around align-items-center">
                <img class="w-50 m-4" src="assets/images/adfib.png" alt="">
                <h2 class="mt-4">{{counterStart > 0 ? 'El Formulario se habilitará en: ': counterEnd < 0 ? 'El formulario ya no se encuentra habilitado para el registro de información' : ''}}</h2>
                <h4 *ngIf="counterStart > 0" class="m-1">{{ counterStart | formatTime }}</h4>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div *ngIf="!this.isValidPeriod" class="d-flex flex-column justify-content-center align-items-center" style="height: 60vh">
    <mat-card class="w-75 mat-elevation-z4" style="padding: 3rem; border-radius: 2rem">
        <mat-card-content class="d-flex flex-column justify-content-around align-items-center">
            <img class="w-50 m-4" src="assets/images/adfib.png" alt="">
            <h2 class="mt-4">No puede acceder al formulario desde la vigencia en la que se encuentra</h2>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #open>
    <div style="background-color: #fff">
        <h2 class="px-2 text-center" style="color: #3f51b5; padding-top: 1em">FORMULARIO DE VALIDACIÓN PI/PAA</h2>
        <mat-vertical-stepper [selectedIndex]="indexStep" linear #stepper *ngIf="validationForm">
            <mat-step label="Equilibrio" [completed]="isStepCompleted('selectEquilibrio')" [editable]="isStepEditable('selectEquilibrio')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>1.1. ¿El acuerdo cargado correspondiente a Presupuesto Inicial es
                            igual en rubros y valores al diligenciado en Plataforma ADFI? (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2" >
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar"
                            #selectEquilibrio [(ngModel)]="validationForm.validDeal" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2" *ngIf="selectEquilibrio.value === false">
                        <mat-form-field class="col-12 col-md-8 m-2">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [disabled]="!canEditForm" [(ngModel)]="validationForm.validDealObs" [disabled]="!canEditForm" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button mat-raised-button color="primary" (click)="validateStep({ validDeal: selectEquilibrio.value, validDealObs: selectEquilibrio.value? null : validationForm.validDealObs}, 'selectEquilibrio')" [disabled]="selectEquilibrio.value === null || (selectEquilibrio.value === false && !validationForm.validDealObs)">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Fecha de Aprobación" [completed]="isStepCompleted('selectFechaAprobacion')" [editable]="isStepEditable('selectFechaAprobacion')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>2.1. ¿La fecha del acuerdo del Presupuesto Inicial es igual a la fecha de impresión del documento firmado (Si / No)?</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectFechaAprobacion [(ngModel)]="validationForm.validFechaAprobacion" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2" *ngIf="selectFechaAprobacion.value === false">
                        <mat-form-field class="col-12 col-md-8 m-2">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.fechaAprobacionObs" [disabled]="!canEditForm" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button mat-raised-button color="primary" (click)="validateStep({ validFechaAprobacion: selectFechaAprobacion.value, fechaAprobacionObs: selectFechaAprobacion.value ? null : validationForm.fechaAprobacionObs}, 'selectFechaAprobacion')" [disabled]="selectFechaAprobacion.value === null || (selectFechaAprobacion.value === false && !validationForm.fechaAprobacionObs)">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Considerandos" [completed]="isStepCompleted('selectConsiderandos')" [editable]="isStepEditable('selectConsiderandos')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>3.1. ¿Se llevo a cabo el diligenciamiento de considerandos 
                            adicionales a los preestablecidos por parte de la Secretaría de 
                            Educación de Boyacá y Plataforma ADFI? (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectConsiderandos [(ngModel)]="validationForm.validConsiderandos" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-3" *ngIf="selectConsiderandos.value === true">
                        <p>Son coherentes (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectCoherenciaConsiderandos [(ngModel)]="validationForm.validCoherenteConsiderandos" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-12 col-md-8 m-2" *ngIf="selectCoherenciaConsiderandos.value === false">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.coherenteConsiderandosObs" [disabled]="!canEditForm" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>

                        <p>Se repiten con los preestablecidos (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectRepetConsiderandos [(ngModel)]="validationForm.validPreestablecidosConsiderandos" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-12 col-md-8 m-2" *ngIf="selectRepetConsiderandos.value">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.preestablecidosConsiderandosObs" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>

                        <p>Incluye normatividad derogada (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectNormaConsiderandos [(ngModel)]="validationForm.validNormatividadConsiderandos" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-12 col-md-8 m-2" *ngIf="selectNormaConsiderandos.value">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.normatividadConsiderandosObs" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button *ngIf="selectConsiderandos.value === true" mat-raised-button color="primary" (click)="validateSubAnswersAfterTimeoutQuestionThree()" [disabled]="isButtonDisabledQuestionThree()">
                                Continuar
                            </button>
                            <button *ngIf="selectConsiderandos.value === false || selectConsiderandos.value === null" mat-raised-button color="primary" (click)="validateStep({ validConsiderandos: selectConsiderandos.value }, 'selectConsiderandos')" [disabled]="selectConsiderandos.value !== false">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Disposiciones Generales" [completed]="isStepCompleted('selectDisposicionesGeneral')" [editable]="isStepEditable('selectDisposicionesGeneral')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>4.1. ¿Se llevó a cabo el diligenciamiento de la tercera parte del 
                            acuerdo de Presupuesto Inicial (Disposiciones Generales)? (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectDisposicionesGeneral [(ngModel)]="validationForm.validDisposicionesGenerales" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-3" *ngIf="selectDisposicionesGeneral.value === true">
                        <p>Corresponden y son coherentes con los rubros programados (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectCoherenciaDisposicionesGeneral [(ngModel)]="validationForm.validCoherenteDisposicionesGenerales" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-12 col-md-8 m-2" *ngIf="selectCoherenciaDisposicionesGeneral.value === false">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.coherenteDisposicionesGeneralesObs" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2" *ngIf="selectDisposicionesGeneral.value === false">
                        <mat-form-field class="col-12 col-md-8 m-2">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.disposicionesGeneralesObs" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button *ngIf="selectDisposicionesGeneral.value === true" mat-raised-button color="primary" (click)="validateSubAnswersAfterTimeoutQuestionFour()" [disabled]="isButtonDisabledQuestionFour()">
                                Continuar
                            </button>
                            <button *ngIf="selectDisposicionesGeneral.value === false" mat-raised-button color="primary" (click)="validateStep({ validDisposicionesGenerales: selectDisposicionesGeneral.value, disposicionesGeneralesObs: selectDisposicionesGeneral.value ? null : validationForm.disposicionesGeneralesObs}, 'selectDisposicionesGeneral')" [disabled]="selectDisposicionesGeneral.value === null || (selectDisposicionesGeneral.value === false && !validationForm.disposicionesGeneralesObs)">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Plan Anual de Adquisiciones / Sección A" [completed]="isStepCompleted('selectPaaA')" [editable]="isStepEditable('selectPaaA')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>5.1. ¿En el plan anual de adquisiciones se diligenció la sección A. 
                            Datos de la Entidad? (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectPaaA [(ngModel)]="validationForm.validPaaSeccionA" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2" *ngIf="selectPaaA.value === false">
                        <mat-form-field class="col-12 col-md-8 m-2">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.paaSeccionAObs" [disabled]="!canEditForm" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button mat-raised-button color="primary" (click)="validateStep({ validPaaSeccionA: selectPaaA.value, paaSeccionAObs: selectPaaA.value ? null : validationForm.paaSeccionAObs}, 'selectPaaA')" [disabled]="selectPaaA.value === null || (selectPaaA.value === false && !validationForm.paaSeccionAObs)">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Plan Anual de Adquisiciones / Sección B" [completed]="isStepCompleted('selectPaaB')" [editable]="isStepEditable('selectPaaB')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>6.1. ¿Los códigos UNSPSC son coherentes con los rubros 
                            programados? (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectPaaB [(ngModel)]="validationForm.validPaaSeccionB" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2" *ngIf="selectPaaB.value === false">
                        <mat-form-field class="col-12 col-md-8 m-2">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.paaSeccionBObs" [disabled]="!canEditForm" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button mat-raised-button color="primary" (click)="validateStep({ validPaaSeccionB: selectPaaB.value, paaSeccionBObs: selectPaaB.value ? null : validationForm.paaSeccionBObs}, 'selectPaaB')" [disabled]="selectPaaB.value === null || (selectPaaB.value === false && !validationForm.paaSeccionBObs)">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Quorum" [completed]="isStepCompleted('selectQuorum')" [editable]="isStepEditable('selectQuorum')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>7.1. ¿Se encuentran enunciados los 8 integrantes del consejo 
                            directivo? (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectQuorum [(ngModel)]="validationForm.validQuorum" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2" *ngIf="selectQuorum.value === false">
                        <mat-form-field class="col-12 col-md-8 m-2">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.quorumObs" [disabled]="!canEditForm" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button mat-raised-button color="primary" (click)="validateStep({ validQuorum: selectQuorum.value, quorumObs: selectQuorum.value ? null : validationForm.quorumObs}, 'selectQuorum')" [disabled]="selectQuorum.value === null || (selectQuorum.value === false && !validationForm.quorumObs)">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Aprobación" [completed]="isStepCompleted('selectAprobacion')" [editable]="isStepEditable('selectAprobacion')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>8.1 De acuerdo al número de integrantes del consejo directivo 
                            enunciados y las firmas registradas ¿Se cumple con Quorum? (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectAprobacion [(ngModel)]="validationForm.validAprobacion" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2" *ngIf="selectAprobacion.value === false">
                        <mat-form-field class="col-12 col-md-8 m-2">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.aprobacionObs" [disabled]="!canEditForm" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button mat-raised-button color="primary" (click)="validateStep({ validAprobacion: selectAprobacion.value, aprobacionObs: selectAprobacion.value ? null : validationForm.aprobacionObs}, 'selectAprobacion')" [disabled]="selectAprobacion.value === null || (selectAprobacion.value === false && !validationForm.aprobacionObs)">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Documento" [completed]="isStepCompleted('selectDocumento')" [editable]="isStepEditable('selectDocumento')">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>9.1 ¿Evidencia que el documento adjunto presenta modificaciones, 
                            alteraciones o ediciones respecto del documento original y definitivo 
                            generado por parte de Plataforma ADFI? (Si / No)</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select placeholder="Seleccionar" #selectDocumento [(ngModel)]="validationForm.validDocumentacion" [disabled]="!canEditForm">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2" *ngIf="selectDocumento.value">
                        <mat-form-field class="col-12 col-md-8 m-2">
                        <mat-label>Observación</mat-label>
                        <textarea maxlength="200" matInput placeholder="Ingrese la observación" [(ngModel)]="validationForm.documentacionObs" [disabled]="!canEditForm"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button mat-raised-button color="primary" (click)="validateStep({ validDocumentacion: selectDocumento.value, documentacionObs: selectDocumento.value === false ? null : validationForm.documentacionObs}, 'selectDocumento')" [disabled]="selectDocumento.value === null || (selectDocumento.value && !validationForm.documentacionObs)">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Certificación">
                <div class="row">
                    <div class="col-12 p-2">
                        <p>10.1 La presente certificación y verificación respecto del Presupuesto
                            Inicial vigencia {{ period }} y Plan Anual de Adquisiciones {{ period }} se llevó a
                            cabo por parte del profesional de apoyo (CPS): a los {{ fechaActual.day }} días del mes 
                            de {{ fechaActual.month }} de {{ fechaActual.year }}. </p>
                    </div>
                </div>
                <!-- <div class="row-p4 mt-2" *ngIf="canEditForm">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button mat-raised-button color="primary" (click)="isEnabledButtons = true">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div> -->
            </mat-step>
        </mat-vertical-stepper>
        <div *ngIf="validationForm">
            <div class="px-2 text-center" *ngIf="!isFinishForm">
                <button mat-raised-button color="primary" (click)="closForm()" [disabled]="!isCompletedForm">
                    Finalizar Diligenciamiento
                </button>
            </div>
            <div class="px-2 text-center" *ngIf="isFinishForm && !isAproved">
                <button style="margin-right: 1%;" mat-raised-button color="primary" (click)="sendToAprove({validForm:'VALIDATE_FORM'})" [disabled]="!isEnabledButtonsAprove">
                    Aprobar
                </button>
                <button mat-raised-button color="primary" (click)="sendToBack({validForm:'BACK_PENDING_PR'})" [disabled]="!isEnabledButtonsBack">
                    Devolver
                </button>
            </div>
            <div class="px-2 text-center" *ngIf="isAproved">
                <button mat-raised-button color="primary" *ngIf="!certificateExist" (click)="generateReportAndSave()">
                    Generar Certificado
                </button>
                <button mat-raised-button color="primary" *ngIf="certificateExist" (click)="downloadFile()">
                    Descargar Certificado
                </button>
            </div>
            <br><br>
        </div>
    </div>
</ng-template>
<form [formGroup]="form1" class="p-4" (ngSubmit)="changePassword()">

    <mat-form-field class="w-100 mt-4">
        <mat-label>Contraseña Actual</mat-label>
        <input matInput
                formControlName="oldPwd"
                type="password"
                class="form-control"/>
        <mat-error *ngIf="oldPwd.errors?.required">Debe ingresar la contraseña actual.</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100 mt-4">
        <mat-label>Nueva Contraseña</mat-label>
        <input matInput
                formControlName="newPwd"
                type="password"
                class="form-control"/>
        <mat-error *ngIf="newPwd.errors?.required">La nueva contraseña es Requerida.</mat-error>
        <mat-error *ngIf="newPwd.hasError('minLength')">La nueva contraseña debe contener mínimo 8 caracteres.</mat-error>
        <mat-error *ngIf="newPwd.hasError('notEquivalent')">
            Las contraseñas no coinciden
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100 mt-4">
        <mat-label>Confirmar Nueva Contaseña</mat-label>
        <input matInput
                formControlName="confirmPwd"
                type="password"
                class="form-control" (input)="valid()"/>
        <mat-error *ngIf="confirmPwd.errors?.required">Debe confirmar la nueva contraseña.</mat-error>
        <mat-error *ngIf="confirmPwd.hasError('notEquivalent')">
            Las contraseñas no coinciden
        </mat-error>
    </mat-form-field>
    <div class="d-flex justify-content-center">
        <button mat-raised-button color="primary" [disabled]="!form1.valid" type="submit">Cambiar Contraseña</button>
    </div>
</form>

import {
    Action,
    DataEntity,
    Entity,
    LocationAction,
    Options,
    Property,
    PropertyTypes,
    TypeFilters
} from '@Interfaces/index';
import { EventEmitter, Input, Output, Directive } from '@angular/core';
import {AdfiRest} from '@services/adfi-rest';
import {AdfiService} from '@services/adfi-service';

export interface ColumnFilter {
    realName: string;
    foundName: string;
    data: any;
    first_data?: any;
    blocked: boolean;
    option?: string[];
    type: TypeFilters;
    strategy?: string;
}

@Directive()
export class Filter {
    dateOptions1 = [
        {
            key: 'after',
            label: 'Despues de'
        },
        {
            key: 'strictly_after',
            label: 'Estrictamente Despues de'
        },
    ];
    dateOptions2 = [
        {
            key: 'before',
            label: 'Antes de'
        },
        {
            key: 'strictly_before',
            label: 'Estrictamente Antes de'
        },
    ];
    rangeOptions = [
        {
            key: 'gt',
            label: 'Mayor A'
        },
        {
            key: 'gte',
            label: 'Mayor o Igual A'
        },
        {
            key: 'lt',
            label: 'Menor A'
        },
        {
            key: 'lte',
            label: 'Menor o Igual A'
        },
        {
            key: 'between',
            label: 'Entre'
        }
    ];
    searchNotOptions = [
        {
            key: 'exact',
            label: 'No Igual a'
        },
        {
            key: 'iexact',
            label: 'No Igual a, sin tener en cuenta mayúsculas o minúsculas'
        },
        {
            key: 'partial',
            label: 'Que no contenga'
        },
        {
            key: 'ipartial',
            label: 'Que no contenga, sin tener en cuenta mayúsculas o minúsculas'
        },
        {
            key: 'start',
            label: 'Que no inicie con'
        },
        {
            key: 'istart',
            label: 'Que no inicie con, sin tener en cuenta mayúsculas o minúsculas'
        },
        {
            key: 'end',
            label: 'Que no finalice con'
        },
        {
            key: 'iend',
            label: 'Que no finalice con, sin tener en cuenta mayúsculas o minúsculas'
        },
    ];
    columns: Property[];
    configTable: Entity;
    // tslint:disable-next-line:variable-name
    protected _filters: Map<string, ColumnFilter>;
    get filters(): Map<string, ColumnFilter> {
        return this._filters;
    }
    // tslint:disable-next-line:variable-name
    protected _defaultFilters: Map<string, ColumnFilter>;
    get defaultFilters(): Map<string, ColumnFilter> {
        return this._defaultFilters;
    }

    @Input() module = '';
    @Input() group: string;
    @Input() entityName: string;
    @Input() dataEntity: DataEntity;
    // tslint:disable-next-line:variable-name
    _customQuery: {column: string, value: any, option?: any, filter?: TypeFilters} [];
    @Input() set customQuery(query: { column: string, value: any, option?: any, filter?: TypeFilters} []) {
        if (this._filters) {
            this._filters.clear();
        }
        this._customQuery = query;
    }
    // tslint:disable-next-line:variable-name
    _defaultQuery: { column: string, value: any, option?: any, filter?: TypeFilters} [];
    @Input() set defaultQuery(query: { column: string, value: any, option?: any, filter?: TypeFilters} []) {
        if (this._defaultFilters) {
            this._defaultFilters.clear();
        }
        this._defaultQuery = query;
    }
    @Output() loadedConfig: EventEmitter<Entity> = new EventEmitter();
    private successLoadedConfig: boolean;
    generalActions: Action[];
    footerActions: Action[];
    specificActions: Action[];
    constructor(public adfiService: AdfiService) {}

    loadConfig(forceReload = false) {
        if (!this.successLoadedConfig || forceReload) {
            this.adfiService.post<Entity>(AdfiRest.getConfigData,
                {entity: this.entityName, module: this.module, displayGroup: this.group,
                    group: this.dataEntity ? this.dataEntity.group : undefined }).subscribe((value: Entity) => {
                this.configTable = value;
                if (!this.configTable.table.options) {
                    this.configTable.table.options = {};
                }
                if (this.group) {
                    this.columns = this.configTable.properties.filter(prop => prop.displayGroup === this.group);
                } else {
                    this.columns = this.configTable.properties.filter(prop => !(prop.options[Options.HIDDEN_LIST] &&
                        !prop.options[Options.FORCE_QUERY]));
                }
                this.configTable.properties.sort((ap, bp) => typeof ap.options[Options.FILTER] === 'string' ? -1 : 1);
                this.loadFilterFromConfig();
                if (value.actions) {
                    this.generalActions = value.actions.filter( (i) => i.location === LocationAction.GENERAL);
                    this.specificActions = value.actions.filter( (i) => i.location === LocationAction.SPECIFIC);
                    this.footerActions = value.actions.filter( (i) => i.location === LocationAction.FOOTER);
                }
                this.successLoadedConfig = true;
                this.loadedConfig.emit(value);
            }, e => {
                this.loadedConfig.error(e);
            });
        }
    }

    private findFilterColumn(arr: string[], fieldName: string, type: TypeFilters, filter?: string, ignoreFilter?: TypeFilters) {
        if (type === TypeFilters.ORDER || (ignoreFilter && type === ignoreFilter)) {
            return false;
        }
        if (arr) {
            const r = arr.find(f => {
                const hasSubValue = fieldName.indexOf('_');
                const hasPredefinedFilter = (!filter || filter === type);
                if (hasSubValue > 0) {
                    return f.replace(/[.]/g, '_') === fieldName && hasPredefinedFilter;
                }
                return f.startsWith(fieldName) && hasPredefinedFilter;
            });
            return r ? r.replace(/[.]/g, '_') : false;
        } else {
            return false;
        }
    }

    generateFilters(returnObj = false) {
        let filterResult = returnObj ? {} : '';
        const arr = [];
        if (this._filters) {
            this._filters.forEach((value, k) => {
                arr.push(k);
                if ((!(value.data && value.data.length) && typeof value.data !== 'number') && this._defaultFilters.has(k)) {
                    value = this._defaultFilters.get(k);
                }
                filterResult = this.evaluateFilterColumn(value, value.foundName, filterResult);
            });
        }
        if (this._defaultFilters) {
            this._defaultFilters.forEach((value, k) => {
                if (!arr.length || (arr.length && !arr.find(c => c === k))) {
                    filterResult = this.evaluateFilterColumn(value, value.foundName, filterResult);
                }
            });
        }
        return filterResult;
    }

    private evaluateFilterColumn(value: ColumnFilter, k: string, strConcat: string | {}) {
        let key = '';
        let valueStr: string | {};
        if (((value.data || value.data === 0 || value.data === false) &&
            (value.data.length || typeof value.data === 'number' || typeof value.data === 'boolean' || typeof value.data === 'object')) ||
            value.type === TypeFilters.CENTER_COST) {
            switch (value.type) {
                case TypeFilters.NUMERIC:
                    if (this.columns.find(c => c.fieldName === k) &&
                        this.columns.find(c => c.fieldName === k).options[PropertyTypes.DROPDOWN]) {
                        key = `${k}_list`;
                        valueStr = `[${value.data.join()}]`;
                    } else if (this.columns.find(c => c.fieldName === value.realName) &&
                        this.columns.find(c => c.fieldName === value.realName).type === PropertyTypes.MANY_TO_ONE && Array.isArray(value.data)) {
                        key = `${k}_list`;
                        valueStr = `[${value.data.join()}]`;
                    } else {
                        if (Array.isArray(value.data)){
                            key = `${k}_list`;
                            valueStr = `[${value.data.join()}]`;
                        } else {
                            key = k;
                            valueStr = `${value.data}`;
                        }
                    }
                    if (typeof strConcat === 'string') {
                        strConcat += `, ${key}: ${valueStr}`;
                    } else {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: value.data
                        };
                    }
                    break;
                case TypeFilters.SEARCH:
                    key = k;
                    if (this._filters.has(`${TypeFilters.NOT_FILTER};${k}`) && this._filters.get(`${TypeFilters.NOT_FILTER};${k}`).data === value.data) {
                        return strConcat;
                    }
                    if (this._defaultFilters.has(`${TypeFilters.NOT_FILTER};${k}`) && this._defaultFilters.get(`${TypeFilters.NOT_FILTER};${k}`).data === value.data) {
                        return strConcat;
                    }
                    if (this.configTable.filters[value.type].properties[k.replace(/[_]/g, '.')] === 'exact') {
                        if (Array.isArray(value.data)) {
                            key += '_list';
                            if (!value.data.length) {
                                return strConcat;
                            }
                            valueStr = `["${value.data.join('","')}"]`;
                        } else {
                            valueStr = `"${value.data}"`;
                        }
                    } else {
                        valueStr = `"${value.data}"`;
                    }
                    // key = key.replace(/[.]/g, '_');
                    if (typeof strConcat === 'string') {
                        strConcat += `, ${key}: ${valueStr}`;
                    } else {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: value.data,
                            strategy: this.configTable.filters[value.type].properties[k.replace(/[_]/g, '.')]
                        };
                    }
                    break;
                case TypeFilters.CENTER_COST:
                    key = `cencosFilter_${k}`;
                    if (typeof strConcat === 'string') {
                        strConcat += `, ${key}: "${value.data}"`;
                    } else {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: value.data
                        };
                    }
                    break;
                case TypeFilters.PERIOD:
                    key = `filter_period_by_${k}`;
                    if (typeof strConcat === 'string') {
                        strConcat += `, ${key}: ${value.data}`;
                    } else {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: value.data
                        };
                    }
                    break;
                case TypeFilters.EXISTS_FILTER:
                    key = 'not_null';
                    if (typeof strConcat === 'string') {
                        if (strConcat.indexOf(key) !== -1 ) {
                            strConcat = strConcat.replace(/,[\s]*(not_null[\s]*:)[\s]*{(.*)}[\s]*/, `, $1 {$2, ${k}: ${value.data}}`);
                        } else {
                            strConcat += `, ${key}: {${k}: ${value.data}}`;
                        }
                    } else {
                        if (strConcat[key]) {
                            strConcat[key].value = {
                                ...strConcat[key].value,
                                [k]: value.data
                            };
                        } else {
                            strConcat[key] = {
                                class: this.configTable.filters[value.type].filterClass,
                                value: { [k]: value.data}
                            };
                        }
                    }
                    break;
                case TypeFilters.BOOLEAN_FILTER:
                    key = k;
                    if (typeof strConcat === 'string') {
                        strConcat += `, ${key}: ${value.data}`;
                    } else {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: value.data
                        };
                    }
                    break;
                case TypeFilters.NOT_FILTER:
                    key = k + '_not';
                    const opt = !value.option || !value.option.length ? this.searchNotOptions[0].key : value.option[0];
                    if (typeof strConcat === 'string') {
                        let additional =  ['integer', 'float', 'boolean', 'smallint']
                            .find( itF => itF === this.configTable.filters[value.type].propertyTypes[key.replace('_not', '')]);
                        additional = additional ? '' : '"';
                        const defVal = opt === 'list' ? '[' + additional + value.data.join(`${additional},${additional}`) + additional + ']'
                            : additional + value.data + additional;
                        strConcat += `, ${key}: {${opt}: ${defVal} }`;
                    } else {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: { [opt]: value.data}
                        };
                    }
                    break;
                case TypeFilters.ACTION_FILTER:
                    key = k + '_apply';
                    if (typeof strConcat === 'string') {
                        strConcat += `, ${key}: {actions: ["${value.data.join('","')}"]}`;
                    } else {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: { actions: value.data}
                        };
                    }
                    break;
                case TypeFilters.USER_PROPERTY_FILTER:
                    key = k + '_by_user';
                    if (typeof strConcat === 'string') {
                        strConcat += `, ${key}: "${value.data}"`;
                    } else {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: value.data
                        };
                    }
                    break;
                case TypeFilters.RANGE:
                    const option = !value.option || !value.option.length ? this.rangeOptions[0].key : value.option[0];
                    key = k;
                    if (option === 'between') {
                        if (value.data && value.data[0] && value.data[1]) {
                            if (typeof strConcat === 'string') {
                                valueStr = `{${option}: "${value.data.join('..')}"}`;
                            } else {
                                valueStr = {[option]: value.data.join('..')};
                            }
                        }
                    } else {
                        if (typeof strConcat === 'string') {
                            valueStr = `{${option}: "${value.data[0]}"}`;
                        } else {
                            valueStr = {[option]: value.data[0].toString()};
                        }
                    }
                    if (typeof strConcat === 'string' && valueStr) {
                        strConcat += `, ${key}: ${valueStr}`;
                    } else if (valueStr) {
                        strConcat[key] = {
                            class: this.configTable.filters[value.type].filterClass,
                            value: valueStr
                        };
                    }
                    break;
                case TypeFilters.DATE:
                    if (value.data[0] || value.data[1]) {
                        const option1 = !value.option || !value.option[0] ? this.dateOptions1[0].key : value.option[0];
                        const option2 = !value.option || !value.option[1] ? this.dateOptions2[0].key : value.option[1];
                        key = k;
                        if (value.data[0]) {
                            if (typeof strConcat === 'string') {
                                valueStr = `${option1}: "${value.data[0]}"`;
                            } else {
                                valueStr = {};
                                valueStr[option1]  = value.data[0];
                            }
                        }
                        if (value.data[1]) {
                            if (typeof strConcat === 'string') {
                                if (valueStr === undefined){
                                    valueStr = '';
                                }
                                valueStr += `${value.data[0] ? ', ' : ''}${option2}: "${value.data[1]}"`;
                            } else {
                                if (valueStr === undefined) {
                                    valueStr = {};
                                }
                                valueStr[option2]  = value.data[1];
                            }
                        }
                        if (typeof strConcat === 'string') {
                            strConcat += `, ${key}: {${valueStr}}`;
                        } else {
                            strConcat[key] = {
                                class: this.configTable.filters[value.type].filterClass,
                                value: valueStr
                            };
                        }
                    }
                    break;
            }
        }
        return strConcat;
    }

    private getArrayFilters(type) {
        if (this.configTable.filters[type] && Array.isArray(this.configTable.filters[type].properties)) {
            return this.configTable.filters[type].properties;
        } else if (this.configTable.filters[type]) {
            return Object.keys(this.configTable.filters[type].properties);
        }
    }

    protected loadFilterFromConfig() {
        if (!this._defaultFilters) {
            this._defaultFilters = new Map<string, ColumnFilter>();
        }
        if (!this._filters) {
            this._filters = new Map<string, ColumnFilter>();
        }
        const unique = [];
        if (this._defaultQuery) {
            this._defaultQuery.sort((a, b) => a.filter ? -1 : 1);
            let fil;
            let arr;
            for (const tf in this.configTable.filters) {
                fil = tf as TypeFilters;
                arr = this.getArrayFilters(fil);
                for (const cf of this._defaultQuery) {
                    const f = this.findFilterColumn(arr, cf.column, fil, cf.filter);
                    if (f && !unique.find(un => un === f)) {
                        if (cf.filter) {
                            unique.push(f);
                        }
                        const nameFilter = fil + ';' + f;
                        if (this._defaultFilters.has(nameFilter)) {
                            this._defaultFilters.get(nameFilter).data = cf.value;
                        } else {
                            this._defaultFilters.set(nameFilter, {
                                realName: cf.column,
                                foundName: f,
                                data: cf.value,
                                blocked: true,
                                option: cf.option,
                                type: fil,
                                strategy: this.configTable.filters[fil].properties[f.replace(/[_]/g, '.')]
                            } as ColumnFilter);
                            const property = this.configTable.properties.find(p => p.fieldName === cf.column);
                            if (property) {
                                property.hasFilter = true;
                                property.nameFilter = nameFilter;
                                property.typeFilter = fil;
                            }
                        }
                    }
                }
            }
        }
        if (this.configTable) {
            this.configTable.properties.forEach((value: Property) => {
                let fil;
                let arr;
                for (const tf in this.configTable.filters) {
                    fil = tf as TypeFilters;
                    arr = this.getArrayFilters(fil);
                    const predefinedFilter = typeof value.options[Options.FILTER] === 'string' ? value.options[Options.FILTER] : undefined;
                    const f = this.findFilterColumn(arr, value.fieldName, fil, predefinedFilter, TypeFilters.CENTER_COST);
                    const nameFilter = fil + ';' + f;
                    if (this._customQuery && f && !unique.find(un => un === f)) {
                        const q = this._customQuery.find(cq => cq.column === f);
                        if (q) {
                            if (this._filters.has(nameFilter)) {
                                if (this._filters.get(nameFilter).blocked)
                                    this._filters.get(nameFilter).data = q.value;
                            } else {
                                this._filters.set(nameFilter, {
                                    realName: value.fieldName,
                                    foundName: f,
                                    data: q.value,
                                    first_data: JSON.parse(JSON.stringify(q.value)),
                                    blocked: !(this.dataEntity && this.dataEntity.allowModifyFilter),
                                    option: q.option,
                                    type: fil,
                                    strategy: this.configTable.filters[fil].properties[f.replace(/[_]/g, '.')]
                                } as ColumnFilter);
                                value.hasFilter = true;
                                value.nameFilter = nameFilter;
                                value.typeFilter = fil;
                            }
                            if(predefinedFilter) {
                                unique.push(f);
                            }
                            break;
                        }
                    }
                    if (f && !this._defaultFilters.has(nameFilter) && !this._filters.has(nameFilter) && !unique.find(un => un === f)) {
                        this._filters.set(nameFilter, {
                            realName: value.fieldName,
                            foundName: f,
                            data: undefined,
                            blocked: false,
                            type: fil,
                            strategy: this.configTable.filters[fil].properties[f.replace(/[_]/g, '.')]
                        } as ColumnFilter);
                        value.hasFilter = true;
                        value.nameFilter = nameFilter;
                        value.typeFilter = fil;
                        break;
                    }
                }
            });
        }
    }
}

<div class="container">
    <div class="">
        <div class="">
            <mat-card>
                <mat-card-title>
                    Informacion de registro presupuestal
                </mat-card-title>
                <mat-card-content *ngIf="isLoading == true">
                    <div class="row pt-2">
                        <div class="col-12 col-md-4"><strong> Número CDP: </strong> <b>{{registerInfo['numAvailability']}}</b></div>
                        <div class="col-12 col-md-4"><strong> Número registro: </strong> <b>{{registerInfo['consecutive']}}</b></div>
                        <div class="col-12 col-md-4 pt-2"><strong> Fuente: </strong> <b>{{registerInfo['source'].prfinVNombre}}</b></div>
                        <div class="col-12 col-md-4 pt-2"><strong> Rubro presupuestal: </strong> <b>{{registerInfo['item'].minipresu+" - "+registerInfo['item'].nombre}}</b></div>
                        <div class="col-12 col-md-4 pt-2"><strong> Objeto del certificado: </strong> <b>{{registerInfo['objet']}}</b></div>
                        <div class="col-12 col-md-4 pt-2"><strong> Tercero: </strong> <b>{{registerInfo['third'].nit+" - "+registerInfo['third'].names}}</b></div>
                        <div class="col-12 col-md-4 pt-2"><strong> Fecha: </strong> <b>{{registerInfo['date']}}</b></div>
                        <div class="col-12 col-md-4 pt-2"><strong> Valor: </strong> <b>{{registerInfo['value']}}</b></div>
                        <div class="col-12 col-md-4 pt-2"><strong> Saldo: </strong> <b>{{registerInfo['balance']}}</b></div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title>
                    Información de orden de pago
                </mat-card-title>
                <mat-card-content>
                    <form (keydown.enter)="preventSubmit($event)" class="col-12" [formGroup]="formAssetsOP" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <mat-form-field appearance="legacy" class="col-12 col-md-6">
                                <input matInput [matDatepicker]="picker" formControlName="fecha"
                                    placeholder="Fecha Creación" [min]="startValidDate" [max]="todayDate < endValidDate?todayDate:endValidDate" required>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="formAssetsOP.get('fecha').errors?.required">La fecha es
                                    requerida</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-md-6">
                                <input matInput [matDatepicker]="datePicker" formControlName="billDate"
                                    placeholder="Fecha Factura" [min]="startValidDate" [max]="todayDate < endValidDate?todayDate:endValidDate" required>
                                <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datePicker></mat-datepicker>
                                <mat-error *ngIf="formAssetsOP.get('billDate').errors?.required">La fecha es
                                    requerida</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="inp-file col-12 col-md-6">
                                <ngx-mat-file-input #removableInput formControlName="billAttachment"
                                    placeholder="Anexe factura o documento equivalente" accept="{{validationTypePDF}}"
                                    multiple="false" (ngModelChange)="changeFile($event)" required>
                                </ngx-mat-file-input>
                                <div matSuffix>
                                    <mat-icon class="pointer" color="warn" *ngIf="!removableInput.empty"
                                        (click)="removableInput.ngControl.reset(); removableInput.ngControl.control.markAsTouched()">clear</mat-icon>
                                </div>
                                <mat-error *ngIf="formAssetsOP.get('billAttachment').hasError('accept')">
                                    El formato del archivo debe ser pdf
                                </mat-error>
                                <mat-error *ngIf="formAssetsOP.get('billAttachment').hasError('required')">
                                    La factura o documento equivalente es requerido 
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-md-6">
                                <input matInput formControlName="billNumber" placeholder="N° Factura" required>
                                <mat-error *ngIf="formAssetsOP.get('billNumber').hasError('required')">
                                    El numero de factura es obligatorio
                                </mat-error>
                                <mat-error *ngIf="formAssetsOP.get('billNumber').hasError('maxlength')">
                                    El numero de factura no puede superar los 100 caracteres
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-12">
                                <textarea matInput formControlName="objeto"
                                    placeholder="Objeto Orden" required></textarea>
                                <mat-error *ngIf="formAssetsOP.get('objeto').hasError('required')">
                                    El objeto es requerido</mat-error>
                                <mat-error *ngIf="formAssetsOP.get('objeto').hasError('maxlength')">
                                    El máximo de caracteres válidos es de 240</mat-error>
                                <mat-hint *ngIf="formAssetsOP.get('objeto').value.length"
                                                  align="end">{{formAssetsOP.get('objeto').value ? formAssetsOP.get('objeto').value.length : 0}}
                                            /240</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-md-6">
                                <span [class.disabled]="!ctrl.enabled"
                                                        *ngIf="formAssetsOP.get('base').value"
                                                        class="prefix-inp-form">$</span>
                                <input matInput appCurrencyDirective maxlength="33" decimals="0" formControlName="base" placeholder="Valor antes de IVA"
                                [class.inp-currency]="formAssetsOP.get('base').value" required>
                                <mat-error *ngIf="formAssetsOP.get('base').hasError('required')">El valor antes de iva es
                                    requerido</mat-error>
                                </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-md-6">
                                <span [class.disabled]="!ctrl.enabled"
                                                        *ngIf="formAssetsOP.get('iva').value"
                                                        class="prefix-inp-form">$</span>
                                <input matInput appCurrencyDirective decimals="0" formControlName="iva" placeholder="IVA"
                                [class.inp-currency]="formAssetsOP.get('iva').value" required>
                                <mat-error *ngIf="formAssetsOP.get('iva').hasError('required')">El valor de iva es requerido</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-md-6">
                                <mat-label>Retención en la fuente</mat-label>
                                <mat-select formControlName="reteFuente" [(value)]="reteFuente" required>
                                    <mat-option *ngFor="let value of listReteFuente" [value]="value">
                                        {{value.description}} DESDE {{value.fromValue| currency}}
                                    </mat-option>
                                </mat-select>
                                <mat-progress-bar class="over-head" mode="indeterminate" *ngIf="loadingListValues.get('N')"></mat-progress-bar>
                                <mat-error *ngIf="formAssetsOP.get('reteFuente').hasError('required')">El campo es
                                    requerido</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-md-6">
                                <mat-label>Retención por IVA</mat-label>
                                <mat-select formControlName="reteIva" [(value)]="iva" required>
                                    <mat-option *ngFor="let value of listIva" [value]="value">
                                        {{value.description}} DESDE {{value.fromValue| currency}}
                                    </mat-option>
                                </mat-select>
                                <mat-progress-bar class="over-head" mode="indeterminate" *ngIf="loadingListValues.get('I')"></mat-progress-bar>
                                <mat-error *ngIf="formAssetsOP.get('reteIva').hasError('required')">El campo es
                                    requerido</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-md-6">
                                <mat-label>Retención por ICA</mat-label>
                                <mat-select formControlName="reteIca" [(value)]="ica" required>
                                    <mat-option *ngFor="let value of listIca" [value]="value" >
                                        {{value.description}} DESDE {{value.fromValue| currency}}
                                    </mat-option>
                                </mat-select>
                                <mat-progress-bar class="over-head" mode="indeterminate" *ngIf="loadingListValues.get('C')"></mat-progress-bar>
                                <mat-error *ngIf="formAssetsOP.get('reteIca').hasError('required')">El campo es
                                    requerido</mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title>
                    Ingreso de activos
                </mat-card-title>
                <mat-card-content>
                    <form class="col-12" [formGroup]="formAssetsOP" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <mat-form-field appearance="legacy" class="col-md-6">
                                <input matInput type="number" formControlName="quantity"
                                    placeholder="Cantidad de elementos a ingresar" (change)="changeQuantity($event)" required>
                                <mat-error *ngIf="formAssetsOP.get('quantity').hasError('required')">la cantidad de elementos es requerida para generar el formato de ingreso a continuación</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="col-12 col-md-6">
                                <input matInput [matDatepicker]="pickerFA" formControlName="fechaFactura"
                                    placeholder="Fecha Adquisición" [min]="startValidDate" [max]="todayDate < endValidDate?todayDate:endValidDate" required>
                                <mat-datepicker-toggle matIconSuffix [for]="pickerFA"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFA></mat-datepicker>
                                <mat-error *ngIf="formAssetsOP.get('fechaFactura').hasError('required')">La fecha es
                                    requerida</mat-error>
                            </mat-form-field>
                        </div>
                    </form>

                </mat-card-content>

                <mat-card-content class="container-table-adf">
                    <form (keydown.enter)="preventSubmit($event)" class="col-12" [formGroup]="formAssetsOP" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <ng-container formArrayName="formAssets">
                                <table mat-table [dataSource]="data">
                                    <ng-container matColumnDef="descripcion">
                                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell text-center">
                                            Descripción</th>
                                        <td mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
                                            <mat-form-field class="tb" appearance="legacy" class="col-md-12">
                                                <textarea matInput type="text" maxlength="700" formControlName="descripcion"
                                                    [readonly]="false" required></textarea>
                                                <mat-error *ngIf="row.get('descripcion').hasError('required')">El campo es
                                                        requerido</mat-error>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="valorCompra">
                                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell nombre-h text-center">Valor
                                            compra</th>
                                        <td mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
                                            <div>
                                                <mat-form-field appearance="legacy" class="nombre-c col-md-12">
                                                    <span [class.disabled]="!ctrl.enabled"
                                                        *ngIf="row.get('valorCompra').value"
                                                        class="prefix-inp-form">$</span>
                                                    <input matInput appCurrencyDirective decimals="0"
                                                        [class.inp-currency]="row.get('valorCompra').value"
                                                        formControlName="valorCompra" [readonly]="false" required>
                                                    <mat-error *ngIf="row.get('valorCompra').hasError('required')">El campo es
                                                            requerido</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cuenta">
                                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell text-center">Cuenta
                                        </th>
                                        <td mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
                                            <mat-form-field appearance="legacy" class="col-md-12">
                                                <mat-label>Cuenta</mat-label>
                                                <mat-select formControlName="cuenta"
                                                    (ngModelChange)="onStatusChange($event,i)" required>
                                                    <mat-option *ngFor="let value of listAccounts" [value]="value">
                                                        {{value.nombre}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="row.get('cuenta').hasError('required')">El campo es
                                                    requerido</mat-error>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="quantityAssets">
                                        <th mat-header-cell *matHeaderCellDef class="nombre-header text-center">Cantidad
                                        </th>
                                        <td mat-cell class="nombre-cell" *matCellDef="let row; let i = index" [formGroup]="row">
                                            <mat-form-field appearance="legacy" class="nombre-cell col-md-12">
                                                <input matInput type="number" formControlName="quantityAssets"
                                                     required>
                                                <mat-error *ngIf="row.get('quantityAssets').hasError('required')">Campo requerido</mat-error>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="trademarkRef">
                                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell text-center">Marca
                                        </th>
                                        <td mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
                                            <mat-form-field appearance="legacy" class="col-md-12">
                                                <mat-label>Marca</mat-label>
                                                <mat-select formControlName="trademarkRef" required>
                                                    <ng-container *ngIf="listTrademarks[i]!=1 && listTrademarks[i]!=0">
                                                        <mat-option *ngFor="let value of listTrademarks[i]" [value]="value">
                                                            {{value.trademarkName}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-progress-bar class="over-head" mode="indeterminate" *ngIf="listTrademarks[i]==0"></mat-progress-bar>
                                                <mat-error *ngIf="row.get('trademarkRef').hasError('required')">El campo es
                                                    requerido</mat-error>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="nroSerial">
                                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell text-center">N°
                                            Serial</th>
                                        <td mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
                                            <mat-form-field appearance="legacy" class="col-md-12">
                                                <input matInput type="text" formControlName="nroSerial">
                                                <mat-error *ngIf="row.get('nroSerial').hasError('required')">El campo es
                                                    requerido</mat-error>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayCol; sticky: true"></tr>
                                    <tr mat-row class="mat-row" *matRowDef="let row; columns: displayCol"></tr>
                                </table>
                            </ng-container>
                        </div>
                        <div>
                            <button [disabled]="formAssetsOP.invalid" mat-raised-button color="primary"
                                type="submit">Enviar</button>
                        </div>
                    </form>
                </mat-card-content>

            </mat-card>
        </div>
        <div class="">

        </div>
    </div>

</div>
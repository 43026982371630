import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {DateRangeSelectionComponent, ParamDate} from '@Components/date-range-selection/date-range-selection.component';
import {Attribute, Column, ColumnGroup, Type} from '@Components/table/table.component';
import {LoadingService} from '@services/loading.service';
import {CurrencyPipe} from '@Components/util/currency.pipe';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {DataEntity, Entities, ENUM_ACTIONS, PeriodState} from '@Interfaces/index';
import {AdfiUtil} from '@Components/util/adfi-util';
import {UIHelper} from '@Components/util/UIHelper';
import {SelectItemComponent} from '@Components/ui/select-item/select-item.component';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

const TYPE = 'TYPE';
@Component({
  selector: 'app-auxiliary-budget-books',
  templateUrl: './auxiliary-budget-books.component.html',
  styleUrls: ['./auxiliary-budget-books.component.css']
})
export class AuxiliaryBudgetBooksComponent implements OnInit {

  columnsHeaderGroupAll: ColumnGroup[];
  columnsFooterGroupAll: ColumnGroup[];
  columnsAll: Column[] = [
    {
      column: 'IDENTIFIER_CODE',
      name: 'Tipo',
      group: 'REFERENCE',
      type: Type.Option,
      attr: { classHeader: '',
        options: {BR: 'Ingresos', PO: 'Orden de Pago', BRE: 'Registro', BA: 'Disponibilidad', IP: 'Pago de Factura', BM: 'Modificación Presupuestal', BT: 'Traslado', BD: 'Devolución'} } as Attribute
    },
    {
      column: 'CONSECUTIVE',
      name: 'Número',
      group: 'REFERENCE',
      type: Type.Number,
      attr: { classHeader: 'text-center px-1', classCell: 'text-center'} as Attribute
    },
    {
      column: 'IDENTIFIER_DEPENDENCY',
      name: 'Número Dependencia',
      group: 'REFERENCE',
      type: Type.Number,
      attr: { classHeader: 'text-center', classCell: 'text-center'} as Attribute
    },
    {
      column: 'VALUE',
      name: 'Valor',
      group: 'REFERENCE',
      type: Type.Currency,
      attr: { classCell: 'text-right px-1'} as Attribute
    },
    {
      column: 'DESCRIPTION',
      name: 'Descripción',
      group: 'REFERENCE',
      type: Type.String,
      attr: null
    },
    {
      column: 'DATE_CREATION',
      name: 'Fecha',
      group: 'REFERENCE',
      type: Type.Date,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'NIT',
      name: 'NIT',
      group: 'REFERENCE',
      type: Type.Number,
      attr: { classCell: 'text-center px-2'} as Attribute
    },
    {
      column: 'THIRD',
      name: 'Nombre Tercero',
      group: 'REFERENCE',
      type: Type.String,
      attr: null
    },
    {
      column: 'REFERENCE_DEPENDENCY',
      name: 'Factura / Recibo de Caja',
      group: 'REFERENCE',
      type: Type.Number,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'DETAIL',
      name: 'Detalle',
      type: Type.String,
      attr: null
    },
    {
      column: 'VALUE_DETAIL',
      name: 'Valor Descuento',
      type: Type.Currency,
      attr: { classCell: 'text-right'} as Attribute
    },
    {
      column: 'ACCOUNT_NUMBER',
      name: 'Número Cuenta',
      type: Type.Number,
      attr: { classCell: 'text-center'} as Attribute
    }
  ];
  selectedDates: ParamDate;

  data: any[];
  townOrIe = '';
  filters = {
    sources: [],
    types: [],
    items: []
  };
  canSearchConsolidated: boolean;
  parameters: { types: {key: string, value: string}[], sources: [], items: {key: string, value: string}[]};

  get keyCache(){
    return this.service.user.period + '_DT_BB_' + this.service.user.id;
  }

  get keyParam() {
    return 'GetParametersBudgetBooks';
  }

  get keyEntity() {
    return 'BudgetBooks';
  }

  get keyExportFile() {
    return 'LibrosAuxiliaresPrespuestales';
  }

  constructor(private matDialog: MatDialog,
              private adfiGrowlService: AdfiGrowlService,
              public service: AdfiService,
              public currency: CurrencyPipe,
              public loading: LoadingService,
              private gaService: GoogleAnalyticsService) { }

  validatePermissions() {
    this.canSearchConsolidated = this.service.validPermission(Entities.AUXILIARY_BUDGET_BOOKS, 'consolidated', PeriodState.NO_STATUS);
    if (this.canSearchConsolidated) {
      this.columnsAll.splice(0, 0, {
        column: 'N_ID_CC',
        name: 'I.E.',
        group: 'REFERENCE',
        type: Type.Number,
        attr: { classHeader: 'text-center', classCell: 'text-center px-1'} as Attribute
      });
    }
    this.checkColumns();
  }

  checkColumns() {
    if (this.townOrIe.search(/^[0-9]{7}$/) === 0 || !this.canSearchConsolidated) {
      this.columnsHeaderGroupAll = [
        {
          column: 'BUDGET_CODE',
          name: 'Rubro',
          type: Type.Number
        },
        {
          column: 'ITEM_NAME',
          name: '',
          hideGroupName: true,
          type: Type.String
        },
        {
          column: 'VALUE_ITEM',
          name: 'Saldo Inicial',
          type: Type.Currency
        }
      ];
      this.columnsFooterGroupAll = [
        {
          column: 'END_VALUE_ITEM',
          name: 'Saldo Final Rubro',
          type: Type.Currency
        },
      ];
    } else {
      this.columnsHeaderGroupAll = [
        {
          column: 'BUDGET_CODE',
          name: 'Rubro',
          type: Type.Number
        },
        {
          column: 'ITEM_NAME',
          name: '',
          hideGroupName: true,
          type: Type.String
        }
      ];
      this.columnsFooterGroupAll = null;
    }
  }

  ngOnInit() {
    this.validatePermissions();
    this.resetFilters();
    this.loadConfigData();
    const cache = AdfiUtil.getFromStorage(this.keyCache);
    if (cache) {
      this.selectedDates = JSON.parse(cache);
      this.selectedDates.startDate = new Date(this.selectedDates.startDate);
      this.selectedDates.endDate = new Date(this.selectedDates.endDate);
    }
  }

  getFilters() {
    return {
      type: this.filters.types,
      sources: this.filters.sources,
      items: this.filters.items,
      townOrIe: this.townOrIe,
      consolidated: this.canSearchConsolidated,
    };
  }

  getAllFilters() {
    return {
      startDate: this.selectedDates.startDate.toUTCString(),
      endDate: this.selectedDates.endDate.toUTCString(),
      ...this.getFilters()
    };
  }

  loadConfigData() {
    const next = (d) => {
      if (d && d.fileName) {
        this.parameters = JSON.parse(d.fileName);
        this.loading.hide();
      } else {
        this.adfiGrowlService.error('Reporte', 'Se ha presentado un error al obtener la información, por favor contacta a soporte técnico');
        this.loading.hide();
      }
    };
    this.service.getJson(
        this.keyParam,
        JSON.stringify(this.getFilters()),
        'fileName',
        next
    );
  }

  selectDates() {
    const paramDates: ParamDate = {
      startDate: this.selectedDates ? this.selectedDates.startDate : null,
      endDate: this.selectedDates ? this.selectedDates.endDate : null
    };
    const dialog = this.matDialog.open(DateRangeSelectionComponent, {
      data: paramDates
    });
    dialog.componentInstance.btnText = 'Seleccionar';
    const subs = dialog.componentInstance.afterSelect.subscribe((dates: ParamDate) => {
      subs.unsubscribe();
      this.selectedDates = dates;
      AdfiUtil.saveInStorage(this.keyCache, JSON.stringify(dates));
      this.loadData();
      dialog.close();
    });
  }

  export(format: string) {
    this.gaService.event('Generar Reporte' , 'ADFI', 'Libros Presupuestales ' + format,  this.service.user.id);
    if (!this.loading.active()) {
      this.loading.show();
      this.service.download(
            this.keyEntity,
            JSON.stringify(this.getAllFilters()),
            'fileName',
            `application/${format}`,
          `${this.keyExportFile}.${format}`, '');
    }
  }


  loadData() {
    if (this.canSearchConsolidated && !this.townOrIe && (this.filters.types.length !== 1 || this.filters.items.length !== 1)) {
      this.adfiGrowlService.warning('Advertencia', `Para evitar incovenientes de rendimiento debido a la gran cantidad de datos, la consulta web solo se permite con fitro de (Mún - I.E.) ó (un tipo y ${this.KeyMessage}) a la vez`, 7500);
      return;
    }
    if (this.selectedDates) {
      const next = (d) => {
        this.data = JSON.parse(d.fileName);
      };
      this.loading.show();
      this.service.getJson(
          this.keyEntity,
          JSON.stringify(this.getAllFilters()),
          'fileName',
          next
      );
    } else  {
      this.adfiGrowlService.warning('Advertencia', 'No hay un rango de fechas seleccionado');
    }
  }

  resetFilters() {
    this.selectedDates = null;
    this.parameters = {
      sources: this.parameters ? this.parameters.sources : [],
      types: this.parameters ? this.parameters.types : [],
      items: this.parameters ? this.parameters.items : []
    };
    this.townOrIe = '';
    this.filters = {
      sources: [],
      types: [],
      items: []
    };
  }

  openCenterSearch() {
    const data = {entityName: 'PrCentroCosto', module: '', enableAdd: false} as DataEntity;
    const c = UIHelper.openDialog(SelectItemComponent, this.matDialog, data, ENUM_ACTIONS.SELECT);
    const select = (row) => {
      if (row) {
        if (!this.townOrIe || this.townOrIe.trim() === '') {
          this.townOrIe = '' + row._id;
        } else {
          this.townOrIe += ',' + row._id;
        }
        this.loadConfigData();
        this.checkColumns();
      }
      subs.unsubscribe();
    };
    const subs = c.componentInstance.dialogRef.afterClosed().subscribe(select);
  }

  get KeyMessage() {
    return 'rubro';
  }
}

import {Component, HostBinding} from '@angular/core';
import {LineComponent} from '@Components/ui/menus/line/line.component';

@Component({
  selector: 'circle-menu',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss']
})
export class CircleComponent extends LineComponent {
}

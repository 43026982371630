<div role="group" class="tel-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField?.getLabelId()">
    <span class="tel-input-spacer">&#40;&#43;</span>
    <input class="tel-input-element"
           formControlName="area" size="3"
           maxLength="3"
           aria-label="Area code"
           placeholder="57"
           (keydown)="verifyArea($event)"
           (keyup.enter)="_handleInput(parts.controls.area, isp)"
           #area>
    <span class="tel-input-spacer">&#41;</span>
    <input class="tel-input-element"
           formControlName="isp" size="3"
           placeholder="3**"
           maxLength="3"
           aria-label="ISP"
           (input)="_handleInput(parts.controls.isp, exchange)"
           (keyup.enter)="_handleInput(parts.controls.isp, exchange)"
           (keyup.backspace)="autoFocusPrev(parts.controls.isp, area)"
           #isp>
    <span class="tel-input-spacer">&ndash;</span>
    <input class="tel-input-element"
           formControlName="exchange"
           maxLength="3"
           size="3"
           aria-label="Exchange code"
           placeholder="***"
           (input)="_handleInput(parts.controls.exchange, subscriber)"
           (keyup.enter)="_handleInput(parts.controls.exchange, subscriber)"
           (keyup.backspace)="autoFocusPrev(parts.controls.exchange, isp)"
           #exchange>
    <span class="tel-input-spacer">&ndash;</span>
    <input class="tel-input-element"
           formControlName="subscriber"
           placeholder="****"
           maxLength="4"
           size="4"
           aria-label="Subscriber number"
           (input)="_handleInput(parts.controls.subscriber)"
           (keyup.backspace)="autoFocusPrev(parts.controls.subscriber, exchange)"
           #subscriber>
</div>
<div *ngIf="approveAccount; else solicitud">
    <p></p>
    <app-list [exportableDocs]="false" entityName="Reserve" module="Egresos" [dataEntity]="{enableAdd: false}" customTitle="Corresponden a las reservas aprobadas por parte de la Institución Educativa en el formulario de cierre y recurso de balance"
              [noActions]="true"
              [defaultQuery]="[{column:'center', value:''}]">
        <ng-container class="footer">
            <div class="w-100 d-flex justify-content-around">
                <button *ngIf="item?.state === 'UPL' || item?.state === 'APPRO'" mat-raised-button class="btn-actions" color="primary" matTooltip="Descargar Archivo Firmado" (click)="downloadCertificate('certificate')">Descargar Archivo Firmado</button>
            </div>
        </ng-container>
    </app-list>
</div>
<ng-template #solicitud>
    <mat-vertical-stepper linear #stepper>
        <mat-step [completed]="stepOne" [editable]="!noEditable && !stepFour">
            <ng-template matStepLabel>Reservas presupuestales Aprobadas</ng-template>
            <app-list #list entityName="Reserve" module="Egresos" [dataEntity]="{enableAdd: false}" [exportableDocs]="false" customTitle="Corresponden a las reservas aprobadas por parte de la Institución Educativa en el formulario de cierre y recurso de balance"
                      [noActions]="true"
                      [defaultQuery]="[{column:'center', value:''}]">
                <ng-template tableColumn="justification" let-row="row">
                    <ng-container *ngIf="row.justification else act">
                        {{row.justification}}
                            <button mat-icon-button
                                    matTooltip="Editar Justificación"
                                    color="primary"
                                    (click)="editJustification(row, list)">
                                <mat-icon>edit</mat-icon>
                            </button>
                    </ng-container>
                    <ng-template #act>
                        <div class="d-flex justify-content-center align-items-center w-100">
                            <button mat-icon-button
                                    matTooltip="Especificar Justificación"
                                    color="primary"
                                    (click)="editJustification(row, list)"
                            >
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </ng-template>
                </ng-template>
            </app-list>
            <div class="row-p4 mt-2">
                <div class="d-flex justify-content-center">
                    <div class="px-4" *ngIf="list.data.length; else noReserve">
                        <button mat-raised-button color="primary" (click)="continueDoc()">
                            Continuar
                        </button>
                    </div>
                    <ng-template #noReserve>
                        <p class="text-center row-green w-50 green-text"
                           style="padding: 0.2rem; border-radius: 0.5rem; font-size: 1rem"><strong>
                            La Institución  Educativa no posee reservas</strong>
                        </p>
                    </ng-template>
                </div>
            </div>
        </mat-step>
        <mat-step [completed]="stepTwo" [editable]="stepOne && !stepFour">
            <ng-template matStepLabel>Información de Acto Administrativo</ng-template>

            <form class="row" [formGroup]="formGroup">
                <mat-form-field appearance="legacy" class="col-md-3 col-6">
                    <mat-label>Número de Acto Administrativo</mat-label>
                    <input matInput #numberAgree placeholder="Ej. 9" maxlength="5" formControlName="agreementNumber" required>
                    <mat-hint align="end">{{numberAgree.value.length}} / 5</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="legacy" class="col-md-3 col-6">
                    <mat-label>Fecha de Acto Administrativo</mat-label>
                    <input matInput formControlName="agreementDate" [matDatepicker]="picker" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>


                <mat-form-field appearance="legacy" class="col-12 col-md-3">
                    <mat-label>Rector</mat-label>
                    <mat-select [required]="true" formControlName="director">
                        <mat-option *ngFor="let value of listDirector" [value]="value.id">
                            {{value.name}} {{value.apellidos}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="legacy" class="col-12 col-md-3">
                    <mat-label>Auxiliar</mat-label>
                    <mat-select [required]="true" formControlName="administrative">
                        <mat-option *ngFor="let value of listAux" [value]="value.id">
                            {{value.name}} {{value.apellidos}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--
                <div class="col-12 mt-4">
                    <div class="row justify-content-between pt-1">
                        <mat-label class="col-8">Consideraciones Adicionales</mat-label>
                    </div>
                    <div class="row pt-1 pb-1">
                    <editor class="col-12"
                            formControlName="observation"
                            [init]="{
                            language: 'es',
                            height: 300,
                            menubar: true,
                            plugins: [
                              'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code',
                              'fullscreen insertdatetime media table paste wordcount'
                            ],
                            base_url: '/assets',
                            suffix: '.min',
                            toolbar:
                              'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
                      }">
                    </editor>
                    </div>
                </div> -->
            </form>
            <div class="d-flex justify-content-around mt-2">
                <button mat-raised-button color="primary" [disabled]="formGroup.invalid" (click)="save()">Continuar</button>
            </div>
        </mat-step>
        <mat-step [completed]="stepTree" [editable]="stepTwo && !stepFour">
            <ng-template matStepLabel>Subir Documento de Acto Administrativo</ng-template>
            <div class="d-flex justify-content-around mt-2">
                <p>
                    Debe subir el documento con el cual se constituyeron las reservas
                </p>
            </div>
            <div class="d-flex justify-content-around mt-2">
                <button mat-raised-button color="primary" (click)="uploadFile('upload_file_act')">Subir Documento Acto Administrativo</button>
            </div>
            <div class="d-flex justify-content-around mt-2">
                <button mat-raised-button color="primary" [disabled]="!stepTree" (click)="save()">Continuar</button>
            </div>
        </mat-step>
        <mat-step [editable]="stepTree && !stepFour" [completed]="stepFour">
            <ng-template matStepLabel>Cerrar Documento Información de constitución</ng-template>
            <div class="d-flex justify-content-around mt-2">
                <button mat-raised-button color="secondary" [disabled]="formGroup.invalid" (click)="getPdf()">Generar Borrador</button>
                <button mat-raised-button color="primary" [disabled]="formGroup.invalid" (click)="save('CLO')">Cerrar Documento Información de constitución</button>
            </div>
        </mat-step>
        <mat-step [editable]="stepFour">
            <ng-template matStepLabel>Subir Documento Firmado</ng-template>
            <div *ngIf="item?.state === 'UPL' || item?.state === 'APPRO'; else upl"
                 class="d-flex flex-column align-items-center justify-content-center w-100">
                <p *ngIf="item?.state === 'APPRO'" class="text-center row-green w-50 green-text"
                   style="padding: 0.2rem; border-radius: 0.5rem; font-size: 1rem"><strong>
                    INFORMACIÓN DE CONSTITUCIÓN DE RESERVA YA CARGADA</strong>
                </p>
                <div class="d-flex justify-content-around mt-2">
                    <button mat-raised-button class="btn-actions mx-1" color="primary" matTooltip="Descargar Archivo Firmado" (click)="downloadCertificate('administrativeActFile')">Descargar Acto Administrativo</button>
                    <button mat-raised-button class="btn-actions mx-1" color="primary" matTooltip="Descargar Archivo Firmado" (click)="downloadCertificate('certificate')">Descargar Archivo Firmado</button>
                </div>
            </div>
            <ng-template #upl>
                <div class="d-flex justify-content-around mt-2">
                    <button *ngIf="canSetInitial" mat-raised-button color="primary" (click)="toInitState()">Devolver a estado Inicial</button>
                    <button mat-raised-button color="primary" (click)="getPdf()">Generar Documento</button>
                    <button mat-raised-button color="primary" (click)="uploadFile()">Subir Documento</button>
                </div>
            </ng-template>
        </mat-step>
    </mat-vertical-stepper>
</ng-template>




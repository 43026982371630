import { Component } from '@angular/core';
import { RubrosPresupuestoComponent } from './rubros-presupuesto.component';
import {Pages, TipoPresupuesto} from '@Interfaces/index';

@Component({
  selector: 'app-rubros-presupuesto',
  templateUrl: './rubros-presupuesto.component.html',
  styleUrls: ['./rubros-presupuesto.component.css']
})
export class RubrosPresupuestoEgresoComponent extends RubrosPresupuestoComponent {
  prefix = 'Egresos';

  getType() {
    return TipoPresupuesto.EGRESO;
  }
  getModule() {
    return Pages.BUDGET_EXPENSES;
  }
}

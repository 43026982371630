import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormComponent} from '@Components/ui/form/form.component';
import {ComponentType} from '@angular/cdk/portal';
import {TemplateRef} from '@angular/core';
import {DataEntity, ENUM_ACTIONS} from '@Interfaces/index';

export class UIHelper {

    public static openDialog<T, D = any, R = any>(component: ComponentType<T> | TemplateRef<T>,
                                                  dialog: MatDialog, dataEntity: DataEntity,
                                                  action: ENUM_ACTIONS,
                                                  id?: string,
                                                  customData: DataEntity = null): MatDialogRef<T, R> {
        dataEntity.action = action;
        if (!dataEntity.customData) {
            dataEntity.customData = customData;
        }
        dataEntity.item = id;
        return dialog.open(component, {
            data: dataEntity,
            minWidth: dataEntity.dialogMinWidth ? dataEntity.dialogMinWidth : '55%',
            maxWidth: dataEntity.dialogMaxWidth ? dataEntity.dialogMaxWidth : '85%',
            maxHeight: dataEntity.dialogMaxHeight ? dataEntity.dialogMaxHeight : '85vh'
        });
    }

    private static checkSizeDialog(dataEntity: DataEntity, action: ENUM_ACTIONS) {
        switch (action) {
            case ENUM_ACTIONS.CREATE:
            case ENUM_ACTIONS.EDIT:
            case ENUM_ACTIONS.VIEW:
                if (!dataEntity.dialogMinWidth) {
                    dataEntity.dialogMinWidth = '55%';
                }
                if (!dataEntity.dialogMaxWidth) {
                    dataEntity.dialogMaxWidth = '80%';
                }
                break;
        }
    }

    public static editItem(dialog: MatDialog, id, entityName: string, module: string, customData, groups?): MatDialogRef<any> {
        const dataEntity = { entityName, module, group: groups} as DataEntity;
        this.checkSizeDialog(dataEntity, ENUM_ACTIONS.EDIT);
        return this.openDialog(FormComponent, dialog, dataEntity, ENUM_ACTIONS.EDIT,
            id, customData);
    }

    public static editItemEntity(dialog: MatDialog, id, data: DataEntity, customData = null): MatDialogRef<any> {
        this.checkSizeDialog(data, ENUM_ACTIONS.EDIT);
        return this.openDialog(FormComponent, dialog, data, ENUM_ACTIONS.EDIT, id, customData);
    }

    public static createItem(dialog: MatDialog, entityName: string, module: string, customData?): MatDialogRef<any> {
        const dataEntity = {entityName, module} as DataEntity;
        this.checkSizeDialog(dataEntity, ENUM_ACTIONS.CREATE);
        return this.openDialog(FormComponent, dialog, dataEntity, ENUM_ACTIONS.CREATE, undefined, customData);
    }

    public static createItemEntity(dialog: MatDialog, dataEntity: DataEntity): MatDialogRef<any> {
        this.checkSizeDialog(dataEntity, ENUM_ACTIONS.CREATE);
        return this.openDialog(FormComponent, dialog, dataEntity, ENUM_ACTIONS.CREATE);
    }
}

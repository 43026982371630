import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {ParamDate} from '@Components/date-range-selection/date-range-selection.component';
import {AdfiService} from '@services/adfi-service';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import moment from 'moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDateRangePicker} from '@angular/material/datepicker';
import {DateRangeHeaderComponent} from '@Components/date-range-selection/date-range-header/date-range-header.component';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html'
})
export class DateRangePickerComponent implements OnInit {

  minDate: moment.Moment;
  maxDate: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;

  @Input() stylePicker: 'Legacy' | 'Advanced' = 'Advanced';
  @Input() params: ParamDate;
  @Input() customStartText = 'Desde';
  @Input() customEndText = 'Hasta';
  _usePeriod = false;
  @Input() set usePeriod(usePeriod: boolean){
    this._usePeriod = usePeriod;
    if (this._initialized) {
      this.ngOnInit();
    }
  }
  get usePeriod() { return this._usePeriod; }
  _initialized = false;

  @ViewChild(MatDateRangePicker) picker: MatDateRangePicker<moment.Moment>;
  @Output() dateSelected = new EventEmitter<{ startDate: Date|moment.Moment, endDate: Date|moment.Moment}>();
  now: moment.Moment = moment();
  form: FormGroup;
  header = DateRangeHeaderComponent;

  constructor(private service: AdfiService,
              private loading: LoadingService,
              private growl: AdfiGrowlService) { }

  ngOnInit(): void {
    if (!this.params) {
      this.params = { startDate: null, endDate: null} as ParamDate;
    }
    if (this.usePeriod) {
      this.loading.show();
      const next = (v) => {
        this.loading.hide();
        if (!this.params.minDate) {
          this.minDate = DateRangePickerComponent.toMoment(v.fechaApertura);
        }
        if (this.params.maxDate) {
          if (this.params.maxDate > v.fechaCierre) {
            this.maxDate = DateRangePickerComponent.toMoment(v.fechaCierre);
          }
          this.maxDate = DateRangePickerComponent.toMoment(v.estado === 'A' ? this.now > v.fechaCierre ? v.fechaCierre : this.now : this.params.maxDate);
        } else {
          this.maxDate = DateRangePickerComponent.toMoment(v.estado === 'A' ? this.now > v.fechaCierre ? v.fechaCierre : this.now: v.fechaCierre);
        }
        this.initForm();
      };
      const error = (e) => {
        this.loading.hide();
        this.growl.errorMessage(e, 'Error al cargar la vigencia actual');
      };
      this.service.getPeriodByUser({next, error});
    } else {
      if (!this.params.maxDate) {
        this.maxDate = this.now;
      }
      this.initForm();
    }
    this._initialized = true;
  }

  initForm() {
    if (this.form){
      this.startDate = this.form.get('startDate').value;
      this.endDate = this.form.get('endDate').value;
      this.form.removeControl('startDate');
      this.form.removeControl('endDate');
    }
    if (this.params.startDate) {
      if (this.startDate)
        this.startDate = this.startDate < this.minDate ? this.minDate : this.startDate;
      else
        this.startDate = DateRangePickerComponent.toMoment(this.params.startDate < this.minDate.toDate() ? this.minDate : this.params.startDate);
    } else
        this.startDate = this.startDate ? this.startDate < this.minDate ? this.minDate : this.startDate: this.minDate;
    if (this.params.endDate) {
      if (this.endDate)
        this.endDate = this.endDate > this.maxDate ? this.maxDate : this.endDate;
      else
        this.endDate = DateRangePickerComponent.toMoment(this.params.endDate > this.maxDate.toDate() ? this.maxDate : this.params.endDate);
    } else
      this.endDate = this.endDate ? this.endDate > this.maxDate ? this.maxDate : this.endDate: this.maxDate;
    this.form = new FormGroup({
      startDate: new FormControl(this.startDate, Validators.required),
      endDate: new FormControl(this.endDate, Validators.required),
    });
    this.form.get('startDate').valueChanges.subscribe((e: moment.Moment) => {
      this.startDate = e
      if (this.form.get('startDate').valid) {
        this.form.get('endDate').enable();
      } else {
        this.form.get('endDate').disable();
      }
    });
    this.form.get('endDate').valueChanges.subscribe((e: moment.Moment) => {
      this.endDate = e;
    });
    if (!this.form.get('startDate').valid) {
      this.form.get('endDate').disable();
    }
  }

  private static toMoment(date: any): moment.Moment {
    if (date instanceof moment) {
        return date as moment.Moment;
    } else if (date instanceof Date){
      return moment(date.toISOString())
    }
    return moment(new Date(date).toISOString())
  }
  private parse(date: any, setTime = false): Date {
    if (date instanceof moment) {
      if (setTime) {
        (date as moment.Moment).set({hour: 23, minute: 59, second: 59});
      }
      return (date as moment.Moment).toDate();
    } else if (date instanceof Date) {
      if (setTime) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
      }
      return date;
    } else {
      return new Date(date);
    }
  }

  onSubmit() {
    if (this.endDate)
      this.dateSelected.emit(this.getDates());
  }

  getDates():ParamDate {
    return { startDate: this.parse(this.form.get('startDate').value), endDate: this.parse(this.form.get('endDate').value, true)} as ParamDate;
  }
}

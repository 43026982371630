import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AdfiGrowlType, AdfiGrowl } from '@Components/util/adfiGrowl';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})
export class AdfiGrowlService {

  private subject = new Subject<AdfiGrowl>();
  private subjectStarted = new Subject<boolean>();
  private idx = 0;
  private DEFAULT_MESSAGE = 'Error inesperado, por favor contacte a soporte técnico';

  constructor( private gaService: GoogleAnalyticsService) { }

  getObservable(): Observable<AdfiGrowl> {
    return this.subject.asObservable();
  }

  getObservableStarted(): Observable<boolean> {
    return this.subjectStarted.asObservable();
  }

  info(title: string, message: string, timeout = 3000) {
    this.subject.next(new AdfiGrowl(this.idx++, AdfiGrowlType.info, title, message, timeout));
  }

  success(title: string, message: string, timeout = 3000) {
    this.subject.next(new AdfiGrowl(this.idx++, AdfiGrowlType.success, title, message, timeout));
  }

  warning(title: string, message: string, timeout = 3000) {
    this.subject.next(new AdfiGrowl(this.idx++, AdfiGrowlType.warning, title, message, timeout));
  }

  error(title: string, message: string, timeout = 0) {

    const res = message.match('(.*Http failure response for.*):(.*)');
    if (res && res.length > 0) {
      message = res[res.length - 1];
    }
    this.subject.next(new AdfiGrowl(this.idx++, AdfiGrowlType.error, title, message, timeout));
    this.gaService.exception(message);
  }

  errorMessage(error, title= 'Error', timeout = 0) {
    if (error && error.graphQLErrors) {
      this.error(title, error.graphQLErrors.map((e) => {
        if (e.debugMessage) {
            const reg = [...e.debugMessage.matchAll('(?<=ORA-2)(.*?)(?=\\s*ORA-)')];
            if (reg.length) {
                return reg.join('\n');
            }
            return e.debugMessage;
        } else {
            return e.message;
        }
      }).join('\n'), timeout);
    } else if (error && error.message) {
      this.error(title, error.message, timeout);
    } else if (error && error.error) {
      const mess = error.error.match('exception-message \\">(.*)<');
      if (mess && mess.length > 0) {
        this.error(title, mess[mess.length - 1], timeout);
      } else {
        this.error(title, error.message ? error.message : this.DEFAULT_MESSAGE, timeout);
      }
    } else {
      this.error(title, this.DEFAULT_MESSAGE, timeout);
    }
  }

}

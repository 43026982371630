<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="row justify-content-center">
        <h3>{{tittle}}</h3>
      </div>
    </div>
    <form class="col-12" [formGroup]="documentForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="col-12">
        <mat-progress-bar *ngIf="loadingProofs" mode="indeterminate" class="over-head"></mat-progress-bar>
        <mat-label>Comprobante</mat-label>
        <mat-select placeholder="Seleccionar" formControlName="proof" (selectionChange)="selectProof($event.value)">
          <mat-option *ngFor="let doc of proofDocuments" [value]="doc">
            {{ doc.name | titlecase}}
          </mat-option>
        </mat-select>
        <mat-error>
          El comprobante es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-progress-bar *ngIf="loadingDocuments" mode="indeterminate" class="over-head"></mat-progress-bar>
        <mat-label>Tipo de Documento</mat-label>
        <mat-select placeholder="Seleccionar" formControlName="type">
          <mat-option *ngFor="let doc of typeDocuments" [value]="doc">
            <b>Nombre:</b> {{ doc.name | titlecase}}
            <ng-container *ngIf="doc.docDescription">
              <b> - Descripción</b>
              {{ doc.docDescription | titlecase}}
            </ng-container>
          </mat-option>
        </mat-select>
        <mat-error>
          El tipo de documento es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label>Descripción</mat-label>
        <textarea matInput #reason formControlName="reason" maxlength="200"></textarea>
        <mat-hint align="end">{{reason.value?.length || 0}}/ {{reason.maxLength}}</mat-hint>
        <mat-error>
          La descripción es <strong>requerida</strong>
        </mat-error>
      </mat-form-field>
      <div class="col-12 mt-3">
        <div class="row justify-content-end">
          <button mat-raised-button mat-dialog-close>Cancelar</button>
          <button mat-raised-button type="submit" color="primary" [disabled]="loadingProofs || loadingDocuments || documentForm.invalid">Guardar</button>
        </div>
      </div>
    </form>
  </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavRoute} from '../../../utils/menu';
import { AdfiService } from '@services/adfi-service';
import { PeriodState } from '@Interfaces/index';
import { AdfiGrowlService } from '@services/adfi-growl.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() items: NavRoute[];
  @Input() subMenu = false;
  @Output() clickedItem = new EventEmitter();

  customClass: string;
    previousPeriod: string;
    currentPeriod: string;


  constructor(private adfiService: AdfiService, private adfiGrowlService: AdfiGrowlService) { }

  ngOnInit() {
    this.customClass = this.subMenu ? "submenu-item" : "";
    if (this.adfiService.getUserPeriod().estado === PeriodState.OPEN) {
      this.getPeriodTitle();
    }
  }

  getPeriodTitle() {
    const next = (d) => {
      if (d) {
        if (this.adfiService.user.period < d.period) {
          this.items.forEach(item => {
            if (Array.isArray(item.groupedNavRoutes)) {
              item.groupedNavRoutes.forEach(element => {
                if (element.period) {
                  this.previousPeriod = '- ' + this.adfiService.user.period;
                  this.currentPeriod = '- ' + d.period;
                }
              });
            }
          });
        }
      }

    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
    };
    this.adfiService.getTimeControl('InitialBudget', next, error);
  }
}

import {Apollo} from 'apollo-angular';
import {Component, OnInit} from '@angular/core';

import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {LoadingService} from '@services/loading.service';
import {AdfiService} from '@services/adfi-service';
import {AdfiGrowlService} from '@services/adfi-growl.service';

@Component({
    selector: 'app-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {

    roles: any[];
    roleSelected: any;
    modules: Map<string, any[]>;

    constructor(
        private apollo: Apollo,
        private adfiGraphql: AdfiGraphqlService,
        private alert: AdfiGrowlService,
        private loading: LoadingService,
        private adfiService: AdfiService,
        private growl: AdfiGrowlService) {
    }

    ngOnInit() {
        this.loadRoles();
        this.loadModules();
    }

    private loadRoles() {
        this.loading.show();
        const entity = 'roles';
        const filters = '';
        const cols = 'name nameProfile accessRights(first:500){edges{node{id module action}}}';
        this.adfiGraphql.countEntity(entity, filters).then((count) => {
            const next = (roles) => {
                this.roles = roles;
                if (this.roleSelected) {
                    for (const r of roles) {
                        if (r.id === this.roleSelected.id){
                            this.roleSelected = r;
                            break;
                        }
                    }
                }
                this.loading.hide();
            };
            const error = (e) => {
                this.loading.hide();
                this.growl.errorMessage(e, 'Error al cargar los roles del sistema');
            };
            this.adfiGraphql.getEntity(entity, cols, count, filters, {next, error});
        });
    }

    private loadModules() {
        this.loading.show();
        const ok = (list: any) => {
            const modules = new Map();
            list.forEach(value => {
                if (modules.has(value.module)) {
                    modules.set(value.module, [...modules.get(value.module), value]);
                } else {
                    modules.set(value.module,  [value]);
                }
            });
            this.modules = modules;
            if (this.roles && this.roles.length) {
                this.loading.hide();
            }
        };
        const err = (e) => {
            this.loading.hide();
            this.alert.errorMessage(e, 'Error al obtener la información del Sistema');
        };
        this.adfiService.get('/api/get/modules').subscribe(ok, err);
    }

    selectRole(rol: any) {
        this.roleSelected = rol;
    }

    isChecked(page: any, action: string) {
        const accessRights = this.roleSelected.accessRights.edges;
        return accessRights.find(a => (action === a.node.action && a.node.module === page.entity)) !== undefined;
    }

    changePermission(page: any, permission: string, event: MatSlideToggleChange) {
        this.loading.show();
        if (event.checked) {
            const next = () => {
                this.alert.success('Correcto', 'Permiso Agregado Correctamente');
                this.loading.hide();
                this.loadRoles();
            };
            const error = () => {
                this.alert.error('Error', 'Error al agregar permiso');
                this.loading.hide();
            };
            this.adfiGraphql.createEntity('AccessRights',
                 { action: permission, module: page.entity , role: this.roleSelected.id}, '',
                { next, error}, true);
        } else {
            const accessRight = this.roleSelected.accessRights.edges.find(a =>
                (permission === a.node.action && a.node.module === page.entity));
            const next = () => {
                this.alert.success('Correcto', 'Permiso Eliminado Correctamente');
                this.loading.hide();
                this.loadRoles();
            };
            const error = () => {
                this.alert.error('Error', 'Error al eliminar el permiso');
                this.loading.hide();
            };
            this.adfiGraphql.deleteEntity('AccessRights', accessRight.node.id, { next, error}, true);
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import {ENUM_ACTIONS} from '@Interfaces/index';

@Pipe({
  name: 'filterDropdown'
})
export class FilterDropdownPipe implements PipeTransform {

  transform(items: any[], action: ENUM_ACTIONS): any {
    if (!items || !action) {
      return items;
    }
    return items.filter(item => item[action] === undefined || item[action]);
  }

}

import { Component } from '@angular/core';
import { NavRouteService } from 'src/app/services/nav-routing-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ADFI';
  constructor(navRouteService: NavRouteService) {
    navRouteService.listenRouting();
  }
}

import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
export interface ParamDate {
  minDate?: Date;
  maxDate?: Date;
  startDate: Date;
  endDate: Date;
  displayStartDate?: boolean;
  usePeriod?: boolean;
}
@Component({
  selector: 'app-date-range-selection',
  templateUrl: './date-range-selection.component.html',
  styleUrls: ['./date-range-selection.component.css']
})
export class DateRangeSelectionComponent {

  @Input() customTitle = 'Seleccionar rango de fechas';
  @Input() customStartText = 'Desde';
  @Input() customEndText = 'Hasta';
  @Input() btnText = 'Consultar';
  @Input() usePeriod;
  @Output() afterSelect = new EventEmitter<ParamDate>();
  constructor(@Inject(MAT_DIALOG_DATA) public data: ParamDate) {
    this.usePeriod = data.usePeriod === undefined ? true : data.usePeriod;
  }
}

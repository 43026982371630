import { Component, OnInit } from '@angular/core';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {MatDialog} from '@angular/material/dialog';
import {UIHelper} from '@Components/util/UIHelper';
import {DataEntity, ENUM_ACTIONS} from '@Interfaces/index';
import {ListComponent} from '@Components/ui/list/list.component';
import {LoadingService} from '@services/loading.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-call-assistance',
  templateUrl: './call-assistance.component.html',
  styleUrls: ['./call-assistance.component.scss']
})
export class CallAssistanceComponent implements OnInit {

  trainingList: any[];
  scheduleList: any;
  trainingCallList: any;
  selectedSchedule: any;
  selectedCall: any;
  selectedTraining: any;

  constructor(private graphqlService: AdfiGraphqlService, private adfiService: AdfiService,
              private growlService: AdfiGrowlService, private matDialog: MatDialog, private loading: LoadingService) { }

  ngOnInit(): void {
    this.loadTraining();
  }

  private loadTraining(){
    const next = (values) => {
      this.trainingList = values;
      if (values && values.length === 1){
        this.selectTraining(values[0]);
      }
    };

    const error = (e) => {
      this.growlService.errorMessage(e);
    };
    this.graphqlService.getEntity('trainings', 'titleTraining schedules{edges{node{_id id callAssistances{edges{node{id _id consecutive dateCall }}} startTown{nombre} endTown{nombre}}}}', 12, `period: ${this.adfiService.user.period}`, {next, error});
  }

  loadCallList(schedule, idSelect?) {
    const next = (values) => {
      this.trainingCallList = values;
      if (idSelect){
        const selected =  this.trainingCallList.find(val => (val.id === idSelect));
        if (selected){
          this.selectCall(selected);
        }
      }
    };

    const error = (e) => {
      this.growlService.errorMessage(e);
    };
    this.graphqlService.getEntity('callAssistances', 'id _id consecutive dateCall', 12, `schedule_id: ${schedule}`, {next, error});
  }

  selectTraining(value: any) {
    this.selectedTraining = value;
    this.scheduleList = value.schedules.edges.map( node => (node.node));
  }

  selectSchedule(value: any) {
    this.selectedSchedule = value;
    this.loadCallList(this.selectedSchedule._id);
  }

  newCall() {
      const refDialog = UIHelper.createItemEntity(this.matDialog, { entityName: 'CallAssistance', module: 'Trainings',
        keepDataObjects: true,
        customReturnAfterSave: ['id'],
        group: 'add',
        customData: {
          schedule: this.selectedSchedule.id
        }
      } as DataEntity);

      refDialog.componentInstance.postSave.subscribe(d => {
        if (d) {
          this.selectedCall = undefined;
          setTimeout(() => {
            this.loadCallList(this.selectedSchedule._id, d.id);
          }, 100);
        }
      });
    }

  selectCall(value: any) {
    this.selectedCall = undefined;
    setTimeout(() => {
      this.selectedCall = value;
    }, 100);  }

  editObservation(row: any, list: ListComponent) {
    const dialogAdd = UIHelper.editItem(this.matDialog, row.id, 'Assistance', 'Trainings', {}, 'EOBS');
    dialogAdd.afterClosed().subscribe(value => {
      if (value) {
        list.reload();
      }
    });
  }

  replyAssistance(row: any, reply: boolean, list: ListComponent) {
    this.loading.show();
    const next = (data) => {
      list.reload();
      this.loading.hide();
    };
    const error = (e) => {
      this.growlService.errorMessage(e);
      this.loading.hide();
    };
    if (row.reply === true || row.reply === false){
      const data: ConfirmDialogData = {
        title: 'Cambiar estado',
        content: `Esta segur@ de cambiar el estado de asistencia de <strong>${row.reservation.name}
                ${row.reservation.lastname}</strong> ha estado <strong>${row.reply ? 'NO PRESENTE' : 'PRESENTE' }</strong>`,
        action: ENUM_ACTIONS.SELECT
      };
      const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
        data
      });
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.graphqlService.mutationEntity('Assistance', {id: row.id, reply: !row.reply},
              'id reply', ENUM_ACTIONS.EDIT, {next, error});
        } else {
          this.loading.hide();
        }
      });
    } else {
      this.graphqlService.mutationEntity('Assistance', {id: row.id, reply},
          'id reply', ENUM_ACTIONS.EDIT, {next, error});
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DocumentationComponent } from './documentation/documentation.component';
import {MatCardModule} from '@angular/material/card';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {CurrencyPipe} from '@Components/util/currency.pipe';
import {UiModule} from '@Components/ui/ui.module';
import {MatDialogModule} from '@angular/material/dialog';
import {UtilModule} from '@Components/util/util.module';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
  declarations: [ChangePasswordComponent, DocumentationComponent],
    imports: [
        CommonModule,
        UserRoutingModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatCardModule,
        MatRippleModule,
        MatIconModule,
        UiModule,
        MatDialogModule,
        UtilModule,
        MatSelectModule
    ],
    providers: [CurrencyPipe]
})
export class UserModule { }

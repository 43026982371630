import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NavRoute} from 'src/app/utils/menu';

import {Entities, Pages, Permission} from '@Interfaces/index';
import {UsuariosSedesComponent} from '@pages/directorio/usuarios-sedes/usuarios-sedes.component';
import {PermissionsComponent} from '@pages/directorio/permissions/permissions.component';

export const RoutesDirectorio: NavRoute[] = [
    {
        path: `${Pages.DIRECTORY_USER_CENCOS}/:cencos`,
        nav: { title: 'Información', icon: 'school' },
        component: UsuariosSedesComponent,
        enabled: false,
        entity: Entities.COST_CENTER,
        permission: Permission.VIEW_ALL
    },
    {
        path: `${Pages.DIRECTORY_USER_CENCOS}`,
        nav: { title: 'Información', icon: 'school' },
        component: UsuariosSedesComponent,
        permission: Permission.VIEW,
        entity: Entities.COST_CENTER
    },
    {
        path: Pages.PERMISSIONS,
        nav: { title: 'Permisos', icon: 'how_to_reg'},
        entity: Entities.ACCESS_RIGHT,
        component: PermissionsComponent,
        permission: Permission.EDIT
    },
    {
        path: '**',
        redirectTo: Pages.DIRECTORY_USER_CENCOS,
    },
];

@NgModule({
    imports: [RouterModule.forChild(RoutesDirectorio)],
    exports: [RouterModule]
})
export class DirectorioRoutingModule { }

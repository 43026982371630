import {AfterContentInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {AdfiGrowlType, AdfiGrowl} from '@Components/util/adfiGrowl';
import {Overlay, CdkOverlayOrigin, OverlayConfig } from '@angular/cdk/overlay';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';

@Component({
    selector: 'app-adfi-growl',
    templateUrl: './adfi-growl.component.html',
    styleUrls: ['./adfi-growl.component.scss']
})
export class AdfiGrowlComponent implements OnDestroy, AfterContentInit {

    constructor(private notificationService: AdfiGrowlService, private overlay: Overlay,
                private adfiGraphqlService: AdfiGraphqlService, private adfiService: AdfiService) {
    }

    public static FPS = 100;

    @ViewChild('notifyTemplate', {static: true}) notifyTemplate;

    @ViewChild(CdkOverlayOrigin, {static: true}) overlayOrigin: CdkOverlayOrigin;
    notifications: AdfiGrowl[] = [];
    notificationSaved: Map<string|number, AdfiGrowl> = new Map<string|number, AdfiGrowl>();
    private subscription: Subscription;
    private subscriptionStarted: Subscription;
    public started = false;
    bell = false;
    total = 0;

    @Input() noShowIcon = false;
    private addNotification(notification: AdfiGrowl) {
        if (notification.priority){
            if (!this.notificationSaved.has(notification.id)){
                this.bell = true;
                this.notificationSaved.set(notification.id, notification);
                setTimeout(() => this.bell = false, 1000);
            }
        } else if (this.started && !this.notificationSaved.has(notification.id)){
            this.notifications.push(notification);
            this.openNotifyTemplatePanel();
            if (notification.timeout !== 0) {
                notification.timeoutDestroy = notification.timeout - 300;
                const intervalID = setInterval(() => this.countSeconds(notification), AdfiGrowlComponent.FPS);
                notification.timerId = setTimeout(() => {
                    this.close(notification);
                    clearTimeout(intervalID);
                }, notification.timeout);
            }
        }
    }

    ngOnInit() {
        this.loadNotifications();
    }

    ngAfterContentInit(): void {
        this.subscription = this.notificationService.getObservable().subscribe(notification => {
            this.loadNotifications();
            this.addNotification(notification);
        });   
    }

    ngOnDestroy() {
        this.notifications.forEach((value: AdfiGrowl) => {
           clearTimeout(value.timerId);
        });
        this.subscription.unsubscribe();
    }

    addNotificationServer(notifications: any[]){
        for (const notification of notifications){
            this.addNotification(new AdfiGrowl(notification.id, AdfiGrowlType.info, 'Notificación',
                notification.event.text, 10000,  notification.event.priority));
        }
        this.started = true;
    }

    loadNotifications() {
        if (this.adfiService.user) {
            const next = async (data) => {
                this.total = await this.adfiGraphqlService.countEntity('notifications', `employeeToNotify_id: ${this.adfiService.user.id}, seenByUser: false`);
                this.addNotificationServer(data);
            };
            const error = (e) => {
                console.log(e);
            };
            this.adfiGraphqlService.getEntity('notifications', 'event{process text creationDate idReference priority} seenByUser', 7,
                `employeeToNotify_id: ${this.adfiService.user.id}, seenByUser: false`, {next, error});
        }
    }

    countSeconds(notification: AdfiGrowl) {
        notification.timeoutDestroy = notification.timeoutDestroy - AdfiGrowlComponent.FPS;
    }

    close(notification: AdfiGrowl) {
        // this.notificationSaved.add(notification);
        clearTimeout(notification.timerId);
        this.notifications = this.notifications.filter(notify => notify.id !== notification.id);
    }

    deleteNotifyLive(notification: AdfiGrowl) {
        clearTimeout(notification.timerId);
        this.notifications = this.notifications.filter(notify => notify.id !== notification.id);
    }

    delete(notification: AdfiGrowl) {
        notification.loading = true;
        const next = value => {
            this.notificationSaved.delete(notification.id);
            this.loadNotifications();
        };
        const error = value => {
            notification.loading = false;
        };
        this.adfiGraphqlService.updateEntity('Notification', {
            id: notification.id,
            seenByUser: true
        }, 'id', {next, error});
    }

    className(notification: AdfiGrowl): string {

        let style: string;

        switch (notification.type) {
            case AdfiGrowlType.success:
                style = 'success';
                break;
            case AdfiGrowlType.warning:
                style = 'warning';
                break;
            case AdfiGrowlType.error:
                style = 'error';
                break;
            default:
                style = 'info';
                break;
        }
        return style;
    }

    getClassProgress(notification: AdfiGrowl) {
        let className: string;
        switch (notification.type) {
            case AdfiGrowlType.success:
                className = 'success';
                break;
            case AdfiGrowlType.warning:
                className = 'warning';
                break;
            case AdfiGrowlType.error:
                className = 'error';
                break;
            default:
                className = 'info';
                break;
        }
        return 'px-2' + className;
    }

    deleteAll() {
        this.notifications = [];
    }

    openNotifyTemplatePanel() {
        const strategy = this.overlay.position()
            .global().height('1px');

        const config = new OverlayConfig({positionStrategy: strategy});
        const overlayRef = this.overlay.create(config);

        overlayRef.attach(this.notifyTemplate);
    }
}


<div class="row p-3">
    <div class="text-center col-12">
        <h2>{{data.title === 1 ? 'Aprobar' : 'Anular' }}</h2>
    </div>
    <div class="col-12">
        <p>Al aprobar o anular se le enviará un correo a la persona, con la repuesta a su solicitud de asistencia a la capacitación</p>
    </div>
    <mat-form-field class="col-12" appearance="fill" *ngIf="data.title === 2">
        <mat-label>Observación</mat-label>
        <textarea #message matInput maxlength="1000" [(ngModel)]="obs"></textarea>
        <mat-hint align="end">{{message.value.length}} /1000</mat-hint>
    </mat-form-field>
    <div class="col-12 d-flex justify-content-center">
        <button type="button" class="mx-1" mat-raised-button (click)="save()">{{data.title === 1 ? 'Aprobar' : 'Anular' }}</button>
        <button type="button" class="mx-1" mat-raised-button (click)="onNoClick()">Cancelar</button>
    </div>

</div>

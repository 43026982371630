import { Component, OnInit } from '@angular/core';
import {Pages} from '@Interfaces/index';

@Component({
  selector: 'app-accesorios',
  templateUrl: './accesorios.component.html',
  styleUrls: ['./accesorios.component.css']
})
export class AccesoriosComponent implements OnInit {

  PAGES = Pages;

  constructor() { }

  ngOnInit() {
  }

}

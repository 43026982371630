<h1 mat-dialog-title>Hola {{ data.names }} {{ data.lastnames}}</h1>
<div mat-dialog-content>
    <h3 class="text-center">Usted Confirma que los Datos que ha ingresado son Correctos</h3>
    <h5>Usted ha ingresado los siguientes Datos</h5>
    <div class="row">
        <p class="col-12 col-md-6">
            <mat-icon aria-hidden="false" aria-label="Nombres y Apellidos">people</mat-icon>
            <strong>  Nombres y Apellidos:</strong> {{ data.names }} {{ data.lastnames}}</p>
        <p class="col-12 col-md-6">
            <mat-icon aria-hidden="false" aria-label="Número de Documento">credit_card</mat-icon>
            <strong>  Número de Documento:</strong> {{ data.document }}</p>
        <p class="col-12 col-md-6">
            <mat-icon aria-hidden="false" aria-label="Número de Celular">settings_cell</mat-icon>
            <strong>  Número de Celular:</strong> {{ data.cellphone }}</p>
        <p class="col-12 col-md-6">
            <mat-icon aria-hidden="false" aria-label="Correo Electronico">email</mat-icon>
            <strong>  Correo Electrónico:</strong> {{ data.email }}</p>
        <p class="col-12 col-md-6" *ngIf="data.ie">
            <mat-icon aria-hidden="false" aria-label="Institución Educativa">domain</mat-icon>
            <strong>  Institución Educativa:</strong> {{ data.ie?.deDescripcion }}</p>
        <p class="col-12 col-md-6" [ngSwitch]="data.role">
            <mat-icon aria-hidden="false" aria-label="Cargo">groups</mat-icon>
            <strong>  Cargo:</strong>
            <span *ngSwitchCase="142">
            Auxiliar
    </span>
            <span *ngSwitchCase="162">
            Rector
    </span>
            <span *ngSwitchCase="202">
            Contador Secretaria
    </span>
            <span *ngSwitchCase="300">
            {{ data.otherRole }}
    </span>
        </p>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="legal__terms">
                <embed src="https://infogex.co/wp-content/uploads/2023/07/Pol%C3%ADtica-de-tratamiento-de-datos-INFOGEX-V2.pdf" type="application/pdf" width="100%" height="600px" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 text-center">
            <mat-checkbox class="example-margin" [checked]="allComplete"
                          style="width: auto"
                          [(ngModel)]="allComplete"
                          tabindex="-1"
                          [color]="'primary'">Confirmo haber leído y acepto la política de privacidad y el tratamiento
                de datos personales
            </mat-checkbox>
        </div>
    </div>
</div>
<div mat-dialog-actions class="d-flex align-content-around" tabindex="-1">
    <button type="button" color="primary" mat-raised-button
            [disabled]="!allComplete" (click)="confirm()" tabindex="-1" >
        <mat-icon aria-hidden="false" aria-label="Confirmar">done</mat-icon>
        CONFIRMAR INFORMACIÓN
    </button>
    <button tabindex="-1" type="button" mat-raised-button [mat-dialog-close]="data" (click)="onNoClick()">Corregir Información
    </button>
</div>

import {Component, EventEmitter, Input, Output } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {LoadingService} from '@services/loading.service';
import {Entities, Permission, Property} from '@Interfaces/index';
import {AdfiGrowlService} from '@services/adfi-growl.service';

interface FoodNode {
  children?: FoodNode[];
  expandable?: boolean;
  level?: number;
}
@Component({
  selector: 'app-tree-presupuesto',
  templateUrl: './tree-presupuesto.component.html',
  styleUrls: ['./tree-presupuesto.component.css'],
})
export class TreePresupuestoComponent {

  @Input() externalPermission = true;
  @Input() customFilter: string;
  @Input() decimals = 0;
  /**
   * Creates an instance of prmini presupuesto component.
   */
  constructor(private service: AdfiService,
              public graphqlService: AdfiGraphqlService,
              public sLoading: LoadingService,
              public alert: AdfiGrowlService) {
    this.maxHeight = window.innerHeight - 240;
    this.valorTotal = '';
    this.edit = !this.service.validPermission(Entities.INITIAL_BUDGET, Permission.APPROVE);
  }

  loading = false;
  data = [];
  source: number;
  helps = [];
  /**
   * Input  of tree presupuesto component
   */
  @Input() tipoPresupuesto: 'E' | 'I';

  @Input() sumValue = true;

  @Input() entity = 'prMiniPresupuestos';

  @Input() strQuery = 'nombre prminNFuente { id prfinVNombre } minipresu descompuesto agrupador valor estadoPresupuesto';

  @Input() idGroup = '_id';

  @Input() customSave: (node: any, tree: TreePresupuestoComponent, item: any, field: Property) => void;

  @Input() item: any;

  @Input() field: Property;

  @Input() typeTransfer: 'CC' | 'CR';

  @Input() value: number;

  @Output() changeItem = new EventEmitter<any>();

  /**
   * Max height of prmini presupuesto component
   */
  public readonly maxHeight: number;

  /**
   * Index selected of tab
   */
  public tabIndex: number;

  /**
   * Valor total of tree presupuesto component
   */
  public valorTotal: string;

  public edit: boolean;

  // tslint:disable-next-line: member-ordering
  treeControl = new FlatTreeControl<FoodNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  transformer(node, level: number) {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      level,
      minipresu: node.minipresu,
      nombre: node.nombre,
      valor: node.valor,
      valorIn: node.valor,
      descompuesto: node.descompuesto,
      estadoPresupuesto: node.estadoPresupuesto,
      prminNFuente: node.prminNFuente,
      vigencia: node.vigencia,
      tipoPresu: node.tipoPresu,
      agrupador: node.agrupador,
      modificationId: node.modificationId,
      detailModificationId: node.detailModificationId,
      valueAvailable: node.valueAvailable,
      descriptionHelp: node.descriptionHelp
    };
  }

  public async update() {
    this.data = [];
    this.loadTree();
    const next = (response) => {
      this.data = response;
      this.data = this.data.map(element => ({
        ...element,
        descriptionHelp: this.findHelpDescription(element.minipresu)
      }));
      this.loading = false;
      this.sLoading.hide();
      if (this.data.length > 0) { this.loadTree(); }
    };
    const error = (e) => {
      this.loading = false;
      this.sLoading.hide();
      this.alert.errorMessage(e);
    };
    this.loading = true;
    this.sLoading.show();
    const filters = this.customFilter ? this.customFilter : `tipoPresu: "${this.tipoPresupuesto}" ${this.source ? `, prminNFuente: "${this.source}"` : ''}, vigencia: ${this.value}`;
    const max = await this.graphqlService.countEntity(this.entity, filters);
    this.graphqlService.getEntity(this.entity,
        this.strQuery,
        max,
        filters,
        {next, error}
    );
  }

  selectPrFuente(id: number) {
    this.source = id;
    this.getHelps();
    this.update();
  }

  private loadTree() {
    const source: FoodNode[] = [];

    for (const item of this.data) {
      const agrupador = this.findParent(item.agrupador, source);
      if (!agrupador) { source.push(item); } else {
        if (!agrupador.children) { agrupador.children = [item]; } else { agrupador.children.push(item); }
      }
    }
    this.tabIndex = 0;
    // minipresu: 'I' === this.tipoPresupuesto ? 'Ingresos' : 'Gastos',
    this.dataSource.data = source;
    this.treeControl.expandAll();
    this.sumValor();
  }

  findParent(id: string, source: any[]): FoodNode {
    let agrupador = null;

    for (const s of source) {
      if (s.children) { agrupador = this.findParent(id, s.children); }
      if (agrupador) { return agrupador; } else if (s[this.idGroup] + '' === id + '') { return s; }
    }

    return agrupador;
  }

  save(prMini) {
    if (this.customSave){
      this.customSave(prMini, this, this.item, this.field);
    } else {
      this.sLoading.show();
      const next = () => {
        this.sLoading.hide();
        this.alert.success('Correcto', 'Rubro correctamente guardado');
        this.update();
        this.changeItem.emit(true);
      };
      const error = (e) => {
        this.sLoading.hide();
        this.alert.errorMessage(e);
      };
      this.graphqlService.updateEntity('PrMiniPresupuesto', {
        id: prMini.id,
        valor: prMini.valor,
      }, undefined, {next, error});
    }
  }

  sumValor() {
    let valor = 0;
    for (const data of this.data) {
      if (data.descompuesto === 'S') { 
        continue;
      }
      valor += data.valor;
    }
    // to fixed sum egre automatic adition balance resourse
    if(valor === 0){
      for (const data of this.data) {
          if(valor === 0 && data.valor !== 0 && data.minipresu === '2.005'){
            valor += data.valor;
          }
      }
    }
    this.valorTotal = valor.toFixed(2);
  }

  getHelps() {
    this.service.getHelps(this.value).subscribe(
        (data) => {
            this.helps= data.response;
        },
        (error) => {
            console.warn("Problema al cargar los datos");
        }
    );
}

findHelpDescription(minipresu: string) {
    const matchedItem = this.helps.find(item => item.V_MINIPRESU === minipresu);
    return matchedItem ? matchedItem.DESCRIPTION : '';
}

  hasChild = (_: number, node: FoodNode) => node.expandable;
}

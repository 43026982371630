<div class="spinner-wrapper">
    <div class="container container-loading">
        <div class="row">
            <div class="content">
                <div class="loader-circle">
                    <div class="loader">
                    </div>
                </div>
                <div class="loader-line-mask">
                    <div class="loader-line"></div>
                </div>
                <div class="loader-line-mask-r">
                    <div class="loader-line"></div>
                </div>
                <div class="loader-line-mask-t">
                    <div class="loader-line"></div>
                </div>
            </div>
        </div>
    </div>
</div>

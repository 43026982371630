import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {NavRoute} from '../../utils/menu';
import {AdfiFormComponent} from '@pages/adfi-form/adfi-form.component';
import {Entities} from '@Interfaces/index';


export const RoutesAdfiForm: NavRoute[] = [
  {
    path: '',
    component: AdfiFormComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(RoutesAdfiForm)],
  exports: [RouterModule]
})
export class AdfiFormRoutingModule { }

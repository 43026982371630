<mat-accordion>
    <ng-container *ngFor="let navItem of items">
        <mat-expansion-panel *ngIf="navItem.groupedNavRoutes && navItem.enabled" class="mat-elevation-z0 mat-expansion-panel {{customClass}}">
            <mat-expansion-panel-header>
                <mat-panel-title class="group-title">
                    <mat-icon>{{ navItem.nav.icon }}</mat-icon>
                    <span> {{ navItem.nav.title }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngFor="let item of navItem.groupedNavRoutes">
                <app-menu *ngIf="item.groupedNavRoutes" subMenu="true" [items]="[item]" (clickedItem)="clickedItem.emit()" ></app-menu>
                <a *ngIf="!item.groupedNavRoutes && item.enabled" mat-list-item class="sidenav-item"
                   [routerLink]="[(item['customPath'] ? item['customPath'] : navItem.path) + '/' + item.path]"
                   routerLinkActive="selected"
                   [ngClass]="{'menu-temp' : navItem.nav.icon === 'remove'}"
                   [routerLinkActiveOptions]="{ exact: true }" disableRipple (click)="clickedItem.emit()">
                    <div class="item-selector"></div>
                    <mat-icon>{{ item.nav?.icon }}</mat-icon>
                    <div *ngIf="!item.period" fxFlex="100%"> {{ item.nav?.title }} </div>
                    <div *ngIf="item.period && item.period ==='C'" fxFlex="100%"> {{ item.nav?.title }} {{currentPeriod}} </div>
                    <div *ngIf="item.period && item.period ==='P'" fxFlex="100%"> {{ item.nav?.title }} {{previousPeriod}} </div>
                </a>
            </ng-container>
        </mat-expansion-panel>
        <a *ngIf="!navItem.groupedNavRoutes && navItem.enabled" mat-list-item class="sidenav-item"
           [routerLink]="navItem.enabled ? [navItem.path]: []"
           [ngClass]="{'menu-temp' : navItem.nav.icon === 'remove' }"
           routerLinkActive="selected" disableRipple (click)="clickedItem.emit()">
            <div class="item-selector"></div>
            <mat-icon>{{ navItem.nav?.icon }}</mat-icon>
            <div fxFlex="100%"> {{ navItem.nav?.title }} </div>
        </a>
    </ng-container>
</mat-accordion>

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    Inject,
    Injector,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import {FormComponent} from '@Components/ui/form/form.component';
import {DataEntity, ENUM_ACTIONS, PeriodState, TICK} from '@Interfaces/index';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {ListComponent} from '@Components/ui/list/list.component';
import {LoadingService} from '@services/loading.service';
import {AdfiUtil} from '@Components/util/adfi-util';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {Subscription, timer} from 'rxjs';
import {CurrencyPipe} from '@Components/util/currency.pipe';

@Component({
    selector: 'app-informacion-complementaria',
    templateUrl: './informacion-complementaria.component.html'
})
export class InformacionComplementariaComponent implements OnInit, AfterViewInit {

    accountsBalanceList: any[];

    constructor(private injector: Injector,
                private componentFactoryResolver: ComponentFactoryResolver,
                private loadingService: LoadingService,
                private adfiService: AdfiService,
                private matDialog: MatDialog,
                private graphqlService: AdfiGraphqlService,
                private adfiGrowlService: AdfiGrowlService) {

    }

    @ViewChild('tem_generalCashForm', { read: ViewContainerRef }) vConGeneralCashForm: ViewContainerRef;
    @ViewChild('tem_accountManForm', { read: ViewContainerRef }) vConAccountManForm: ViewContainerRef;
    @ViewChild('tem_declarantConceptForm', { read: ViewContainerRef }) vConDeclarantConceptForm: ViewContainerRef;
    @ViewChild('tem_ieForm', { read: ViewContainerRef }) vconIeForm: ViewContainerRef;
    @ViewChild('tem_employeesForm', { read: ViewContainerRef }) vconEmployeesForm: ViewContainerRef;

    @ViewChild('listCashBox') listCashBox: ListComponent;
    @ViewChild('tabsPc') tabsPc: MatTabGroup;
    @ViewChild('cncList') cncList: ListComponent;
    @ViewChild('csiList') csiList: ListComponent;
    @ViewChild('cetList') cetList: ListComponent;
    @ViewChild('ndeList') ndeList: ListComponent;
    @ViewChild('ncrList') ncrList: ListComponent;

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('yesAccountMan') haveAccountMan: MatSelect;
    @ViewChild('accountSelect') accountSelected: MatSelect;




    generalCashForm: FormComponent;
    declarantConceptForm: FormComponent;
    private ieForm: FormComponent;
    employeesForm: FormComponent;
    bankAccounts: any[];
    totalCashBox = 0;
    countDown: Subscription;
    countStart: Subscription;
    counterEnd = 0;
    counterStart = 1;

    indexStep = 0;

    account;

    accountBalance;
    canEditForm: boolean;
    canUploadFiles: boolean;

    closingForm: any;
    allowWithException = false;

    private returnClosingForm = 'id _id status generalBox accounts accountsReceivable accountsPayable responsibilityIca withholdingSource incomes egress accountantName accountantDni profesionalCounterCard hasNewAccount hasReteIca hasNewIncome hasExpenses certificate';

    ngOnInit() {
        this.loadingService.show();
        this.canEditForm = this.adfiService.getUserPeriod().estado === PeriodState.OPEN;
        this.canUploadFiles = this.canEditForm;
    }

    ngAfterViewInit() {
        this.loadingService.show();
        const next = (d) => {
            if (d) {
                this.counterStart = this.adfiService.getCountDown(d.startDateRegister);
                if (this.counterStart > 0) {
                    this.countStart = timer(0, TICK).subscribe(() => {
                        --this.counterStart;
                        if (this.counterStart <= 0) {
                            this.countStart.unsubscribe();
                            this.endTime(d);
                        }
                    });
                } else {
                    this.endTime(d);
                }
            } else if (!this.canEditForm){
                const valid = (dates) => {
                    if (dates.length) {
                        const param = JSON.parse(dates[0]);
                        this.allowWithException = true;
                        this.counterStart = this.adfiService.getCountDown(param.startDate);
                        if (this.counterStart > 0) {
                            this.countStart = timer(0, TICK).subscribe(() => {
                                --this.counterStart;
                                if (this.counterStart <= 0) {
                                    this.countStart.unsubscribe();
                                    this.endTime(param);
                                }
                            });
                        } else {
                            this.endTime({endDateRegister: param.endDate});
                        }
                    } else  {
                        this.counterStart = 0;
                        this.startLoadForm();
                    }
                };
                const onErr = () => {
                    this.counterStart = 0;
                    this.startLoadForm();
                };
                this.graphqlService.loadAlerts('ExeptionAvalibleDate', 'getExceptionRegisterFor', `"3", "ClosingForm", "${this.adfiService.user.centroCosto._id}", "${new Date().getMonth() + 1}"`, valid, onErr);
            }
            this.loadingService.hide();
        };
        const error = (e) => {
            this.loadingService.hide();
            this.adfiGrowlService.errorMessage(e);
        };
        this.adfiService.getTimeControl('ClosingForm', next, error);
    }

    startLoadForm() {
        this.loadForm();
        this.loadAccounts();
    }

    endTime(period) {
        this.counterEnd = this.adfiService.getCountDown(period.endDateRegister);
        this.countDown = timer(0, TICK).subscribe(() => --this.counterEnd);
        if (this.counterEnd > 0) {
            this.startLoadForm();
        } else {
            this.countDown.unsubscribe();
            this.loadingService.hide();
        }
    }

    getColorTime() {
        const days = (this.counterEnd / 86400);
        if (days < 1) {
            return 'red';
        } else if (days < 2) {
            return 'orange';
        }
        return 'green';
    }

    updatePermission(){
        this.canEditForm = (this.adfiService.getUserPeriod().estado === PeriodState.OPEN || this.allowWithException)
            && this.closingForm.status === 'INI';
        this.canUploadFiles = (this.adfiService.getUserPeriod().estado === PeriodState.OPEN || this.allowWithException)
            && this.closingForm.status === 'CLO';
    }

    loadForm() {
        const next = (data) => {
            if (data) {
                this.closingForm = data;
                this.updatePermission();
                if (data.generalBox) {
                    setTimeout(() => {
                        this.stepper.next();
                    }, 1000);
                }
                if (data.accounts) {
                    setTimeout(() => {
                        this.stepper.next();
                    }, 1000);
                }
                if (data.accountsReceivable) {
                    setTimeout(() => {
                        this.stepper.next();
                    }, 1000);
                }
                if (data.accountsPayable) {
                    setTimeout(() => {
                        this.stepper.next();
                    }, 1000);
                }
                if (data.responsibilityIca != null) {
                    setTimeout(() => {
                        this.stepper.next();
                    }, 1000);
                }
                if (data.withholdingSource) {
                    setTimeout(() => {
                        this.stepper.next();
                    }, 1000);
                }
                if (data.incomes) {
                    setTimeout(() => {
                        this.stepper.next();
                    }, 1000);
                }
                if (data.egress) {
                    setTimeout(() => {
                        this.stepper.next();
                    }, 1000);
                }
                setTimeout(() => {this.loadIeForm(); }, 100);
                setTimeout(() => {this.loadGeneralCash(); }, 500);
                setTimeout(() => {this.loadDeclarantConcept(); }, 500);
                setTimeout(() => {this.loadEmployeesForm(); }, 500);
            } else if ((PeriodState.OPEN === this.adfiService.getUserPeriod().estado || this.allowWithException)){
                this.graphqlService.createEntity('ClosingForm', {status: 'INI'}, this.returnClosingForm, { next, error });
            } else {
                this.closingForm = null;
            }
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.getFirstEntity('closingForms',
            this.returnClosingForm,
            'cencosFilter_centroCosto: ""', {next, error});
    }

    loadGeneralCash() {
        const next = (val) => {
            const dataForm = {
                entityName: 'GeneralBox',
                module: 'ClosingForm',
                customActionBtnText: 'Continuar',
                customTitle: 'Caja general',
                displayBtnCancel: false,
                displayBtnSave: false,
                disableEdit: !this.canEditForm
            } as DataEntity;
            if (val) {
                dataForm.action = ENUM_ACTIONS.EDIT;
                dataForm.item = val.id;
            } else {
                dataForm.action = ENUM_ACTIONS.CREATE;
            }
            this.graphqlService.loadAlerts('Tesoreria\\\\PaymentTreasuryOrder', 'getAlerts',
                `"${this.adfiService.user.centroCosto._id}"`, (cash) => {
                dataForm.customTitle = `Caja General. Saldo actual (${new CurrencyPipe().transform(cash[0])})`;
                this.generalCashForm = this.createForm(this.vConGeneralCashForm, dataForm);
            });
        };
        const error = (val) => {
            this.adfiGrowlService.errorMessage(val, 'Caja General');
        };
        this.graphqlService.getFirstEntity('generalBoxes', 'id', `cencosFilter_center: ""`, {next, error});
    }

    loadDeclarantConcept() {
        const next = (val) => {
            const dataForm = {
                entityName: 'DeclarantByConcept',
                module: 'ClosingForm',
                customActionBtnText: 'Continuar',
                customTitle: 'Conceptos declarados',
                displayBtnCancel: false,
                displayBtnSave: false,
                disableEdit: !this.canEditForm
            } as DataEntity;
            if (val) {
                dataForm.action = ENUM_ACTIONS.EDIT;
                dataForm.item = val.id;
            } else {
                dataForm.action = ENUM_ACTIONS.CREATE;
            }
            this.declarantConceptForm = this.createForm(this.vConDeclarantConceptForm, dataForm);
            this.declarantConceptForm.successInit.subscribe(() => {
                if (this.canEditForm){
                    this.declarantConceptForm.disableEditInForm = !this.closingForm.responsibilityIca;
                }
            });
        };
        const error = (val) => {
            this.adfiGrowlService.errorMessage(val, 'Declarante de Conceptos');
        };
        this.graphqlService.getFirstEntity('declarantByConcepts', 'id', `cencosFilter_idCc: ""`, {next, error});
    }

    loadIeForm() {
        const dataForm = {
            entityName: 'ClosingForm',
            module: 'ClosingForm',
            group: 'ieData',
            customActionBtnText: 'Guardar',
            customTitle: 'Datos Institucionales',
            displayBtnCancel: false,
            disableEdit: !this.canEditForm,
            action: ENUM_ACTIONS.EDIT,
            item : this.closingForm.id
        } as DataEntity;
        this.ieForm = this.createForm(this.vconIeForm, dataForm);
        this.ieForm.postSave.subscribe( () => {
            this.stepper.selectedIndex = 0;
        });
    }

    loadEmployeesForm() {
        const dataForm = {
            entityName: 'ClosingForm',
            module: 'ClosingForm',
            group: 'signature',
            customActionBtnText: 'Guardar',
            customTitle: 'Observaciones e Información de contacto',
            displayBtnCancel: false,
            displayBtnSave: false,
            disableEdit: !this.canEditForm,
            action: ENUM_ACTIONS.EDIT,
            item : this.closingForm.id
        } as DataEntity;
        this.employeesForm = this.createForm(this.vconEmployeesForm, dataForm);
    }

    saveStep(param: any, postSave: () => void = null) {
        this.loadingService.show();
        const next = (data) => {
            this.closingForm = data;
            setTimeout(() => {
                this.loadingService.hide();
                this.stepper.next();
            }, 1000);
            this.updatePermission();
            if (postSave) {
                postSave();
            }
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.mutationEntity('ClosingForm', {id: this.closingForm.id, ...param},
            this.returnClosingForm, ENUM_ACTIONS.EDIT, { next, error });
    }

     async loadAccounts() {
        this.loadingService.show();
        const next = (data) => {
            this.bankAccounts = data;
            this.loadAllAccountsBalance();
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        const filter = 'cencosFilter_centroCosto: "", vMaint_not: {exact:"D"}';
        const all = await this.graphqlService.countEntity('cuentaBancos', filter);
        this.graphqlService.getEntity('cuentaBancos', 'id,_id,nombre,numero,tipo', all, filter, {next, error});
    }

    selectAllConciliation(account: any) {
        if (account) {
            this.account = account;
            this.loadingService.show();
            this.loadAccountBalance(account);
        }
    }

    async loadAllAccountsBalance() {
        const next = (val) => {
            this.accountsBalanceList = val;
            this.bankAccounts.forEach(value => {
                this.accountsBalanceList.forEach(value1 => {
                    if (value.id === value1.account.id) {
                        value.state = 'Y';
                    }
                });
            });
        };
        const error = (val) => {
            this.adfiGrowlService.errorMessage(val);
        };
        const count = await this.graphqlService.countEntity('accountBalances', 'cencosFilter_cencos: ""');
        this.graphqlService.getEntity('accountBalances', 'id value account{ id }', count, 'cencosFilter_cencos: ""', {next, error});
    }

     loadAccountBalance(account) {
        const next = (val) => {
            this.loadingService.hide();
            const type = account.tipo ? account.tipo === 'CC' ? 'Cuenta Corriente' : 'Cuenta de Ahorros' : '';
            if (val) {
                this.loadingService.hide();
                this.accountBalance = val;
                this.changeAccountConciliation();
            } else if (this.canEditForm){
                const dataForm = {
                    entityName: 'AccountBalance',
                    module: 'ClosingForm',
                    customActionBtnText: 'Continuar',
                    customTitle: `Cuenta:  ${account ? account.nombre : ''} ${type.toUpperCase()}`,
                    customData: {
                        account: account.id
                    },
                    group: 'EFE',
                    action: ENUM_ACTIONS.CREATE
                } as DataEntity;
                const dialog = this.openDialogForm(dataForm);
                dialog.afterClosed().subscribe(value => {
                    if (value) {
                        account.state = 'Y';
                        this.loadAccountBalance(account);
                    } else {
                        this.accountSelected.value = undefined;
                    }
                });
            }
        };
        const error = (val) => {
            this.loadingService.hide();
            this.adfiGrowlService.errorMessage(val);
        };
        this.loadingService.show();
        this.graphqlService.getFirstEntity('accountBalances', 'value extractFile tipo banco {nombre}',
            `account_id: ${account._id}`, {next, error});
    }

    changeAccountConciliation() {
        this.tabsPc.selectedIndex = 0;
        setTimeout(() => {
            if (this.cncList) {
                this.cncList.reload();
            } else {
                this.cncList.loadedConfig.subscribe(() => {
                    this.cncList.reload();
                });
            }
        } , 3000);
    }

    editExtract() {
        const type = this.account.tipo ? this.account.tipo === 'CC' ? 'Cuenta Corriente' : 'Cuenta de Ahorros' : '';
        const dataForm = {
            entityName: 'AccountBalance',
            module: 'ClosingForm',
            customTitle: `Cuenta:  ${this.account ? this.account.nombre : ''} ${type.toUpperCase()}`,
            item: this.accountBalance.id,
            action: ENUM_ACTIONS.EDIT,
            group: 'EFE'
        } as DataEntity;
        const dialog = this.openDialogForm(dataForm);
        dialog.afterClosed().subscribe(value => {
            if (value) {
                this.loadAccountBalance(this.account);
            }
        });
    }

    postLoadCashBox() {
        const next = (d) => {
            this.totalCashBox = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'ClosingForm\\CashBox',
            filters: JSON.stringify(this.listCashBox.getAllFilters())
        }, 'total', {next, error});
    }

     openDialogForm(dataDialog, minWidth = '35%') {
        return this.matDialog.open(FormComponent, {data: dataDialog, minWidth, maxHeight: '90vh'});
    }

     createForm(form: ViewContainerRef, dataDialog: DataEntity): FormComponent {
        form.clear();
        const inj: Injector = this.makeCustomInjector(dataDialog);
        const resolver = this.componentFactoryResolver.resolveComponentFactory(FormComponent);
        return form.createComponent(resolver, 0, inj).instance;
    }

     makeCustomInjector(dataDialog) {
        return Injector.create({
            providers: [{
                provide: MatDialogRef, useValue: {}
            }, {
                provide: MAT_DIALOG_DATA, useValue: dataDialog
            }],
            parent: this.injector
        });
    }

     saveGeneralBox() {
        this.generalCashForm.save(this.generalCashForm.form);
        this.generalCashForm.postSave.subscribe((val) => {
            this.saveStep({generalBox: true});
            this.loadGeneralCash();
        });
    }

     createItemConciliation() {
         const dialogRef = this.matDialog.open(TipoPartidaDialogComponent, {
             width: '60%',
             data: {type: {}}
         });

         dialogRef.afterClosed().subscribe(result => {
             if (result) {
                 const dataForm = {
                     entityName: 'ClosingConciliation',
                     module: 'ClosingForm',
                     customTitle: `Añadir:  ${result.name}`,
                     subtitleHTML: `<p>${result.text}</p>`,
                     customData: {
                         type: result.group,
                         account: this.account.id
                     },
                     action: ENUM_ACTIONS.CREATE,
                     group: result.group
                 } as DataEntity;
                 const dialogAdd = this.openDialogForm(dataForm);
                 dialogAdd.afterClosed().subscribe(value => {
                     if (value) {
                         switch (result.group) {
                             case 'CNC':
                                 this.changeTypeConciliation(0, this.cncList);
                                 break;
                             case 'CSI':
                                 this.changeTypeConciliation(1, this.csiList);
                                 break;
                             case 'CET':
                                 this.changeTypeConciliation(2, this.cetList);
                                 break;
                             case 'DEB':
                                 this.changeTypeConciliation(3, this.ndeList);
                                 break;
                             case 'CRE':
                                 this.changeTypeConciliation(4, this.ncrList);
                                 break;
                         }
                     }
                 });
                 }
         });
     }

     changeTypeConciliation(index: number, list: ListComponent) {
        if (index === this.tabsPc.selectedIndex) {
            list.reload();
        } else {
            this.tabsPc.selectedIndex = index;
        }
     }

    generateReport() {
        this.adfiService.download(
            'ClosingForm\\ClosingForm',
            JSON.stringify({}),
            'fileName',
            'application/pdf',
            'INFO-COMPLEMENTARIA-CIERRE-VIGENCIA-2020.pdf');
    }

    editSignature() {
        if (this.employeesForm.form.valid){
            this.employeesForm.save(this.employeesForm.form);
            const subscription = this.employeesForm.postSave.subscribe(() => {
                subscription.unsubscribe();
                this.generateReport();
            }, (e) => {
                subscription.unsubscribe();
                this.adfiGrowlService.errorMessage(e);
            });
        } else {
            this.adfiGrowlService.warning('Advertencia', 'Debe completar los datos de contacto');
        }
    }

    editReasonPaymentIncomplete(row: any, pO: ListComponent) {
        const dialogAdd = this.openDialogForm({
            entityName: 'PaymentOrder',
            module: 'Egresos',
            customTitle: 'Diligenciar razón',
            item: row.id,
            action: ENUM_ACTIONS.EDIT,
            group: 'not_paid_edit'
        } as DataEntity);
        dialogAdd.afterClosed().subscribe(value => {
            if (value) {
                pO.reload();
            }
        });
    }

    updatePaymentIncomplete(checked: boolean, row: any, pO: ListComponent) {
        this.loadingService.show();
        const data = { id: row.id, isAccountPayable: checked};
        const next = (d) => {
            if (d) {
                row.isAccountPayable = d.isAccountPayable;
                if (!row.reasonForNonPayment) {
                    this.editReasonPaymentIncomplete(row, pO);
                }
            }
            this.loadingService.hide();
        };
        const error = (e) => {
            this.loadingService.hide();
            this.adfiGrowlService.errorMessage(e, 'No se logro la actualización');
        };
        this.graphqlService.updateEntity(AdfiUtil.capitalizeFirstLetter(pO.entityName), data, 'isAccountPayable', {next, error});
    }

    selectAccountMan(event: any) {
        if (event && !this.closingForm.accountantName) {
            const dialogAdd = this.openDialogForm({
                entityName: 'ClosingForm',
                module: 'ClosingForm',
                customTitle: 'Contador Público',
                item: this.closingForm.id,
                action: ENUM_ACTIONS.EDIT,
                group: 'accountman'
            } as DataEntity);
            dialogAdd.afterClosed().subscribe(value => {
                if (value) {
                    this.loadForm();
                }
            });
        } else if (this.closingForm.accountantName) {
            this.loadingService.show();
            const next = (data) => {
                this.loadingService.hide();
                this.loadForm();
            };
            const error = (e) => {
                this.loadingService.hide();
                this.adfiGrowlService.errorMessage(e);
            };
            this.graphqlService.mutationEntity('ClosingForm',
                {id: this.closingForm.id, accountantName: null, accountantDni: null, profesionalCounterCard: null},
                'id', ENUM_ACTIONS.EDIT, { next, error });
        }
    }

    closForm() {
        if (this.ieForm.form.valid && this.employeesForm.form.valid) {
            this.employeesForm.save(this.employeesForm.form);
            const subscription = this.employeesForm.postSave.subscribe(() => {
                subscription.unsubscribe();
                const dialog = this.matDialog.open(ConfirmDialogComponent, {data: {
                        title: 'Finalizar diligenciamiento',
                        content: `<p>¿Está seguro/a de finalizar el diligenciamiento?</p><p>Tenga en cuenta que una vez finalizado el diligenciamiento <span class="alert-color"><strong>no podrá modificar la información registrada</strong></span>, por lo que se recomienda validar los datos suministrados mediante el documento en borrador generado por el boton <strong>Generar Archivo Borrador</strong></p>`,
                        action: ENUM_ACTIONS.DELETE
                    } as ConfirmDialogData });
                dialog.beforeClosed().subscribe((ok) => {
                    if (ok) {
                        this.loadingService.show();
                        this.saveStep({status: 'CLO'}, () => {
                            this.loadIeForm();
                            this.loadDeclarantConcept();
                            this.loadEmployeesForm();
                        });
                    }
                });
            }, (e) => {
                subscription.unsubscribe();
                this.adfiGrowlService.errorMessage(e);
            });
        } else {
            this.adfiGrowlService.warning('Advertencia', 'Debe completar los datos de la institución educativa y observaciones e información de contacto');
        }
    }

    uploadFile() {
        const dialogAdd = this.openDialogForm({
            entityName: 'ClosingForm',
            module: 'ClosingForm',
            customTitle: 'Subir Archivo Firmado',
            item: this.closingForm.id,
            action: ENUM_ACTIONS.EDIT,
            customData: {
                status: 'UPL'
            },
            group: 'upload_file'
        } as DataEntity);
        dialogAdd.afterClosed().subscribe(value => {
            if (value) {
                this.loadForm();
            }
        });
    }

    downloadExtract() {
        AdfiUtil.downloadMediaObject(this.accountBalance.extractFile ,
            { fieldName: 'extractFile', options: {bucket: 'files_end_period'}}, this.adfiService.user.period);
    }

    downloadFile() {
        AdfiUtil.downloadMediaObject(this.closingForm.certificate ,
            { fieldName: 'certificate', options: {bucket: 'files_end_period'}}, this.adfiService.user.period);
    }

    validStep(action, params) {
        this.loadingService.show();
        const next = (d) => {
            this.saveStep(params);
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
            this.loadingService.hide();
        };
        this.graphqlService.createEntity('Action', {nameClass: action}, 'message', {next, error});
    }

    saveDeclIca(value) {
        const next = () => {
            this.saveStep({responsibilityIca: value});
            this.loadDeclarantConcept();
        };
        if (this.declarantConceptForm.form.valid && value === true){
            this.declarantConceptForm.save(this.declarantConceptForm.form);
            this.declarantConceptForm.postSave.subscribe(next, (e) => this.adfiGrowlService.errorMessage(e));
        } else {
            next();
        }
    }

    editFileExtract() {
        const dialogAdd = this.openDialogForm({
            entityName: 'AccountBalance',
            module: 'ClosingForm',
            customTitle: 'Editar Archivo Extracto',
            item: this.accountBalance.id,
            action: ENUM_ACTIONS.EDIT,
            group: 'AEXT'
        } as DataEntity);
        dialogAdd.afterClosed().subscribe(value => {
            if (value) {
                this.loadAccountBalance(this.account);
            }
        });
    }
}

@Component({
    selector: 'app-tipo-partida-dialog',
    templateUrl: 'tipo-partida-dialog.component.html',
})
export class TipoPartidaDialogComponent {
    types = [{group: 'CNC', name: 'CHEQUE GIRADO Y NO COBRADO', text: '<p class="info-form"> Corresponden a pagos realizados a proveedores de los que se registró el comprobante de egreso, pero que la transacción en el extracto bancario no se efectúo en la vigencia.</p>'},
        {group: 'CSI', name: 'CONSIGNACIÓN SIN IDENTIFICAR', text: '<p class="info-form"> Son recursos que aparecen como abonos en el extracto bancario, pero que en los comprobantes de ingreso de la plataforma ADFI de la Institución no se cuenta con algún registro.</p>'},
        {group: 'CET', name: 'CONSIGNACIÓN EN TRÁNSITO', text: '<p class="info-form"> Consignaciones en tránsito, corresponden a abonos a favor de la Institución que si bien cuentan con un comprobante de ingreso en la plataforma ADFI en el extracto bancario no están relacionados.</p>'},
        {group: 'DEB', name: 'NOTA DÉBITO', text: '<p class="info-form"> Notas débito, por dineros que el banco descuenta en el extracto bancario, pero que el Fondo de Servicios Educativos no ha registrado. Un ejemplo de esta partida conciliatoria son los gastos financieros cobrados por el banco al cierre de la vigencia, pero que por algún motivo la Institución no registró en la plataforma ADFI.</p>'},
        {group: 'CRE', name: 'NOTA CRÉDITO', text: '<p class="info-form"> Notas crédito, por dineros que el banco abona en el extracto bancario, pero que el Fondo de Servicios Educativos no ha registrado. Un ejemplo de esta partida conciliatoria son los rendimientos financieros que la institución recibe al cierre de la vigencia, pero que por algún motivo no registró en la plataforma ADFI.</p>'}];

    constructor(
        public dialogRef: MatDialogRef<TipoPartidaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

}

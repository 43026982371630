import { AdfiUtil } from '@Components/util/adfi-util';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AdfiGraphqlService } from '@services/adfi-graphql.service';
import { AdfiGrowlService } from '@services/adfi-growl.service';
import { LoadingService } from '@services/loading.service';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { AcceptValidator, MaxSizeValidator, transformBytes } from '@Components/ui/util/Utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { AdfiService } from '@services/adfi-service';
import { AdfiRest } from '@services/adfi-rest';

@Component({
  selector: 'app-form-assets',
  templateUrl: './form-assets.component.html',
  styleUrls: ['./form-assets.component.scss']
})
export class FormAssetsComponent implements OnInit {


  formAssetsOP: FormGroup;

  displayCol: string[] = ['descripcion', 'valorCompra', 'cuenta', 'quantityAssets', 'trademarkRef', 'nroSerial']


  data = new MatTableDataSource<any>();

  iva: any;
  ica: any;
  reteFuente: any;
  listIva: any[];
  listIca: any[];
  listReteFuente: any[];
  listAccounts: any[];
  listTrademarks: any[];

  registerInfo: {} = {}

  opcionSeleccionada: string;
  id: any = null;
  idOP: any = null;
  assetsOP: any = {};
  base64Files: Map<string, any>;
  md5Files: Map<string, any>;

  todayDate = new Date();
  startValidDate: Date;
  endValidDate: Date;
  loadingListValues = new Map<string, boolean>();

  validationTypePDF = "application/pdf";

  ctrl: FormControl = new FormControl();

  dataSource = new MatTableDataSource<any>();

  isLoading = false;
  isInit = true;

  initWithholdings: [string, boolean][] = [
    ['I', true],
    ['C', true],
    ['N', true]
  ];

  constructor(
    private formBuilder: FormBuilder,
    private graphqlService: AdfiGraphqlService,
    private apollo: Apollo,
    public sLoading: LoadingService,
    private adfiGrowlService: AdfiGrowlService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private adfiService: AdfiService

  ) {



    this.aRoute.queryParams.subscribe(params => {
      if (router.getCurrentNavigation().extras.state !== undefined) {
        this.registerInfo = this.router.getCurrentNavigation().extras.state.data;
        if (Object.keys(this.registerInfo).length > 0) {
          this.isLoading = true
        }
      }
    });
    this.formAssetsOP = this.formBuilder.group({
      fecha: ['', Validators.required],
      billDate: ['', [Validators.required]],
      billAttachment: ['', [Validators.required, AcceptValidator(this.validationTypePDF)]],
      objeto: ['', [Validators.required, Validators.maxLength(240)]],
      base: ['', Validators.required],
      iva: ['', Validators.required],
      reteFuente: ['', Validators.required],
      reteIva: ['', Validators.required],
      reteIca: ['', Validators.required],
      billNumber: ['', [Validators.required, Validators.maxLength(100)]],
      quantity: ['', Validators.required],
      fechaFactura: ['', Validators.required],
      formAssets: new FormArray([], [Validators.required])
    });

  }



  async ngOnInit(): Promise<void> {
    this.id = this.aRoute.snapshot.paramMap.get('id');
    if (Object.keys(this.registerInfo).length == 0) {
      await this.getRegister(this.id)
    }
    this.loadDates();
    this.loadingListValues = new Map<string, boolean>(this.initWithholdings);
    await this.getAccount();
    await this.getDiscounts("I")
    await this.getDiscounts("C")
    await this.getDiscounts("N")
  }

  initiateFormAssets(): FormGroup {
    return this.formBuilder.group({
      descripcion: ['', Validators.required],
      valorCompra: ['', Validators.required],
      cuenta: ['', Validators.required],
      quantityAssets: ['', Validators.required],
      trademarkRef: ['', Validators.required],
      nroSerial: ['']
    });
  }

  addRow() {
    const control = this.formAssetsOP.get('formAssets') as FormArray;
    control.push(this.initiateFormAssets());
    this.data = new MatTableDataSource(control.controls)
  }

  get getFormControls() {
    const control = this.formAssetsOP.get('formAssets') as FormArray;
    return control
  }

  async getDiscounts(type: string) {
    this.graphqlService.getDiscounts(type)
      .pipe(
        map((p) => p.data['discounts'].edges.map((obj) => obj.node))
      ).subscribe(
        (data) => {
          if (type === "I") {
            this.loadingListValues.set('I', false);
            this.listIva = data
          } else if (type === "C") {
            this.loadingListValues.set('C', false);
            this.listIca = data
          } else {
            this.loadingListValues.set('N', false);
            this.listReteFuente = data
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  EditSVO(AssetFormElement, i) {
    AssetFormElement.get('VORows').at(i).get('isEditable').patchValue(false);

  }

  SaveVO(AssetFormElement, i) {
    AssetFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
  }

  CancelSVO(AssetFormElement, i) {
    AssetFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
  }

  async onSubmit() {
    if (this.checkSumAssets() === true) {
      if (this.formAssetsOP.valid) {
        await this.addAssetsOP()
      }
      if (this.id === null) {
        this.addAssetsOP();
      } else {
        this.editAssetsOP(this.id);
      }
    } else {
      this.adfiGrowlService.error('Valores', 'Los valores de compra de los activos no coinciden con la información de la orden de pago o el registro ya no cuenta con saldo');
    }

  }

  async addAssetsOP() {
    const files = [this.base64Files.get('base64File')];
    const filesM5 = JSON.stringify([this.md5Files.get('archivo')]);
    const assetsOP: any = {
      fecha: AdfiUtil.getUniversalDateString(this.formAssetsOP.value.fecha),
      billDate: AdfiUtil.getUniversalDateString(this.formAssetsOP.value.billDate),
      objeto: this.formAssetsOP.value.objeto,
      base: this.removeNonNumeric(this.formAssetsOP.value.base),
      iva: this.removeNonNumeric(this.formAssetsOP.value.iva),
      reteFuente: this.formAssetsOP.value.reteFuente._id,
      reteIva: this.formAssetsOP.value.reteIva._id,
      reteIca: this.formAssetsOP.value.reteIca._id,
      billNumber: this.formAssetsOP.value.billNumber,
      register: this.id,
      isAssets: 1,
      archivo: filesM5,
      billAttachment: files
    }
    const assets = this.formAssetsOP.get('formAssets').value.map(value => {
      return {
        ...value, valorCompra: this.removeNonNumeric(value.valorCompra)
      }
    })
    assetsOP['formAssets'] = assets
    assetsOP['fechaFactura'] = AdfiUtil.getUniversalDateString(this.formAssetsOP.value.fechaFactura)
    assetsOP['nit'] = this.registerInfo['third'].nit
    console.log(assetsOP);

    this.saveAssetsOP(assetsOP)
  }


  async saveAssetsOP(dataOP: object) {
    this.sLoading.show();
    this.adfiService.post(AdfiRest.addAssetsArray(), dataOP)
      .subscribe((ok) => {
        if (ok) {
          this.sLoading.hide();
          this.adfiGrowlService.success('GUARDADO', 'Orden de pago creada');
          this.adfiGrowlService.success('GUARDADO', 'Activos contabilizados correctamente');
          this.router.navigate(['/nav/list/ZXNGODl4cUsrZHNxTlFBR1Rkc05lZz09/ZHMxKzl3R00ydHcwTlE9PQ%3D%3D'])
        } else {
          this.sLoading.hide();
          this.adfiGrowlService.error('Activos', 'Error al contabilizar activos');
        }
      },
        error => {
          this.sLoading.hide();
          this.adfiGrowlService.error('Error', error.error);
        }
      );
  }

  editAssetsOP(id: string) {

  }

  async isEdit() {
    if (this.id !== null) {
      console.log("es editar")
      const data = { "id": this.id }
      // await this.service.getMaterial(data).then((res) => {
      //   this.material = res
      //   console.log(this.material)
      //  }).catch(error => {
      //    console.log(error);
      // })
    }
  }

  changeFile($event: any) {
    if ($event) {
      const file = $event;
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        this.fireBase64Files("base64File", reader.result);
      };
      AdfiUtil.computeChecksumMd5Hash(file).then(
        (md5) => {
          this.fireMd5Files("archivo", md5)
        }
      );
      //   }
      // } else {
      //   this.removeBase64Files(field.options.base64Field);
      //   this.removeMd5Files(field.fieldName);
      // }
    } else {
      this.removeBase64Files("base64File");
      this.removeMd5Files("archivo");
    }
  }

  fireBase64Files(name: string, base64: string | ArrayBuffer) {
    if (!this.base64Files) {
      this.base64Files = new Map<string, any>();
    }
    this.base64Files.set(name, base64);
  }

  fireMd5Files(name: string, checkSum) {
    if (!this.md5Files) {
      this.md5Files = new Map<string, any>();
    }
    this.md5Files.set(name, checkSum);
  }

  removeBase64Files(name: string) {
    if (this.base64Files && this.base64Files.delete(name)) {
      this.base64Files.delete(name);
    }
  }

  removeMd5Files(name: string) {
    if (this.md5Files && this.md5Files.has(name)) {
      this.md5Files.delete(name);
    }
  }

  async getAccount() {
    const max = await this.graphqlService.countEntity("ppeAccounts", ``)
    const next = (data) => {
      this.listAccounts = data
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e, 'Error al cargar las cuentas');
    };
    const aux = this.graphqlService.getEntity("ppeAccounts", "nombre id", max, ``, { next, error });
  }

  async getTradeMark(accountId: number, i: number) {
    const max = await this.graphqlService.countEntity("trademarks", `accounts_id:${accountId}`)
    const next = (data) => {
      this.listTrademarks[i] = data
      this.listTrademarks[i].push({ trademarkName: "NO TIENE MARCA" })
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e, 'Error al cargar las marcas');
    };
    this.listTrademarks[i] = 0;
    const aux = this.graphqlService.getEntity("trademarks", "trademarkName id", max, `accounts_id:${accountId}`, { next, error });
  }

  get account() {
    return this.getFormControls
  }

  onStatusChange(event: any, index: number) {
    this.data.data[index].controls.trademarkRef.setValue(null)
    const excludeSerial = [167002, 165522, 167001, 165520, 165505, 165504, 165511]
    if (event._id != null) {
      const control = this.data.data[index].controls.quantityAssets
      const controlSerial = this.data.data[index].controls.nroSerial
      this.getTradeMark(event._id, index)
      if (excludeSerial.includes(event._id)) {
        controlSerial.setValidators([Validators.required]);
        controlSerial.updateValueAndValidity();
      } else {
        controlSerial.clearValidators(); 
        controlSerial.updateValueAndValidity();
      }
      if (event._id != 166501) {
        control.disable()
        control.setValue(1)
      } else {
        control.enable()
      }
    }
  }

  changeQuantity(event: any) {
    const aux = this.formAssetsOP.get('quantity').value

    if (this.isInit === true) {
      this.createArraysTrademarks(aux)
      for (let index = 0; index < aux; index++) {
        this.addRow();
      }
      this.isInit = false

    } else {
      if (this.data.data.length <= aux) {
        this.addRows(aux - this.data.data.length);
      } else {
        //poner dialogo que se va a resetear la info
        const control = this.formAssetsOP.get('formAssets') as FormArray;
        control.clear();
        this.data.data = []
        this.createArraysTrademarks(aux)
        this.addRows(aux)

      }
    }
  }

  createArraysTrademarks(i: number) {
    this.listTrademarks = new Array(i).fill(1);
  }

  addRows(quantity: number) {
    for (let index = 0; index < quantity; index++) {
      this.addRow();
    }
  }

  resetTable() {
    const control = this.formAssetsOP.get('formAssets') as FormArray;
    control.reset();
  }

  async getRegister(registerId: number) {
    const next = (data) => {
      this.registerInfo = data
      this.isLoading = true;

    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e, 'Error al cargar los registros');
    };
    const aux = this.graphqlService.getOneEntity("registro", "numAvailability consecutive source{prfinVNombre} item{minipresu nombre} objet third{nit names} date value balance ", `/api/registros/${registerId}`, ``, { next, error });
  }

  removeNonNumeric(inputString: string) {
    return inputString.replace(/[^0-9]/g, "");
  }

  checkSumAssets() {
    const aux = this.registerInfo['balance']
    const actualValues = this.data.data.reduce((a, b) => a + Number(this.removeNonNumeric(b.controls.valorCompra.value)), 0)
    const valueOP = Number(this.removeNonNumeric(this.formAssetsOP.get('base').value)) + Number(this.removeNonNumeric(this.formAssetsOP.get('iva').value));
    if (actualValues <= aux && actualValues === valueOP) {
      return true
    } else {
      return false
    }
  }

  loadDates() {
    const next = (v: any) => {
      this.startValidDate = v.fechaApertura;
      this.endValidDate = v.fechaCierre;
      if (this.startValidDate.getMonth() === 0 && this.startValidDate.getDate() === 1) {
        this.startValidDate.setDate(this.startValidDate.getDate() + 1);
      }
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e, 'Error al cargar la vigencia del usuario');
    };
    this.adfiService.getPeriodByUser({ next, error });
  }

  preventSubmit(event: KeyboardEvent): void {
    event.preventDefault();
  }

}

import {Action, DataEntity, ENUM_ACTIONS, Middleware, Options, TypeAction} from '@Interfaces/index';
import {MonthPickerComponent} from '@Components/month-selector/month-picker/month-picker.component';
import {DateRangePickerComponent} from '@Components/date-range-selection/date-range-picker/date-range-picker.component';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {MatDialog} from '@angular/material/dialog';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {MatCalendarHeader} from '@angular/material/datepicker';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {UIHelper} from '@Components/util/UIHelper';
import {ListComponent} from '@Components/ui/list/list.component';
import {DisplayItemComponent} from '@Components/ui/display-item/display-item.component';
import {SelectItemComponent} from '@Components/ui/select-item/select-item.component';
import {AdfiService} from '@services/adfi-service';

export class UIActionsHandler {

    public static handleFilesActions(service: AdfiService, item: any, report: string, filters: string, nameFile: any, action: Action, group: string) {
        const types = {
            pdf: 'application/pdf',
            xlsx: 'application/xlsx',
            txt: 'text/plain'
        };
        const type = action.properties && action.properties[Options.FILE_TYPE] ? action.properties[Options.FILE_TYPE] : 'pdf';
        const customReturn = action.properties && action.properties[Options.CUSTOM_RETURN] ? action.properties[Options.CUSTOM_RETURN] : 'fileName';
        const reportAction = action.properties && action.properties[Options.FILE_ACTION] ? action.properties[Options.FILE_ACTION] : 'printItem';
        report = action.properties && action.properties[Options.FILE_ENTITY] ? action.properties[Options.FILE_ENTITY] : report;
        nameFile = action.properties && action.properties[Options.FILE_NAME] ? action.properties[Options.FILE_NAME] : nameFile;
        if (item) {
            nameFile = nameFile.formatUnicorn(item);
        }
        service.download(report, filters, customReturn, types[type], nameFile.replace(/\s/g, '_')  + `.${type}`,  reportAction, null, group);
    }

    public static handleCustomActions(item: Action, monthComponent: MonthPickerComponent, rangePicker: DateRangePickerComponent,
                                      adfiGrowlService: AdfiGrowlService, dialog: MatDialog, load: LoadingService, dataEntity: DataEntity,
                                      graphqlService: AdfiGraphqlService, filters: any, list?: any, row?, router?: Router,
                                      adfiService?: AdfiService) {
        const handleList = (pDataEntity: DataEntity, initialData) => {
            pDataEntity.action = ENUM_ACTIONS.LIST;
            const d = UIHelper.openDialog(ListComponent, dialog, {}, ENUM_ACTIONS.LIST);
            d.componentInstance.entityName = pDataEntity.entityName;
            d.componentInstance.module = pDataEntity.module;
            d.componentInstance.group = pDataEntity.group;
            d.componentInstance.dataEntity = pDataEntity;
            if (pDataEntity.rows) {
                d.componentInstance.withPaginator = false;
            }
            d.componentInstance.initialData = initialData;
        };
        const next = async (r) => {
            const message = JSON.parse(r.message);
            const actions = JSON.parse(r.actions);
            if (typeof actions === 'string') {
                adfiGrowlService.info(actions, message);
                if (typeof list.reload === 'function') {
                    list.reload();
                }
                load.hide();
                return;
            }
            if (actions.entity && actions.entity.updateUser) {
                await adfiService?.loadUser();
            }
            if (actions.action === TypeAction.REDIRECT) {
                router.navigate(actions.entity.url, actions.entity.extras);
                load.hide();
                return;
            } else if (actions.action === TypeAction.GENERATE_FILE) {
                this.handleFilesActions(adfiService, null, '', actions.filters, '', actions, actions.group);
                return;
            }
            const entity = {...actions.entity} as DataEntity;
            if (actions.initialData && Array.isArray(actions.initialData)) {
                entity.initialData = {};
                for (const dat of actions.initialData) {
                    let value = dat.predefinedValue ? dat.predefinedValue : row[dat.from];
                    switch (dat.cast) {
                        case 'number':
                            value = parseFloat(value);
                            break;
                    }
                    entity.initialData[dat.as] = value;
                }
            }
            if (item.type === TypeAction.BACKEND) {
                if (!message.length) {
                    adfiGrowlService.warning('Información', 'Sin datos que mostrar');
                } else {
                    handleList({rows: message, ...actions.entity}, actions.initialData);
                }
            } else if (item.type === TypeAction.UI) {
                switch (actions.action) {
                    case 'add':
                        UIHelper.createItemEntity(dialog, entity).afterClosed().subscribe(data => {
                            if (data) {
                                if (list) {
                                    list.reload();
                                }
                            } else if (typeof list.reload === 'function') {
                                list.reload();
                            }
                        });
                        break;
                    case 'list':
                        handleList(entity, actions.initialData);
                        break;
                    case 'edit':
                        UIHelper.editItemEntity(dialog, entity.customData.id, entity).afterClosed().subscribe(data => {
                            if (data) {
                                if (list) {
                                    list.reload();
                                }
                            } else if (typeof list.reload === 'function') {
                                list.reload();
                            }
                        });
                        break;
                    case 'view':
                        UIHelper.openDialog(DisplayItemComponent, dialog, {
                            entityName: actions.entity.entityName,
                            module: actions.entity.module,
                            group: actions.entity.group
                        } as DataEntity, ENUM_ACTIONS.VIEW, actions.entity.id);
                        break;
                    case 'select':
                        UIHelper.openDialog(SelectItemComponent, dialog, entity, ENUM_ACTIONS.SELECT);
                        break;
                }
            }
            load.hide();
        };
        const error = (e) => {
            adfiGrowlService.errorMessage(e);
            load.hide();
        };
        const proceed = (actions) => {
            if (item.type === TypeAction.LOAD_DATA) {
                if (item.middleware && item.middleware === Middleware.SELECT_RANGE_DATES) {
                    let data = {};
                    if (item.middlewareProperties && item.middlewareProperties.mapping) {
                        if (item.middlewareProperties.mapping.start !== undefined) {
                            data[item.middlewareProperties.mapping.start] =  actions.start;
                        } else {
                            data = {...data, start: actions.start};
                        }
                        if (item.middlewareProperties.mapping.end !== undefined) {
                            data[item.middlewareProperties.mapping.end] = actions.end;
                        } else {
                            data = {...data, end: actions.end};
                        }
                    } else {
                        data = {...data, start: actions.start, end: actions.end};
                    }
                    if (item.middlewareProperties && item.middlewareProperties.filterName){
                        list.updateDefaultFilters(item.middlewareProperties.filterName, data);
                    } else {
                        list.updateDefaultFilters('rangeDates', data);
                    }
                }
                if (item.middleware && (item.middleware === Middleware.SELECT_DATE ||
                    item.middleware === Middleware.SELECT_DATE_LAST_YEAR)) {
                    let data = {};
                    if (item.middlewareProperties && item.middlewareProperties.mapping) {
                        if (item.middlewareProperties.mapping.end !== undefined) {
                            data[item.middlewareProperties.mapping.end] = actions.date;
                        } else {
                            data = {...data, end: actions.date};
                        }
                    } else {
                        data = {...data, end: actions.date};
                    }
                    if (item.middlewareProperties && item.middlewareProperties.filterName){
                        list.updateDefaultFilters(item.middlewareProperties.filterName, data);
                    } else {
                        list.updateDefaultFilters('dates', data);
                    }
                }
                list.reload();
            } else {
                load.show();
                const cf = {filters: [], selectFilters: {}, item: row};
                if (dataEntity.customFilters) {
                    cf.filters = dataEntity.customFilters;
                }
                if (dataEntity.defaultFilters) {
                    cf.filters.push(...dataEntity.defaultFilters);
                }
                if (dataEntity.selectFilters) {
                    cf.selectFilters = dataEntity.selectFilters;
                }
                graphqlService.createEntity('Action', {
                    nameClass: item.action,
                    filters: JSON.stringify(filters),
                    actions: JSON.stringify(actions),
                    message: JSON.stringify(cf)
                }, 'message actions', {next, error});
            }
        };

        if (item.middleware){
            if (item.middleware === Middleware.SELECT_MONTH) {
                monthComponent.picker.touchUi = item.middlewareProperties ? item.middlewareProperties.touchUi : false;
                monthComponent.picker.open();
                const s = monthComponent.monthChange.subscribe( (e: moment.Moment) => {
                    proceed({month: e.month() + 1});
                });
                const sc = monthComponent.picker.closedStream.subscribe( () => {
                    s.unsubscribe();
                    sc.unsubscribe();
                });
            } else if (item.middleware === Middleware.SELECT_DATE) {
                monthComponent.picker.startView = 'month';
                monthComponent.picker.calendarHeaderComponent = MatCalendarHeader;
                monthComponent.picker.touchUi = item.middlewareProperties ? item.middlewareProperties.touchUi : false;
                monthComponent.picker.open();
                monthComponent.closeOnSelectMonth = false;
                monthComponent.endDate = moment();
                const s = monthComponent.dateChange.subscribe( (e) => {
                    proceed({ date: e.toISOString(true)});
                });
                const sc = monthComponent.picker.closedStream.subscribe( () => {
                    s.unsubscribe();
                    sc.unsubscribe();
                });
            }else if (item.middleware === Middleware.SELECT_DATE_LAST_YEAR) {
                monthComponent.picker.startView = 'month';
                const yearPeriod: number = item.middlewareProperties ? item.middlewareProperties.period : new Date().getFullYear();
                monthComponent.startDate = moment(`${yearPeriod}-01`, 'YYYY-MM');
                monthComponent.endDate = moment(`${yearPeriod}-12-30`, 'YYYY-MM-DD');
                const actualDate = new Date();
                if (actualDate.getFullYear() === yearPeriod) {
                    monthComponent.endDate = moment(`${actualDate.getFullYear()}-${actualDate.getMonth() + 1}-${actualDate.getDate()}`, 'YYYY-MM-DD');
                }
                monthComponent.picker.calendarHeaderComponent = MatCalendarHeader;
                monthComponent.picker.touchUi = item.middlewareProperties ? item.middlewareProperties.touchUi : false;
                monthComponent.picker.open();
                monthComponent.closeOnSelectMonth = false;
                const s = monthComponent.dateChange.subscribe( (e) => {
                    proceed({ date: e.toISOString(true) });
                });
                const sc = monthComponent.picker.closedStream.subscribe( () => {
                    s.unsubscribe();
                    sc.unsubscribe();
                });
            } else if (item.middleware === Middleware.SELECT_RANGE_DATES) {
                rangePicker.usePeriod = item.middlewareProperties ? item.middlewareProperties.period : true;
                rangePicker.picker.touchUi = item.middlewareProperties ? item.middlewareProperties.touchUi : false;
                rangePicker.picker.open();
                const s = rangePicker.dateSelected.subscribe( (dates) => {
                    proceed({start: dates.startDate.toISOString(true), end: dates.endDate.toISOString(true)});
                });
                const sc = rangePicker.picker.closedStream.subscribe( () => {
                    s.unsubscribe();
                    sc.unsubscribe();
                });
            } else if (item.middleware === Middleware.CONFIRM_DIALOG) {
                const matDialog = dialog.open(ConfirmDialogComponent, {
                    data: {
                        title: item.middlewareProperties.title,
                        content: item.middlewareProperties.content,
                        action: ENUM_ACTIONS.DELETE
                    } as ConfirmDialogData
                });
                matDialog.beforeClosed().subscribe((ok) => {
                    if (ok) {
                        proceed({});
                    }
                });
            }
        } else {
            proceed({});
        }
    }
}

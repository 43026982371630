import {Component, OnInit, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {AdfiService} from '@services/adfi-service';
import {LoadingService} from '@services/loading.service';
import {DateRangeSelectionComponent, ParamDate} from '@Components/date-range-selection/date-range-selection.component';
import {CurrencyPipe} from '@Components/util/currency.pipe';
import {Attribute, Column, Type} from '@Components/table/table.component';
import {AdfiUtil} from '@Components/util/adfi-util';
import {keyCache as adjustmentKey} from '../adjust/adjust.component';

@Component({
    selector: 'app-checking-balance',
    templateUrl: './checking-balance.component.html',
    styleUrls: ['./checking-balance.component.scss']
})
export class CheckingBalanceComponent implements OnInit {

    consolidated = false;
    selectedDates: ParamDate;
    townOrIe = '';
    get keyCache() {
        return this.service.user.period + '_DP_BAL_' + this.service.user.id;
    }
    get keyCacheDetailLevel() {
        return 'DL_BAL_' + this.service.user.id;
    }

    parameters: { levels: [], groups: [], subgroups: [], major: [], sub_account: []};

    columnsData: Column[] = [
        {
            column: 'ACCOUNT',
            name: 'Cuenta',
            type: Type.String,
            attr: {} as Attribute
        },
        {
            column: 'NAME_ACCOUNT',
            name: 'Nombre',
            type: Type.String,
            attr: {} as Attribute
        },
        {
            column: 'VALUE_ANT_DB',
            name: 'Saldo Inicial Débito',
            type: Type.Currency,
            attr: {classCell: 'text-right', } as Attribute
        },
        {
            column: 'VALUE_ANT_CR',
            name: 'Saldo Inicial Crédito',
            type: Type.Currency,
            attr: {classCell: 'text-right', } as Attribute
        },
        {
            column: 'DB',
            name: 'Débito',
            type: Type.Currency,
            attr: {classCell: 'text-right', } as Attribute
        },
        {
            column: 'CR',
            name: 'Crédito',
            type: Type.Currency,
            attr: {classCell: 'text-right', } as Attribute
        },
        {
            column: 'VALUE_DB',
            name: 'Saldo Final Débito',
            type: Type.Currency,
            attr: {classCell: 'text-right', } as Attribute
        },
        {
            column: 'VALUE_CR',
            name: 'Saldo Final Crédito',
            type: Type.Currency,
            attr: {classCell: 'text-right', } as Attribute
        }
    ];

    data: any[];
    level;
    active: number;
    filters = {
        groups: [],
        subgroups: [],
        major: [],
        sub_account: []
    };
    modules: string;
    dateChange: EventEmitter<ParamDate> = new EventEmitter();

    constructor(protected matDialog: MatDialog,
                protected adfiGrowlService: AdfiGrowlService,
                public service: AdfiService,
                public currency: CurrencyPipe,
                public loading: LoadingService) {
    }

    ngOnInit(): void {
        this.resetFilters();
        this.loadConfigData();
        const cache = AdfiUtil.getFromStorage(this.keyCache);
        if (cache) {
            this.selectedDates = JSON.parse(cache);
            this.selectedDates.startDate = new Date(this.selectedDates.startDate);
            this.selectedDates.endDate = new Date(this.selectedDates.endDate);
        }
        const cacheDet = AdfiUtil.getFromStorage(this.keyCacheDetailLevel);
        if (cacheDet) {
            this.level = cacheDet;
        }
        this.modules = AdfiUtil.getFromStorage(adjustmentKey(this.service));
        AdfiUtil.removeFromStorage(adjustmentKey(this.service));
    }

    selectDates() {
        const paramDates: ParamDate = {
            startDate: this.selectedDates ? this.selectedDates.startDate : null,
            endDate: this.selectedDates ? this.selectedDates.endDate : null
        };
        const dialog = this.matDialog.open(DateRangeSelectionComponent, {
            data: paramDates
        });
        dialog.componentInstance.btnText = 'Seleccionar';
        const subs = dialog.componentInstance.afterSelect.subscribe((dates: ParamDate) => {
            subs.unsubscribe();
            this.selectedDates = dates;
            AdfiUtil.saveInStorage(this.keyCache, JSON.stringify(dates));
            this.dateChange.emit();
            this.loadData();
            dialog.close();
        });
    }

    loadData() {
        const next = (d) => {
            if (d && d.fileName) {
                this.data = JSON.parse(d.fileName);
                this.loading.hide();
            } else {
                this.adfiGrowlService.error('Reporte', 'Se ha presentado un error al obtener la información, por favor contacta a soporte técnico');
                this.loading.hide();
            }
        };
        if (!this.selectedDates) {
            this.adfiGrowlService.warning('Precaución', 'Por favor selecciona un rango de fechas');
        } else {
            this.service.getJson(
                'ParamConBal',
                JSON.stringify(this.getAllFilters()),
                'fileName',
                next,
                this.consolidated ? '' : 'thisCenter'
            );
        }
    }

    loadConfigData() {
        const next = (d) => {
            if (d && d.fileName) {
                this.parameters = JSON.parse(d.fileName);
                this.loading.hide();
            } else {
                this.adfiGrowlService.error('Reporte', 'Se ha presentado un error al obtener la información, por favor contacta a soporte técnico');
                this.loading.hide();
            }
        };
        this.service.getJson(
            'GetParametersCheckBalanceFilter',
             JSON.stringify(this.getFilters()),
            'fileName',
            next
        );
    }

    getFilters() {
        return {
            group: this.filters.groups,
            subgroup: this.filters.subgroups,
            major: this.filters.major,
            sub_account: this.filters.sub_account,
            type: this.active
        };
    }

    getAllFilters() {
        return {
            level: this.level,
            startDate: this.selectedDates.startDate.toUTCString(),
            endDate: this.selectedDates.endDate.toUTCString(),
            townOrIe: this.townOrIe,
            modules: this.modules,
           ...this.getFilters()
        };
    }

    export(format: string, entity: string = 'ParamConBal', nameReport: string = null) {
        if (!this.selectedDates) {
            this.adfiGrowlService.warning('Precaución', 'Por favor selecciona un rango de fechas');
        } else {
            nameReport = nameReport ? nameReport : this.consolidated ? 'Balance_Consolidado' : 'Balance';
            this.service.download(
                entity,
                JSON.stringify(this.getAllFilters()),
                'fileName',
                `application/${format}`,
                AdfiUtil.getNameFile(nameReport, this.selectedDates,
                    this.consolidated ? undefined : this.service.user.centroCosto.deDescripcion) + '.xlsx',
                this.consolidated ? '' : 'thisCenter'
            );
        }
    }

    checkParentClass(ctx, row, table) {
        return row.LVL > ctx.level - 1 ? '' : 'font-weight-bold';
    }

    resetFilters() {
        this.selectedDates = null;
        this.level = 4;
        this.parameters = {
            levels: this.parameters ? this.parameters.levels : [],
            groups: this.parameters ? this.parameters.groups : [],
            subgroups: [],
            major: [],
            sub_account: []
        };
        this.townOrIe = '';
        this.filters = {
            groups: [],
            subgroups: [],
            major: [],
            sub_account: []
        };
    }

    saveDetailLevel(level: number) {
        AdfiUtil.saveInStorage(this.keyCacheDetailLevel, level.toString());
    }
}

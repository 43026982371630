<app-list #table module="EndProcess" entityName="BudgetReserve"
          group="stats"
          [dataEntity]="{enableAdd: false, customTitle: 'Información de Constitución de Reservas Presupuestales'}">

    <ng-template tableColumn="approve" let-row="row">
        <button mat-icon-button aria-label="Revisar" color="primary" (click)="showBalanceResource(row)">
            <mat-icon>visibility</mat-icon>
        </button>
    </ng-template>
</app-list>

<div class="row" mat-dialog-title>
    <div class="col-12 pt-1">
        <h2 class="text-center">Seleccione el tipo de orden de pago: </h2>
    </div>
</div>
<div mat-dialog-content class="text-center">
            <button mat-flat-button color="primary" mat-dialog-close (click)="purchase()">Compra de bienes</button>
            <button mat-flat-button color="primary"  mat-dialog-close (click)="services()">Servicios</button>
            <button mat-flat-button color="primary" mat-dialog-close disabled="true">Inventarios de consumo</button>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-stroked-button color="primary" mat-dialog-close>Cancelar</button>
</div>
<div class="row" *ngIf="data.account">
    <div class="col">
        <mat-tab-group #tabsPc>
            <mat-tab label="CHEQUE GIRADO Y NO COBRADO">
                <ng-template matTabContent>
                    <app-list #cncList entityName="ClosingConciliation" module="ClosingForm"
                              [customData]="{approve: true, originData: 'FR_BALANCE', type: 'CNC', account: data.account.id}"
                              noActions="true"
                              [dataEntity]="{group: 'CNC', enableAdd: data.editable, customTitle:'CHEQUE GIRADO Y NO COBRADO', subtitleHTML: '<p class=\'info-form\'> Corresponden a pagos realizados a proveedores de los que se registró el comprobante de egreso, pero que la transacción en el extracto bancario no se efectúo en la vigencia '+data.period+'</p>' }"
                              group="CNC_F"
                              [defaultQuery]="[{column: 'type', value: 'CNC'}, {column :'account', value: data.account._id}]"
                              customTitle="CONCILIACIÓN BANCARIA">
                        <ng-template tableColumn="approve" let-row="row">
                            <mat-slide-toggle [checked]="row.approve" color="primary"
                                              [disabled]="data.editable === false"
                                              (change)="updateConciliation($event.checked, row, cncList)">
                                {{row.approve ? 'Si' : 'No' }}
                            </mat-slide-toggle>
                        </ng-template>
                    </app-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="CONSIGNACIÓN SIN IDENTIFICAR">
                <ng-template matTabContent>
                    <app-list #csiList entityName="ClosingConciliation" module="ClosingForm"
                              [customData]="{approve: true, originData: 'FR_BALANCE', type: 'CSI', account: data.account.id}"
                              noActions="true"
                              [dataEntity]="{group: 'CSI', enableAdd: data.editable, customTitle: 'CONSIGNACIÓN SIN IDENTIFICAR', subtitleHTML: '<p class=\'info-form\'> Son recursos que aparecen como abonos en el extracto bancario, pero que en los comprobantes de ingreso de la plataforma ADFI de la Institución no se cuenta con algún registro.</p>'}"
                              group="CSI_F"
                              [defaultQuery]="[{column: 'type', value: 'CSI'}, {column :'account', value: data.account._id}]"
                              customTitle="CONCILIACIÓN BANCARIA">
                        <ng-template tableColumn="approve" let-row="row">
                            <mat-slide-toggle [checked]="row.approve" color="primary"
                                              [disabled]="data.editable === false"
                                              (change)="updateConciliation($event.checked, row, csiList)">
                                {{row.approve ? 'Si' : 'No' }}
                            </mat-slide-toggle>
                        </ng-template>
                    </app-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="CONSIGNACIÓN EN TRÁNSITO">
                <ng-template matTabContent>
                    <app-list #cetList entityName="ClosingConciliation" module="ClosingForm"
                              [customData]="{approve: true, originData: 'FR_BALANCE', type: 'CET', account: data.account.id}"
                              [dataEntity]="{group: 'CET', enableAdd: data.editable, customTitle:'CONSIGNACIÓN EN TRÁNSITO', subtitleHTML:'<p class=\'info-form\'> Consignaciones en tránsito, corresponden a abonos a favor de la Institución que si bien cuentan con un comprobante de ingreso en la plataforma ADFI en el extracto bancario no están relacionados.</p>' }"
                              noActions="true"
                              group="CET_F"
                              [defaultQuery]="[{column: 'type', value: 'CET'}, {column :'account', value: data.account._id}]"
                              customTitle="CONCILIACIÓN BANCARIA">
                        <ng-template tableColumn="approve" let-row="row">
                            <mat-slide-toggle [checked]="row.approve" color="primary"
                                              [disabled]="data.editable === false"
                                              (change)="updateConciliation($event.checked, row, cetList)">
                                {{row.approve ? 'Si' : 'No' }}
                            </mat-slide-toggle>
                        </ng-template>
                    </app-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="NOTA DÉBITO">
                <ng-template matTabContent>
                    <app-list #ndeList entityName="ClosingConciliation" module="ClosingForm"
                              [customData]="{approve: true, originData: 'FR_BALANCE', type: 'DEB', account: data.account.id}"
                              [dataEntity]="{group: 'DEB', enableAdd: data.editable, customTitle: 'NOTA DÉBITO', subtitleHTML: '<p class=\'info-form\'> Notas débito, por dineros que el banco descuenta en el extracto bancario, pero que el Fondo de Servicios Educativos no ha registrado. Un ejemplo de esta partida conciliatoria son los gastos financieros cobrados por el banco al cierre de la vigencia, pero que por algún motivo la Institución no registró en la plataforma ADFI.</p>'}"
                              group="DEB_F"
                              noActions="true"
                              [defaultQuery]="[{column: 'type', value: 'DEB'}, {column :'account', value: data.account._id}]"
                              customTitle="CONCILIACIÓN BANCARIA">
                        <ng-template tableColumn="approve" let-row="row">
                            <mat-slide-toggle [checked]="row.approve" color="primary"
                                              [disabled]="data.editable === false"
                                              (change)="updateConciliation($event.checked, row, ndeList)">
                                {{row.approve ? 'Si' : 'No' }}
                            </mat-slide-toggle>
                        </ng-template>
                    </app-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="NOTA CRÉDITO">
                <ng-template matTabContent>
                    <app-list #ncrList entityName="ClosingConciliation" module="ClosingForm"
                              [customData]="{approve: true, originData: 'FR_BALANCE', type: 'CRE', account: data.account.id}"
                              [dataEntity]="{group: 'CRE', enableAdd: data.editable, customTitle: 'NOTA CRÉDITO', subtitleHTML:'<p class=\'info-form\'> Notas crédito, por dineros que el banco abona en el extracto bancario, pero que el Fondo de Servicios Educativos no ha registrado. Un ejemplo de esta partida conciliatoria son los rendimientos financieros que la institución recibe al cierre de la vigencia, pero que por algún motivo no registró en la plataforma ADFI.</p>'}"
                              group="CRE_F"
                              noActions="true"
                              [defaultQuery]="[{column: 'type', value: 'CRE'}, {column :'account', value: data.account._id}]"
                              customTitle="CONCILIACIÓN BANCARIA">
                        <ng-template tableColumn="approve" let-row="row">
                            <mat-slide-toggle [checked]="row.approve" color="primary"
                                              [disabled]="data.editable === false"
                                              (change)="updateConciliation($event.checked, row, ncrList)">
                                {{row.approve ? 'Si' : 'No' }}
                            </mat-slide-toggle>
                        </ng-template>
                    </app-list>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

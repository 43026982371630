import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Pages} from '@Interfaces/index';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {UIHelper} from '@Components/util/UIHelper';
import {MatDialog} from '@angular/material/dialog';
import {AdfiUtil} from '@Components/util/adfi-util';

@Component({
    selector: 'app-usuarios-sedes',
    templateUrl: './usuarios-sedes.component.html',
    styleUrls: ['./usuarios-sedes.component.css']
})
export class UsuariosSedesComponent implements OnInit {

    /**
     * Centro costo of usuarios sedes component
     */
    public centroCosto: any;

    public cencos;

    public centerCost;
    period: number;

    PAGES = Pages;
    fields = [
        'deDescripcion',
        'correo',
        'ubicacionGps',
        'fileRut',
        'prcencosCodDane',
        'nit',
        'telefono',
        'celular',
        // This is ought to be removed
        // 'sector',
        'municipio{nombre}',
        'fileNameRut',
        'mision',
        'vision',
        'webPage',
        'address'
    ];
    constructor(
        private activatedRoute: ActivatedRoute,
        private  adfiGraphqlService: AdfiGraphqlService,
        private service: AdfiService,
        private adfiGrowl: AdfiGrowlService,
        private dialog: MatDialog
    ) {
    }

    /**
     * on init
     */
    ngOnInit() {
        this.cencos = this.activatedRoute.snapshot.paramMap.get('cencos') ?
            this.activatedRoute.snapshot.paramMap.get('cencos') : this.service.user.centroCosto._id;
        this.centerCost = `/api/pr_centro_costos/${this.cencos}`;
        this.period = this.service.user.period;
        this.loadInfoCencos();
    }

    private loadInfoCencos() {
        const next = (val) => {
            this.centroCosto = val;
        };
        const error = (err) => {
            this.adfiGrowl.errorMessage(err);
        };
        this.adfiGraphqlService.getOneEntity('prCentroCosto',
            this.fields.join(' '),
            this.centerCost, undefined, { next, error});
    }

    editCenter(group, title) {
        const ref = UIHelper.editItemEntity(this.dialog, this.centerCost, {
            entityName: 'PrCentroCosto',
            module: '',
            group,
            customReturnAfterSave: this.fields,
            customTitle: title
        });
        ref.componentInstance.postSave.subscribe(d => {
            if (d) {
                this.centroCosto = d;
            }
        });
    }

    downloadFile(file: string) {
        AdfiUtil.downloadMediaObject(this.centroCosto, {
            fieldName: file,
            options: {bucket: 'files_active_directory', bucketNoVigencia: true }
        }, false);
    }
}

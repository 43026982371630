<div class="row pt-2">
    <mat-card class="col-12 mb-2">
        <mat-card-title>Estados Financieros</mat-card-title>
        <mat-card-content>
            <div class="row justify-content-center align-items-center">
                <mat-form-field *ngIf="withLevel" class="col-3 col-md-2">
                    <mat-label>Nivel de detalle</mat-label>
                    <mat-select placeholder="Seleccionar" [(value)]="level" (valueChange)="tryLoadData()">
                        <mat-option [disabled]="currentTab === 2 && doc === 1" *ngFor="let doc of levels" [value]="doc">
                            {{ doc }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="px-4" *ngIf="!forceComparative">
                    <mat-label>Tipo de Reporte</mat-label>
                    <mat-select [value]="false" (valueChange)="updatedComparative()"
                                [(ngModel)]="comparative">
                        <mat-option [value]="false">Individual</mat-option>
                        <mat-option [value]="true">Comparativo</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="col-auto" *ngIf="comparative">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip style="min-width: 10em" color="primary" selected matTooltip="Seleccinar fecha">
                            <mat-form-field class="d-flex align-items-center without-underline justify-content-around pt-1">
                                <input matInput [min]="minDate" [matDatepicker]="pickerAnt" [max]="current ? current : maxDate"
                                       [(ngModel)]="previous" style="display: none" (dateChange)="tryLoadData()">
                                <h6> Anterior:
                                    <ng-container *ngIf="previous" style="font-weight: lighter">
                                        <span style="font-weight: lighter; font-size: 0.8rem">{{ previous | date: 'mediumDate':null:'es-CO'}}</span>
                                    </ng-container>
                                </h6>
                                <mat-datepicker-toggle style="color: white" matSuffix [for]="pickerAnt"></mat-datepicker-toggle>
                                <mat-datepicker #pickerAnt></mat-datepicker>
                            </mat-form-field>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <div class="col-auto">
                    <mat-chip-list aria-label="fiantial states">
                        <mat-chip style="min-width: 10em" color="primary" selected matTooltip="Seleccinar fecha {{ comparative ? 'a comparar' : '' }}">
                            <mat-form-field class="d-flex align-items-center justify-content-around pt-1 without-underline" >
                                <input matInput [min]="previous ? previous : minDate" [matDatepicker]="pickerCom" [max]="maxDate"
                                       [(ngModel)]="current" style="display: none" (dateChange)="tryLoadData()">
                                <h6> {{ comparative ? 'A comparar: ' : 'Fecha: ' }}
                                    <ng-container *ngIf="current">
                                        <span style="font-weight: lighter; font-size: 0.8rem">{{ current | date: 'mediumDate':null:'es-CO'}}</span>
                                    </ng-container>
                                </h6>
                                <mat-datepicker-toggle style="color: white" matSuffix [for]="pickerCom"></mat-datepicker-toggle>
                                <mat-datepicker #pickerCom></mat-datepicker>
                            </mat-form-field>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <mat-chip-list *ngIf="havePermissionMassive" class="mat-chip-list-stacked" aria-label="Color selection" matTooltip="Realizar las consultas para la I.E. Actual o Consolidado">
                    <mat-chip [class.massive]="generateMassive" [class.simple]="!generateMassive" selected (click)="generateMassive = !generateMassive">{{generateMassive?'Consolidado':'I.E. Actual'}}</mat-chip>
                </mat-chip-list>
                <div class="col-auto d-flex flex-row">
                    <mat-icon class="pointer" matTooltip="Generar PDF" (click)="export('pdf')">picture_as_pdf</mat-icon>
                    <mat-icon class="pointer" matTooltip="Generar XLSX" (click)="export('xlsx')">analytics</mat-icon>
                </div>
            </div>
            <div class="row pt-2">
                <mat-tab-group color="white" class="col-12 tab-custom-overflow" mat-align-tabs="center" (selectedIndexChange)="active = $event">
                    <mat-tab *ngFor="let link of keys; let i = index" [label]="tabs[link].title">
                        <app-table [context]="this" [dynamicRowClass]="checkParentClass" [columns]="comparative ? tabs[link].colsCom: tabs[link].cols"
                                   [items]="tabs[link].rows" (reload)="loadData(link)"></app-table>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>

import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
    selector: '[tableColumn]'
})
export class TableColumnDirective {
    constructor(public readonly template: TemplateRef<any>) { }
    @Input('tableColumn') columnName: string;
}

export function transformBytes (value) {
    if (parseInt(value, 10) >= 0) {
        value = formatBytes(+value, 2);
    }
    return value;
}

function formatBytes(bytes, decimals) {
    if (bytes === 0) {
        return '0 Byte';
    }
    const B = 'B';
    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Byte', 'K' + B, 'M' + B, 'G' + B, 'T' + B, 'P' + B, 'E' + B, 'Z' + B, 'Y' + B];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export function MaxSizeValidator(max) {
    return (ctrl) => {
        max = Number(max);
        if (isNaN(max)) {
            throw 'MaxSizeValidator: max of size of file is invalid';
        }
        if (!ctrl.value)
            return null;
        let files = ctrl.value;
        if (!Array.isArray(ctrl.value)) {
            files = [ctrl.value];
        }
        if (!files.length)
            return null;
        const add = (a, b) => a + b;
        const sumSize = files.map(x => x.size).reduce(add);
        if (sumSize > max) {
            return {
                maxContentSize: {
                    currentSize: sumSize,
                    max: max
                }
            };
        }
        return null;
    };
}

export function AcceptValidator(accept) {
    return (ctrl) => {
        if (!accept) {
            throw ('AcceptValidator: allowable type of file can not be empty');
        }
        if (ctrl.value == null)
            return null;
        let files = ctrl.value;
        if (!Array.isArray(ctrl.value)) {
            files = [ctrl.value];
        }
        if (!files.length)
            return null;
        let notFoundType = undefined;
        for (let i = 0; i < files.length && notFoundType === undefined; i++) {
            if (!accept.includes(files[i].type)) {
                notFoundType = files[i].type;
            }
        }
        if (notFoundType) {
            return {
                accept: {
                    currentFormat: notFoundType
                }
            };
        }
        return null;
    };
}

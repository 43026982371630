import { gql, Apollo } from 'apollo-angular';

import { Injectable } from '@angular/core';

import { first, map } from 'rxjs/operators';
import { PartialObserver } from 'rxjs';
import { ENUM_ACTIONS } from '@Interfaces/index';

@Injectable({
    providedIn: 'root'
})
export class AdfiGraphqlService {
    constructor(private apollo: Apollo) {
    }

    private static lowerFirstLetter(cad: string) {
        return cad.charAt(0).toLowerCase() + cad.slice(1);
    }

    private static generateQueryBy(entityPlural, columns, firstNodes, params = null, withoutIds = false) {
        return gql`
            query getEntity {
                ${entityPlural} (first: ${firstNodes} ${params ? `, ${params}` : ''}) {
                    edges{
                        node{
                            ${firstNodes === 0 && withoutIds ? 'id' : ''}
                            ${withoutIds ? '' : 'id _id'}
                            ${columns}
                        }
                    }
                    ${firstNodes === 0 ? 'totalCount' : ''}
                }
            }
        `;
    }

    private static generateQueryOneEntity(entitySingular, columns, params = null, withoutIds = false) {
        return gql`
            query getEntity {
                ${entitySingular} (${params ? `${params}` : ''}) {
                        ${withoutIds ? '' : 'id _id'}
                        ${columns}
                }
            }
        `;
    }

    private static generateMutationByAction(name: string, action: ENUM_ACTIONS, customReturn: string = null, withoutId = false) {
        return gql`
            mutation q ($input: ${action}${name}Input!) {
                ${action}${name} (input: $input ) {
                    ${AdfiGraphqlService.lowerFirstLetter(name)} {
                        id
                        ${withoutId ? '' : '_id'}
                        ${customReturn ? customReturn : ''}
                    }
                }
            }`;
    }

    searchCenter(id: string) {
        return gql`
            query prCentroCostos{
                prCentroCostos(municipio_id:${id}){
                    edges{
                        node{
                            id
                            deDescripcion
                            _id
                        }
                    }
                }
            }
        `;
    }

    getEmployeesByCenter(center: string) {
        return gql`
            query employees{
                employees(cencos:"${center}"){
                    edges{
                        node{
                            name
                            apellidos
                            id
                        }
                    }
                }
            }
        `;
    }

    getEmployeesRole(role: number) {
        return gql`
            query employees{
                employees(role_id:${role}){
                    edges{
                        node{
                            name
                            apellidos
                            id
                        }
                    }
                }
            }
        `;
    }

    searchTown(name: string) {
        return gql`
            query municipios{
                municipios(nombre:"${name}"){
                    edges{
                        node{
                            _id
                            nombre
                        }
                    }
                }
            }
        `;
    }

    getCategoriesIncedents() {
        return gql`
            query categoriesIncidents{
                categoriesIncidents(first: 200){
                    edges{
                        node{
                            id
                            _id
                            typeIncidence
                            name
                        }
                    }
                }
            }
        `;
    }

    saveCall(call: any, idCencos: string, idEmployee: string, incidenceType, numberCell) {
        return gql`
            mutation createCall{
                createCall(input:{
                    _id:"${call._id}",
                    ${idCencos ? `centroCosto: "${idCencos}",` : ''}
                    employee:"${idEmployee}",
                    initDate:"${call.initDate.toUTCString()}",
                    endDate:"${call.endDate ? call.endDate.toUTCString() : call.initDate.toUTCString()}",
                    typeIncidence:"${incidenceType}",
                    observation:"""${call.observation}""",
                    auxiliaryCenter: "${call.auxiliaryCenter.id}",
                    typeCall: ${!call.typeCall}
                    ${numberCell !== '' ? ', phone: "' + numberCell + '"' : ''}
                }){
                    call{
                        id
                    }
                }
            }
        `;
    }

    updateAdfiForm() {
        return gql`
            mutation updateAdfiForm($input: updateAdfiFormInput!){
                updateAdfiForm(input: $input ){
                    adfiForm{
                        id
                    }
                }
            }
        `;
    }

    getDiscount(type: string) {
        return gql`
        query discounts{
            discounts(typeNational:"${type}", filter_period_by_period: 0){
                edges{
                        node{
                            id
                            _id
                            orderNum
                            description
                            fromValue
                        }
                    }
            }}
            `
    }


    extractListResponse(target, entityPlural, customMappingField?, customActionMapping?: (obj: any) => void) {
        if (!target || !target.data || !target.data[entityPlural]) { return undefined; }
        const arr = target.data[entityPlural].edges;
        return arr ? arr.map(obj => {
            if (customActionMapping) {
                return customActionMapping(obj.node);
            }
            return customMappingField ? obj.node[customMappingField] : obj.node;
        }) : undefined;
    }

    handleResponse(result, handler: () => void) {
        if (result && result.errors) {
            return { graphQLErrors: result.errors };
        }
        return handler();
    }

    extractSingleResponse(result, entitySingular) {
        if (!result || !result.data) { return undefined; }
        return result.data ? result.data[entitySingular] : undefined;
    }

    handleErrorMessages(result, observer: PartialObserver<any>) {
        if (result && result.graphQLErrors) {
            observer.error(result);
        } else {
            observer.next(result);
        }
    }

    getEntityWithOutIds(entityPlural: string, columns: string, firsts = 10, params: string, observer: PartialObserver<any>,
        customMappingField: string = null, customActionMapping: (entity: any) => any = null) {
        return this.apollo.query({
            query: AdfiGraphqlService.generateQueryBy(entityPlural, columns, firsts, params, true)
        }).pipe(
            first(),
            map(result => this.handleResponse(result,
                () => this.extractListResponse(result, entityPlural, customMappingField, customActionMapping))
            )
        ).subscribe({ next: (result) => this.handleErrorMessages(result, observer), error: observer.error });
    }

    getEntity(entityPlural: string, columns: string, firsts = 10, params: string, observer: PartialObserver<any>,
        withoutIds = false, customMappingField: string = null, customActionMapping: (entity: any) => any = null) {
        return this.apollo.query({
            query: AdfiGraphqlService.generateQueryBy(entityPlural, columns, firsts, params, withoutIds)
        }).pipe(
            first(),
            map(result => this.handleResponse(result,
                () => this.extractListResponse(result, entityPlural, customMappingField, customActionMapping))
            )
        ).subscribe({ next: (result) => this.handleErrorMessages(result, observer), error: observer.error });
    }

    getOneEntity(entitySingular: string, columns: string, id: string, params: string, observer: PartialObserver<any>, withoutIds = false) {
        return this.apollo.query({
            query: AdfiGraphqlService.generateQueryOneEntity(
                entitySingular,
                columns, `${id ? `id: "${id}"` : ''} ${params ? `${id ? ', ' : ''}${params}` : ''}`, withoutIds)
        }).pipe(
            first(),
            map(result => this.handleResponse(result, () => this.extractSingleResponse(result, entitySingular)))
        ).subscribe({ next: (result) => this.handleErrorMessages(result, observer), error: observer.error });
    }

    getFirstEntity(entityPlural: string, columns: string, params: string, observer: PartialObserver<any>, withoutIds = false) {
        return this.apollo.query({
            query: AdfiGraphqlService.generateQueryBy(entityPlural, columns, 1, params, withoutIds)
        }).pipe(
            first(),
            map(result => this.handleResponse(result,
                () => {
                    const arr = result.data[entityPlural].edges;
                    return arr && arr.length ? arr[0].node : undefined;
                })
            )
        ).subscribe({ next: (result) => this.handleErrorMessages(result, observer), error: observer.error });
    }

    async countEntity(entityPlural, params: string): Promise<number> {
        return this.apollo.query({
            query: AdfiGraphqlService.generateQueryBy(entityPlural, '', 0, params, true)
        }).pipe(
            first(),
            map(result => {
                return result.data[entityPlural] ? result.data[entityPlural].totalCount : 0;
            })
        ).toPromise();
    }

    mutationEntity(name: string, params: any, customReturn: string, action: ENUM_ACTIONS, observer: PartialObserver<any>, notId?) {
        this.apollo.mutate({
            mutation: AdfiGraphqlService.generateMutationByAction(name, action, customReturn, notId),
            variables: {
                input: { clientMutationId: 'id', ...params }
            }
        }).pipe(
            first(),
            map(result => this.handleResponse(result, () => {
                const res = result.data[`${action}${name}`];
                return res ? res[AdfiGraphqlService.lowerFirstLetter(name)] : undefined;
            })
            )
        )
            .subscribe({ next: (result) => this.handleErrorMessages(result, observer), error: observer.error });
    }

    createEntity(name: string, params: any, customReturn: string, observer: PartialObserver<any>, notId?) {
        this.mutationEntity(name, params, customReturn, ENUM_ACTIONS.CREATE, observer, notId);
    }

    updateEntity(name: string, params: any, customReturn: string, observer: PartialObserver<any>, notId?) {
        this.mutationEntity(name, params, customReturn, ENUM_ACTIONS.EDIT, observer, notId);
    }

    deleteEntity(name: string, id: string, observer: PartialObserver<any>, notId?) {
        this.apollo.query({
            query: AdfiGraphqlService.generateMutationByAction(name, ENUM_ACTIONS.DELETE, undefined, notId),
            variables: {
                input: {
                    clientMutationId: 'id',
                    id
                }
            }
        }).pipe(
            first(),
            map(result => this.handleResponse(result, () => {
                const res = result.data[`${ENUM_ACTIONS.DELETE}${name}`];
                return res ? res[AdfiGraphqlService.lowerFirstLetter(name)] : undefined;
            })
            )
        ).subscribe({
            next: (result: { graphQLErrors: any } | void) => {
                if (result && result.graphQLErrors && result.graphQLErrors.length > 0) {
                    if (result.graphQLErrors[0].debugMessage.indexOf('Expected a value of type \"Int\" but received:') >= 0) {
                        delete result.graphQLErrors;
                    }
                }
                this.handleErrorMessages(result, observer);
            }, error: observer.error
        });
    }

    loadAlerts(entityClass: string, functionName: string, values: string, onSuccess: (values: string[]) => void, onError: (err) => void = null) {
        this.getOneEntity('notRetrievedQueryAlert', 'values',
            undefined,
            `className:"${entityClass}", functionName: "${functionName}", values: [${values}]`,
            {
                next: (v) => {
                    onSuccess(v.values);
                }, error: (err) => {
                    if (onError) {
                        onError(err);
                    }
                }
            }, true);
    }

    getDiscounts(type: string){
        return this.apollo.query({
            query: this.getDiscount(type)
          })
    }
}

import {
  Component,
  ContentChildren, Directive,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList, TemplateRef
} from '@angular/core';
import {FormControl} from '@Components/ui/util/FormControl';
import {
  AbstractControl,
  ControlContainer, ControlValueAccessor,
  DefaultValueAccessor,
  FormGroup,
  FormGroupDirective, NG_VALUE_ACCESSOR,
  NgControl, Validators
} from '@angular/forms';
@Directive({
  selector: '[autocompleteContentItem]'
})
export class AutocompleteContentItemDirective {
  constructor(public readonly template: TemplateRef<any>) { }
  @Input('autocompleteContentItem') name: string;
}

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {

  loading = false;
  displayItems: any[];
  @Input() set items(items: any[]) {
    this.loading = false;
    this.displayItems = items;
  }
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() label: string;
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @ContentChildren(AutocompleteContentItemDirective) itemTemplate: QueryList<AutocompleteContentItemDirective>;
  @Input() displayFn: (item) => string = null;

  @Output() keyChange: EventEmitter<string> = new EventEmitter();
  constructor(@Optional() private parent: FormGroupDirective) {}

  ngOnInit(): void {
    if (this.parent) {
      if (this.formControlName) {
        this.parent.form.addControl(this.formControlName, new FormControl(this.value, this.required ? Validators.required : null));
      } else if (this.formControl) {
        this.formControl.setValue(this.value);
        this.parent.form.addControl(this.formControlName, this.formControl);
      }
    }
  }

  onEnterSearch(value) {
    if (typeof value === 'string') {
      this.loading = true;
      this.keyChange.emit(value);
    }
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: any) {
    this.value = input;
  }

  getTemplate(): null | TemplateRef<any> {
    if (this.itemTemplate && this.itemTemplate.length) {
      return this.itemTemplate.first.template;
    }
    return null;
  }
}

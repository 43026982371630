<div class="menu" #menu>
    <input type="checkbox" [disabled]="disabled" class="menu-open" name="menu-open" [id]="menuItemID"
           (change)="updateChecked($event.target); menu.className.includes('menu-opened-z-over') ? menu.classList.remove('menu-opened-z-over') : menu.classList.add('menu-opened-z-over')"/>
    <label class="menu-open-button no-select" [for]="menuItemID" matTooltip="{{tooltip}}" [matTooltipPosition]="tooltipPosition">
        <span *ngIf="!hamburger && !checked">{{showValue ? value : content}}</span>
        <span *ngIf="hamburger || checked" class="hamburger hamburger-1"></span>
        <span *ngIf="hamburger" class="hamburger hamburger-2"></span>
        <span *ngIf="hamburger || checked" class="hamburger hamburger-3"></span>
    </label>
    <div class="menu-content">
        <a class="menu-item no-select cursor-pointer" [ngClass]="value === i || checks[loc] ? 'selected' : ''"
           *ngFor="let i of optionsItems; let loc = index" (click)="handleAction(loc, i); $event.preventDefault()">
            <mat-icon *ngIf="i.iconMenu">{{i.iconMenu}}</mat-icon>
            {{toString(i)}}
        </a>
    </div>
</div>
<!-- filters -->
<svg xmlns="http://www.w3.org/2000/svg" height="40" class="d-none">
    <defs>
        <filter id="shadowed-goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feGaussianBlur in="goo" stdDeviation="3" result="shadow" />
            <feColorMatrix in="shadow" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2" result="shadow" />
            <feOffset in="shadow" dx="1" dy="1" result="shadow" />
            <feComposite in2="shadow" in="goo" result="goo" />
            <feComposite in2="goo" in="SourceGraphic" result="mix" />
        </filter>
        <filter id="goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feComposite in2="goo" in="SourceGraphic" result="mix" />
        </filter>
    </defs>
</svg>

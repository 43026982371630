import { environment } from 'src/environments/environment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Provider } from '@angular/core';

// Constantes
export const URL_SERVER = environment.urlServer;
export const URL_GRAPHQL = environment.urlServer + '/api/graphql';
export const APP_TOKEN = '[TOKEN_ADFIB]';
export const PATH_HOME = 'nav';
export const PATH_OUT_OF_SERVICE = 'out-of-service';

// Formato fechas

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

export const PROVIDERS_DATE_FORMAT: Provider[] = [
    {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        useValue: 'es'
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
];

export const NAME_MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
];

export const NAME_ENTITY = {
    ExtractBankByAccount: 'Extractos Bancarios',
    ALL: 'Información',
    PeriodsPlatform: { plural: 'periodsPlatforms'}
};

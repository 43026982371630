import { Component} from '@angular/core';
import {AuxiliaryBudgetBooksComponent} from '@pages/presupuesto/auxiliary-budget-books/auxiliary-budget-books.component';
import {Attribute, Column, ColumnGroup, Type} from '@Components/table/table.component';
import {Entities, PeriodState} from '@Interfaces/index';

@Component({
  selector: 'app-contab-books',
  templateUrl: './contab-books.component.html',
  styleUrls: ['./contab-books.component.scss']
})
export class ContabBooksComponent extends AuxiliaryBudgetBooksComponent {

  columnsHeaderGroupAll: ColumnGroup[];
  columnsFooterGroupAll: ColumnGroup[];

  columnsAll: Column[] = [
    {
      column: 'DOCUMENT',
      name: 'Tipo',
      type: Type.Option,
      attr: { classHeader: '',
        options: {RC: 'Ingreso de Tesorería', OP: 'Causación Orden de Pago', TET: 'Operación de Tesorería',
          CG: 'Consignaciones de Caja', ND: 'Devoluciones', CO_T: 'Operaciones de Terceros', CXP: 'Cuentas por Pagar',
          'M/ De Pago': 'Anulación Egreso', TE: 'Comprobante De Egreso', AUTO: 'Comprobante Automatico', AJ: 'Ajuste',CB: 'Causación Orden de Pago Bienes'}
      } as Attribute
    },
    {
      column: 'NUMBER_DOC',
      name: 'N.° Documento',
      type: Type.String,
      attr: null
    },
    {
      column: 'DESCRIPTION',
      name: 'Descripción',
      type: Type.String,
      attr: null
    },
    {
      column: 'DATE_CREATION',
      name: 'Fecha',
      type: Type.Date,
      attr: { classCell: 'text-center'} as Attribute
    },
    {
      column: 'DB',
      name: 'Valor Débito',
      type: Type.Currency,
      attr: { classCell: 'text-right px-1'} as Attribute
    },
    {
      column: 'CR',
      name: 'Valor Crédito',
      type: Type.Currency,
      attr: { classCell: 'text-right px-1'} as Attribute
    },
    {
      column: 'NIT',
      name: 'NIT',
      type: Type.Number,
      attr: { classCell: 'text-center px-1'} as Attribute
    },
    {
      column: 'THIRD',
      name: 'Nombre Tercero',
      type: Type.String,
      attr: null
    },
    {
      column: 'RESPONSIBLE',
      name: 'Nombre Responsable',
      type: Type.String,
      attr: null
    }
  ];

  get keyCache(){
    return this.service.user.period + '_DT_BKC_' + this.service.user.id;
  }

  get keyParam() {
    return 'GetParametersContabBooks';
  }

  get keyEntity() {
    return Entities.AUXILIARY_CONTAB_BOOKS;
  }

  get keyExportFile() {
    return 'LibrosAuxiliaresContables';
  }

  get KeyMessage() {
    return 'cuenta';
  }

  validatePermissions() {
    this.canSearchConsolidated = this.service.validPermission(this.keyEntity, 'consolidated', PeriodState.NO_STATUS);
    if (this.canSearchConsolidated) {
      this.columnsAll.splice(0, 0, {
        column: 'N_ID_CC',
        name: 'I.E.',
        type: Type.Number,
        attr: { classHeader: 'text-center', classCell: 'text-center px-1'} as Attribute
      });
    }
    this.checkColumns();
  }

  checkColumns() {
    if (this.townOrIe.search(/^[0-9]{7}$/) === 0 || !this.canSearchConsolidated) {
      this.columnsHeaderGroupAll = [
        {
          column: 'ACCOUNT',
          name: 'Cuenta',
          type: Type.Number
        },
        {
          column: 'NAME_ACCOUNT',
          name: '',
          hideGroupName: true,
          type: Type.String
        },
        {
          column: 'START_VALUE',
          name: 'Saldo Inicial',
          type: Type.Currency
        }
      ];
      this.columnsFooterGroupAll = [
        {
          column: 'DB',
          sum: true,
          name: '',
          sumName: 'Total Débito',
          type: Type.Currency
        },
        {
          column: 'CR',
          sum: true,
          name: '',
          sumName: 'Total Crédito',
          type: Type.Currency
        },
        {
          column: 'END_VALUE',
          name: 'Saldo Final',
          type: Type.Currency
        },
      ];
    } else {
      this.columnsHeaderGroupAll = [
        {
          column: 'ACCOUNT',
          name: 'Cuenta',
          type: Type.Number
        },
        {
          column: 'NAME_ACCOUNT',
          name: '',
          hideGroupName: true,
          type: Type.String
        }
      ];
      this.columnsFooterGroupAll = [
        {
          column: 'DB',
          sum: true,
          name: '',
          sumName: 'Total Débito',
          type: Type.Currency
        },
        {
          column: 'CR',
          sum: true,
          name: '',
          sumName: 'Total Crédito',
          type: Type.Currency
        }
      ];
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {Filter} from '@Components/ui/util/Filter';
import {AdfiService} from '@services/adfi-service';
import {AdfiUtil} from '@Components/util/adfi-util';

interface ItemPendingProcess {
  id: string;
  valueTransfer: string;
  description: string;
  consecutive: number;
}

interface PendingProcess {
  name: string;
  module: string;
  entity: string;
  loading: boolean;
  group: string;
  messageEmpty: boolean;
  valueTransfer: string;
  list: ItemPendingProcess[];
}

@Component({
  selector: 'app-pending-process',
  templateUrl: './pending-process.component.html',
  styleUrls: ['./pending-process.component.scss']
})
export class PendingProcessComponent implements OnInit {

  constructor(private graphqlService: AdfiGraphqlService, private adfiService: AdfiService) { }

  listPendingProcess: PendingProcess[] = null;

  private static createItemPendingProcess(id , valueTransfer, description, consecutive): ItemPendingProcess {
    return {
      id,
      valueTransfer,
      description,
      consecutive
    };
  }

  ngOnInit(): void {
    this.adfiService.get('/api/adfi/alert/process').subscribe((alerts: any[]) => {
      if (alerts) {
        const listPendingProcess = [];
        alerts.forEach( alert => {
          const pendingProcess = {
            name: alert.name,
            entity: alert.entity,
            module: alert.module,
            valueTransfer: '0',
            loading: true,
            group: alert.group,
            messageEmpty: alert.messageEmpty,
            list: []
          };
          listPendingProcess.push(pendingProcess);
          this.loadListPending(pendingProcess, alert.keyIdentifier, alert.keysDescription, alert.keysValue, alert.filters);
        });
        this.listPendingProcess = listPendingProcess;
      }
    });
  }

  private async loadListPending(process: PendingProcess,
                                keyIdentifier: string = 'consecutive',
                                keysDescription: string[],
                                keyValue: string[],
                                filters) {
    await this.getPendingProcess(process, `id ${keyIdentifier} ${keysDescription.join(' ')} ${keyValue.join(' ')}`,
        filters, (data, config) => {
        process.valueTransfer = this.calculateTotalFromList(data, keyValue);
        process.list = data.map(item =>
            PendingProcessComponent.createItemPendingProcess(item.id,
                keyValue.length === 1 ? item[keyValue[0]] : keyValue.reduce((v, k) => (+item[v] + +item[k]).toString()),
                keysDescription.map( desc => AdfiUtil.getColumnValue(item, config.columns.find(k => k.fieldName === desc))).join(' - '),
                AdfiUtil.getColumnValue(item, config.columns.find(k => k.fieldName === keyIdentifier))
            ));
        process.loading = false;
      /*this.listPendingProcess.set(priority, {
        name: nameEntity,
        valueTransfer: this.calculateTotalFromList(data, keyValue),
        list: data.map(item =>
            PendingProcessComponent.createItemPendingProcess(item.id,
                keyValue.length === 1 ? item[keyValue[0]] : keyValue.reduce((v, k) => (+item[v] + +item[k]).toString()),
                keysDescription.map( desc => AdfiUtil.getColumnValue(item, config.columns.find(k => k.fieldName === desc))).join(' - '),
                AdfiUtil.getColumnValue(item, config.columns.find(k => k.fieldName === keyIdentifier))))
      });
      this.listPendingProcess = new Map([...this.listPendingProcess].sort());*/
    });
  }

  /**
   * Generate total pending process
   * @param list list of pending process
   * @param key key to calculate total
   */
  private calculateTotalFromList(list: any[], key: string[]): string {
    let total = 0;
    list.forEach(item => {
      key.forEach(multiKey => {
        total += item[multiKey];
      });
    });
    return total.toFixed(2);
  }

  private async getPendingProcess(process: PendingProcess, columns: string, filters, next: (data: any, config: Filter) => void) {
    const error = (err: any) => {
      console.log(err);
    };
    const f = new Filter(this.adfiService);
    f.entityName = process.entity;
    f.module = process.module;
    f.group = process.group;
    f.defaultQuery = filters;
    f.loadedConfig.subscribe(async () => {
      const params: any = f.generateFilters();
      const count = await this.graphqlService.countEntity(f.configTable.plural, params);
      this.graphqlService.getEntity(f.configTable.plural, columns, count, params, {next: (data) => { next(data, f); }, error});
    });
    f.loadConfig();
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdfiService } from '@services/adfi-service';
import { SelectItemComponent } from '@Components/ui/select-item/select-item.component';

@Component({
  selector: 'app-third-by-town',
  templateUrl: './third-by-town.component.html',
})
export class ThirdByTownComponent implements OnInit {

  periods: any[];
  monthly = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];
  bimonthly = [
    'Enero - Febrero',
    'Marzo - Abril',
    'Mayo - Junio',
    'Julio - Agosto',
    'Septiembre - Octubre',
    'Noviembre - Diciembre',
  ];
  quaterly = [
    'Enero - Abril',
    'Mayo - Agosto',
    'Septiembre - Diciembre',
  ];
  towns = [  
    {code:'01', town:'Armenia'},  
    {code:'02', town:'Barranquilla'},  
    {code:'03', town:'Bogotá'},  
    {code:'04', town:'Bucaramanga'},  
    {code:'05', town:'Cali'},  
    {code:'06', town:'Cartagena'},  
    {code:'07', town:'Cúcuta'},  
    {code:'08', town:'Girardot'},  
    {code:'09', town:'Ibagué'},  
    {code:'10', town:'Manizales'},  
    {code:'11', town:'Medellín'},  
    {code:'12', town:'Montería'},  
    {code:'13', town:'Neiva'},  
    {code:'14', town:'Pasto'},  
    {code:'15', town:'Palmira'},  
    {code:'16', town:'Pereira'},  
    {code:'17', town:'Popayán'},  
    {code:'18', town:'Quibdó'},  
    {code:'19', town:'Santa Marta'},  
    {code:'20', town:'Tunja'},  
    {code:'21', town:'Túlua'},  
    {code:'22', town:'Villavicencio'},  
    {code:'23', town:'Sincelejo'},  
    {code:'24', town:'Valledupar'},  
    {code:'25', town:'Riohacha'},  
    {code:'26', town:'Sogamoso'},  
    {code:'27', town:'San Andrés'},  
    {code:'28', town:'Florencia'},  
    {code:'29', town:'Barrancabermeja'},  
    {code:'31', town:'Grandes Contribuyentes'},  
    {code:'32', town:'Bogotá'},  
    {code:'34', town:'Arauca'},  
    {code:'35', town:'Buenaventura'},  
    {code:'36', town:'Cartago'},  
    {code:'37', town:'Ipiales'},  
    {code:'38', town:'Leticia'},  
    {code:'39', town:'Maicao'},  
    {code:'40', town:'Tumaco'},  
    {code:'41', town:'Urabá'},  
    {code:'42', town:'Puerto Carreño'},  
    {code:'43', town:'Inírida'},  
    {code:'44', town:'Yopal'},  
    {code:'45', town:'Mitú'},  
    {code:'46', town:'Puerto Asís'},  
    {code:'48', town:'Cartagena'},   
    {code:'78', town:'San José de Guaviare'},    
    {code:'86', town:'Pamplona'},  
    {code:'87', town:'Barranquilla'},  
    {code:'88', town:'Cali'},  
    {code:'89', town:'Cúcuta'},  
    {code:'90', town:'Medellín'}
  ];
  currentPeriod: number;
  label: string | null = null;
  third: number;
  type: string;
  range: string;
  dates: string;
  town: string;
  help = 'Recuerde verificar la seccional de la DIAN a la que está inscrita la institución educativa. Esta información se encuentra en la casilla 12 del RUT institucional.';

  constructor(
    private service: AdfiService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.periods = this.service.periods.filter(period => period.year >= 2024);
  }

  selectItem() {
    let dialogRef: MatDialogRef<any>;
    const data = {
      module: "Adjustments",
      entityName: "ThirdByTown",
      action: "select",
      enableAdd: false,
      defaultFilters: [
        {
          column: "codTown",
          value: "centroCosto_coCentroCosto",
          filter: "userPropertyFilter"
        }
      ],
      customTitle: 'Seleccionar Terceros'
    };

    dialogRef = this.dialog.open(SelectItemComponent, {
      data,
      width: "80%",
      maxHeight: "70vh",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.third = result._id;
      this.label = `${result.nit} - ${result.names} ${result.firstSurname || ''}`;
    });
  }

  removeItem() {
    this.label = null;
    this.currentPeriod = null;
    this.town = null;
    this.type = null;
    this.range = null;
    this.dates = null;
  }

  clear() {
    this.currentPeriod = null;
    this.town = null;
    this.range = null;
    this.dates = null;
  }


  getAllFilters() {
    console.log(this.range);
    return {
      third: this.third,
      type: this.type,
      period: this.currentPeriod,
      town: this.town,
      dates: this.range === 'A'? 'A': this.dates
    };
  }

  download() {
    this.service.download(
      'Adjustments\\ThirdByTown',
      JSON.stringify(this.getAllFilters()),
      'fileName',
      `application/pdf`,
      `Certificado de Retención.pdf`,
      undefined,
      null
    );
  }

}

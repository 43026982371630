<form *ngIf="stylePicker === 'Legacy' else pik" class="row" [formGroup]="form">
    <mat-form-field class="col-12 col-md-6" *ngIf="params.displayStartDate === undefined || params.displayStartDate">
        <mat-label>{{customStartText}}</mat-label>
        <input matInput [min]="minDate" [max]="maxDate" readonly (click)="pickerStart.open()"
               [matDatepicker]="pickerStart" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart color="primary"></mat-datepicker>
        <mat-error *ngIf="form.get('startDate').invalid">El campo es obligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="col-12 {{ params.displayStartDate === undefined || params.displayStartDate ? 'col-md-6' : '' }}">
        <mat-label>{{customEndText}}</mat-label>
        <input matInput [min]="form.get('startDate').value" [max]="maxDate"
               readonly (click)="pickerEnd.open()"
               [matDatepicker]="pickerEnd" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd color="primary"></mat-datepicker>
        <mat-error *ngIf="form.get('endDate').invalid">El campo es obligatorio</mat-error>
    </mat-form-field>
</form>
<ng-template #pik>
    <mat-date-range-input [rangePicker]="rp" required  [min]="minDate" [max]="maxDate" [formGroup]="form" class="size-zero">
        <input matStartDate formControlName="startDate">
        <input matEndDate formControlName="endDate">
    </mat-date-range-input>
    <mat-date-range-picker #rp color="primary" [calendarHeaderComponent]="header">
        <mat-date-range-picker-actions>
            <div class="mt-4">
                <button mat-button matDateRangePickerCancel>Cancelar</button>
                <button mat-raised-button color="primary" matDateRangePickerApply (click)="onSubmit()">Continuar</button>
            </div>
        </mat-date-range-picker-actions>
    </mat-date-range-picker>
</ng-template>

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimalNumberDirective]'
})
export class DecimalNumberDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    let input = this.el.nativeElement as HTMLInputElement;
    let originalValue = input.value;
    let sanitizedValue = this.sanitizeDecimal(originalValue);
    input.value = sanitizedValue;

    if (originalValue !== sanitizedValue) {
      event.stopPropagation();
    }
  }

  private sanitizeDecimal(value: string): string {
    let sanitized = value.replace(/(?![0-9]*[.,]?[0-9])[^\d.,]/g, '');
    let parts = sanitized.split(/[.,]/);
    return parts.length > 1 ? parts[0] + '.' + parts.slice(1,parts.length).join(''):sanitized;
  }
}

import {Apollo} from 'apollo-angular';
import {Component, OnInit} from '@angular/core';
import {DateRangeSelectionComponent, ParamDate} from '@Components/date-range-selection/date-range-selection.component';

import {MatDialog} from '@angular/material/dialog';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';

@Component({
    selector: 'app-help-desk',
    templateUrl: './help-desk.component.html',
    styleUrls: ['./help-desk.component.css']
})
export class HelpDeskComponent implements OnInit {

    selectedDates: ParamDate;
    paramDates: ParamDate;
    color = 'rgba(103,58,183,0.65)';

    constructor(private graphql: Apollo,
                private matDialog: MatDialog,
                private adfiGrowlService: AdfiGrowlService,
                public service: AdfiService,
                private adfiGraphql: AdfiGraphqlService,
                public loading: LoadingService,
                iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        iconRegistry.addSvgIcon(
            'help-desk',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/informacion.svg'));
        iconRegistry.addSvgIcon(
            'calls',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/centro-de-llamadas.svg'));
    }

    ngOnInit() {
        this.paramDates = {
            startDate: this.selectedDates ? this.selectedDates.startDate : null,
            endDate: this.selectedDates ? this.selectedDates.endDate : null,
            usePeriod: false
        };
    }

    selectDates() {
        const dialog = this.matDialog.open(DateRangeSelectionComponent, {
            data: this.paramDates
        });
        dialog.componentInstance.btnText = 'Seleccionar';
        const subs = dialog.componentInstance.afterSelect.subscribe((dates: ParamDate) => {
            subs.unsubscribe();
            this.selectedDates = dates;
            dialog.close();
        });
    }

    getFilters() {
        return JSON.stringify({
            startDate: this.selectedDates.startDate.toUTCString(),
            endDate: this.selectedDates.endDate.toUTCString()
        });
    }

    exportReport(entity: string = 'HelpDesk\\Call', fileName: string = 'Llamadas') {
        if (!this.loading.active() && this.selectedDates) {
            this.service.download(entity, this.getFilters(),
                'fileName',
                'application/xlsx',
                `Reporte ${fileName} de ${this.selectedDates.startDate.toJSON().slice(0, 10)} a ${this.selectedDates.endDate.toJSON().slice(0, 10)}.xlsx`);
        } else {
            this.adfiGrowlService.warning('Advertencia', 'Primero seleccione un rango de fechas');
        }
    }

}

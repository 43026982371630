import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {DataEntity, Entities, ENUM_ACTIONS, Permission, TICK, TipoPresupuesto} from '@Interfaces/index';
import {TreePresupuestoComponent} from '@Components/presupuesto/tree-presupuesto/tree-presupuesto.component';
import { BudgetTableComponent } from '@Components/presupuesto/budget-table/budget-table.component';
import {ViewPresupuestoInicialComponent} from '@Components/presupuesto/view-presupuesto-inicial/view-presupuesto-inicial.component';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {LoadingService} from '@services/loading.service';
import {FormComponent} from '@Components/ui/form/form.component';
import {AdfiRest} from '@services/adfi-rest';
import {Subscription, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {AdfiUtil} from '@Components/util/adfi-util';
import { UIHelper } from '@Components/util/UIHelper';
import { DisplayItemComponent } from '@Components/ui/display-item/display-item.component';
import {Pages} from '@Interfaces/index';

@Component({
    selector: 'app-prmini-presupuesto',
    templateUrl: './prmini-presupuesto.component.html',
    styleUrls: ['./prmini-presupuesto.component.css']
})
export class PrminiPresupuestoComponent implements OnInit, OnDestroy {

    /**
     * Fuentes  of prmini presupuesto component
     */
    public fuentes: any[];

    /**
     * Presupuesto inicial of prmini presupuesto component
     */
    public presupuestoInicial: any;

     /**
     * Plan Anual de adquisiciones
     */
     public planAnualAdquisiciones: boolean;
     public filePAA: boolean;

     /**
     * Vigencia actual
     */
     public vigencia: any;
     /**
      * Presupuesto inicial of prmini presupuesto component
      */
     public message: any;

     public type: any;

    /**
     * Max height of prmini presupuesto component
     */
    public readonly maxHeight: number;

    /**
     * View child of prmini presupuesto component
     */
    @ViewChildren(TreePresupuestoComponent) presupuestos: QueryList<TreePresupuestoComponent>;


    @ViewChildren(BudgetTableComponent) presupuestosTable: QueryList<BudgetTableComponent>;

    public archivo: boolean;

    /**
     * Estado  of prmini presupuesto component
     */
    public estado: string;

    /**
     * Aprobar  of prmini presupuesto component
     */
    public aprobar: boolean;

    /**
     * Determines whether select pr fuente on
     */
    private onSelectPrFuente: boolean;

    counter: {
        counterStart: number;
        counterEnd: number;
        countDown: Subscription | null;
        countStart: Subscription | null;
    } = {
        counterStart: 1,
        counterEnd: 0,
        countDown: null,
        countStart: null,
    };
    period: number;

    constructor(
        public sLoading: LoadingService,
        private matDialog: MatDialog,
        private adfiGrowlService: AdfiGrowlService,
        private adfiService: AdfiService,
        private graphService: AdfiGraphqlService) {
        this.maxHeight = window.innerHeight - 240;
    }

    /**
     * Gets tipo
     */
    public get tipo() {
        return TipoPresupuesto;
    }

    /**
     * on init
     */
    ngOnInit() {
        this.sLoading.show();
        this.getTime();
    }

    reloadData() {
        const next = (d) => {
            if (Array.isArray(d)) {
                this.fuentes = d;
            } else {
                this.sLoading.hide();
                this.presupuestoInicial = d ? d : {};
                this.updatePresupuesto();
            }
        };
        const error = (e) => {
            this.sLoading.hide();
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphService.getEntity('prFuentesFinans', 'prfinVNombre', 5, null, {next, error});
        this.loadMiniPresu(next, error);
    }

    reloadBudget(reload: boolean) {
        const next = (d) => {
                this.presupuestoInicial = d ? d : {};
                this.updateObjectPresupuesto(this.presupuestoInicial);
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.loadMiniPresu(next, error);
    }

    getPAA(){
        this.graphService.getFirstEntity(
            'planAnualAdquisicions',
            'paaFile vigencia',
            `vigencia: ${this.period}, cencosFilter_cencos: ""`,
            {
                next: (response: any) => {
                    if (response) {
                            this.planAnualAdquisiciones= true;
                        if (response.paaFile !== null){
                            this.filePAA = true;
                        }else{
                            this.message = 'No se ha subido el archivo del Plan Anual de Adquisiciones para la vigencia ' + this.period;
                        }
                    }else if(this.vigencia.estado == 'C') {
                        this.message = `No se ha generado un Plan Anual de Adquisiciones para la vigencia ${this.adfiService.user.period}`;

                    }else if(this.vigencia.estado == 'A' ) {
                        this.message = `No se ha generado un Plan Anual de Adquisiciones para la vigencia ${this.period}`;
                    }
                },
                error: (error) => {
                    this.adfiGrowlService.errorMessage(error);
                }
            }
        );
    }

  getTime() {
    const next = (d) => {
        this.vigencia = this.adfiService.getUserPeriod();
        if(this.vigencia.estado == 'C') {
            this.period = this.vigencia.year;
        }else {
            this.period = d.period;
        }
        this.getPAA();
      this.counter.counterStart = this.adfiService.getCountDown(d.startDateRegister);
      if (this.counter.counterStart > 0) {
          this.counter.countStart = timer(0, TICK).subscribe(() => {
              --this.counter.counterStart;
              if (this.counter.counterStart <= 0) {
                  this.counter.countStart.unsubscribe();
                  this.endTime(d);
              }
          });
      } else {
          this.endTime(d);
          if (this.counter.counterEnd <= 0) {
              const valid = (dates) => {
                  if (dates.length) {
                      const param = JSON.parse(dates[0]);
                      const end = new Date(param.endDate);
                      this.counter.counterEnd = (end.getTime() / 1000) - ((new Date()).getTime() / 1000);
                      if (this.counter.counterEnd > 0) {
                          this.counter.countDown = timer(0, TICK).subscribe(() => --this.counter.counterEnd);
                      }
                  }
              };
              this.graphService.loadAlerts('ExeptionAvalibleDate', 'getExceptionRegisterFor', `"3", "InitialBudget", "${this.adfiService.user.centroCosto._id}", "${new Date().getMonth() + 1}"`, valid);
          }
      }
      this.reloadData();
    };
    const error = (e) => {
      this.sLoading.hide();
      this.adfiGrowlService.errorMessage(e);
    };
    this.adfiService.getTimeControl('InitialBudget', next, error);
  }

    getColorTime() {
        const days = (this.counter.counterEnd / 86400);
        if (days < 7) {
            return 'red';
        } else if (days < 15) {
            return 'orange';
        }
        return 'green';
    }

    loadMiniPresu(next: (d) => void, error: (e) => void) {
        this.graphService.getFirstEntity('presupuestoInicials',
            'fechaAcuerdo numeroAcuerdo estado archivo fileName vigencia',
            `centroCosto_id: ${this.adfiService.user.centroCosto._id}, vigencia: "${this.period}"`, {next, error});
    }


    updateObjectPresupuesto(val?: any) {
        if (val) {
            this.presupuestoInicial = Object.assign(this.presupuestoInicial, val);
        }

        if (!this.presupuestoInicial) {
            return;
        }

        this.archivo = this.presupuestoInicial.estado === 'A'
            && this.adfiService.validPermission(Entities.INITIAL_BUDGET, Permission.DOWNLOAD);

        switch (this.presupuestoInicial.estado) {
            case 'A':
                this.estado = 'Aprobado';
                break;
            case 'P':
                this.estado = 'Pendiente aprobación';
                break;
            case 'C':
                this.estado = 'Cargado';
                break;
            case 'X':
                this.estado = 'Cerrado';
                break;
            default:
                this.estado = 'Inicial';
                break;
        }

        this.aprobar = this.adfiService.validPermission(Entities.INITIAL_BUDGET, Permission.EDIT) &&
            !this.adfiService.validPermission(Entities.INITIAL_BUDGET, Permission.APPROVE);
        if (this.aprobar) {
            this.aprobar = !this.presupuestoInicial.id || ['I', 'P'].indexOf(this.presupuestoInicial.estado) >= 0;
        }
    }

    private updatePresupuesto(val?: any) {
        this.updateObjectPresupuesto(val);
        if (this.onSelectPrFuente) {
            for (const c of this.presupuestos.toArray()) {
                c.update();
            }
        }
    }

    public openVideoPresupuesto(){
        const URL = '/nav/help/' + Pages.VIDEOS + '?video=PI';
        window.open(URL);
    }

    /**
     * Views verificacion
     */
    public viewVerificacion() {
        if( this.presupuestoInicial.vigencia ) {
            const minWidth = window.innerWidth > 1000 ? window.innerWidth / 2 : '90%';
            const maxHeight = window.innerHeight - 100;
            const dialog = this.matDialog.open(ViewPresupuestoInicialComponent, {data: this.presupuestoInicial, minWidth, maxHeight});
            const subsSave = dialog.componentInstance.saved.subscribe((pi: any) => {
                this.updatePresupuesto(pi);
                dialog.close();
                
            });
            const subsForm = dialog.afterClosed().subscribe(() => {
                subsForm.unsubscribe();
                subsSave.unsubscribe();
            });
        }else{
            this.adfiGrowlService.warning("Alerta","Todavía no se ha diligenciado el presupuesto inicial.");
        }
    }

    /**
     * Views presupuesto
     */
    public viewPrInicial() {
        this.sLoading.show();
        this.graphService.getFirstEntity(
            'pAAAdquisicions',
            'vigencia',
            `vigencia: ${this.period}, cencosFilter_cencos: ""`,
            {
                next: async (response: any) => {
                    if (response) {
                        const value = await this.sumValueBudget();
                        const messageAcquisitionDetail = await this.validateAcquisitionDetails();
                        if (value > 0) {
                            this.sLoading.hide();
                            this.adfiGrowlService.error('Error', 'No se puede solicitar la aprobación debido a que el Plan Anual de Adquisiciones se encuentra desbalanceado.');
                        } else if(Array.isArray(messageAcquisitionDetail) && messageAcquisitionDetail.length > 0){
                            this.sLoading.hide();
                            messageAcquisitionDetail.forEach((item) => {
                                this.adfiGrowlService.error('Error',item);
                            });
                        }else {
                            this.sLoading.hide();
                            const minWidth = window.innerWidth > 1000 ? window.innerWidth / 2 : '90%';
                            const dialog = this.matDialog.open(FormComponent,
                                {
                                    data: {
                                        module: 'Presupuesto',
                                        entityName: 'PresupuestoInicial',
                                        action: ENUM_ACTIONS.EDIT,
                                        item: this.presupuestoInicial.id,
                                        returnDataAfterSave: true
                                    } as DataEntity,
                                    minWidth, maxHeight: '70vh'
                                });
                            const subsSave = dialog.componentInstance.dialogRef.afterClosed().subscribe((data) => {
                                if (data) {
                                    const next = (mini) => {
                                        this.updatePresupuesto(mini);
                                    };
                                    const error = (e) => {
                                        this.adfiGrowlService.errorMessage(e);
                                    };
                                    this.loadMiniPresu(next, error);
                                    if (data.consejoDirectivo && data.consejoDirectivo.length) {
                                        let index = 1;
                                        for (const d of data.consejoDirectivo) {
                                            this.graphService.updateEntity('ConsejoDirectivo', {
                                                id: d,
                                                orderAdded: index
                                            }, undefined, { next: () => { }, error: () => { } });
                                            index++;
                                        }
                                    }
                                }
                                subsSave.unsubscribe();
                            });
                        }
                    }else {
                        this.sLoading.hide();
                        this.adfiGrowlService.error('Error','No se puede soliticar aprobación sin haber diligenciado el plan anual de adquisiciones.');
                    }
               
                },
                error: (error) => {
                    this.adfiGrowlService.errorMessage(error);
                }
            }
        );       
    }   

    public selectPrFuente(id: number) {
        for (const c of this.presupuestos.toArray()) {
            c.selectPrFuente(id);
        }
        this.onSelectPrFuente = true;
    }

    public descargarArchivo() {
        AdfiUtil.downloadMediaObject(this.presupuestoInicial.archivo, { fieldName: '', options: {bucket: 'files_initial_budget'}},
            this.period);
    }

    public archivoAcuerdo() {
        if(this.filePAA) {
            const dialog = this.matDialog.open(FormComponent, {
                data: {
                    entityName: 'PresupuestoInicial',
                    module: 'Presupuesto',
                    group: 'uploadPresu',
                    item: this.presupuestoInicial.id,
                    customTitle: 'Subir Presupuesto Inicial',
                    customActionBtnText: 'Subir',
                    action: ENUM_ACTIONS.EDIT,
                } as DataEntity,
                minWidth: '50%'
            });
            const subs = dialog.afterClosed().subscribe((event) => {
                this.reloadData();
                this.sLoading.hide();
            });
        }else {
            this.adfiGrowlService.error('Error','No se puede subir el archivo de acuerdo sin haber cargado el plan anual de adquisiciones.');
        }
    }

    /**
     * estadoAPendiente
     */
    public estadoAPendiente() {
        const message = this.presupuestoInicial.archivo
            ? `Se borrará el archivo cargado y el estado se actualizará a "Pendiente"`
            : 'Se actualizará el estado a "Pendiente"';
        const dialog = this.matDialog.open(ConfirmDialogComponent,
            {data: {title: 'Seguro que desea continuar?', content: message, action: ENUM_ACTIONS.EDIT} as ConfirmDialogData});

        const callConfirm = (val) => {
            subs.unsubscribe();
            if (!val) {
                return;
            }
            this.cambioEstadoAPendiente();
        };
        const subs = dialog.afterClosed().subscribe(callConfirm);
    }

    /**
     * Cambios estado apendiente
     */
    private cambioEstadoAPendiente() {
        this.sLoading.show();
        const callCambioEstado = (updatedEntity) => {
            this.sLoading.hide();
            this.adfiGrowlService.success('Cambio estado', 'Actualizado correctamente a Pendiente');
            this.updatePresupuesto(updatedEntity);
        };
        const callCambioEstadoError = (error) => {
            this.sLoading.hide();
            this.adfiGrowlService.errorMessage(error);
        };
        const form = {id: this.presupuestoInicial.id, estado: 'I'};
        this.graphService.updateEntity('PresupuestoInicial', form,
            'fechaAcuerdo numeroAcuerdo estado archivo fileName',
            {next: callCambioEstado, error: callCambioEstadoError});
    }

    print() {
        this.sLoading.show();
        this.adfiService.get(`/api/adfib/report?report=PRESUPUESTO_INICIAL`, undefined,
            'arraybuffer').subscribe((data) => {
            AdfiService.downLoadFile(data, 'application/pdf', 'report.pdf');
            this.sLoading.hide();
        }, () => {
            this.sLoading.hide();
            this.adfiGrowlService.error('Reporte de presupuesto inicial',
                ' Se ha presentado un error al generar el reporte, por favor contacta a soporte técnico'
            );
        });
    }

    isEditable() {
        return this.adfiService.hasRole(182) || this.adfiService.hasRole(420);
    }

    ngOnDestroy() {
        if (this.counter.countStart) {
            this.counter.countStart.unsubscribe();
        }
        if (this.counter.countDown) {
            this.counter.countDown.unsubscribe();
        }
    }

    private endTime(period: any) {
        this.counter.counterEnd = this.adfiService.getCountDown(
            period.endDateRegister
        );
        this.counter.countDown = timer(0, TICK).subscribe(
            () => --this.counter.counterEnd
        );
        if (this.counter.counterEnd <= 0) {
            this.counter.countDown.unsubscribe();
        }
    }

    getObservations() {
        const next = (d) => {
            if (d!== undefined) {
                const mostRecentHistory = d.validationFormHistories.edges.reduce(
                    (mostRecent, current) => {
                      const currentDate = Date.parse(current.node.creationSysDate);
                      const mostRecentDate = Date.parse(mostRecent.node.creationSysDate);
                      return currentDate > mostRecentDate ? current : mostRecent;
                    }
                  );
                const next = (x) => {
                    this.sLoading.hide();
                    UIHelper.openDialog(DisplayItemComponent, this.matDialog, {
                        entityName: "ValidationFormHistory",
                        module: "Presupuesto",
                        group: undefined
                    } as DataEntity, ENUM_ACTIONS.VIEW, x.id);
                };
                const error = (e) => {
                    this.sLoading.hide();
                    this.adfiGrowlService.errorMessage(e);
                };
                this.graphService.getFirstEntity('validationFormHistories',
                    'validDealObs',
                    `id: ${mostRecentHistory.node._id}`, {next, error});    
            }else{
                this.sLoading.hide();
                this.adfiGrowlService.warning("Alerta","Todavía no hay observaciones que mostrar");
            }
            
            
        };
        const error = (e) => {
            this.sLoading.hide();
            this.adfiGrowlService.errorMessage(e);
        };
        this.sLoading.show();
        this.graphService.getFirstEntity('validationForms',
            'validationFormHistories {edges {node {  id  _id  creationSysDate  statusForm  observation  __typename}__typename  }  __typename}',
            `cencosFilter_centroCosto: "${this.adfiService.user.centroCosto._id}" , vigencia: ${this.period}`, {next, error});
    }

    getAllFilters() {
        return {
          cencos: this.adfiService.user.centroCosto._id,
          period: this.period
        };
      }

    downloadPi(){
        this.adfiService.download(
            'PresupuestoInicial',
            JSON.stringify(this.getAllFilters()),
            'fileName',
            `application/xlsx`,
            `Presupuesto Inicial_${this.adfiService.user.centroCosto.deDescripcion}_${this.period}.xlsx`,
            undefined ,
            null
        );
    }

    sumValueBudget(): Promise<number> {
        return this.adfiService.get<number>(AdfiRest.sumValuePAA())
            .pipe(
                map((response) =>  {
                    if (response) {
                        return response;
                    }
                    return 0;
                })
            ).toPromise();
    }

    validateAcquisitionDetails(): Promise<number> {
        return this.adfiService.get<number>(AdfiRest.validateAcquisitionDetails())
            .pipe(
                map((response) =>  {
                    if (response) {
                        return response;
                    }
                    return 0;
                })
            ).toPromise();
    }

}

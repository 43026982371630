<div class="container pb-5">
    <div class="row pt-2">
        <mat-card class="col-12 mb-2">
            <mat-card-title>Reportes de Contraloria</mat-card-title>
            <mat-card-content>
                <mat-card>
                    <mat-card-title class="text-center">
                        Centros de costo seleccionados
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row pt-2">
                            <div class="col-12">
                                <div class="row justify-content-center">
                                    <div class="col-12 col-md-5">
                                        <div class="row justify-content-center">
                                            <mat-form-field appearance="fill" class="w-75">
                                                <mat-label>Desde</mat-label>
                                                <mat-select  [(ngModel)]="startCenter">
                                                    <mat-option>Selección</mat-option>
                                                    <mat-option *ngFor="let center of allCenters" [value]="center._id" [disabled]="endCenter < center._id">{{ center.municipio.nombre }} - {{center.deDescripcion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-5">
                                        <div class="row justify-content-center">
                                            <mat-form-field appearance="fill" class="w-75">
                                                <mat-label>Hasta</mat-label>
                                                <mat-select [(ngModel)]="endCenter">
                                                    <mat-option>Selección</mat-option>
                                                    <mat-option *ngFor="let center of allCenters" [value]="center._id" [disabled]="startCenter>center._id">{{ center.municipio.nombre }} -  {{center.deDescripcion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-1 pt-2">
                                        <div class="d-flex justify-content-center">
                                            <button mat-raised-button class="btn-actions" color="primary" (click)="rp.picker.open()">
                                                <mat-icon>date_range</mat-icon>
                                                <app-date-range-picker #rp stylePicker="Advanced" [usePeriod]="true"></app-date-range-picker>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <div class="row d-flex justify-content-center">
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateBudgets()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Presupuesto</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generatePAAs()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Plan Anual de Adquisiciones</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generatePolice()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Polizas</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateModifications()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Modificaciones</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateF04()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>F04: Pólizas aseguramiento</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateF11()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>F11: Cuentas por pagar</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateExtracts()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Extractos bancarios</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateRut()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>RUT</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateCC()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Cedulas</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateApointment()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Acto Administrativo nombramiento</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateRetentions()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Retenciones</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateClosingForms()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Formulario de Cierre</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color"
                            (click)="generateIncomes()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%208.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Relación Ingresos</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color" (click)="generateExpenses()">
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%202.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Relación Egresos</h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                             [matRippleColor]="color" (click)="generateBudgetaryExecution()" >
                            <div class="flex-row">
                                <img class="icon" src="assets/images/Recurso%203.svg">
                            </div>
                            <div class="flex-row">
                                <h4>Ejecución Presupuestal</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row d-flex justify-content-center">
                                <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                                     [matRippleColor]="color" (click)="generateBalance()">
                                    <div class="flex-row">
                                        <img class="icon" src="assets/images/Recurso%204.svg">
                                    </div>
                                    <div class="flex-row">
                                        <h4>Balance consolidado</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 mat-elevation-z5 icon-container d-flex flex-column align-items-center" matRipple
                                     [matRippleColor]="color" (click)="generateAll()">
                                    <div class="flex-row">
                                        <img class="icon" src="assets/images/Recurso%201.svg">
                                    </div>
                                    <div class="flex-row">
                                        <h4>Descargar todos</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="over-message" *ngIf="downloadedFiles">
    Archivos descargados {{downloadedFiles}}
</div>
<div class="over-message" *ngIf="generatingZip">
    Comprimiendo archivos {{ percentage }}%
</div>
<a class="mat-flat-button" target="_blank" style="text-align: right; text-decoration: none; font-size: 8px; float: right; margin-bottom: 1px"  href='https://www.freepik.com/vectors/coffee'>Coffee vector created by macrovector - www.freepik.com</a>

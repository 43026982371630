import { Pages } from '@Interfaces/index';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdfiGrowlService } from '@services/adfi-growl.service';


@Component({
  selector: 'app-dialog-assets',
  templateUrl: './dialog-assets.component.html',
  styleUrls: ['./dialog-assets.component.scss']
})
export class DialogAssetsComponent implements OnInit {

  formulario: FormGroup;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogAssetsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private growl: AdfiGrowlService) { 
    

  }

  ngOnInit(): void {
  }

  purchase(){
    try {
      const queryParams = this.data.info
      console.log(queryParams);
      
      if (queryParams!=null || queryParams!=undefined) {
        this.router.navigate(['/nav',Pages.MOVEMENT_ASSETS, Pages.MOVEMENT_ASSETS_FORM_ADD, queryParams._id], {
          state:{
            data: this.data.info
          }})
      }
      
    } catch (error) {
      this.growl.errorMessage(error, 'Error al cargar rp');
    }
  }

  services(){
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

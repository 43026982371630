import {Component, Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DataEntity, Entity} from '@Interfaces/index';

@Component({
    selector: 'app-select-item',
    templateUrl: './select-item.component.html',
    styleUrls: ['./select-item.component.css']
})
export class SelectItemComponent {

    @ViewChild('list') private list;
    get entity(): Entity {
        return this.list ? this.list.configTable : null;
    }

    constructor(public dialogRef: MatDialogRef<SelectItemComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DataEntity) {
    }

    suggestionWasClicked(clickedEntry: any): void {
        this.dialogRef.close(clickedEntry);
    }
}

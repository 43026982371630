import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThirdByTownComponent } from './third-by-town.component';
import { NavRoute } from 'src/app/utils/menu';
import { Entities, Pages, PeriodState } from '@Interfaces/index';

export const ThirdRoutes: NavRoute[] = [
  { 
    path: Pages.WITHHOLDING_CERTIFICATES,
    nav: { title: 'Certificaciones Tributarias', icon: 'post_add' },
    component: ThirdByTownComponent,
    entity: 'ThirdByTown',
    permission:'WithholdingCertificate',
    periodSate: PeriodState.NO_STATUS
  }
];

@NgModule({
  imports: [RouterModule.forChild(ThirdRoutes)],
  exports: [RouterModule]
})
export class ThirdByTownRoutingModule { }
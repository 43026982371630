<div class="row justify-content-end">
    <div class="row justify-content-start d-flex align-items-center ml-3">
        <h6 class="pl-3 d-inline">Seleccionar Fuente: </h6>
        <half-circle-menu tooltip="{{filters | json}}" tooltipPosition="above" class="mx-md-2" content="*"
                        [(value)]="filters"  [multiple]="true" [options]="parameters" (selectionChange)="reloadData()">
        </half-circle-menu>
    </div>
    <div class="col text-right">
      <button
        class="btn-action-currency"
        color="primary"
        mat-button
        matSuffix
        mat-icon-button
        (click)="reloadData()"
        matTooltip="Actualizar"
        [disabled]="loading "
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

<mat-tab-group class="col-12 w">
    <mat-tab label="Ingresos">
        <mat-progress-bar
            *ngIf="loading"
            class="col-12"
            mode="indeterminate"
        ></mat-progress-bar>
        <table mat-table [dataSource]="income" class="mat-elevation-z8">
            <ng-container matColumnDef="budget-item">
                <th mat-header-cell *matHeaderCellDef>Rubro</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [ngClass]="getClass(element.descompuesto, 'I')"
                >
                    {{ element.minipresu? element.minipresu : element.MINIPRESU }} 
                </td>
            </ng-container>

            <ng-container matColumnDef="budget-name">
                <th mat-header-cell *matHeaderCellDef>Nombre</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="getClass(element.descompuesto, 'I')"
                >
                  <span>{{ element.nombre ? element.nombre : element.NOMBRE }}</span>
                  <mat-icon *ngIf="element.minipresu && element.descriptionHelp" class="icon-help" [matTooltip]="element.descriptionHelp" style="float: right;">help_outline</mat-icon>
                </td>
              </ng-container>
              
            

            <ng-container matColumnDef="budget-value">
                <th mat-header-cell *matHeaderCellDef>Valor</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [ngClass]="getClass(element.descompuesto, 'I')"
                >
                    <div>
                        <div
                            *ngIf="!isEditing || currentEditElement !== element"
                            style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            "
                        >
                            <span>{{ (element.valor ? element.valor : element.VALOR) | currency }}</span>
                            <button
                                mat-icon-button
                                *ngIf="!element.estadoPresupuesto &&
                                    (element.descompuesto == 'N' ||
                                    element.descompuesto == 'V') && edit && externalPermission
                                "
                                (click)="editElement(element)"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>

                        <div
                            *ngIf="isEditing && currentEditElement === element"
                        >
                            <mat-form-field appearance="legacy">
                                <span matPrefix class="prefix-inp-form">$</span>
                                <input
                                    matInput
                                    appCurrencyDirective
                                    (valueChange)="element.valor = $event"
                                    required
                                />
                            </mat-form-field>

                            <button
                                class="btn-action-currency"
                                mat-button
                                matSuffix
                                mat-icon-button
                                matTooltip="Guardar"
                                (click)="save(element)"
                            >
                                <mat-icon>save</mat-icon>
                            </button>
                            <button
                                class="btn-action-currency"
                                mat-button
                                matSuffix
                                mat-icon-button
                                matTooltip="Cancelar"
                                (click)="cancelEdit()"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-tab>

    <mat-tab label="Egresos">
        <mat-progress-bar
            *ngIf="loading"
            class="col-12"
            mode="indeterminate"
        ></mat-progress-bar>
        <table mat-table [dataSource]="bill" class="mat-elevation-z8">
            <ng-container matColumnDef="budget-item">
                <th mat-header-cell *matHeaderCellDef>Rubro</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [ngClass]="getClass(element.descompuesto, 'E')"
                >
                    {{ element.minipresu? element.minipresu : element.MINIPRESU }} 
                </td>
            </ng-container>

            <ng-container matColumnDef="budget-name">
                <th mat-header-cell *matHeaderCellDef>Nombre</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="getClass(element.descompuesto, 'E')"
                >
                  <span>{{ element.nombre ? element.nombre : element.NOMBRE }}</span>
                  <mat-icon *ngIf="element.minipresu && element.descriptionHelp" class="icon-help" [matTooltip]="element.descriptionHelp" style="float: right;">help_outline</mat-icon>
                </td>
              </ng-container>

            <ng-container matColumnDef="budget-value">
                <th mat-header-cell *matHeaderCellDef>Valor</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [ngClass]="getClass(element.descompuesto, 'E')"
                >
                    <div>
                        <div
                            *ngIf="!isEditing || currentEditElement !== element"
                            style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            "
                        >
                            <span>{{ (element.valor ? element.valor : element.VALOR) | currency }}</span>
                            <button
                                mat-icon-button
                                *ngIf="!element.estadoPresupuesto &&
                                    (element.descompuesto == 'N' ||
                                    element.descompuesto == 'V')
                                "
                                (click)="editElement(element)"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>

                        <div
                            *ngIf="isEditing && currentEditElement === element"
                        >
                            <mat-form-field appearance="legacy">
                                <span matPrefix class="prefix-inp-form">$</span>
                                <input
                                    matInput
                                    appCurrencyDirective
                                    (valueChange)="element.valor = $event"
                                    required
                                />
                            </mat-form-field>

                            <button
                                class="btn-action-currency"
                                mat-button
                                matSuffix
                                mat-icon-button
                                matTooltip="Guardar"
                                (click)="save(element)"
                            >
                                <mat-icon>save</mat-icon>
                            </button>
                            <button
                                class="btn-action-currency"
                                mat-button
                                matSuffix
                                mat-icon-button
                                matTooltip="Cancelar"
                                (click)="cancelEdit()"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-tab>
</mat-tab-group>

import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {TREE_ACTIONS} from '@services/accounts.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {TitleCasePipe} from '@angular/common';
import {TreeAccountComponent} from '@pages/adjustment/tree-account/tree-account.component';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AdfiService} from '@services/adfi-service';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {SelectItemComponent} from '@Components/ui/select-item/select-item.component';
import {DataEntity} from '@Interfaces/index';
import {AdfiGrowlService} from '@services/adfi-growl.service';
export interface CompAdjDoc {
  document: any;
  adjustment?: any;
}
const ACCOUNT = 'account';
@Component({
  selector: 'app-movement',
  templateUrl: './movement.component.html',
  styleUrls: ['./movement.component.css']
})
export class MovementComponent implements OnInit {
  documentForm: FormGroup;
  nature = [
      {label: 'Debito',
        value: 'D'
      },
    {label: 'Credito',
      value: 'C'
    }];
  third: any;
  value: number;
  accounts: any[];
  loadingAccount: boolean;
  accountsObs: Observable<any[]>;
  @Input() tittle: string;
  @Output() afterSave = new EventEmitter<any>();
  private form: FormGroupDirective;
  showRecl = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: CompAdjDoc,
              public loading: LoadingService,
              private matDialog: MatDialog,
              private service: AdfiService,
              private adfiGraphql: AdfiGraphqlService,
              private titleCasePipe: TitleCasePipe,
              private alert: AdfiGrowlService) {
  }
  @Input() set onSave(value: boolean) {
    if (value) {
      this.ngOnInit();
      this.form.resetForm();
      this.updateThird(this.third);
    }
  }

  ngOnInit() {
    if (this.data.document) {
      this.documentForm = new FormGroup({
        nature: new FormControl(this.data.adjustment ? this.data.adjustment.nature : this.nature[0].value, Validators.required),
        value: new FormControl(this.data.adjustment ? this.data.adjustment.value : '', Validators.required),
        third: new FormControl('', Validators.required),
        description: new FormControl(this.data.adjustment ? this.data.adjustment.description : '', Validators.required),
        account: new FormControl('', [Validators.required, this.accountValidator])
      });
      if (this.data.adjustment) {
        if (this.data.adjustment.third) {
          this.updateThird(this.data.adjustment.third);
        }
        this.documentForm.get('account').setValue(this.data.adjustment.account);
      }
    }
  }

  private accountValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (typeof control.value !== 'object') {
      return { account : false };
    }
    return null;
  }

  onEnterSearch(input, v) {
    if (!this.loadingAccount) {
      if (typeof v === 'string') {
        if (v.length < 4 || v.length > 14) {
          this.alert.info('Incorrecto', 'Se necesitan mínimo 4 ó máximo 14 digitos para la busqueda');
          return;
        }
      } else {
        if (v && v.minicode && v.minicode.trim()) {
          return;
        }
      }
      if (typeof input === 'boolean') {
        this.showRecl = input;
      }
      this.loadAccounts(input);
    }
  }

  validateQuery(minicode: string) {
    let query = '';
    let i;
    let level;
    for (i = 0, level = 0; level < TREE_ACTIONS.length; i += TREE_ACTIONS[level].digits, level++) {
      if (i + TREE_ACTIONS[level].digits > minicode.length) {
        break;
      }
      query += `${TREE_ACTIONS[level].action}: "${minicode.substr(i, TREE_ACTIONS[level].digits)}", `;
    }
    let field;
    const otherFilter = minicode.startsWith('111005') || minicode.startsWith('111006');
    if (!otherFilter && level > 5) {
      return undefined;
    }
    if (level > 3 && otherFilter) {
      field = 'minicode';
      query = `minicode: "${minicode}"`;
      if (this.showRecl) {
        query += `, aux2: "99"`;
      } else {
        query += `, cencos: "${this.data.document.cencos._id}"`;
      }
    } else {
      field = TREE_ACTIONS[level].action;
      query += `${TREE_ACTIONS[level].depend}: "${TREE_ACTIONS[level].value}"`;
    }
    return [query, field];
  }

  async loadAccounts(input) {
    this.documentForm.controls[ACCOUNT].disable();
    this.loadingAccount = true;
    let minicode = this.documentForm.controls[ACCOUNT].value;
    if (typeof minicode !== 'string') {
      let level;
      let digits = 0;
      for (level = 0; level < TREE_ACTIONS.length && !minicode[TREE_ACTIONS[level].action]; level++) {
        digits += TREE_ACTIONS[level].digits;
      }
      minicode = minicode._id.toString().substr(0, digits + TREE_ACTIONS[level].digits);
    }
    const params = this.validateQuery(minicode);
    if (params) {
      const next = (v) => {
        if (v && v[0] && (!v[0].minicode || !v[0].minicode.trim())) {
          v.shift();
        }
        this.accounts = v;
        if (!this.accounts) {
          this.alert.info('No encontrado', 'No se encontraron cuentas con el parametro de busqueda proporcionado');
        }
        this.loadingAccount = false;
        this.documentForm.controls[ACCOUNT].enable();
        if (typeof input !== 'boolean') {
          input.focus();
        }
        this.accountsObs = this.documentForm.controls[ACCOUNT].valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value ? value._id : ''),
                map(name => name ? this._filter(name) : this.accounts.slice())
            );
      };
      const error = (e) => {
        this.loadingAccount = false;
        this.documentForm.controls[ACCOUNT].enable();
        this.alert.errorMessage(e, 'Error al cargar las cuentas');
      };
      const filter = `${params[0]}, order: { ${params[1] }: "ASC" }`;
      const count = await this.adfiGraphql.countEntity('accounts', filter);
      this.adfiGraphql.getEntity('accounts', `${params[1] !== 'minicode' ? params[1] : ''} minicode description type`,
          count, filter, {next, error});
    } else {
      this.loadingAccount = false;
      this.documentForm.controls[ACCOUNT].enable();
      this.alert.info('No disponible', 'Ya se encontro la ultima cuenta posible de acuerdo a las cuentas seleccionadas');
    }
  }

  displayFn(v: any) {
    if (!v || typeof v === 'string') {
      return '';
    }
    if (v && v.minicode && v.minicode.trim()) {
      return v.minicode;
    }
    let level;
    let digits = 0;
    for (level = 0; level < TREE_ACTIONS.length && !v[TREE_ACTIONS[level].action]; level++) { digits += TREE_ACTIONS[level].digits; }
    return v._id.toString().substr(0, digits + TREE_ACTIONS[level].digits);
  }

  displaySearchCode(v: any) {
    let level;
    let digits = 0;
    for (level = 0; level < TREE_ACTIONS.length && !v[TREE_ACTIONS[level].action]; level++) { digits += TREE_ACTIONS[level].digits; }
    const idStr = String(v._id);
    if (level < TREE_ACTIONS.length) {
      return [idStr.substr(0, digits), idStr.substr(digits, TREE_ACTIONS[level].digits)];
    }
    let current = this.documentForm.get('account').value;
    current = typeof current === 'string' ? current : this.displayFn(this.documentForm.get('account').value);
    for (level = 0; level < current.length && current[level] === idStr[level]; level++) { }
    return [idStr.substr(0, level), idStr.substr(level)];
  }

  private _filter(value: string): any[] {
    const filterValue = value;
    return this.accounts.filter(option => option._id.toString().includes(filterValue));
  }

  selectThird() {
    const c = this.matDialog.open(SelectItemComponent, { data: {
      entityName: 'ThirdByTown', module: 'Adjustments',
        defaultFilters: [
          {column: 'codTown', value: this.data.document.cencos._id.toString().substr(0, 5), filter: 'numericFilter'}
        ]
      } as DataEntity, minWidth: '80%' });
    const select = (row) => {
      if (row) {
        this.updateThird(row);
      }
      c.close();
      subs.unsubscribe();
    };
    const subs = c.afterClosed().subscribe(select);
  }

  private updateThird(row) {
    this.third = row;
    this.documentForm.get('third').setValue(this.titleCasePipe.transform(this.third.names + ' ' + this.third.firstSurname));
  }

  selectAccount() {
    const dialog = this.matDialog.open(TreeAccountComponent, { height: '80vh', maxHeight: '80vh', minWidth: '70%'});
    const subs = dialog.componentInstance.afterSave.subscribe((e: string) => {
      if (e) {
        this.documentForm.get('account').setValue(e);
        dialog.close();
      }
      subs.unsubscribe();
    });
  }

  onSubmit(form: FormGroupDirective) {
    this.form = form;
    if (typeof this.documentForm.get('account') === 'string') {
      this.alert.error('Error', 'No ha seleccionado una cuenta valida');
    } else {
      const adj = this.documentForm.value;
      adj.third = this.third;
      if ( this.data.adjustment) {
        adj.id = this.data.adjustment.id;
      }
      adj.value = this.value;
      this.afterSave.emit(adj);
    }
  }

  clearThird() {
    this.third = undefined;
    this.value = 0;
    this.documentForm.get('third').reset();
  }
}

import {
  Component,
  OnInit
} from '@angular/core';
import { Action, DataEntity, Property } from '@Interfaces/index';
import { AdfiGraphqlService } from '@services/adfi-graphql.service';
import { AdfiGrowlService } from '@services/adfi-growl.service';
import { AdfiService } from '@services/adfi-service';
import { LoadingService } from '@services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { AdfiUtil } from '@Components/util/adfi-util';
import {ListComponent} from '@Components/ui/list/list.component';
import { UIActionsHandler } from '@Components/util/UIActionsHandler';
import { NAME_ENTITY } from 'src/app/config/constants';

@Component({
  selector: 'app-previous-annual-acquisitions-plan',
  templateUrl: './previous-annual-acquisitions-plan.component.html',
  styleUrls: ['./previous-annual-acquisitions-plan.component.scss']
})
export class PreviousAnnualAcquisitionsPlanComponent implements OnInit {

  period: number;
  paa: any;
  public vigencia: number;
  public estadoVigencia: any;
  constructor(
    private loadingService: LoadingService,
    private graphqlService: AdfiGraphqlService,
    private adfiService: AdfiService,
    private adfiGrowlService: AdfiGrowlService,
    private dialog: MatDialog,
    private graphService: AdfiGraphqlService
  ) { }

  ngOnInit(): void {
    this.loadingService.show();
  }

  ngAfterViewInit(): void {
    this.loadingService.show();
    this.getVigencia();
  }

  getVigencia() {
    this.graphService.getFirstEntity(NAME_ENTITY.PeriodsPlatform.plural,
      'year estado',
      `year: ${this.adfiService.user.period}`, {
      next: (response: any) => {
        if(response.estado == 'A') {
          this.period = response.year;
          this.checkPAA(response.year);
        }else  {
          this.loadingService.hide();
        }
        this.estadoVigencia = response.estado;
      },
      error: (error) => {
        this.adfiGrowlService.errorMessage(error);
      }
    });
  }
  /**
   *  This functions checks if the PAA is created for this period, if it
   *  doesn't then it sends the signal to create it in the backend.
   */
  private checkPAA(period: number): void {
    this.loadingService.show();
    this.graphqlService.getFirstEntity(
      'planAnualAdquisicions',
      'id vigencia lastUpdateDate state paaFile fileNamePaa',
      `vigencia: ${period}, cencosFilter_cencos: ""`,
      {
        next: (response: any) => {
          // If the response is undefined, it means there is no PAA
          // for this period.
          if (!response) {
            this.loadingService.hide();
          } else {
            this.paa = response;
            this.loadingService.hide();
          }
        },
        error: (error) => {
          this.adfiGrowlService.errorMessage(error);
          this.loadingService.hide();
        }
      }
    );
  }

  getDataMenu(row: any, property: Property) {
    return AdfiUtil.getDataOneToMany(row, property, true);
  }
  
  transformValue(obj, property: Property, index: number) {
    return AdfiUtil.evaluateColumnsToDisplay(obj, [property.display[index]], property.customFormatDisplay, property.separators, property, 0);
  }

  downloadFile(type: 'pdf' | 'xlsx') {
    UIActionsHandler.handleFilesActions(this.adfiService, null, 'PAA\\PlanAnualAdquisicion',
      JSON.stringify({ id: this.paa._id }),
      'Plan Anual de Adquisiones',
      { properties: { fileType: type, fileAction: 'report' } } as Action, null);
  }

  downloadPaa() {
    AdfiUtil.downloadMediaObject(this.paa, { fieldName: 'paaFile', options: { bucket: 'files_paa' } },
      this.period);
  }

  openVerification() {
    const ref = this.dialog.open(ListComponent, {
      minHeight: '30vh',
      minWidth: '50%',
      maxHeight: '90vh',
    });
    ref.componentInstance.group = 'VA';
    ref.componentInstance.module = 'Presupuesto';
    ref.componentInstance.entityName = 'VCurrentItemsBudget';
    ref.componentInstance.defaultQuery = [{column: 'center', value: ''}, {column: 'itemType', value: 'E'}, {column: 'vigencia', value: this.period}];
    ref.componentInstance.dataEntity = {
      enableAdd: false,
      customTitle: 'Validación Plan Anual de Adquisiciones'
    } as DataEntity;
    }

}

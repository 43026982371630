<div mat-dialog-title style="background-color: #fff">
    <h3 class="col-12" style="margin-block-start: 0;margin-block-end: 1em;">Asignar cuentas UNSPSC</h3>
    <div class="d-flex justify-content-between">
        <mat-form-field class="col-6" appearance="legacy">
            <mat-label>Buscar</mat-label>
            <input matInput [(ngModel)]="searchDescription" (keydown.enter)="searchAccountByDescription(opt.value)"/>
        </mat-form-field>
        <mat-form-field class="col-3" appearance="legacy">
            <mat-label>Tipo</mat-label>
            <mat-select #opt value="acc">
                <mat-option [value]="'acc'">Nombre</mat-option>
                <mat-option [value]="'id'">Número</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col-3 d-flex align-items-center">
            <button mat-raised-button class="full-width" color="primary" (click)="searchAccountByDescription(opt.value)">Buscar</button>
        </div>
    </div>
</div>
<mat-dialog-content *ngIf="dataSource">
    <mat-tree class="mt-4" style="min-height: 45vh" [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node
                *matTreeNodeDef="let node"
                matTreeNodeToggle
                matTreeNodePadding
        >
            <button mat-icon-button disabled></button>
            <mat-checkbox
                    class="checklist-leaf-node"
                    [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)" *ngIf="!node.expandable"
            >{{ node.account }}
            </mat-checkbox>
            &nbsp; - &nbsp; {{ node.description }}
        </mat-tree-node>

        <mat-tree-node
                *matTreeNodeDef="let node; when: hasChild"
                matTreeNodePadding
        >
            <button
                    mat-icon-button
                    matTreeNodeToggle [matTreeNodeToggleRecursive]="searchDescription && searchDescription.trim().length > 0"
                    [attr.aria-label]="'Toggle ' + node.item"
            >
                <mat-icon class="mat-icon-rtl-mirror">
                    {{
                    treeControl.isExpanded(node)
                        ? "expand_more"
                        : "chevron_right"
                    }}
                </mat-icon>
            </button>
            <mat-checkbox
                    [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node)"
            >{{ node.account }}
            </mat-checkbox>
            &nbsp; - &nbsp; {{ node.description }}
        </mat-tree-node>
    </mat-tree>
</mat-dialog-content>
<mat-dialog-actions>
    <mat-card class="full-width">
        <mat-card-subtitle class="mb-0 text-center">Cuentas Actuales</mat-card-subtitle>
        <mat-card-content class="row d-flex flex-column align-items-center">
            <mat-chip-list class="d-flex justify-content-center" aria-label="accounts">
                <mat-chip *ngFor="let account of data.accounts; let i = index" selected color="primary" class="pr-4">
                    {{account.unspscAccount.account}}
                    <button class="icon-remove-chip" mat-icon-button (click)="remove(i)" style="position: absolute;right: -6px; top: -6px;">
                        <mat-icon [inline]="true">cancel</mat-icon>
                    </button>
                </mat-chip>
                <mat-chip *ngFor="let account of accountToAdd(); let i = index" color="primary" class="pr-4">
                    {{account.account}}
                    <button class="icon-remove-chip" mat-icon-button (click)="todoItemSelectionToggle(account)" style="position: absolute;right: -6px; top: -6px;">
                        <mat-icon [inline]="true">cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-list>
            <button mat-raised-button class="col-2 mt-2" color="primary" (click)="updateAccounts()">Asignar</button>
        </mat-card-content>
    </mat-card>
</mat-dialog-actions>

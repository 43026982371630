<div class="container pb-5">
    <div class="row pt-2">
        <mat-card class="col-12 mb-2">
            <mat-card-title>Reporte de Ejecución Presupuestal <ng-container *ngIf="!canSearchConsolidated"> - {{service.user.centroCosto._id + ' - ' + service.user.centroCosto.deDescripcion}} </ng-container> </mat-card-title>
            <mat-card-content>
                <div class="row pt-2 align-items-center">

                    <div class="col-12 col-md-9">
                        <div class="row">
                            <div class="col-12 mt-1">
                                <div class="row">
                                    <div class="col-md-{{canSearchConsolidated ? '3' : '6'}} col-6">
                                        <div class="row justify-content-start align-items-center">
                                            <h6 class="pl-3 d-inline">Fuente: </h6>
                                            <half-circle-menu tooltip="{{filters.sources | json}}" tooltipPosition="above" class="mx-md-2" content="*"
                                                              [(value)]="filters.sources" [multiple]="true" [options]="parameters.sources" (selectionChange)="loadConfigData()">
                                            </half-circle-menu>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6 col-6">
                                        <div class="row full-height align-items-center">
                                            <mat-form-field class="col-12">
                                                <mat-label>Filtro por</mat-label>
                                                <mat-select placeholder="Seleccionar" [(value)]="type" (selectionChange)="resetFilters();loadConfigData()">
                                                    <mat-option *ngFor="let doc of typeFilters" [value]="doc.value">
                                                        {{ doc.name | titlecase}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-md-{{canSearchConsolidated ? '3' : '6'}} col-6">
                                        <div class="row full-height align-items-center">
                                            <mat-form-field class="col-12">
                                                <mat-label>Nivel de detalle:</mat-label>
                                                <mat-select  [(value)]="level">
                                                    <mat-option *ngFor="let index of parameters.levels" [value]="index">
                                                        {{index}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6 col-6">
                                        <div class="row full-height align-items-center">
                                            <mat-form-field class="col-12" appearance="legacy">
                                                <mat-label>Filtro por Rubro:</mat-label>
                                                <input matInput placeholder="Código de rubro" [(ngModel)]="itemKey" autocomplete="off" (keypress)="omitKey($event)">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6 col-12" *ngIf="canSearchConsolidated">
                                        <div class="row full-height align-items-center">
                                            <mat-form-field class="col-12" appearance="legacy">
                                                <mat-label>Filtro (Municipio - I.E.)</mat-label>
                                                <input matInput placeholder="Filtro (Municipio - I.E.)" [(ngModel)]="townOrIe" autocomplete="off">
                                                <mat-icon matPrefix class="mr-2 cursor-pointer no-select" (click)="openCenterSearch()">travel_explore</mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="row justify-content-around align-items-center">
                            <div class="col-10 pl-sm-4">
                                <h6 class="d-inline"> Fecha Inicial:</h6>
                                <ng-container *ngIf="selectedDates">
                                    {{ selectedDates.startDate | date: 'mediumDate':null:'es-co'}}
                                </ng-container> <br>
                                <h6 class="d-inline"> Fecha Final:</h6>
                                <ng-container *ngIf="selectedDates">
                                    {{ selectedDates.endDate | date: 'mediumDate':null:'es-co'}}
                                </ng-container>
                            </div>
                            <div class="col-2 d-flex align-items-center flex-column">
                                <button mat-mini-fab color="primary"
                                        matTooltip="Seleccionar fechas" (click)="selectDates()">
                                    <mat-icon>calendar_today</mat-icon>
                                </button>
                                <button mat-mini-fab color="primary"
                                        matTooltip="Reiniciar Filtros" (click)="resetFilters()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="container">
        <div class="row pt-3">
            <mat-tab-group class="col-12 tab-custom-overflow">
                <mat-tab label="Ingresos" *ngIf="type == '' || type == 'I'">
                    <mat-progress-bar *ngIf="loadingIng" class="col-12 " mode="indeterminate"></mat-progress-bar>
                    <app-table [context]="this" [dynamicRowClass]="checkRowColor" [columns]="columnsIng" [items]="revenues" (reload)="this.loadData('I')">
                        <div class="footer col-12 pt-2">
                            <div class="row justify-content-end">
                                <button mat-raised-button [disabled]="loadingIng || !this.selectedDates" (click)="exportTo('xlsx')"
                                        class="mr-2" color="primary" matTooltip="Exportar a excel">
                                    <mat-icon>receipt_long</mat-icon> Exportar a excel
                                </button>
                                <button mat-raised-button [disabled]="loadingIng || !this.selectedDates" (click)="exportTo('pdf')"
                                        color="primary" matTooltip="Exportar a pdf">
                                    <mat-icon>description</mat-icon>Exportar a pdf
                                </button>
                            </div>
                        </div>
                    </app-table>
                </mat-tab>
                <mat-tab label="Egresos" *ngIf="type == '' || type == 'E'">
                    <mat-progress-bar *ngIf="loadingEgr" class="col-12 " mode="indeterminate"></mat-progress-bar>
                    <app-table [context]="this" [dynamicRowClass]="checkRowColor" [columns]="columnsEgr" [items]="expenses" (reload)="this.loadData('E')">
                        <div class="footer col-12 pt-2">
                            <div class="row justify-content-end">
                                <button mat-raised-button [disabled]="loadingEgr || !this.selectedDates" (click)="exportTo('xlsx')"
                                        class="mr-2" color="primary" matTooltip="Exportar a excel">
                                    <mat-icon>receipt_long</mat-icon> Exportar a excel
                                </button>
                                <button mat-raised-button [disabled]="loadingEgr || !this.selectedDates" (click)="exportTo('pdf')"
                                        color="primary" matTooltip="Exportar a pdf">
                                    <mat-icon>description</mat-icon>Exportar a pdf
                                </button>
                            </div>
                        </div>
                    </app-table>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

import {Component, OnInit} from '@angular/core';
import {CheckingBalanceComponent} from '@pages/adjustment/checking-balance/checking-balance.component';
import {Attribute, Column, Type} from '@Components/table/table.component';
import {DataEntity, ENUM_ACTIONS} from '@Interfaces/index';
import {UIHelper} from '@Components/util/UIHelper';
import {SelectItemComponent} from '@Components/ui/select-item/select-item.component';
import {AdfiUtil} from '@Components/util/adfi-util';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent extends CheckingBalanceComponent implements OnInit{
  consolidated = true;

  columnsData2: Column[] = [
    {
      column: 'ACCOUNT',
      name: 'Cuenta',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'NAME_ACCOUNT',
      name: 'Nombre',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'VALUE_ANT',
      name: 'Saldo Inicial',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'DB',
      name: 'Débito',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'CR',
      name: 'Crédito',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'VALUE',
      name: 'Saldo Final',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    }
  ];

  columnsCGN: Column[] = [
    {
      column: 'ACCOUNT',
      name: 'Cuenta',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'NAME_ACCOUNT',
      name: 'Nombre',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'VALUE_ANT',
      name: 'Saldo Inicial',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'DB',
      name: 'Débito',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'CR',
      name: 'Crédito',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'VALUE',
      name: 'Saldo Final',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'VALUE_COR',
      name: 'Saldo Final Corriente',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'VALUE_NO_COR',
      name: 'Saldo Final No Corriente',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    }
  ];
  columnsVar: Column[] = [
    {
      column: 'ACCOUNT',
      name: 'Cuenta',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'NAME_ACCOUNT',
      name: 'Nombre',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'VALUE',
      name: 'Valor Actual',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'VALUE_ANT',
      name: 'Valor Anterior',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    },
    {
      column: 'VALUE_DEF',
      name: 'Variación',
      type: Type.Currency,
      attr: {classCell: 'text-right', } as Attribute
    }
  ];

  listResults = {
    GetCGN : [],
    GetCOVID : [],
    GetFOME : [],
    GetReciprocal : [],
    GetVariations : []
  };

  ngOnInit() {
    super.ngOnInit();
    this.modules = undefined;
    this.dateChange.subscribe(() => {
        this.listResults = {
          GetCGN : [],
          GetCOVID : [],
          GetFOME : [],
          GetReciprocal : [],
          GetVariations : []
        };
    });
  }

  load(entity = 'GetCGN') {
    const next = (d) => {
      if (d && d.fileName) {
        this.listResults[entity] = JSON.parse(d.fileName);
        this.loading.hide();
      } else {
        this.adfiGrowlService.error('Reporte', 'Se ha presentado un error al obtener la información, por favor contacta a soporte técnico');
        this.loading.hide();
      }
    };
    if (this.selectedDates) {
      this.service.getJson(
          entity,
          JSON.stringify(this.getAllFilters()),
          'fileName',
          next
      );
    }
  }

  exportPlain(entity = 'GetCGN', name = 'CGN') {
    if (!this.selectedDates) {
      this.adfiGrowlService.warning('Precaución', 'Por favor selecciona un rango de fechas');
    } else {
      this.service.download(
          entity,
          JSON.stringify(this.getAllFilters()),
          'fileName',
          `text/plain`, AdfiUtil.getNameFile(name, this.selectedDates) + '.txt'
      );
    }
  }

    openCenterSearch() {
        const data = {entityName: 'PrCentroCosto', module: '', enableAdd: false} as DataEntity;
        const c = UIHelper.openDialog(SelectItemComponent, this.matDialog, data, ENUM_ACTIONS.SELECT);
        const select = (row) => {
          if (row) {
            if (!this.townOrIe || this.townOrIe.trim() === '') {
              this.townOrIe = '' + row._id;
            } else {
              this.townOrIe += ',' + row._id;
            }
          }
          subs.unsubscribe();
        };
        const subs = c.componentInstance.dialogRef.afterClosed().subscribe(select);
    }

  uploadPlainFile(type: 'CGN'|'COVID'|'FOME') {
    const c = UIHelper.createItem(this.matDialog, 'CgnFile', 'Contabilidad', {originType: type});
    const ok = (row) => {
      subs.unsubscribe();
    };
    const subs = c.componentInstance.dialogRef.afterClosed().subscribe(ok);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'; // Importa DomSanitizer

@Component({
  selector: 'app-dialog-videos',
  templateUrl: './dialog-videos.component.html',
  styleUrls: ['./dialog-videos.component.scss']
})
export class DialogVideosComponent implements OnInit {
  safeVideoUrl: SafeResourceUrl; // Crea una variable para la URL segura

  constructor(
    public dialogRef: MatDialogRef<DialogVideosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer // Inyecta DomSanitizer
  ) {}

  ngOnInit(): void {
    // Sanitiza la URL del video y asígnala a safeVideoUrl
    this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://drive.google.com/file/d/${this.data.idVideo}/preview`);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

<div mat-dialog-content>
    <p>Recuerde que el correo electrónico ingresado es con el que tendrá acceso a la capacitación, por lo tanto confirme
        que <strong><span style="font-size: 18px">{{data.email}}</span></strong> es
        correcto</p>
</div>
<div mat-dialog-actions align="center">
    <button mat-raised-button  type="button" color="primary" [mat-dialog-close]="true">
        <mat-icon aria-hidden="false" aria-label="Confirmar">done</mat-icon>
        Es Correcto
    </button>
    <button mat-raised-button type="button" (click)="onNoClick()">Corregir Información</button>
</div>

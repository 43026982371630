import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivateChild,
    Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AdfiService} from '@services/adfi-service';

const NAV = 'nav';
const ENTITY = 'entity';
const PERMISSION = 'permission';
const PERIOD_STATE = 'periodSate';

@Injectable({
    providedIn: 'root'
})
export class AdfiRolRouteControlGuard implements CanActivate, CanActivateChild {

    constructor(private service: AdfiService, protected router: Router,) {
    }


    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (next.routeConfig[NAV]) {
            const result = this.service.validPermission(next.routeConfig[ENTITY], next.routeConfig[PERMISSION], next.routeConfig[PERIOD_STATE]);
            if (!result)
                this.router.navigate(['/']);
            return true;
        }
        return true;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (childRoute.routeConfig[NAV]) {
            const result = this.service.validPermission(childRoute.routeConfig[ENTITY],
                childRoute.routeConfig[PERMISSION], childRoute.routeConfig[PERIOD_STATE]);
            if (!result)
                this.router.navigate(['/']);
            return true;
        }
        return true;
    }

}

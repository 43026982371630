<div class="container mt-2">
  <div class="row">
    <div class="col-12 mb-2">
      <h3 class="text-center">{{customTitle}}</h3>
    </div>
    <app-date-range-picker #drp [customEndText]="customEndText" [customStartText]="customStartText"
                           stylePicker="Legacy" class="col-12" [params]="data" [usePeriod]="usePeriod">
    </app-date-range-picker>
    <div class="col-12 mt-2 mb-3">
      <div class="row justify-content-center">
        <button mat-raised-button mat-dialog-close class="mr-2">Cancelar</button>
        <button mat-raised-button (click)="afterSelect.emit(drp.getDates())" color="primary" [disabled]="drp.form.invalid"> {{ btnText }}</button>
      </div>
    </div>
  </div>
</div>

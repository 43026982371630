<div mat-dialog-content>
    <p>Visualizar Certificado</p>
    <mat-form-field class="w-100">
        <mat-label>Observación en Documento PDF</mat-label>
        <textarea matInput #input maxlength="800" [(ngModel)]="data.obs" placeholder="Observación...."></textarea>
        <mat-hint align="end">{{input.value?.length || 0}}/800</mat-hint>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button color="primary" mat-raised-button [mat-dialog-close]="data.obs" cdkFocusInitial>GENERAR</button>
</div>
<mat-card class="col-12 mb-2 justify-content-between" *ngIf="canExportIng">
    <mat-card-title>Reporte de Ingresos</mat-card-title>
    <mat-card-content>
        <div class="row pt-2">
            <div class="col-12 col-md-8">
                <mat-form-field class="col-12  col-md-6"
                                matTooltip="*Para realizar la busqueda debe seleccionar el rango de fechas">
                    <mat-progress-bar *ngIf="loadingSources" mode="indeterminate" class="over-head"></mat-progress-bar>
                    <mat-label>Filtrar por Fuente</mat-label>
                    <mat-select placeholder="Seleccionar" [(value)]="source" (valueChange)="changeSource()">
                        <mat-option *ngFor="let doc of sources" [value]="doc._id">
                            {{ doc._id }} - {{ doc.prfinVNombre | titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-12 col-md-6" matTooltip="*Para realizar la busqueda debe seleccionar el rango de fechas">
                    <mat-progress-bar *ngIf="loadingItems" mode="indeterminate" class="over-head"></mat-progress-bar>
                    <mat-label>Filtrar rubro</mat-label>
                    <mat-select placeholder="Seleccionar" [(value)]="item" [disabled]="!source" multiple>
                        <mat-option *ngFor="let doc of items | callback: filterItem : source" [value]="doc.minipresu">
                            {{ doc.minipresu }} - {{ doc.nombre | titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>


            <div class="col-12 col-md-4 justify-content-end">
                <button mat-raised-button color="primary"
                        [disabled]="source === undefined"
                        matTooltip="Exportar a excel" (click)="this.selectDatesIncomes()">
                    <mat-icon>calendar_today</mat-icon>
                    Seleccionar fechas
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>


<mat-card class="col-12 mb-2 justify-content-between" *ngIf="canExportCon">
    <mat-card-title>Reporte de Conciliaciones</mat-card-title>
    <mat-card-content>
        <div class="row pt-2">
            <div class="col-12  col-md-8">
                <mat-form-field class="col-12  col-md-6">
                    <mat-progress-bar *ngIf="loadingPeriod" mode="indeterminate" class="over-head"></mat-progress-bar>
                    <mat-label>Vigencia</mat-label>
                    <mat-select placeholder="Seleccionar" [(ngModel)]="currentPeriod">
                        <mat-option *ngFor="let period of periods" [value]="period.year">{{ period.year }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4 justify-content-end">
                <button mat-raised-button color="primary" [disabled]="currentPeriod === undefined" (click)="reporteConciliaciones()">Exportar Conciliaciones</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="col-12 mb-2 justify-content-between" *ngIf="canExportMod">
    <mat-card-title>Reporte de Modificaciones Presupuestales</mat-card-title>
    <mat-card-content>
        <div class="row pt-2">
            <div class="col-12  col-md-8">
            </div>
            <div class="col-12 col-md-4 justify-content-end">
                <button mat-raised-button color="primary" (click)="reportModifications()">Exportar Modificaciones</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="col-12 mb-2 justify-content-between" *ngIf="canExportRub">
    <mat-card-title>Reporte de Rubros por Adicionar</mat-card-title>
    <mat-card-content>
        <div class="row pt-2">
            <div class="col-12  col-md-8">
            </div>
            <div class="col-12 col-md-4 justify-content-end">
                <button mat-raised-button color="primary"
                    matTooltip="Exportar a excel" (click)="this.selectDatesItems()">
                <mat-icon>calendar_today</mat-icon>
                Seleccionar fechas
            </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {MatCalendar, MatDatepickerIntl, yearsPerPage} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from "@angular/material/core";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-date-range-header',
  templateUrl: './date-range-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(private _intl: MatDatepickerIntl,
      public calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
      @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    calendar.stateChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => cdr.markForCheck());
  }
  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  euclideanModulo (a: number, b: number): number {
    return (a % b + b) % b;
  }

  getStartingYear<D>(
      dateAdapter: DateAdapter<D>, minDate: D | null, maxDate: D | null): number {
    let startingYear = 0;
    if (maxDate) {
      const maxYear = dateAdapter.getYear(maxDate);
      startingYear = maxYear - yearsPerPage + 1;
    } else if (minDate) {
      startingYear = dateAdapter.getYear(minDate);
    }
    return startingYear;
  }

  getActiveOffset<D>(
      dateAdapter: DateAdapter<D>, activeDate: D, minDate: D | null, maxDate: D | null): number {
    const activeYear = dateAdapter.getYear(activeDate);
    return this.euclideanModulo((activeYear - this.getStartingYear(dateAdapter, minDate, maxDate)),
        yearsPerPage);
  }

  dateLabel(date) {
    if (date)
      return this._dateAdapter
        .format(date, 'DD MMM YYYY')
        .toLocaleString();
    return '';
  }

  get periodButtonText(): string {
    if (this.calendar.currentView == 'month') {
      return this._dateAdapter
          .format(this.calendar.activeDate, this._dateFormats.display.monthYearLabel)
          .toLocaleUpperCase();
    }
    if (this.calendar.currentView == 'year') {
      return this._dateAdapter.getYearName(this.calendar.activeDate);
    }

    // The offset from the active year to the "slot" for the starting year is the
    // *actual* first rendered year in the multi-year view, and the last year is
    // just yearsPerPage - 1 away.
    const activeYear = this._dateAdapter.getYear(this.calendar.activeDate);
    const minYearOfPage = activeYear - this.getActiveOffset(
        this._dateAdapter, this.calendar.activeDate, this.calendar.minDate, this.calendar.maxDate);
    const maxYearOfPage = minYearOfPage + yearsPerPage - 1;
    const minYearName =
        this._dateAdapter.getYearName(this._dateAdapter.createDate(minYearOfPage, 0, 1));
    const maxYearName =
        this._dateAdapter.getYearName(this._dateAdapter.createDate(maxYearOfPage, 0, 1));
    return this._intl.formatYearRange(minYearName, maxYearName);
  }

  get periodButtonLabel(): string {
    return this.calendar.currentView == 'month' ?
        this._intl.switchToMultiYearViewLabel : this._intl.switchToMonthViewLabel;
  }

  /** The label for the previous button. */
  get prevButtonLabel(): string {
    return {
      'month': this._intl.prevMonthLabel,
      'year': this._intl.prevYearLabel,
      'multi-year': this._intl.prevMultiYearLabel
    }[this.calendar.currentView];
  }

  /** The label for the next button. */
  get nextButtonLabel(): string {
    return {
      'month': this._intl.nextMonthLabel,
      'year': this._intl.nextYearLabel,
      'multi-year': this._intl.nextMultiYearLabel
    }[this.calendar.currentView];
  }

  /** Handles user clicks on the period label. */
  currentPeriodClicked(): void {
    this.calendar.currentView = this.calendar.currentView == 'month' ? 'multi-year' : 'month';
  }

  /** Handles user clicks on the previous button. */
  previousClicked(): void {
    this.calendar.activeDate = this.calendar.currentView == 'month' ?
        this._dateAdapter.addCalendarMonths(this.calendar.activeDate, -1) :
        this._dateAdapter.addCalendarYears(
            this.calendar.activeDate, this.calendar.currentView == 'year' ? -1 : -yearsPerPage
        );
  }

  /** Handles user clicks on the next button. */
  nextClicked(): void {
    this.calendar.activeDate = this.calendar.currentView == 'month' ?
        this._dateAdapter.addCalendarMonths(this.calendar.activeDate, 1) :
        this._dateAdapter.addCalendarYears(
            this.calendar.activeDate,
            this.calendar.currentView == 'year' ? 1 : yearsPerPage
        );
  }

  /** Whether the previous period button is enabled. */
  previousEnabled(): boolean {
    if (!this.calendar.minDate) {
      return true;
    }
    return !this.calendar.minDate ||
        !this._isSameView(this.calendar.activeDate, this.calendar.minDate);
  }

  /** Whether the next period button is enabled. */
  nextEnabled(): boolean {
    return !this.calendar.maxDate ||
        !this._isSameView(this.calendar.activeDate, this.calendar.maxDate);
  }

  /** Whether the two dates represent the same view in the current view mode (month or year). */
  private _isSameView(date1: D, date2: D): boolean {
    if (this.calendar.currentView == 'month') {
      return this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2) &&
          this._dateAdapter.getMonth(date1) == this._dateAdapter.getMonth(date2);
    }
    if (this.calendar.currentView == 'year') {
      return this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2);
    }
    // Otherwise we are in 'multi-year' view.
    return this.isSameMultiYearView(
        this._dateAdapter, date1, date2, this.calendar.minDate, this.calendar.maxDate);
  }

  isSameMultiYearView<D>(
      dateAdapter: DateAdapter<D>, date1: D, date2: D, minDate: D | null, maxDate: D | null): boolean {
    const year1 = dateAdapter.getYear(date1);
    const year2 = dateAdapter.getYear(date2);
    const startingYear = this.getStartingYear(dateAdapter, minDate, maxDate);
    return Math.floor((year1 - startingYear) / yearsPerPage) ===
        Math.floor((year2 - startingYear) / yearsPerPage);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParamDate, DateRangeSelectionComponent } from '@Components/date-range-selection/date-range-selection.component';
import { AdfiGraphqlService } from '@services/adfi-graphql.service';
import { AdfiGrowlService } from '@services/adfi-growl.service';
import { AdfiService } from '@services/adfi-service';
import { Attribute, Column, ColumnGroup, Type } from '@Components/table/table.component';
import { LoadingService } from '@services/loading.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { TableComponent } from '@Components/table/table.component';

@Component({
  selector: 'app-bank-transactions',
  templateUrl: './bank-transactions.component.html'
})
export class BankTransactionsComponent implements OnInit {

  columnsHeaderGroupAll: ColumnGroup[];
  columnsFooterGroupAll: ColumnGroup[];

  columnsAll: Column[] = [
    {
      column: 'D_MOVIM',
      name: 'Fecha',
      type: Type.Date,
      attr: { classCell: 'text-center' } as Attribute
    },
    {
      column: 'TYPE',
      name: 'Tipo',
      type: Type.Option,
      attr: {
        classHeader: '',
        options: {
          RC: 'Ingreso de Tesorería', OP: 'Causación Orden de Pago', EGRET: 'Operación de Tesorería', 'EG-TR':'Operación de Tesorería',
          CONSIG: 'Consignaciones de Caja', ND: 'Devoluciones', CO_T: 'Operaciones de Terceros', EGRE: 'Comprobante De Egreso', AJ: 'Ajuste', CB: 'Causación Orden de Pago Bienes'
        }
      } as Attribute
    },
    {
      column: 'OBSERVATION',
      name: 'Descripción',
      type: Type.String,
      attr: null
    },
    {
      column: 'DEBIT',
      name: 'Valor Débito',
      type: Type.Currency,
      attr: { classCell: 'text-right px-1' } as Attribute
    },
    {
      column: 'CREDIT',
      name: 'Valor Crédito',
      type: Type.Currency,
      attr: { classCell: 'text-right px-1' } as Attribute
    },
    {
      column: 'CUMULATIVE_BALANCE',
      name: 'Saldo Acumulado',
      type: Type.Currency,
      attr: { classCell: 'text-right px-1' } as Attribute
    }
  ];

  types = [
    { acronym: 'RC', text: 'Ingreso de Tesorería' },
    { acronym: 'OP', text: 'Causación Orden de Pago' },
    { acronym: 'EGRET', text: 'Operación de Tesorería' },
    { acronym: 'CONSIG', text: 'Consignaciones de Caja' },
    { acronym: 'ND', text: 'Devoluciones' },
    { acronym: 'CO_T', text: 'Operaciones de Terceros' },
    { acronym: 'EGRE', text: 'Comprobante De Egreso' },
    { acronym: 'AJ', text: 'Ajuste' },
    { acronym: 'CB', text: 'Causación Orden de Pago Bienes' }
  ];
  type: any;

  bankAccount: any;
  bankAccounts: any[];

  data: any[];

  selectedDates: ParamDate;

  @ViewChild(TableComponent) tableComponent: TableComponent;

  constructor(
    private adfiGrowlService: AdfiGrowlService,
    private graphqlService: AdfiGraphqlService,
    private matDialog: MatDialog,
    private service: AdfiService,
    public loading: LoadingService,
    private gaService: GoogleAnalyticsService,
  ) { }

  async ngOnInit() {
    const next = (value) => {
      this.bankAccounts = value;
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
    };
    const filter = 'cencosFilter_centroCosto: "", vMaint_not: {exact: \"D\"}';
    const all = await this.graphqlService.countEntity('cuentaBancos', filter);
    this.graphqlService.getEntity('cuentaBancos', 'nombre,numero,currentValue', all, filter, { next, error });
    this.checkColumns();
  }

  selectBankAccount(account: any) {
    if (account) {
      this.bankAccount = account;
      this.data = null;
    }
  }

  selectType(type: any) {
    if (type) {
      this.type = type;
      this.tableComponent.columns = this.columnsAll.filter(c => c.column !== 'CUMULATIVE_BALANCE');
      this.tableComponent.footerGroupBy = this.columnsFooterGroupAll.filter(c => c.column !== 'FINAL_BALANCE');
      this.tableComponent.reload.emit();
    }
  }

  selectDates() {
    const paramDates: ParamDate = {
      startDate: this.selectedDates ? this.selectedDates.startDate : null,
      endDate: this.selectedDates ? this.selectedDates.endDate : null
    };
    const dialog = this.matDialog.open(DateRangeSelectionComponent, {
      data: paramDates
    });
    dialog.componentInstance.btnText = 'Seleccionar';
    const subs = dialog.componentInstance.afterSelect.subscribe((dates: ParamDate) => {
      subs.unsubscribe();
      this.selectedDates = dates;
      this.loadData();
      dialog.close();
    });
  }


  loadData() {
      const next = (d) => {
        this.data = JSON.parse(d.fileName);
      };
      this.loading.show();
      this.service.getJson(
          'Tesoreria\\BankTransactions',
          JSON.stringify(this.getAllFilters()),
          'fileName',
          next
      );
   
  }

  resetFilters() {
    this.selectedDates = null;
    this.type = null;
    this.tableComponent.columns = this.columnsAll;
    this.tableComponent.footerGroupBy = this.columnsFooterGroupAll;
    this.data = null;
  }

  export(format: string) {
    this.gaService.event('Generar Reporte', 'ADFI', 'Libros Presupuestales ' + format, this.service.user.id);
    if (!this.loading.active()) {
      this.loading.show();
      this.service.download(
        'Tesoreria\\BankTransactions',
        JSON.stringify(this.getAllFilters()),
        'fileName',
        `application/${format}`,
        `Movimientos Cuenta Bancaria.${format}`, '');
    }
  }


  getFilters() {
    if(this.type) {
      return {
        account: this.bankAccount._id,
        account_balance: this.bankAccount.currentValue,
        type: this.type.acronym
      };
    }else {
      return {
        account: this.bankAccount._id,
        account_balance: this.bankAccount.currentValue,
      };
    }
  }

  getAllFilters() {
    if (this.selectedDates) {
      return {
        startDate: this.selectedDates.startDate.toUTCString(),
        endDate: this.selectedDates.endDate.toUTCString(),
        ...this.getFilters()
      };
    }else {
      return this.getFilters();
    }
    
  }
  
  get filteredColumns() {
    if(this.type) {
      this.columnsAll.pop();
      return this.columnsAll;
    }else {
      return this.columnsAll;
    }
  }

  checkColumns() {
      this.columnsHeaderGroupAll = [
        {
          column: 'ACCOUNT',
          name: 'Cuenta',
          type: Type.Number
        },
        {
          column: 'INITIAL_BALANCE',
          name: 'Saldo Inicial',
          type: Type.Currency
        }
      ];
      this.columnsFooterGroupAll = [
        {
          column: 'DEBIT',
          sum: true,
          name: '',
          sumName: 'Total Débito',
          type: Type.Currency
        },
        {
          column: 'CREDIT',
          sum: true,
          name: '',
          sumName: 'Total Crédito',
          type: Type.Currency
        },
        {
          column: 'FINAL_BALANCE',
          name: 'Saldo Final',
          type: Type.Currency
        }
      ];
  }

}

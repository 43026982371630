import {Component, HostBinding} from '@angular/core';
import {LineComponent} from '@Components/ui/menus/line/line.component';

@Component({
  selector: 'half-circle-menu',
  templateUrl: './half-circle.component.html',
  styleUrls: ['./half-circle.component.scss']
})
export class HalfCircleComponent extends LineComponent {
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analitycs',
  templateUrl: './analitycs.component.html',
  styleUrls: ['./analitycs.component.scss']
})
export class AnalitycsComponent implements OnInit {

  constructor() { }


ngOnInit() {
}

}

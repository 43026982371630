import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormComponent} from '@Components/ui/form/form.component';
import {DataEntity, ENUM_ACTIONS, Permission} from '@Interfaces/index';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadingService} from '@services/loading.service';
import {MatStepper} from '@angular/material/stepper';
import {AdfiUtil} from '@Components/util/adfi-util';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {UIHelper} from '@Components/util/UIHelper';
import {ListComponent} from '@Components/ui/list/list.component';

@Component({
    selector: 'app-end-reservations',
    templateUrl: './end-reservations.component.html',
    styleUrls: ['./end-reservations.component.css']
})
export class EndReservationsComponent implements OnInit {

    @ViewChild('tem_ReservationForm', { read: ViewContainerRef }) vReservationForm: ViewContainerRef;

    minDate = new Date(new Date().getFullYear(), 0, 1);
    maxDate =  new Date();
    listAux = [];
    listDirector = [];
    formGroup: FormGroup;
    private action: ENUM_ACTIONS;
    private idReserve: string;
    noEditable: boolean;
    item: any;
    approveAccount: boolean;
    stepOne: boolean;
    stepTwo: boolean;
    stepTree: boolean;
    stepFour: boolean;

    canSetInitial: boolean;

    private COLUMNS_RETURN = 'id _id agreementNumber certificate agreementDate director{id name apellidos} administrative{id name apellidos} observation state administrativeActFile';
    @ViewChild('stepper') stepper: MatStepper;

    constructor(private matDialog: MatDialog,
                private graphqlService: AdfiGraphqlService,
                private adfiService: AdfiService,
                private loading: LoadingService,
                private adfiGrowlService: AdfiGrowlService) {
        this.minDate = new Date(this.adfiService.user.period - 1, 0, 1 );
        this.maxDate = new Date(this.adfiService.user.period - 1, 11, 31  );
    }

    ngOnInit() {
        this.approveAccount = this.adfiService.validPermission('BudgetReserve', Permission.APPROVE_ACCOUNT_MAN);
        this.canSetInitial = this.adfiService.validPermission('BudgetReserve', 'ToInitialStateBudget')
        this.loading.show();
        this.loadingReserve();
        this.formGroup = new FormGroup({
            agreementNumber: new FormControl('', [Validators.pattern(/[0-9]+/), Validators.required, Validators.maxLength(3)]),
            agreementDate: new FormControl('', Validators.required),
            director: new FormControl('', Validators.required),
            administrative: new FormControl('', Validators.required),
            observation: new FormControl('', null),
        });
        const next = (val) => {
            this.listAux = val;
        };
        const nextR = (val) => {
            this.listDirector = val;
        };
        this.loadListEmployee(142, next);
        this.loadListEmployee(162, nextR);
    }

    loadListEmployee(role: number, next) {
        const error = (val) => {
            this.adfiGrowlService.errorMessage(val);
        };
        this.graphqlService.getEntity('employees',  'id name apellidos', 1000, `rolList_centroCosto_id: ${this.adfiService.user.centroCosto._id}, rolList_role_id: ${role}`, {next, error});
    }

    loadingReserve(loading = true) {
        const next = (val) => {
            if (val) {
               this.action = ENUM_ACTIONS.EDIT;
               this.idReserve = val.id;
               this.formGroup.get('agreementNumber').setValue(val.agreementNumber);
               this.formGroup.get('agreementDate').setValue(val.agreementDate);
               this.formGroup.get('director').setValue(val.director.id);
               this.formGroup.get('administrative').setValue(val.administrative.id);
               this.formGroup.get('observation').setValue(val.observation);
               this.noEditable = val.state === 'CLO' || val.state === 'UPL' || val.state === 'APPRO';
               this.item = val;
               if (!this.approveAccount && this.stepper) {
                   this.stepOne = true;
                   this.stepTwo = true;
                   setTimeout(() => {
                       this.stepper.next();
                       this.stepper.next();
                       if (loading){
                           this.loading.hide();
                       }}, 100);
                   if (this.item.administrativeActFile) {
                       this.stepTree = true;
                       setTimeout(() => {
                           this.stepper.next();
                       }, 100);
                       if (this.item.state === 'CLO' || this.item.state === 'APPRO') {
                           this.stepFour = true;
                           setTimeout(() => {
                               this.stepper.next();
                           }, 100);
                       }
                   }
               } else if (loading) {
                   this.loading.hide();
               }
            } else {
                this.action = ENUM_ACTIONS.CREATE;
                this.loading.hide();
            }
        };
        const error = (val) => {
            this.adfiGrowlService.errorMessage(val);
            this.loading.hide();
        };
        this.graphqlService.getFirstEntity('budgetReserves', this.COLUMNS_RETURN, `cencosFilter_centroCosto: ""`, {next, error});
    }


    getPdf() {
        this.adfiService.download(
            'EndProcess\\BudgetReserve',
            JSON.stringify({}),
            'fileName',
            'application/pdf',
            'infomacionreserva.pdf', 'authorization');
    }

    saveGraphql(obj, next, error){
        this.graphqlService.mutationEntity('BudgetReserve', obj, this.COLUMNS_RETURN, this.action, {next, error});
    }

    save(state = 'INI', generatePdf = false ) {
        this.loading.show();
        this.stepOne = true;
        const obj = {
                agreementNumber: this.formGroup.get('agreementNumber').value,
                agreementDate: this.formGroup.get('agreementDate').value,
                director: this.formGroup.get('director').value,
                administrative: this.formGroup.get('administrative').value,
                observation: this.formGroup.get('observation').value,
                state
            };
        if (this.action === ENUM_ACTIONS.EDIT) {
                obj['id'] = this.idReserve;
            }
        const next = (val) => {
                this.loadingReserve(true);
                this.noEditable = true;
                this.stepTwo = true;
                setTimeout(() => {
                    this.stepper.next();
                }, 100);
                if (generatePdf) {
                    this.getPdf();
                }
            };
        const error = (val) => {
                this.adfiGrowlService.errorMessage(val);
                this.loading.hide();
            };
        if (state === 'CLO') {
                const dialog = this.matDialog.open(ConfirmDialogComponent, {
                    data: {
                        title: 'Cerrar Documento: Información de constitución de Reservas',
                        content: `<p>¿Está seguro/a de Cerrar la edición?</p><p>Tenga en cuenta que una vez cerrado el documento de información de constitución de reserva no podrá modificar la información registrada, tenga presente que puede generar el documento borrador para verificar la información suministrada.</p>`,
                        action: ENUM_ACTIONS.DELETE
                    } as ConfirmDialogData
                });
                dialog.beforeClosed().subscribe((ok) => {
                    if (ok) {
                        this.saveGraphql(obj, next, error);
                        setTimeout(() => {
                                this.stepper.next();
                            }, 3000);
                    } else {
                      this.loading.hide();
                    }
                });
            } else {
                this.saveGraphql(obj, next, error);
        }
    }

    uploadFile(group = 'upload_file'){
        this.loading.show();
        const dialogAdd = this.matDialog.open(FormComponent, {
            data: {
                entityName: 'BudgetReserve',
                module: 'EndProcess',
                customTitle: 'Subir Archivo Firmado',
                item: this.idReserve,
                action: ENUM_ACTIONS.EDIT,
                group
            } as DataEntity
        });
        dialogAdd.afterClosed().subscribe(value => {
            if (value) {
                if (group === 'upload_file') {
                    const next = (val) => {
                        this.loadingReserve();
                    };
                    const error = (val) => {
                        this.adfiGrowlService.errorMessage(val);
                        this.loading.hide();
                    };
                    this.saveGraphql({
                        id: this.idReserve,
                        state: 'APPRO'
                    }, next, error);
                } else {
                   this.loadingReserve(true);
                }
            }
        });
    }

    downloadCertificate(fieldName) {
        AdfiUtil.downloadMediaObject(this.item[fieldName], {fieldName, options: {bucket: 'files_end_period'}}, this.adfiService.user.period);
    }

    approveReserve() {
        const dialog = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Cerrar Documento: Información de constitución de Reservas',
                content: `<p>¿Está seguro/a de Cerrar la edición?</p><p>Tenga en cuenta que una vez cerrado el documento de información de constitución de reserva no podrá modificar la información registrada.</p>`,
                action: ENUM_ACTIONS.DELETE
            } as ConfirmDialogData
        });
        dialog.beforeClosed().subscribe((ok) => {
            if (ok) {
                const next = (val) => {
                    this.loadingReserve();
                };
                const error = (val) => {
                    this.adfiGrowlService.errorMessage(val);
                    this.loading.hide();
                };
                this.loading.show();
                this.saveGraphql({
                    id: this.idReserve,
                    state: 'APPRO'
                }, next, error);
            } else {
                this.loading.hide();
            }
        });
    }

    editJustification(row: any, list: ListComponent) {
        const dialogRef = UIHelper.editItem(this.matDialog, row.id, 'Reserve', 'Egresos', undefined, 'addJustify');
        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                list.reload();
            }
        });
    }

    validateComplete(next){
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
            this.loading.hide();
        };
        this.graphqlService.createEntity('Action', {nameClass: 'RequestReserveJustification'}, 'message', {next, error});
    }

    continueDoc() {
        this.stepOne = true;
        setTimeout(() => {
            this.loading.hide();
            this.stepper.next();
        }, 100);
        /*this.loading.show();
        const next = (d) => {
            this.stepOne = true;
            setTimeout(() => {
                this.loading.hide();
                this.stepper.next();
            }, 1000);        };
        this.validateComplete(next);*/
    }

    toInitState() {
        this.loading.show();
        const next = (val) => {
            this.loadingReserve();
            this.stepFour = false;
            setTimeout(() => {
                this.stepper.previous();
            }, 100);
        };
        const error = (val) => {
            this.adfiGrowlService.errorMessage(val);
            this.loading.hide();
        };
        this.saveGraphql({
            id: this.idReserve,
            state: 'INI'
        }, next, error);
    }
}

import {Apollo, gql} from 'apollo-angular';
import {Component, Inject, OnInit, Optional} from '@angular/core';
import {AdfiService} from '@services/adfi-service';

import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataEntity, Entity, Options, Property, PropertyTypes} from '@Interfaces/index';
import {AdfiRest} from '@services/adfi-rest';

import {AdfiUtil} from '@Components/util/adfi-util';
import {UIHelper} from '@Components/util/UIHelper';
import {LoadingService} from '@services/loading.service';
import {SelectGroupComponent} from '@Components/ui/select-group/select-group.component';
import {AdfiGrowlService} from '@services/adfi-growl.service';

@Component({
    selector: 'app-display-item',
    templateUrl: './display-item.component.html',
    styleUrls: ['./display-item.component.css']
})
export class DisplayItemComponent implements OnInit {

    TYPES = PropertyTypes;
    OPTION = Options;
    configTable: Entity;
    columns: Property[];
    item: any;
    classLabel = 'col-4 col-md-3';
    classContent = 'col-8 col-md-9';

    constructor(private adfiService: AdfiService,
                private apollo: Apollo,
                @Optional() private dialogRef: MatDialogRef<DisplayItemComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DataEntity,
                public dialog: MatDialog,
                private loader: LoadingService,
                private growl: AdfiGrowlService) {
    }

    ngOnInit() {
        this.loadConfig();
    }

    private loadConfig() {
        this.loader.show();
        this.adfiService.post<Entity>(AdfiRest.getConfigData,
            {
                entity: this.data.entityName, module: this.data.module,
                formGroup: this.data.group, identifier: this.data.item
            }).subscribe((value: Entity) => {
                this.configTable = value;
                this.columns = this.configTable.properties.filter(
                    prop => !(prop.options[Options.HIDDEN_DISPLAY]));
                this.loadItem();
            }
        , (e) => {
           this.growl.errorMessage(e);
           this.loader.hide();
           this.dialogRef?.close();
        });
    }

    loadItem() {
        this.loader.show();
        const id = this.data.item;
        this.apollo.query({
            query: this.getQueryItem(id)
        }).subscribe(value => {
            this.loader.hide();
            this.item = value.data[this.configTable.singular];
        });
    }

    private getQueryItem(id) {
        return gql`
            query{
                ${this.configTable.singular}(id:"${id}"){
                id
                ${AdfiUtil.getBasicFieldsGraphql(this.columns)}
                ${AdfiUtil.getFieldManyToOne(this.columns)}
                ${AdfiUtil.getFieldOneToMany(this.columns)}
                ${AdfiUtil.getFieldManyToMany(this.columns)}
                ${AdfiUtil.getFieldOneToOne(this.columns)}
            }
            }
        `;
    }

    getDataDropdown(field: Property) {
        const val = field.options[this.TYPES.DROPDOWN].find(value =>
            value.key === this.item[field.fieldName]
        );
        return val ? val.value : undefined;
    }


    getDataOneToMany(row: any, property: Property) {
        return AdfiUtil.getDataOneToMany(row, property, true);
    }

    getDataManyToOne(row: any, property: Property) {
        return AdfiUtil.getDataManyToOne(row, property);
    }

    downloadMediaObject(mediaObject, property: Property, index?) {
        if (index !== undefined) {
            const arr = mediaObject[property.fieldName].split(',');
            AdfiUtil.downloadMediaObject(arr[index], property, this.adfiService.user.period);
        } else {
            AdfiUtil.downloadMediaObject(mediaObject, property, this.adfiService.user.period);
        }
    }

    transformColumnValue(col, obj, property: Property, index) {
        return AdfiUtil.transformColumnValue(AdfiUtil.extractSubfields(obj, col),
            property.customFormatDisplay && property.customFormatDisplay[index] ? property.customFormatDisplay[index] : null,
            obj, property
        );
    }

    editRelation(item: any, field: Property) {
        if (field.options[Options.GROUPS_EDIT]) {
            const dialogGroup = this.dialog.open(SelectGroupComponent, {
                data: {
                    groups: field.options[Options.GROUPS_EDIT],
                },
                maxHeight: '90vh',
                minWidth: '40%'
            });
            dialogGroup.afterClosed().subscribe((group) => {
                if (group) {
                    const dialogRef = UIHelper.editItem(this.dialog, item.id, field.column.entity, field.column.module, null, group.group);
                    dialogRef.afterClosed().subscribe((data) => {
                        if (data) {
                            this.loadItem();
                        }
                    });
                }
            });
        } else {
            const dialogRef = UIHelper.editItem(this.dialog, item.id, field.column.entity, field.column.module, null);
            dialogRef.afterClosed().subscribe((data) => {
                if (data) {
                    this.loadItem();
                }
            });
        }

    }

    getValueCellphone(itemElement: null|string) {
        return itemElement ? itemElement.split(',') : null;
    }
}

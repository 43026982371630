import { AfterViewInit, Component } from '@angular/core';
import { AdfiRest } from '@services/adfi-rest';
import {AdfiService} from '@services/adfi-service';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import * as JSZip from 'jszip';
import {AdfiGrowlService} from '@services/adfi-growl.service';


@Component({
  selector: 'app-presupuesto-inicial',
  templateUrl: './presupuesto-inicial.component.html',
  styleUrls: ['./presupuesto-inicial.component.css']
})
export class PresupuestoInicialComponent implements AfterViewInit{

  downloadedFiles: number;
  total: number;
  generatingZip: boolean;
  percentageGenZip: string;
  period: string;

  constructor(
    public loading: LoadingService,
    private adfiGrowlService: AdfiGrowlService,
    private adfiService: AdfiService,
    private adfiGraphqlService: AdfiGraphqlService,
  ) {
  }

  ngAfterViewInit(): void {
    this.loading.show();
    this.adfiService.getTimeControl(
      'InitialBudget',
      (data: any) => {
          if (data){
            this.period = data.period;
            this.loading.hide();
          }else {
              this.loading.hide();
          }
      },
      (error: any) => {
          this.loading.hide();
          this.adfiGrowlService.errorMessage(error);
      }
  );
  }

  /**
   * Exports presupuesto inicial component
   */
  public export() {
    this.loading.show();
    const callOk = (response) => {
      this.loading.hide();
      AdfiRest.exportCsv(response, 'Presupuesto Inicial.xls');
    };
    const callError = (error) => {
      this.loading.hide();
      this.adfiGrowlService.errorMessage(error);
    };
    this.adfiService.get(AdfiRest.exportPresupuestoInicial, undefined, 'arraybuffer', false).subscribe(callOk, callError);
  }

    async download() {
      this.loading.show();
      const filter = `estado_list: ["C","X"], vigencia:"${this.period}"`;
      const all = await this.adfiGraphqlService.countEntity('presupuestoInicials', filter);
      const next = async (pres) => {
        const zip = new JSZip();
        this.downloadedFiles = 1;
        this.total = pres.length;
        for (const p of pres) {
          if (p.archivo) {
            const binary = await this.adfiService.http.get<any>(
            // @ts-ignore
                `https://storage.googleapis.com/files_initial_budget_${p.vigencia}/${p.archivo}`, {responseType: 'blob'})
                .toPromise();
            zip.file(p.fileName + '.pdf', binary, {binary: true});
            this.downloadedFiles++;
          }
        }
        this.downloadedFiles = 0;
        this.generatingZip = true;
        zip.generateAsync({type: 'blob'},  (metadata)=> {
          this.percentageGenZip = metadata?.percent?.toFixed(0);
        }).then( (blob) => {
          const dataType = 'application/x-zip-compressed';
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: dataType }));
          downloadLink.setAttribute('download', 'presupuestos.zip');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.generatingZip = false;
          this.loading.hide();
        }, e => {
          this.loading.hide();
          this.adfiGrowlService.errorMessage(e);
        });
      };
      const error = (e) => {
        this.loading.hide();
        this.adfiGrowlService.errorMessage(e);
      };
      this.adfiGraphqlService.getEntity('presupuestoInicials', 'archivo fileName vigencia', all, filter, {next, error});

    }
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {AccountsService, DynamicFlatNode, TREE_ACTIONS} from '@services/accounts.service';
import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdfiService} from '@services/adfi-service';
import {AdfiGrowlService} from '@services/adfi-growl.service';

export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);
  get data(): DynamicFlatNode[] { return this.dataChange.value; }
  set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }
  constructor(private treeControl: FlatTreeControl<DynamicFlatNode>,
              private database: AccountsService,
              private alert: AdfiGrowlService,
              private form: FormGroup) {}
  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });
    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  getIndex(node: DynamicFlatNode) {
    /*let index = -1;
    for (let i = 0; i < this.data.length && index === -1; i++) {
      if (this.data[i][TREE_ACTIONS[this.data[i].level].action] === node[TREE_ACTIONS[this.data[i].level].action]) {
        index = i;
      }
    }*/
    return this.data.indexOf(node);
  }

  toggleNode(node: DynamicFlatNode, expand: boolean) {
    node.isLoading = true;
    if (expand) {
      const ok = (children) => {
        let index = this.getIndex(node);
        // const index = this.data.indexOf(node);
        if (!children || index < 0) {
          node.isLoading = false;
          return;
        }
        // close others same level
        let i;
        for (i = index - 1; i >= 0 && this.data[i].level >= node.level; i--) {
          if (this.data[i].level > node.level) {
            for (; i >= 0 && this.data[i].level > node.level; i--) {}
            this.treeControl.collapse(this.data[i]);
            break;
          }
        }
        index = this.getIndex(node);
        for (i = index + 1; i < this.data.length && this.data[i].level === node.level; i++, index++) {}
        if (i < this.data.length && this.data[i].level > node.level) {
          this.treeControl.collapse(this.data[index]);
        }
        // --------------------------------------------------------------------------------
        index = this.getIndex(node);
        this.data.splice(index + 1, 0, ...children);

        // notify the change
        this.dataChange.next(this.data);
        this.form.get(TREE_ACTIONS[node.level].action).setValue(node[TREE_ACTIONS[node.level].action]);
        node.isLoading = false;
      };
      const err = (e) => {
        node.isLoading = false;
        this.alert.errorMessage(e, 'Error al cargar Nivel ' + (node.level + 1));
      };
      this.database.getChildren(node, this.form, ok, err);
    } else {
      this.form.get(TREE_ACTIONS[node.level].action).reset();
      let count = 0;
      const index = this.getIndex(node);
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }
}


@Component({
  selector: 'app-tree-account',
  templateUrl: './tree-account.component.html',
  styleUrls: ['./tree-account.component.css']
})
export class TreeAccountComponent implements OnInit {
  loadSources = false;
  documentForm: FormGroup;
  dataSource: DynamicDataSource;
  treeControl: FlatTreeControl<DynamicFlatNode>;
  @Output() afterSave = new EventEmitter<any>();
  currentAccount: any;
  constructor(public database: AccountsService, private alert: AdfiGrowlService, private service: AdfiService) {}
  getLevel = (node: DynamicFlatNode) => node.level;
  isExpandable = (node: DynamicFlatNode) => node.expandable;
  hasChild = (_: number, nodeData: DynamicFlatNode) => nodeData.expandable;
  ngOnInit() {
    this.documentForm = new FormGroup({
      group: new FormControl('', Validators.required),
      subGroup: new FormControl('', Validators.required),
      major: new FormControl('', Validators.required),
      subAccount: new FormControl('', Validators.required),
      aux1: new FormControl('', Validators.required),
      aux2: new FormControl('', Validators.required),
      aux3: new FormControl('', Validators.required),
      cencos: new FormControl(this.service.user.centroCosto._id),
    });
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.database, this.alert, this.documentForm);
    this.documentForm.get('group').valueChanges.subscribe(() => {
      this.documentForm.get('subGroup').reset();
    });
    this.documentForm.get('subGroup').valueChanges.subscribe(() => {
      this.documentForm.get('major').reset();
    });
    this.documentForm.get('major').valueChanges.subscribe(() => {
      this.documentForm.get('subAccount').reset();
    });
    this.documentForm.get('subAccount').valueChanges.subscribe(() => {
      this.documentForm.get('aux1').reset();
    });
    this.documentForm.get('aux1').valueChanges.subscribe(() => {
      this.documentForm.get('aux2').reset();
    });
    this.documentForm.get('aux2').valueChanges.subscribe(() => {
      this.documentForm.get('aux3').reset();
    });
    this.loadRoots();
  }

  loadRoots() {
    this.loadSources = true;
    const ok = (d) => {
      this.dataSource.data = d;
      this.loadSources = false;
    };
    const err = (e) => {
      this.loadSources = false;
      this.alert.errorMessage(e, 'Error al cargar Nivel 1');
    };
    this.database.initialData(ok, err);
  }

  onSubmit(node: any) {
    if (node) {
      this.afterSave.emit(node);
    }
    return '';
  }

  showAccount(node: any) {
    this.currentAccount = node;
  }
}


/**
 * Errors server
 * @param message 
 * @param [start] 
 * @param [end] 
 * @returns  
 */
export function errorServer(message: string, start = '20211', end = "\n") {
    const index = message.indexOf(start);
    if (index > 0)
        message = message.slice(index + start.length + 1);
    return message.slice(0, message.indexOf(end));
}
export function generateRandomLetter() {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return alphabet[Math.floor(Math.random() * alphabet.length)];
}

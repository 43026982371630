<ng-container *ngIf="configTable && !loadingData">
    <div class="container" *ngIf="hasPermissions; else noPermission">
        <div class="col-12 text-center" *ngIf="displayTitle">
            <h2>{{ titleForm }}</h2>
            <span class="px-3 row justify-content-center" [innerHTML]="data.subtitleHTML"></span>
        </div>
        <form class="col-12" [formGroup]="entityForm" #form="ngForm" (ngSubmit)="save(form)">
            <div class="row">
                <ng-container *ngFor="let field of columns">
                    <ng-container *ngIf="field.formType" [ngSwitch]="field.formType">
                        <ng-container *ngIf="showField(field.fieldName)">
                            <ng-container *ngIf="entityForm.get(field.fieldName); let ctrl">
                                <ng-container *ngSwitchCase="TYPES.DATE">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <input *ngIf="!field.options.novigencia && !field.options.noFutureDate" matInput
                                               [matDatepicker]="picker"
                                               [formControlName]="field.fieldName"
                                               [min]="field.options[OPTION.MIN_DATE] ? entityForm.get(field.options[OPTION.MIN_DATE]).value : field.options[OPTION.MIN_DATE_BACKEND] ? item[field.options[OPTION.MIN_DATE_BACKEND]] : startValidDate"
                                               [max]="field.options[OPTION.MAX_DATE] ? entityForm.get(field.options[OPTION.MAX_DATE]).value : field.options[OPTION.MAX_DATE_BACKEND] ? item[field.options[OPTION.MAX_DATE_BACKEND]] : endValidDate"
                                               (click)="picker.open()" (keydown)="false" readonly
                                               [required]="fieldsRequired[field.fieldName]">
                                        <input *ngIf="field.options.novigencia && !field.options.noFutureDate" matInput [matDatepicker]="picker"
                                               [min]="field.options[OPTION.MIN_DATE] ? entityForm.get(field.options[OPTION.MIN_DATE]).value : 0"
                                               [formControlName]="field.fieldName" (click)="picker.open()" (keydown)="false"
                                               readonly [required]="fieldsRequired[field.fieldName]">
                                        <input *ngIf="field.options.novigencia && field.options.noFutureDate" matInput [matDatepicker]="picker"
                                               [min]="field.options[OPTION.MIN_DATE] ? entityForm.get(field.options[OPTION.MIN_DATE]).value : field.options[OPTION.MIN_DATE_CUSTOM]?customMinDateRange:0"
                                               [max]="field.options[OPTION.MAX_DATE_CUSTOM]? customMaxDateRange : todayDate"
                                               [formControlName]="field.fieldName" (click)="picker.open()" (keydown)="false"
                                               readonly [required]="fieldsRequired[field.fieldName]">
                                        <input *ngIf="!field.options.novigencia && field.options.noFutureDate" matInput [matDatepicker]="picker"
                                               [formControlName]="field.fieldName" (click)="picker.open()" (keydown)="false"
                                               [min]="field.options[OPTION.MIN_DATE] ? entityForm.get(field.options[OPTION.MIN_DATE]).value : field.options[OPTION.MIN_DATE_BACKEND] ? item[field.options[OPTION.MIN_DATE_BACKEND]] : startValidDate"
                                               [max]="todayDate"
                                               readonly [required]="fieldsRequired[field.fieldName]">
                                        <mat-datepicker-toggle matSuffix [for]="picker"
                                                               *ngIf="data.action !== ENUM_ACTIONS.VIEW"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <button mat-icon-button matSuffix
                                                [disabled]="ctrl.disabled"
                                                type="button"
                                                color="warn"
                                                *ngIf="data.action !== ENUM_ACTIONS.VIEW && ctrl.value"
                                                (click)="removeItem(field.fieldName)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.MONTH">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <app-month-picker #mp [formControlName]="field.fieldName" useFormControl="true"
                                                          [required]="fieldsRequired[field.fieldName]" [style]="field.options[OPTION.VIEW_MODE] ? field.options[OPTION.VIEW_MODE] : 'Selector'">
                                        </app-month-picker>
                                        <mat-datepicker-toggle *ngIf="field.options[OPTION.VIEW_MODE] !== 'Dropdown'" matSuffix [for]="mp.picker"></mat-datepicker-toggle>
                                        <button mat-icon-button matSuffix
                                                [disabled]="ctrl.disabled"
                                                type="button"
                                                color="warn"
                                                *ngIf="ctrl.value && field.options[OPTION.VIEW_MODE] !== 'Dropdown'"
                                                (click)="removeItem(field.fieldName)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <mat-hint class="form-error" *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-hint>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.DATE_TIME">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <input matInput [formControlName]="field.fieldName" [matDatetimepicker]="datetimePicker" [required]="fieldsRequired[field.fieldName]" autocomplete="false">
                                        <mat-datepicker-toggle matSuffix [for]="datetimePicker"
                                                               *ngIf="data.action !== ENUM_ACTIONS.VIEW"></mat-datepicker-toggle>
                                        <mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                                        <button mat-icon-button matSuffix
                                                [disabled]="ctrl.disabled"
                                                type="button"
                                                color="warn"
                                                *ngIf="data.action !== ENUM_ACTIONS.VIEW && ctrl.value"
                                                (click)="removeItem(field.fieldName)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.TEXT_AREA">
                                    <div class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label style="font-size: small; white-space: pre-wrap; hyphens: auto" *ngIf="field.options[OPTION.LABEL_OVER]">{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <mat-form-field appearance="legacy" class="full-width">
                                            <mat-label *ngIf="!field.options[OPTION.LABEL_OVER]">{{ field.label ? field.label : field.fieldName }}</mat-label>
                                            <mat-label *ngIf="field.options[OPTION.LABEL_OVER]">{{field.options[OPTION.PLACEHOLDER]}}</mat-label>
                                            <textarea matInput [formControlName]="field.fieldName"
                                                      (input)="toUppercase(field)" [required]="fieldsRequired[field.fieldName]"></textarea>
                                            <mat-error
                                                    *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                            <mat-hint *ngIf="field.column.length"
                                                      align="end">{{ctrl.value ? ctrl.value.length : 0}}
                                                /{{field.column.length}}</mat-hint>
                                            <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.NUMBER">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <input matInput [formControlName]="field.fieldName" appNumberDirective
                                               [required]="fieldsRequired[field.fieldName]">
                                        <mat-hint *ngIf="field.column?.length"
                                                  align="end">{{ctrl.value ? ctrl.value.length : 0}}
                                            /{{field.column.length}}</mat-hint>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.DECIMAL">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <input matInput [formControlName]="field.fieldName" appDecimalNumberDirective
                                               [required]="fieldsRequired[field.fieldName]">
                                        <mat-hint *ngIf="field.column?.length"
                                                  align="end">{{ctrl.value ? ctrl.value.length : 0}}
                                            /{{field.column.length}}</mat-hint>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.TEXT">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <input matInput [formControlName]="field.fieldName" (input)="toUppercase(field)"
                                               [required]="fieldsRequired[field.fieldName]">
                                        <mat-hint *ngIf="field.column?.length"
                                                  align="end">{{ctrl.value ? ctrl.value.length : 0}}
                                            /{{field.column.length}}</mat-hint>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.FILE">
                                    <mat-form-field appearance="legacy"
                                                    class="inp-file {{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <ngx-mat-file-input #removableInput [formControlName]="field.fieldName"
                                                            placeholder="{{ field.label ? field.label : field.fieldName }}"
                                                            accept="{{field.options[OPTION.FILE_TYPE]}}"
                                                            [required]="fieldsRequired[field.fieldName]"
                                                            [multiple]="field.options[OPTION.MULTIPLE_FILE]"
                                                            (ngModelChange)="changeFile(field, $event)"
                                                            color="primary" (click)="removableInput.openFilePicker()">
                                        </ngx-mat-file-input>
                                        <div matSuffix>
                                            <mat-icon class="pointer" color="warn" *ngIf="!removableInput.empty" (click)="removableInput.ngControl.reset(); removableInput.ngControl.control.markAsTouched()">clear</mat-icon>
                                        </div>
                                        <mat-error *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">
                                            {{getError(field, ctrl)}}
                                        </mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.EDITOR">
                                    <div class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <div class="row justify-content-between pt-1">
                                            <mat-label class="col-8"> {{ field.label ? field.label : field.fieldName }}
                                                <label class="d-inline mat-error" *ngIf="fieldsRequired[field.fieldName]">*</label>
                                            </mat-label>
                                            <mat-slide-toggle color="primary" (change)="field['toogleAdf'] = $event.checked" [disabled]="ctrl.disabled"
                                                              checked class="pr-3">Editor
                                            </mat-slide-toggle>
                                        </div>
                                        <div class="row pt-1 pb-1">
                                            <div *ngIf="field['toogleAdf'] === false || ctrl.disabled" class="col-12">
                                                <div class="col-12 mat-elevation-z2"
                                                     [innerHTML]="entityForm.controls[field.fieldName].value"
                                                     style="max-height: 300px; overflow-y: scroll"></div>
                                            </div>
                                            <editor *ngIf="!ctrl.disabled && (field['toogleAdf'] === undefined || field['toogleAdf'])"
                                                    [formControlName]="field.fieldName"
                                                    class="col-12"
                                                    [init]="{
                                                        language: 'es',
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                          'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code',
                                                          'fullscreen insertdatetime media table paste wordcount'
                                                        ],
                                                        base_url: '/assets',
                                                        suffix: '.min',
                                                        toolbar:
                                                        'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
                                          }">
                                            </editor>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.CHECKBOX">
                                    <div class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-checkbox [formControlName]="field.fieldName"
                                                      [required]="fieldsRequired[field.fieldName]">{{ field.label ? field.label : field.fieldName }}</mat-checkbox>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.CURRENCY">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <span [class.disabled]="!ctrl.enabled" *ngIf="ctrl.value && !field.options[OPTION.HIDDEN_SYMBOL]" class="prefix-inp-form">$</span>
                                        <input matInput [formControlName]="field.fieldName" appCurrencyDirective [class.inp-currency]="ctrl.value && !field.options[OPTION.HIDDEN_SYMBOL]"
                                               (valueChange)="fireCurrency(field.fieldName, $event)"
                                               [required]="fieldsRequired[field.fieldName]">
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.DROPDOWN">
                                    <div class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label style="font-size: small; white-space: pre-wrap; hyphens: auto" *ngIf="field.options[OPTION.LABEL_OVER]">{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <mat-form-field appearance="legacy" class="full-width">
                                            <mat-label *ngIf="!field.options[OPTION.LABEL_OVER]">{{ field.label ? field.label : field.fieldName }}</mat-label>
                                            <mat-label *ngIf="field.options[OPTION.LABEL_OVER]">{{field.options[OPTION.PLACEHOLDER]}}</mat-label>
                                            <mat-select [formControlName]="field.fieldName" [required]="fieldsRequired[field.fieldName]">
                                                <mat-option *ngFor="let opt of field.options[TYPES.DROPDOWN] | filterDropdown:data.action " [value]="opt.key">
                                                    {{opt.value}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                    *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                            <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.MANY_TO_ONE">
    
                                    <mat-form-field appearance="legacy" class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}" *ngIf="field.options[OPTION.MAT_SELECT]; else elseSelect">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <mat-select [formControlName]="field.fieldName" [required]="fieldsRequired[field.fieldName]" [disabled]="data.action === ENUM_ACTIONS.EDIT && field.options[OPTION.DISABLED_EDIT]">
                                        <ng-container *ngFor="let value of listValues.get(field.fieldName)">
                                            <mat-option *ngIf="AdfiUtil.getDataManyToOne(value, field); let content" [value]="value" [matTooltip]="content">
                                            {{content}}
                                            </mat-option>
                                        </ng-container>
                                        </mat-select>
                                        <mat-progress-bar class="over-head" mode="indeterminate" *ngIf="loadingListValues.get(field.fieldName)"></mat-progress-bar>
                                        <mat-error *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                    <ng-template #elseSelect>
                                        <mat-form-field appearance="legacy" class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <input matInput readonly style="cursor:pointer;" [formControlName]="field.fieldName"
                                            (click)="selectItem({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT, filterCenter: field.filterCenter }, field)"
                                            [required]="fieldsRequired[field.fieldName]" [disabled]="ctrl.disabled || field.options[OPTION.DISABLED_EDIT]">
                                    
                                        <button mat-icon-button matSuffix type="button" [disabled]="ctrl.disabled || field.options[OPTION.DISABLED_EDIT]"
                                            *ngIf="data.action !== ENUM_ACTIONS.VIEW"
                                            (click)="selectItem({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT}, field)">
                                            <mat-icon matTooltip="Agregar">playlist_add</mat-icon>
                                        </button>
                                        <button mat-icon-button matSuffix [disabled]="ctrl.disabled || !ctrl.value || field.options[OPTION.DISABLED_EDIT]"
                                            type="button" color="warn"
                                            *ngIf="data.action !== ENUM_ACTIONS.VIEW && ctrl.value"
                                            (click)="removeItem(field.fieldName)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <mat-error *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                        </mat-form-field>
                                    </ng-template>
                                </ng-container>
    
                                <ng-container *ngSwitchCase="TYPES.ONE_TO_ONE">
                                    <mat-form-field appearance="legacy" class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}" *ngIf="field.options[OPTION.MAT_SELECT]; else elseSelect">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <mat-select [formControlName]="field.fieldName" [required]="fieldsRequired[field.fieldName]">
                                            <mat-option *ngFor="let value of listValues.get(field.fieldName)" [value]="value">
                                                {{ AdfiUtil.getDataManyToOne(value, field) }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-progress-bar class="over-head"  mode="indeterminate" *ngIf="loadingListValues.get(field.fieldName)"></mat-progress-bar>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                    </mat-form-field>
                                    <ng-template #elseSelect>
                                        <mat-form-field appearance="legacy"
                                                        class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                            <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                            <input matInput readonly style="cursor:pointer;" [formControlName]="field.fieldName"
                                                   (click)="selectItem({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT, filterCenter: field.filterCenter }, field)"
                                                   [required]="fieldsRequired[field.fieldName]">
    
                                            <button mat-icon-button matSuffix
                                                    type="button"
                                                    [disabled]="ctrl.disabled"
                                                    *ngIf="data.action !== ENUM_ACTIONS.VIEW"
                                                    (click)="selectItem({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT}, field)">
                                                <mat-icon matTooltip="Agregar">playlist_add</mat-icon>
                                            </button>
                                            <button mat-icon-button matSuffix
                                                    type="button"
                                                    color="warn"
                                                    [disabled]="ctrl.disabled"
                                                    *ngIf="data.action !== ENUM_ACTIONS.VIEW && ctrl.value"
                                                    (click)="removeItem(field.fieldName)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                            <mat-error
                                                    *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                            <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                        </mat-form-field>
                                    </ng-template>
                                </ng-container>
    
                                <ng-container *ngSwitchCase="TYPES.ONE_TO_MANY">
                                    <mat-accordion
                                            class="my-3 {{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    {{ field.label ? field.label : field.fieldName }}
                                                </mat-panel-title>
                                                <mat-panel-description>
    
                                                    <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                                </mat-panel-description>
                                                <mat-panel-description>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div>
    
                                                <mat-form-field appearance="legacy" style="width: 100%"
                                                                *ngFor="let item of ctrl.value"
                                                >
                                                    <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
    
                                                    <input
                                                            matInput readonly [value]="item" style="cursor:pointer;" [disabled]="ctrl.disabled"
                                                            [required]="fieldsRequired[field.fieldName]">
    
                                                    <button mat-icon-button matSuffix
                                                            type="button"
                                                            color="warn"
                                                            [disabled]="ctrl.disabled"
                                                            *ngIf="data.action !== ENUM_ACTIONS.VIEW && ctrl.value"
                                                            (click)="removeItems(field.fieldName, item)">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                    <mat-error
                                                            *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                                </mat-form-field>
                                                <mat-form-field appearance="legacy" style="width: 100%">
                                                    <input matInput readonly style="cursor: pointer" [disabled]="ctrl.disabled"
                                                           (click)="selectItems({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT }, field)"
                                                           [required]="fieldsRequired[field.fieldName]"/>
    
                                                    <button mat-icon-button matSuffix
                                                            type="button"
                                                            [disabled]="ctrl.disabled"
                                                            *ngIf="data.action !== ENUM_ACTIONS.VIEW"
                                                            (click)="selectItems({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT}, field)">
                                                        <mat-icon matTooltip="Agregar">playlist_add</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.MANY_TO_MANY">
                                    <div class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-expansion-panel style="width: 100%" class="mat-elevation-z2">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    {{ field.label ? field.label : field.fieldName }}
                                                </mat-panel-title>
                                                <mat-panel-description>
    
                                                    <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <mat-form-field appearance="legacy" style="width: 100%"
                                                            *ngFor="let item of ctrl.value">
                                                <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                                <input matInput readonly class="pointer" [value]="item"
                                                       [required]="fieldsRequired[field.fieldName]">
                                                <button mat-icon-button matSuffix
                                                        type="button"
                                                        color="warn"
                                                        [disabled]="ctrl.disabled"
                                                        *ngIf="data.action !== ENUM_ACTIONS.VIEW && ctrl.value"
                                                        (click)="removeItems(field.fieldName, item)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <mat-error
                                                        *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="legacy" style="width: 100%">
                                                <input matInput readonly class="pointer"
                                                       (click)="selectItemsManyToMany({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT }, field)"
                                                       [required]="fieldsRequired[field.fieldName]"/>
                                                <button mat-icon-button matSuffix
                                                        type="button"
                                                        [disabled]="ctrl.disabled"
                                                        *ngIf="data.action !== ENUM_ACTIONS.VIEW"
                                                        (click)="selectItemsManyToMany({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT}, field)">
                                                    <mat-icon matTooltip="Agregar">playlist_add</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </mat-expansion-panel>
                                    </div>
                                </ng-container>
    
                                <ng-container *ngSwitchCase="TYPES.MANY_TO_MANY_JSON">
                                    <div class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-expansion-panel style="width: 100%" class="mat-elevation-z2">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    {{ field.label ? field.label : field.fieldName }}
                                                </mat-panel-title>
                                                <mat-panel-description class="mat-panel-description">
    
                                                    <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <mat-form-field appearance="legacy" style="width: 100%"
                                                            *ngFor="let item of ctrl.value">
                                                <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                                <input matInput readonly class="pointer" [value]="item"
                                                       [required]="fieldsRequired[field.fieldName]">
                                                <button mat-icon-button matSuffix
                                                        type="button"
                                                        color="warn"
                                                        [disabled]="ctrl.disabled"
                                                        *ngIf="data.action !== ENUM_ACTIONS.VIEW && ctrl.value"
                                                        (click)="removeItems(field.fieldName, item)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <mat-error
                                                        *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="legacy" style="width: 100%">
                                                <input matInput readonly class="pointer"
                                                       [disabled]="ctrl.disabled"
                                                       (click)="selectItemsManyToManyJson({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT }, field)"
                                                       [required]="fieldsRequired[field.fieldName]"/>
                                                <button mat-icon-button matSuffix
                                                        type="button"
                                                        [disabled]="ctrl.disabled"
                                                        *ngIf="data.action !== ENUM_ACTIONS.VIEW"
                                                        (click)="selectItemsManyToManyJson({module: field.column.module, entityName: field.column.entity, action: ENUM_ACTIONS.SELECT}, field)">
                                                    <mat-icon matTooltip="Agregar">playlist_add</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </mat-expansion-panel>
                                    </div>
                                </ng-container>
    
    
                                <ng-container *ngSwitchCase="TYPES.AUTOLIST">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <input matInput [formControlName]="field.fieldName"
                                               (keyup)="getAutoList(field.fieldName)" [matAutocomplete]="auto"
                                               (input)="toUppercase(field)" [required]="fieldsRequired[field.fieldName]">
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredOptions[field.fieldName] | async"
                                                        [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-progress-bar *ngIf="loading[field.fieldName]" mode="indeterminate"
                                                          class="over-head"></mat-progress-bar>
                                        <mat-hint *ngIf="field.column.length"
                                                  align="end">{{ctrl.value ? ctrl.value.length : 0}}
                                            /{{field.column.length}}
                                        </mat-hint>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="TYPES.SLIDE">
                                    <div class="d-flex align-items-center {{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-slide-toggle color="primary"  [formControlName]="field.fieldName">{{ field.label ? field.label : field.fieldName }}</mat-slide-toggle>
                                    </div>
                                </ng-container>
    
                                <ng-container *ngSwitchCase="TYPES.PASSWORD">
                                    <mat-form-field appearance="legacy"
                                                    class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <input matInput [formControlName]="field.fieldName"
                                               type="password"
                                               [required]="fieldsRequired[field.fieldName]">
                                        <mat-hint *ngIf="field.column?.length"
                                                  align="end">{{ctrl.value ? ctrl.value.length : 0}}
                                            /{{field.column.length}}</mat-hint>
                                        <mat-icon matSuffix *ngIf="field?.helpText" class="icon-help" matTooltip="{{field.helpText}}">help_outline</mat-icon>
                                        <mat-error
                                                *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                    </mat-form-field>
                                </ng-container>
    
                                <ng-container *ngSwitchCase="TYPES.CELLPHONE">
                                    <mat-form-field appearance="legacy" class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <app-tel-input [required]="fieldsRequired[field.fieldName]" [formControlName]="field.fieldName"></app-tel-input>
                                        <mat-error *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                    </mat-form-field>
                                </ng-container>
    
                                <ng-container *ngSwitchCase="TYPES.CUSTOM_COMPONENT">
                                    <mat-form-field appearance="legacy" class="{{field.options[OPTION.CLASS_FORM] ? field.options[OPTION.CLASS_FORM] : 'col-12'}}">
                                        <mat-label>{{ field.label ? field.label : field.fieldName }}</mat-label>
                                        <mat-chip-list #chipList>
                                            <mat-chip *ngFor="let it of listCustomValues.get(field.fieldName)" color="primary" selected>
                                                {{it[field.options[OPTION.CUSTOM_DISPLAY]]}}
                                            </mat-chip>
                                        </mat-chip-list>
                                        <input [matChipInputFor]="chipList" readonly [required]="fieldsRequired[field.fieldName]" [formControlName]="field.fieldName">
                                        <button mat-icon-button matSuffix
                                                type="button"
                                                [disabled]="ctrl.disabled"
                                                *ngIf="data.action !== ENUM_ACTIONS.VIEW"
                                                (click)="selectItemsCustomComponent(field)">
                                            <mat-icon matTooltip="Agregar">playlist_add</mat-icon>
                                        </button>
                                        <mat-error *ngIf="(ctrl.dirty || ctrl.touched) && ctrl.invalid">{{getError(field, ctrl)}}</mat-error>
                                    </mat-form-field>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="row justify-content-end py-3">
                <button *ngIf="data.displayBtnCancel === undefined || data.displayBtnCancel" mat-raised-button type="button" mat-dialog-close class="mx-2">Cancelar</button>
                <button *ngIf="data.displayBtnSave === undefined || data.displayBtnSave" [disabled]="entityForm.invalid" mat-raised-button type="button" color="primary"
                        (click)="save(form)">
                    {{ data.customActionBtnText ? data.customActionBtnText : getButtonText(data.action) }}
                </button>
            </div>
        </form>
    </div>
    <ng-template #noPermission>
        <div class="container">
            <h2>No tiene permisos para realizar esta acción</h2>
        </div>
    </ng-template>
</ng-container>

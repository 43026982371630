import {Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {LoadingComponent} from '@Components/ui/loading/loading.component';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private overlayRef: OverlayRef = null;
    private component;

    constructor(private overlay: Overlay) {}

    public show() {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create();
        }
        if (!this.component) {
            const spinnerOverlayPortal = new ComponentPortal(LoadingComponent);
            this.component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
        }
    }

    public hide() {
        if (this.overlayRef) {
            this.overlayRef.detach();
            if (this.component) {
                this.component.destroy();
            }
            this.component = undefined;
        }
    }
    public active() {
        return this.component !== undefined;
    }
}

<div *ngIf="balanceResource === undefined; else yesBalanceResource"
     class="d-flex flex-column justify-content-center align-items-center" style="height: 60vh">
    <mat-card class="w-75 mat-elevation-z4" style="padding: 3rem; border-radius: 2rem">
        <mat-card-content class="d-flex flex-column justify-content-around align-items-center">
            <img class="w-25 m-4" src="assets/images/adfib.png" alt="">
            <button class="m-5" mat-raised-button color="primary" (click)="requestBalance()">SOLICITAR RECURSO DE
                BALANCE
            </button>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #yesBalanceResource>
    <mat-vertical-stepper linear #stepper>
        <mat-step
                [completed]="!(this.approveResourceBalance || this.approveResourceBalanceAccount) && (closingForm === undefined || (this.balanceResource && (this.balanceResource.state === 'CLO' || this.balanceResource.state === 'UPL')) || ((totalBalanceSource === 0) && (totalBalanceSourceExpenses===totalBalanceResource) && (totalBalanceSourceIngres===totalBalanceResource)  && (totalBalanceSourceIngres === totalBalanceSourceExpenses)))"
                [editable]="closingForm !== undefined && canEditForm">
            <ng-template matStepLabel>Formulario solicitud recursos de balance</ng-template>

            <mat-accordion class="example-headers-align">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            (+) Caja
                        </mat-panel-title>
                        <mat-panel-description>
                            <strong> {{ totalCashBox | currency  }}</strong>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <app-list #listCashBox entityName="CashBox"
                              noActions="true"
                              [customData]="{approve: true, originData: 'FR_BALANCE'}"
                              group="fr_balance"
                              [dataEntity]="{enableAdd: canEditAnything && canEditForm}"
                              [exportableDocs]="false"
                              [defaultQuery]="[{column: 'cencos', value: ''}]"
                              module="ClosingForm" (loadedData)="postLoadCashBox()">

                        <ng-template tableColumn="approve" let-row="row">
                            <mat-slide-toggle [disabled]="!canEditAnything || !canEditForm || approveCashBox === false"
                                              [checked]="row.approve" color="primary"
                                              (change)="approveData($event.checked, row, listCashBox)">
                                {{row.approve ? 'Si' : 'No' }}
                            </mat-slide-toggle>
                        </ng-template>

                        <ng-template tableColumn="footer_description">
                            <strong>Total</strong>
                        </ng-template>
                        <ng-template tableColumn="footer_value">
                            <strong> {{ totalCashBox | currency }}</strong>
                        </ng-template>
                    </app-list>
                </mat-expansion-panel>

                <mat-expansion-panel>

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            (+) Bancos
                        </mat-panel-title>
                        <mat-panel-description>
                            <strong> {{ totalClosingConciliation | currency  }}</strong>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <app-list #listBalanceAccount entityName="AccountBalance"
                              customTitle="Cuentas bancarias"
                              group="balanceResource"
                              [dataEntity]="{enableAdd: false}"
                              [noActions]="true"
                              [exportableDocs]="false"
                              [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'vMaint', value: {exact:'D'}}]"
                              module="ClosingForm" (loadedData)="postLoadAccounts()">

                        <ng-template tableColumn="value" let-row="row">
                            {{ row.value | currency }}
                            <!--button *ngIf="editValueExtract" mat-icon-button aria-label="Editar Saldo"
                                    color="primary"
                                    (click)="editExtract(row)">
                                <mat-icon>edit</mat-icon>
                            </button-->
                        </ng-template>

                        <ng-template tableColumn="loteConciliation" let-row="row">
                            {{ row.loteConciliation | currency }}
                            <button mat-icon-button aria-label="Lote conciliaciones" color="primary"
                                    (click)="createItemConciliation(row)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </ng-template>

                        <ng-template tableColumn="footer_totalConciliation">
                            <strong> {{ totalClosingConciliation | currency }}</strong>
                        </ng-template>
                    </app-list>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            (-) Cuentas por pagar
                        </mat-panel-title>
                        <mat-panel-description>
                            <strong> {{ (totalAccountPayment + totalTreasuryPaymetOrderForm + totalPassivesForm + 
                          totaBillsNotRegisterForm + totalPassivesNotRegisterForm)?.toFixed(2) | currency }}</strong>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <app-list #listAccountPayment entityName="PaymentOrder" module="Egresos" group="not_paid_confirm"
                              [dataEntity]="{customTitle: 'Cuentas por pagar', enableAdd: false}"
                              [noActions]="!canEditAnything || !canEditForm"
                              [exportableDocs]="false"
                              [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'orders', value: ['wps']}]"
                              (loadedData)="postLoadAccountPayment()">
                        <ng-template tableColumn="isAccountPayable" let-row="row">
                            {{row.isAccountPayable ? 'Si' : 'No' }}
                        </ng-template>

                        <ng-template tableColumn="approve" let-row="row">
                            <mat-slide-toggle [checked]="row.approve" color="primary"
                                              [disabled]="!canEditForm || approveAccountPayment === false"
                                              (change)="approveData($event.checked, row, listAccountPayment)">
                                {{row.approve ? 'Si' : 'No' }}
                            </mat-slide-toggle>
                        </ng-template>

                        <ng-template tableColumn="footer_description">
                            <strong>Total</strong>
                        </ng-template>
                        <ng-template tableColumn="footer_valueTransfer">
                            <strong> {{ totalAccountPayment| currency }}</strong>
                        </ng-template>
                    </app-list>

                    <app-list #listTreasuryOrder entityName="TreasuryPaymetOrderForm" module="ClosingForm"
                                      noActions="true"
                                      group="fr_balance"
                                      (loadedData)="postLoadTreasuryPaymetOrder()"
                                      [customData]="{approve: true, originData: 'FR_BALANCE'}"
                                      [dataEntity]="{enableAdd: canEditAnything && canEditForm}"
                                      [exportableDocs]="false"
                                      [defaultQuery]="[{column: 'cencos', value: ''}]">

                                <ng-template tableColumn="approve" let-row="row">
                                    <mat-slide-toggle [checked]="row.approve" color="primary"
                                                      [disabled]="approveTreasuryResources === false"
                                                      (change)="approveData($event.checked, row, listTreasuryOrder)">
                                        {{row.approve ? 'Si' : 'No' }}
                                    </mat-slide-toggle>
                                </ng-template>

                                <ng-template tableColumn="footer_description">
                                    <strong>Total</strong>
                                </ng-template>
                                <ng-template tableColumn="footer_value">
                                    <strong> {{ totalTreasuryPaymetOrderForm| currency }}</strong>
                                </ng-template>

                            </app-list>

                            <app-list #listPassives entityName="PassivesBalance" module="BalanceResource"
                                      noActions="true"
                                      (loadedData)="postLoadPassives()"
                                      [dataEntity]="{enableAdd: false}"
                                      [withPaginator]="false"
                                      [exportableDocs]="false"
                                      [customData]="{approve: true}">

                                <ng-template tableColumn="objet" let-row="row">
                                    {{row.objet}}
                                    <button *ngIf="canEditAnything && canEditForm && row.date && approvePassives" mat-icon-button aria-label="Editar Observación"
                                            color="primary"
                                            (click)="addObservationToPassive(row)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </ng-template>
                                <ng-template tableColumn="approve" let-row="row">
                                    <mat-slide-toggle [checked]="row.approve" color="primary"
                                                      [disabled]="!canEditAnything || approvePassives === false"
                                                      (change)="approvePassive($event.checked, row, listPassives)">
                                        {{row.approve ? 'Si' : 'No' }}
                                    </mat-slide-toggle>
                                </ng-template>

                                <ng-template tableColumn="footer_description">
                                    <strong>Total</strong>
                                </ng-template>
                                <ng-template tableColumn="footer_value">
                                    <strong> {{ totalPassivesForm | currency }}</strong>
                                </ng-template>

                            </app-list>
                            <app-list #listBillsNotRegister entityName="PaymentOrderWP"
                            module="ClosingForm" group="not_paid"
                            [dataEntity]="{customTitle: 'Gastos no registrados en el sistema', enableAdd: false, hiddenActionsTable: !canEditAnything || !canEditForm, exportableDocs: false}"
                            (loadedData)="postLoadBillsNotRegisters()"
                            [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'typePayment', value: 'G'}, {column: 'payByBank', value: false}, {column: 'vigencia', value: 0}]" [customData]="{typePayment: 'G'}">

                      <ng-template tableColumn="footer_value">
                          <strong> {{ totaBillsNotRegisterForm | currency }}</strong>
                      </ng-template>

                  </app-list>

                   
                            <app-list #listPassivesNotRegister entityName="PaymentOrderWP"
                              module="ClosingForm" group="not_paid_fr"
                              [dataEntity]="{customTitle: 'Pasivos no registrados en el sistema', enableAdd: canEditAnything && canEditForm, group: 'not_paid_fr', editGroup: 'not_paid_fr', hiddenActionsTable: !canEditAnything || !canEditForm, exportableDocs: false}"
                              (loadedData)="postLoadPassivesNotRegisters()"
                              [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'typePayment', value: 'R'}, {column: 'vigencia', value: 0}]" [customData]="{typePayment: 'R'}">

                        <ng-template tableColumn="footer_value">
                            <strong> {{ totalPassivesNotRegisterForm | currency }}</strong>
                        </ng-template>

                    </app-list>


                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            (-) Reservas
                        </mat-panel-title>
                        <mat-panel-description>
                            <strong> {{ totalRegisters| currency }}</strong>
                        </mat-panel-description>
                    </mat-expansion-panel-header>


                    <app-list #listRegister entityName="Registro" module="Egresos"
                              customTitle="Reservas"
                              [noActions]="!canEditAnything"
                              [dataEntity]="{enableAdd: false}"
                              group="fr_balance"
                              [exportableDocs]="false"
                              [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'without', value: ['paymentOrder']}]"
                              (loadedData)="postLoadRegisters()">
                        <ng-template tableColumn="footer_totalOrders">
                            <strong>Saldo a aprobar</strong>
                        </ng-template>

                        <ng-template tableColumn="approve" let-row="row">
                            <mat-slide-toggle [checked]="row.approve" color="primary"
                                              [disabled]="approveResources === false"
                                              (change)="approveData($event.checked, row, listRegister)">
                                {{row.approve ? 'Si' : 'No' }}
                            </mat-slide-toggle>
                        </ng-template>

                        <ng-template tableColumn="footer_balance">
                            <strong> {{ totalRegisters| currency }}</strong>
                        </ng-template>
                    </app-list>
                </mat-expansion-panel>
                <mat-expansion-panel disabled class="text-black-im">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="text-black-im">
                            Total
                        </mat-panel-title>
                        <mat-panel-description class="text-black-im">
                            <strong> {{ totalBalanceResource | currency  }}</strong>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </mat-accordion>


            <div class="mt-5">
                <app-list #listBalanceSource entityName="BalanceSource" module="BalanceResource"
                          [dataEntity]="{enableAdd: false}"
                          [noActions]="!canEditAnything || !canEditForm"
                          [exportableDocs]="false"
                          [defaultQuery]="[{column: 'centroCosto', value: ''}]" (loadedData)="postLoadBalanceSource()">

                    <ng-template tableColumn="valueI" let-row="row">
                        {{(row.typePresu === 'I' ? row.value : 0) | currency}}
                    </ng-template>

                    <ng-template tableColumn="valueE" let-row="row">
                        {{ (row.typePresu === 'E' ? row.value : 0) | currency}}
                    </ng-template>

                    <ng-container class="actionsTable">
                        <button mat-raised-button *ngIf="addBalanceSources" color="primary" class="mx-2"
                                (click)="addSourceBalance('I')">
                            Agregar Ingreso
                        </button>
                        <button mat-raised-button *ngIf="addBalanceSources" color="primary" class="mx-2"
                                (click)="addSourceBalance('E')">
                            Agregar Egreso
                        </button>
                    </ng-container>
                    <ng-template tableColumn="footer_typePresu">
                        <strong>Total</strong>
                    </ng-template>
                    <ng-template tableColumn="footer_valueI">
                        <strong> {{  totalBalanceSourceIngres| currency }}</strong>
                    </ng-template>
                    <ng-template tableColumn="footer_valueE">
                        <strong> {{  totalBalanceSourceExpenses | currency }}</strong>
                    </ng-template>
                    <ng-container class="footer">
                        <div class="col-12">
                            <p matTooltip="Recuerde que la diferencia final debe ser cero"
                               class="text-center justify-content-center" style="font-size: 1rem"><strong
                                    class="justify-content-center">
                                Diferencia (Ingresos - Egresos): {{  totalBalanceSource | currency }}</strong></p>
                            <div class="row justify-content-center">
                                <div class="col-8 col-md-6 red-alert text-center"
                                     *ngIf="totalBalanceResource < 0 else slide"
                                     style="padding: 0.2rem; border-radius: 0.5rem">
                                    <p style="font-size: 1rem">Por favor verifique de nuevo los pasos anteriores del
                                        formulario porque su saldo de recurso de balance es negativo</p>
                                </div>
                                <ng-template #slide>
                                    <div class="col-8 col-md-6 red-alert text-center"
                                         *ngIf="totalBalanceSourceIngres > totalBalanceResource else slider"
                                         style="padding: 0.2rem; border-radius: 0.5rem">
                                        <p style="font-size: 1rem">Recuerde que no puede asignar un valor mayor al total
                                            del recurso de balance</p>
                                    </div>
                                    <ng-template #slider>
                                        <div class="col-8 col-md-6 text-center row-green"
                                             style="padding: 0.2rem; border-radius: 0.5rem">
                                            <p class="green-text" style="font-size: 1rem"><strong> Recurso por
                                                distribuir en
                                                presupuesto {{ ((totalBalanceResource * 100) - (totalBalanceSourceIngres * 100)) / 100 | currency}}</strong>
                                            </p> <br>
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>

                    </ng-container>
                </app-list>
            </div>
            <div class="row mt-4">
                <div *ngIf="!this.approveResourceBalance && !this.approveResourceBalanceAccount"
                     class="col d-flex justify-content-center">
                    <button mat-raised-button color="primary"
                            (click)="continue()"
                            [disabled]="(totalBalanceSource !== 0) || (totalBalanceSourceExpenses!==totalBalanceResource) || (totalBalanceSourceIngres!==totalBalanceResource)  || (totalBalanceSourceIngres !== totalBalanceSourceExpenses)">
                        Confirmar Información
                    </button>
                </div>


                <div *ngIf="this.approveResourceBalance && balanceResource.state === 'ACS'"
                     class="col d-flex justify-content-around">
                    <button mat-raised-button color="primary"
                            (click)="approveResource()">
                        APROBAR RECURSO DE BALANCE
                    </button>

                    <button mat-stroked-button
                            color="warn"
                            (click)="denyResource('DENY')">
                        DENEGAR RECURSO DE BALANCE
                    </button>
                </div>


                <div *ngIf="this.approveResourceBalanceAccount && (balanceResource.state === 'UPL' || balanceResource.state === 'DENY')"
                     class="col d-flex justify-content-around">
                    <button mat-raised-button color="primary"
                            (click)="approveResourceAccount()">
                        APROBAR RECURSO DE BALANCE
                    </button>

                    <button mat-stroked-button
                            color="warn"
                            (click)="denyResource('DCS')">
                        DENEGAR RECURSO DE BALANCE
                    </button>

                    <button mat-raised-button color="primary"
                            (click)="downloadAuthorization(true)">
                        Previsualizar Certificado
                    </button>

                    <button mat-raised-button color="primary"
                            (click)="downloadRequest()">
                        Descargar Solicitud
                    </button>

                </div>

                <div *ngIf="(this.approveResourceBalanceAccount || this.approveResourceBalance) && (balanceResource.state === 'INI' || balanceResource.state === 'CLO' || balanceResource.state === 'DCS')"
                     class="d-flex justify-content-center w-100">
                    <p class="text-center row-green w-50 green-text"
                       style="padding: 0.2rem; border-radius: 0.5rem; font-size: 1rem"><strong>
                        RECURSO DE BALANCE EN PROCESO DE SOLICITUD</strong>
                    </p>
                </div>

                <div *ngIf="(this.approveResourceBalance) && balanceResource.state === 'UPL'"
                     class="d-flex justify-content-center w-100">
                    <p class="text-center row-green w-50 green-text"
                       style="padding: 0.2rem; border-radius: 0.5rem; font-size: 1rem"><strong>
                        RECURSO DE BALANCE EN PROCESO DE APROBACIÓN POR PARTE DE CONTADOR</strong>
                    </p>
                </div>

                <div *ngIf="(this.approveResourceBalanceAccount) && balanceResource.state === 'ACS'"
                     class="d-flex justify-content-center w-100">
                    <p class="text-center row-green w-50 green-text"
                       style="padding: 0.2rem; border-radius: 0.5rem; font-size: 1rem"><strong>
                        RECURSO DE BALANCE EN PROCESO DE APROBACIÓN POR PARTE DE LÍDER DE GESTIÓN FINANCIERA</strong>
                    </p>
                </div>

                <div *ngIf="balanceResource.state === 'APPRO'"
                     class="d-flex justify-content-center w-100">
                    <p class="text-center row-green w-50 green-text" style="font-size: 1rem; padding: 1rem; border-radius: 0.5rem"><strong>
                        RECURSO DE BALANCE APROBADO</strong>
                    </p> <br>
                </div>

                <div *ngIf="(!this.approveResourceBalance) && (balanceResource.state === 'DENY' || balanceResource.state === 'DCS')"
                     class="d-flex justify-content-center w-100">
                    <p class="row-red w-50 text-center black-text"
                       style="font-size: 1rem; padding: 1rem; border-radius: 0.5rem"><strong>
                        RECURSO DE BALANCE DENEGADO
                        POR {{ balanceResource.state === 'DENY' ? 'EL LIDER DE GESTIÓN FINANCIERA' : 'CONTADOR SEC'}} </strong>
                        <a class="stretched-link black-text" style="text-decoration-line: underline; cursor: pointer"
                           (click)="enableHistory()">{{!showHistory ? 'Ver observaciones' : 'Ocultar observaciones'}}</a>
                    </p> <br>
                </div>
                <div *ngIf="((!this.approveResourceBalance) && showHistory) || approveResourceBalanceAccount || canSeeObservations" class="w-100">
                    <app-list entityName="HistoryResource" module="BalanceResource"
                              [dataEntity]="{enableAdd: false, customTitle: 'Observaciones'}"
                              [defaultQuery]="[{column:'balanceResource', value: balanceResource._id }]"></app-list>
                </div>

            </div>
        </mat-step>
        <mat-step
                [editable]="!(this.approveResourceBalance || this.approveResourceBalanceAccount) && ((totalBalanceSource === 0) && (totalBalanceSourceExpenses===totalBalanceResource) && (totalBalanceSourceIngres===totalBalanceResource)  && (totalBalanceSourceIngres === totalBalanceSourceExpenses)) && (balanceResource.state === 'INI' || balanceResource.state === 'CLO' || balanceResource.state === 'DCS')">
            <ng-template matStepLabel>Generar PDF</ng-template>
            <div class="row">
                <div class="col-12 d-flex justify-content-around">
                    <button mat-raised-button color="primary"
                            *ngIf="balanceResource.state === 'INI' || balanceResource.state === 'DCS'"
                            (click)="editSignature()">Generar Archivo Borrador
                    </button>
                    <button mat-raised-button color="primary"
                            *ngIf="balanceResource.state === 'INI' || balanceResource.state === 'DCS'"
                            (click)="closForm()">Finalizar diligenciamiento
                    </button>
                    <button mat-raised-button color="primary" *ngIf="balanceResource.state === 'CLO'"
                            (click)="editSignature()">Generar Archivo
                    </button>
                    <button mat-raised-button color="primary" *ngIf="balanceResource.state === 'CLO'"
                            (click)="uploadFile()">Subir Archivo
                    </button>
                </div>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Por Confirmar</ng-template>

            <div
                    class="d-flex flex-column justify-content-center align-items-center" style="height: 60vh">
                <mat-card class="w-75 mat-elevation-z4" style="padding: 3rem; border-radius: 2rem">
                    <mat-card-content class="d-flex flex-column justify-content-around align-items-center">
                        <img class="w-25 m-4" src="assets/images/adfib.png" alt="">
                        <h6>Su solicitud se encuentra en espera de ser aprobada</h6>
                        <div *ngIf="balanceResource.state === 'UPL' || balanceResource.state === 'DENY'"
                             class="d-flex justify-content-center w-100">
                            <p class="text-center row-green w-50 green-text"
                               style="padding: 0.2rem; border-radius: 0.5rem; font-size: 1rem"><strong>
                                RECURSO DE BALANCE EN PROCESO DE APROBACIÓN POR PARTE DE CONTADOR</strong>
                            </p>
                        </div>

                        <div *ngIf="balanceResource.state === 'ACS'"
                             class="d-flex justify-content-center w-100">
                            <p class="text-center row-green w-50 green-text"
                               style="padding: 0.2rem; border-radius: 0.5rem; font-size: 1rem"><strong>
                                RECURSO DE BALANCE EN PROCESO DE APROBACIÓN POR PARTE DE LÍDER DE GESTIÓN
                                FINANCIERA</strong>
                            </p>
                        </div>

                        <div *ngIf="balanceResource.state === 'APPRO'"
                             class="text-center row-green w-50"
                             style="padding: 0.2rem; border-radius: 0.5rem">
                            <p class="green-text" style="font-size: 1rem"><strong>
                                RECURSO DE BALANCE APROBADO</strong>
                            </p> <br>

                            <button mat-raised-button (click)="downloadAuthorization()"><mat-icon>save_alt</mat-icon> DESCARGAR AUTORIZACIÓN</button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

        </mat-step>
    </mat-vertical-stepper>
</ng-template>

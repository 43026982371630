import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import {LoadingService} from '@services/loading.service';
import {AdfiService} from '@services/adfi-service';
import {DateRangeSelectionComponent, ParamDate} from '@Components/date-range-selection/date-range-selection.component';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {PeriodState} from '@Interfaces/index';

export interface ReporteIngresos  {
  fechaInicial: Date;
  fechaFinal: Date;
  rubro: any;
  fuenteFinanciacion: any;
}

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {


  sources: any[];
  loadingSources: boolean;
  loadingPeriod: boolean;
  loadingItems: boolean;
  source;
  selectedDates: ParamDate;
  currentPeriod: number;
  periods: any[];
  items: any[];
  item = [];
  canExportMod = false;
  canExportIng = false;
  canExportCon = false;
  canExportRub = false;

  constructor(
    private matDialog: MatDialog,
    private loading: LoadingService,
    private service: AdfiService,
    private adfiGrowlService: AdfiGrowlService,
    private adfiGraphql: AdfiGraphqlService,
  ) { }

  ngOnInit() {
    this.loadSources();
    this.loadItems();
    this.periods = this.service.periods;
    this.currentPeriod = this.periods && this.periods[0] ? this.periods[0].year : undefined;
    this.canExportIng = this.service.validPermission('ReportExcel', 'ing', PeriodState.NO_STATUS);
    this.canExportCon = this.service.validPermission('ReportExcel', 'concil', PeriodState.NO_STATUS);
    this.canExportMod = this.service.validPermission('ReportExcel', 'mod', PeriodState.NO_STATUS);
    this.canExportRub = this.service.validPermission('ReportExcel', 'rubros', PeriodState.NO_STATUS);
  
  }

  loadSources() {
    this.loadingSources = true;
    const error = (e) => {
      this.loadingSources = false;
      this.adfiGrowlService.errorMessage(e, `Error al cargar las fuentes de financiación`, 5000);
    };
    const next = (d) => {
      this.sources = d;
      this.loadingSources = false;
    };
    this.adfiGraphql.getEntity('prFuentesFinans', 'prfinVNombre', 5, null, {next, error});
  }

  async loadItems() {
    this.loadingItems = true;
    const error = (e) => {
      this.loadingItems = false;
      this.adfiGrowlService.errorMessage(e, `Error al cargar los rubros`, 5000);
    };
    const next = (d) => {
      this.items = d;
      this.loadingItems = false;
    };
    const filter = 'tipoPresu: "I", filter_period_by_year: 0';
    const count = await this.adfiGraphql.countEntity('miniPrs', filter);
    this.adfiGraphql.getEntity('miniPrs', 'minipresu nombre prminNFuente{_id}', count , filter, {next, error});
  }

  filterItem(item: any, param: any) {
    if (param) {
      return item && param === item.prminNFuente._id;
    }
    return true;
  }

  reporteConciliaciones() {
    this.service.download('ConcilitationList', JSON.stringify({period: this.currentPeriod}),
                'fileName',
                'application/xlsx',
                `Reporte de Conciliaciones ${this.currentPeriod}.xlsx`);
  }
  reportModifications() {
    this.service.download('ModificationList', '',
                'fileName',
                'application/xlsx',
                `Reporte Modificaciones.xlsx`);
  }

  reportItemsToAdd(){
    const year = this.selectedDates.endDate.getFullYear();
    const month = String(this.selectedDates.endDate.getMonth() + 1).padStart(2, '0');
    const day = String(this.selectedDates.endDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    this.service.download('ItemsAddList', 
        JSON.stringify({
        startDate: this.selectedDates.startDate.toUTCString(),
        endDate: this.selectedDates.endDate.toUTCString(),
        source: this.source,
        item: this.item.length > 0 ? this.item : null,
      }),
      'fileName',
      'application/xlsx',
      `Reporte de Rubros por Adicionar de ${this.selectedDates.startDate.toJSON().slice(0, 10)} a ${formattedDate}.xlsx`);
  }

  reporteIngresos() {    
    this.service.download('IncomesSourceItem',
        JSON.stringify({
            startDate: this.selectedDates.startDate.toUTCString(),
            endDate: this.selectedDates.endDate.toUTCString(),
            source: this.source,
            item: this.item.length > 0 ? this.item : null,
        }),
        'fileName',
        'application/xlsx',
        `Reporte de Ingreso de ${this.selectedDates.startDate.toJSON().slice(0, 10)} a ${this.selectedDates.endDate.toJSON().slice(0, 10)}.xlsx`);
  }

  selectDatesIncomes() {
    const paramDates: ParamDate = {
      startDate: this.selectedDates ? this.selectedDates.startDate : null,
      endDate: this.selectedDates ? this.selectedDates.endDate : new Date()
    };
    const dialog = this.matDialog.open(DateRangeSelectionComponent, {
      data: paramDates
    });
    dialog.componentInstance.btnText = 'Seleccionar';
    const subs = dialog.componentInstance.afterSelect.subscribe((dates: ParamDate) => {
      subs.unsubscribe();
      this.selectedDates = dates;
      this.reporteIngresos();
      dialog.close();
    });
  }

  selectDatesItems() {
    const paramDates: ParamDate = {
      startDate: this.selectedDates ? this.selectedDates.startDate : null,
      endDate: this.selectedDates ? this.selectedDates.endDate : new Date()
    };
    const dialog = this.matDialog.open(DateRangeSelectionComponent, {
      data: paramDates
    });
    dialog.componentInstance.btnText = 'Seleccionar';
    const subs = dialog.componentInstance.afterSelect.subscribe((dates: ParamDate) => {
      subs.unsubscribe();
      this.selectedDates = dates;
      this.reportItemsToAdd();
      dialog.close();
    });
  }

  changeSource() {
    this.item = [];
  }
}

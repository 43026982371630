import {Component, OnInit} from '@angular/core';
import {AdfiService} from '@services/adfi-service';
import {MatDialog} from '@angular/material/dialog';
import {PeriodState, Permission} from '@Interfaces/index';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {ActivatedRoute} from '@angular/router';
import {Attribute, Column, Type} from '@Components/table/table.component';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {CurrencyPipe} from '@Components/util/currency.pipe';
import {AccountmanSignatureComponent} from '@Components/ui/accountman-signature/accountman-signature.component';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
    selector: 'app-financial-state',
    templateUrl: './financial-state.component.html',
    styleUrls: ['./financial-state.component.scss']
})
export class FinancialStateComponent implements OnInit {

    color = 'rgba(63,81,181,0.65)';
    minDate = new Date(2020, 0, 1);
    maxDate = new Date();
    previous: Date;
    current: Date;
    generateMassive = false;
    levels = [1, 2, 3, 4];
    level = 4;
    comparative = false;
    currentTab = 0;
    forceComparative = false;
    withLevel = true;

    situationCol: Column[] = [
        {
            column: 'ACCOUNT',
            name: 'Código',
            type: Type.Number,
            attr: {} as Attribute
        },
        {
            column: 'NAME_ACCOUNT',
            name: 'Cuenta',
            type: Type.String,
            attr: { classHeader: ''} as Attribute
        },
        {
            column: 'VALUE',
            name: '',
            dynamicName: this.nameColumnValue,
            type: Type.Currency,
            attr: { classCell: 'text-right'} as Attribute
        }
    ];

    havePermissionMassive: any;
    profitLossCol: Column[] = [
        {
            column: 'ACCOUNT',
            name: 'Código',
            type: Type.Number,
            attr: {} as Attribute
        },
        {
            column: 'NAME_ACCOUNT',
            name: 'Cuenta',
            type: Type.String,
            attr: { classHeader: ''} as Attribute
        },
        {
            column: 'VALUE',
            name: '',
            dynamicName: this.nameColumnValue,
            type: Type.Currency,
            attr: { classCell: 'text-right'} as Attribute
        }
    ];

    changeInEquityCol: Column[] = [
        {
            column: 'ACCOUNT',
            name: 'Código',
            type: Type.Number,
            attr: {} as Attribute
        },
        {
            column: 'NAME_ACCOUNT',
            name: 'Cuenta',
            type: Type.String,
            attr: { classHeader: ''} as Attribute
        },
        {
            column: 'NET_CHANGE',
            name: 'Variación',
            type: Type.Currency,
            attr: { classCell: 'text-right'} as Attribute
        }
    ];

        cashFlow: Column[] = [
        {
            column: 'NAME_ACCOUNT',
            name: 'Actividades',
            type: Type.String,
            attr: { classHeader: ''} as Attribute
        },
        {
            column: 'RESULT',
            name: 'Valor',
            type: Type.Currency,
            attr: { classCell: 'text-right'} as Attribute
        }
    ];

    tabs = {
        FinancialState: {
            title: 'Estado de Situación Financiera',
            cols: this.situationCol,
            colsCom: [...this.situationCol,
                {
                    column: 'VALUE_ANT',
                    name: '',
                    dynamicName: this.nameColumnEndValue,
                    type: Type.Currency,
                    attr: { classCell: 'text-right'} as Attribute
                }, {
                    column: 'NET_CHANGE',
                    name: 'Variación Neta',
                    type: Type.Currency,
                    attr: { classCell: 'text-right'} as Attribute
                }, {
                    column: 'PERCENT_CHANGE',
                    name: 'Porcentaje',
                    type: Type.Number,
                    attr: { classCell: 'text-center' } as Attribute
                }],
            rows: []
        },
        ProfitLossState: {
            title: 'Estado de Resultados',
            cols: this.profitLossCol,
            colsCom: [...this.profitLossCol,
                {
                    column: 'VALUE_ANT',
                    name: '',
                    dynamicName: this.nameColumnEndValue,
                    type: Type.Currency,
                    attr: { classCell: 'text-right'} as Attribute
                }, {
                    column: 'NET_CHANGE',
                    name: 'Variación Neta',
                    type: Type.Currency,
                    attr: { classCell: 'text-right'} as Attribute
                }, {
                    column: 'PERCENT_CHANGE',
                    name: 'Porcentaje',
                    type: Type.Number,
                    attr: { classCell: 'text-center'} as Attribute
                }
            ],
            rows: []
        },
        ChangeInEquity: {
            title: 'Estado de Cambios en el Patrimonio',
            cols: this.changeInEquityCol,
            colsCom: this.changeInEquityCol,
            rows: []
        },
        CashFlow: {
            title: 'Estado de Flujo de Efectivo',
            cols: this.cashFlow,
            colsCom: this.cashFlow,
            rows: []
        }
    };
    keys = Object.keys(this.tabs);
    set active(tab) {
        this.currentTab = tab;
        if (this.keys[this.currentTab] === 'ChangeInEquity' || this.keys[this.currentTab] === 'CashFlow' ) {
            if (!this.comparative)
                this.updatedComparative();
            this.comparative = true;
            if (this.level < 2)
                this.level = 4;
            this.forceComparative = true;
        } else {
            this.forceComparative = false;
        }
        this.withLevel = this.keys[this.currentTab] !== 'CashFlow';
        if (!this.tabs[this.keys[tab]].rows.length) {
            this.tryLoadData();
        }
    }

    constructor(public currency: CurrencyPipe, private service: AdfiService, private matDialog: MatDialog, private growl: AdfiGrowlService,
                private route: ActivatedRoute, private loading: LoadingService, private graphqlService: AdfiGraphqlService,
                private gaService: GoogleAnalyticsService) {}

    ngOnInit() {
        this.havePermissionMassive = this.service.validPermission('FinancialState', Permission.VIEW_ALL, PeriodState.NO_STATUS);
    }

    tryLoadData() {
        if (this.comparative) {
            if (this.current && this.previous)
                this.loadData(this.keys[this.currentTab]);
        } else if (this.current) {
            this.loadData(this.keys[this.currentTab]);
        }
    }

    loadData(entity: string) {
        this.gaService.event('Cargar FinancialState' , 'ADFI', this.tabs[this.keys[this.currentTab]].title,  this.service.user.id);
        const next = (d) => {
            if (d && d.fileName) {
                this.tabs[entity].rows = JSON.parse(d.fileName);
                this.loading.hide();
            } else {
                this.growl.error('Información', 'Se ha presentado un error al generar la consulta, por favor contacta a soporte técnico');
                this.loading.hide();
            }
        };
        const filters = this.getFilters();
        if (filters) {
            this.service.getJson(entity, JSON.stringify(filters), 'fileName', next, this.generateMassive ? 'accumulated' : '');
        } else {
            this.growl.warning('Precaución', 'Por favor completa los campos de fecha necesarios');
        }
    }

    getFilters() {
        const data = {
            level: this.level
        };
        if (this.comparative && this.previous && this.current) {
            data['startDate'] = this.previous ? this.previous.toISOString() : null;
            data['endDate'] = this.current ? this.current.toISOString() : null;
            return data;
        } else if (!this.comparative && this.current){
            data['startDate'] = this.current ? this.current.toISOString() : null;
            data['endDate'] = null;
            return data;
        }
        return undefined;
    }

    export(type: string) {
        this.gaService.event('Generar Reporte', 'ADFI', this.tabs[this.keys[this.currentTab]].title + ' ' + type,  this.service.user.id);
        const filters = this.getFilters();
        if (filters) {
            if (type === 'pdf' && !this.generateMassive) {
                const dialogRef = this.matDialog.open(AccountmanSignatureComponent, {
                    maxWidth: '900px',
                    width: '80%',
                    data: {}
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        filters['accountManSiganture'] = result;
                        this.service.download(this.keys[this.currentTab],  JSON.stringify(filters), 'fileName', 'application/' + type, (this.tabs[this.keys[this.currentTab]].title + '_' + this.service.user.centroCosto.deDescripcion).replace(/\s/g, '_')  + '.' + type, this.generateMassive ? 'accumulated' : '');
                    }
                });
            } else {
                this.service.download(this.keys[this.currentTab],  JSON.stringify(filters), 'fileName', 'application/' + type, (this.tabs[this.keys[this.currentTab]].title + '_' + this.service.user.centroCosto.deDescripcion).replace(/\s/g, '_')  + '.' + type, this.generateMassive ? 'accumulated' : '');
            }
        } else {
            this.growl.warning('Precaución', 'Por favor completa los campos de fecha necesarios');
        }
    }

    nameColumnValue(context, table) {
        if(context.current)
            return table.datePipe.transform(context.current, 'MMMM yyyy', null, table.locale).toUpperCase()+' <br>'+ table.datePipe.transform(context.current, 'shortDate', null, table.locale);
        return 'Saldo';
    }
    nameColumnEndValue(context, table) {
        if(context.previous)
            return table.datePipe.transform(context.previous, 'MMMM yyyy', null, table.locale).toUpperCase()+' <br>'+ table.datePipe.transform(context.previous, 'shortDate', null, table.locale);
        return 'Saldo';
    }

    updatedComparative() {
        this.current = null;
        this.previous = null;
        for (const i of this.keys) {
            this.tabs[i].rows = [];
        }
    }

    checkParentClass(ctx, row, table) {
        if (ctx.keys[ctx.currentTab] === 'CashFlow') {
          return row['FONT_WEIGHT'] ? 'font-weight-bold' : '';
        }
        return row['LVL'] > ctx.level - 1 ? '' : 'font-weight-bold';
    }
}

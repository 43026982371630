import {NavRoute} from 'src/app/utils/menu';

import {PrincipalComponent} from './principal/principal.component';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HelpDeskComponent} from '@pages/reports/help-desk/help-desk.component';
import {Entities, Pages, PeriodState} from '@Interfaces/index';
import {BalanceComponent} from '@pages/reports/balance/balance.component';
import {ComptrollershipComponent} from '@pages/reports/comptrollership/comptrollership.component';
import {FinancialStateComponent} from '@pages/reports/financial-state/financial-state.component';
import {TransactionsOfThirdComponent} from '@pages/reports/transactions-of-third/transactions-of-third.component';
import {ContabBooksComponent} from '@pages/reports/contab-books/contab-books.component';
import {AnalitycsComponent} from './analitycs/analitycs.component';
import { PlataformBackupComponent } from './plataform-backup/plataform-backup.component';


export const ReportsRoutes: NavRoute[] = [
  {
    path: Pages.REPORTS_AUX_BUDGET_BOOKS_CONTAB,
    component: ContabBooksComponent
  },
  {
    path: Pages.REPORTS_CONSOLIDATED_BALANCE_SHEET,
    nav: { title: 'Balance Consolidado', icon: 'request_quote'},
    component: BalanceComponent,
    entity: Entities.REPORT_BALANCE
  },
  {
    path: Pages.REPORTS_HELP_DESK,
    nav: { title: 'Mesa de Ayuda', icon: 'contact_support'},
    component: HelpDeskComponent,
    entity: Entities.HELP_DESK
  },
  {
    path: Pages.REPORTS_INCOME_CONCILIATION,
    nav: { title: 'Reportes excel', icon: 'assessment' },
    component: PrincipalComponent,
    entity: Entities.REPORT_EXCEL
  },
  {
    path: Pages.REPORTS_COMPTROLLERSHIP,
    nav: { title: 'Reportes Contraloria', icon: 'saved_search' },
    component: ComptrollershipComponent,
    entity: Entities.REPORT_COMPTROLLERSHIP,
    periodSate: PeriodState.CLOSE
  },
  {
    path: Pages.REPORTS_FINANCIAL_STATE,
    component: FinancialStateComponent,
    entity: Entities.FINANCIAL_STATE,
    periodSate: PeriodState.NO_STATUS,
    data: { type: Entities.FINANCIAL_STATE }
  },
  {
    path: Pages.REPORTS_TRANSACTIONS_THIRD,
    component: TransactionsOfThirdComponent,
    entity: Entities.TE_MOV_EGRE,
    periodSate: PeriodState.NO_STATUS
  },
  {
    path: Pages.ANALITYCS,
    nav: { title: 'Analitycs', icon: 'analytics' },
    component: AnalitycsComponent,
    entity: Entities.ANALITYCS,
    periodSate: PeriodState.NO_STATUS
  },
  {
    path: Pages.PLATAFORM_BACKUP,
    nav: { title: 'Reporte de Plataforma', icon: 'cloud_download' },
    component: PlataformBackupComponent,
    entity: Entities.REPORT_PLATAFORM
  },
];

@NgModule({
  imports: [RouterModule.forChild(ReportsRoutes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }

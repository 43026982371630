import { Component, OnInit, Input, Optional, Inject, AfterContentInit, Output, EventEmitter, OnDestroy, ViewEncapsulation, QueryList } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { A, Z, ZERO, NINE, SPACE } from '@angular/cdk/keycodes';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'serviw-mat-select-filter',
  templateUrl: './serviw-mat-select-filter.component.html',
  styles: [`
  .serviw-field-filter { width: 100%; } 
  .contains-mat-select-search { padding-top: 30px; min-height: 60px; } 
  .serviw-filter-input { color: black !important; }
  `],
  encapsulation: ViewEncapsulation.None
})
export class ServiwMatSelectFilterComponent<I> implements OnInit, AfterContentInit, OnDestroy {

  /**
   * Input  of serviw mat select filter component
   */
  @Input() matSelect: MatSelect;

  /**
   * Output  of serviw mat select filter component
   */
  @Output() onFilter: EventEmitter<Observable<I[]>>;

  /**
   * Value  of serviw mat select filter component
   */
  public value: string;

  /**
   * Subject  of serviw mat select filter component
   */
  private subject: Subject<I[]>;

  /**
   * Mat options of serviw mat select filter component
   */
  private _matOptions: MatOption[];

  /**
   * Items filter of serviw mat select filter component
   */
  private itemsFilter: I[];

  /**
   * Determines whether filter in
   */
  private inFilter: boolean;

  /**
   * Update obs of serviw mat select filter component
   */
  private updateObs: boolean;

  /**
   * Subs  of serviw mat select filter component
   */
  private subs: Subscription;

  /**
   * Creates an instance of serviw mat select filter component.
   * @param [matOption] 
   */
  constructor(
    @Optional() @Inject(MatOption) public matOption: MatOption = null
  ) {
    this.subject = new Subject();
    this.onFilter = new EventEmitter();
    this.itemsFilter = [];
    this.inFilter = false;
    this.value = '';
    this._matOptions = [];
  }

  /**
   * Sets input
   */
  @Input() set options(vals: Observable<I[]>) {
    if (this.inFilter)
      setTimeout(() => { this.subject.next(this.itemsFilter); }, 300);
  }

  /**
   * on init
   */
  ngOnInit() {
    if (this.matOption) {
      this.matOption.disabled = true;
      this.matOption._getHostElement().classList.add('contains-mat-select-search');
    }
  }

  /**
   * after content init
   */
  ngAfterContentInit() {
    const call = (options: QueryList<MatOption>) => {
      if (!this.inFilter && options)
        this._matOptions = options.map((i) => i);
    }
    this.subs = this.matSelect.options.changes.subscribe(call);
    call(this.matSelect.options);
  }

  /**
   * Resets serviw mat select filter component
   */
  public reset() {
    this.value = '';
    this.writeValue('');
  }

  /**
   * Sets filter
   * @param val 
   */
  public setFilter(val: boolean) {
    this.inFilter = val;
    if (!val)
      this.updateObs = val;
  }

  /**
   * Keypress serviw mat select filter component
   */
  public onkeyup(event: KeyboardEvent) {
    const value = event.target['value'];
    if (value !== this.value) {
      this.value = value;
      this.writeValue(value);
    }
  }

  /**
   * Handles keydown
   * @param event 
   */
  handleKeydown(event: KeyboardEvent) {
    if ((event.key && event.key.length === 1) ||
      (event.keyCode >= A && event.keyCode <= Z) ||
      (event.keyCode >= ZERO && event.keyCode <= NINE) ||
      (event.keyCode === SPACE)) {
      event.stopPropagation();
    }

    if (!this.updateObs) {
      this.updateObs = true;
      this.onFilter.emit(this.subject.asObservable());
    }
  }

  /**
   * Writes value
   * @param value 
   */
  private writeValue(value: string) {

    this.value = value;

    this.itemsFilter = this._matOptions.map((m, i) => {
      const label = m.getLabel().toLowerCase();
      if (label.indexOf(this.value.toLowerCase()) > -1)
        return m.value;
    });
    this.itemsFilter = this.itemsFilter.filter((v) => v != undefined);
    this.updateList();
  }

  /**
   * Updates list
   * @param [filter] 
   */
  private updateList(filter = true) {
    if (filter)
      this.subject.next(this.itemsFilter);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

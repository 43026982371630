<div class="row">
    <div class="col-2">
        <mat-form-field appearance="legacy">
            <mat-label>Seleccione rol</mat-label>
        <mat-select [(ngModel)]="roleSelected">
            <mat-option *ngFor="let rol of roles" [value]="rol" >
                {{ rol.nameProfile }}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div class="col-10" *ngIf="roleSelected">
        <mat-tab-group>
            <mat-tab *ngFor="let item of modules  | keyvalue" class="tab-title" [label] = item.key>
                <div class="row">
                    <div class="col-4 my-2" *ngFor="let page of item.value">
                        <mat-card class="full-height ">
                            <mat-card-header>
                              <mat-card-title> {{page.name }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="col-12">
                                    <div *ngFor="let permission of page.permissions" class="m-3">
                                        <mat-slide-toggle [checked]="isChecked(page, permission)" (change)="changePermission(page, permission, $event)">{{permission}}</mat-slide-toggle>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {Alert, TypeAlert, TypeValueAlert} from '@Interfaces/index';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiUtil} from '@Components/util/adfi-util';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    @Input() alert: Alert;
    @Input() module: string;
    @Input() entity: string;
    @Input() values: any = {};
    @Input() group: string;

    message: string;
    displayAlert = false;

    constructor(private adfiGraphqlService: AdfiGraphqlService, private adfiService: AdfiService) {
    }

    ngOnInit() {
        this.displayAlert = !this.alert.groups || this.alert.groups.includes(this.group);
        if (this.displayAlert) {
            this.loadValues();
        }
    }

    private loadValues() {
        const className = `${this.module ? this.module + '\\\\' : ''}${this.entity}`;
        const values = this.getValues();
        const formats = this.alert.formats.split('_');
        const next = (valuesRes) => {
            if (this.validateDisplay(valuesRes)) {
                let message = this.alert.message;
                valuesRes.forEach((value, index) => {
                    const format = formats.length > index ? formats[index] : '';
                    message = message.replace(`$${index}`,  AdfiUtil.formatText(value, format));
                });
                this.message = message;
            }
        };
        this.adfiGraphqlService.loadAlerts(className, this.alert.function, values, next);
    }

    private validateDisplay(values) {
        if (this.alert.onlyDisplay.length > 0) {
            let result = false;
            this.alert.onlyDisplay.forEach(value => {
                if (value.same !== undefined && values[value.item] === value.value) {
                    result = true;
                }
                if (value.noSame !== undefined && values[value.item] !== value.value) {
                    result = true;
                }
                if (value.higher !== undefined && values[value.item] > value.value) {
                    result = true;
                }
                if (value.less !== undefined && values[value.item] < value.value) {
                    result = true;
                }
                return result;
            });
        }
        return true;
    }

    private getValues() {
        const values = [];
        this.alert.values.forEach(value => {
            values.push(this.getValue(value));
        });
        return values.join();
    }

    private getValue(name: string) {
        switch (name) {
            case TypeValueAlert.CENCOS:
                return `"${this.adfiService.user.centroCosto._id}"`;
            default:
                return '';
        }
    }

}

import {Apollo} from 'apollo-angular';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DateRangeSelectionComponent, ParamDate} from '@Components/date-range-selection/date-range-selection.component';

import {MatDialog} from '@angular/material/dialog';
import {AdfiService} from '@services/adfi-service';
import {CurrencyPipe} from '@Components/util/currency.pipe';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {Attribute, Column, Type} from '@Components/table/table.component';
import {DataEntity, Entities, ENUM_ACTIONS, PeriodState} from '@Interfaces/index';
import {UIHelper} from '@Components/util/UIHelper';
import {SelectItemComponent} from '@Components/ui/select-item/select-item.component';
import {AdfiUtil} from '@Components/util/adfi-util';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-budget-execution',
  templateUrl: './budget-execution.component.html',
  styleUrls: ['./budget-execution.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetExecutionComponent implements OnInit {
  type: string;
  source: number;
  level: number;
  selectedDates: ParamDate;
  townOrIe = '';
  itemKey = '';
  parameters: { levels: [], sources: [] };
  filters = {
    sources: []
  };
  typeFilters = [
    {
      name: 'Ingresos / Egresos',
      value: ''
    },
    {
      name: 'Ingresos',
      value: 'I'
    },
    {
      name: 'Egresos',
      value: 'E'
    }
  ];
  columnsIng: Column[] = [
    {
      column: 'BUDGET_CODE',
      name: 'Rubro',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'ITEM_NAME',
      name: 'Nombre',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'INITIAL_BUDGET',
      name: 'Presupuesto Inicial',
      type: Type.Currency,
      attr: {classCell: 'text-right'} as Attribute
    },
    {
      column: 'ADDITIONS',
      name: 'Adición',
      type: Type.Currency,
      attr: {classCell: 'text-right'} as Attribute
    },
    {
      column: 'REDUCTIONS',
      name: 'Reducción',
      type: Type.Currency,
      attr: {classCell: 'text-right'} as Attribute
    },
    {
      column: 'FINAL_BUDGET',
      name: 'Presupuesto Definitivo',
      type: Type.Currency,
      attr: {classCell: 'text-right'} as Attribute
    },
    {
      column: 'ACCUMULATED_COLLECTION',
      name: 'Recaudo Acumulado',
      type: Type.Currency,
      attr: {classCell: 'text-right'} as Attribute
    },
    {
      column: 'FINAL_VALUE',
      name: 'Por Recaudar',
      type: Type.Currency,
      attr: {classCell: 'text-right'} as Attribute
    }
  ];
  columnsEgr: Column[] = [
    {
      column: 'BUDGET_CODE',
      name: 'Rubro',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'ITEM_NAME',
      name: 'Nombre',
      type: Type.String,
      attr: {} as Attribute
    },
    {
      column: 'INITIAL_BUDGET',
      name: 'Presupuesto Inicial',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'ADDITIONS',
      name: 'Adición',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'REDUCTIONS',
      name: 'Reducción',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'CREDITS',
      name: 'Crédito',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'CONTRA_CREDITS',
      name: 'Contra Crédito',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'FINAL_BUDGET',
      name: 'Presupuesto Definitivo',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'AVAILABILITY',
      name: 'Disponibilidad',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'REGISTERS',
      name: 'Registro / Compromiso',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'PAYMENT_ORDERS',
      name: 'Orden de Pago / Obligaciones',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'PAYMENTS',
      name: 'Pagos',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'FINAL_VALUE',
      name: 'Por Comprometer',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    },
    {
      column: 'PAYABLE',
      name: 'Por Pagar',
      type: Type.Currency,
      attr: {classCell: 'text-right', tooltip: true} as Attribute
    }
  ];
  revenues: any[];
  expenses: any[];
  loadingIng: boolean;
  loadingEgr: boolean;
  canSearchConsolidated: boolean;

  private get keyCache(){
    return this.service.user.period + '_DT_BP_' + this.service.user.id;
  }
  constructor( private graphql: Apollo,
               private matDialog: MatDialog,
               private adfiGrowlService: AdfiGrowlService,
               public service: AdfiService,
               public currency: CurrencyPipe,
               public loading: LoadingService,
               private gaService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.canSearchConsolidated = this.service.validPermission(Entities.BUDGET_EXECUTION, 'consolidated', PeriodState.NO_STATUS);
    this.resetFilters();
    this.loadConfigData();
    const cache = AdfiUtil.getFromStorage(this.keyCache);
    if (cache) {
      this.selectedDates = JSON.parse(cache);
      this.selectedDates.startDate = new Date(this.selectedDates.startDate);
      this.selectedDates.endDate = new Date(this.selectedDates.endDate);
    }
  }

  loadConfigData() {
    const next = (d) => {
      if (d && d.fileName) {
        this.parameters = JSON.parse(d.fileName);
        this.checkFilters();
        this.loading.hide();
      } else {
        this.adfiGrowlService.error('Reporte', 'Se ha presentado un error al obtener la información, por favor contacta a soporte técnico');
        this.loading.hide();
      }
    };
    this.service.getJson(
        'GetParametersBudgetExecution',
        JSON.stringify(this.getFilters()),
        'fileName',
        next
    );
  }

  checkFilters() {
    this.filters.sources = this.parameters.sources.length ? this.filters.sources : [];
  }

  getFilters(type = null) {
    return {
      level: this.level,
      type: type ?? this.type,
      sources: this.filters.sources,
      townOrIe: this.townOrIe,
      itemKey: this.itemKey,
      consolidated: this.canSearchConsolidated,
    };
  }

  getAllFilters(type = null) {
    return {
      startDate: this.selectedDates.startDate.toUTCString(),
      endDate: this.selectedDates.endDate.toUTCString(),
      ...this.getFilters(type)
    };
  }

  selectDates() {
    this.loading.show();
    const dialog = this.matDialog.open(DateRangeSelectionComponent, {
      data: {
        startDate: this.selectedDates ? this.selectedDates.startDate : null,
        endDate: this.selectedDates ? this.selectedDates.endDate : null
      } as ParamDate
    });
    dialog.componentInstance.btnText = 'Seleccionar';
    const subs = dialog.componentInstance.afterSelect.subscribe((dates: ParamDate) => {
      subs.unsubscribe();
      this.selectedDates = dates;
      AdfiUtil.saveInStorage(this.keyCache, JSON.stringify(dates));
      this.loadData(this.type);
      dialog.close();
    });
  }

  exportTo(format: string) {
    this.gaService.event('Generar Reporte' , 'ADFI', 'Ejecución Presupuestal ' + format,  this.service.user.id);
    if (!this.loading.active()) {
      if (!this.selectedDates) {
        this.adfiGrowlService.warning('Precaución', 'Por favor selecciona un rango de fechas');
      } else {
        this.service.download(
            'BudgetExecution',
            JSON.stringify(this.getAllFilters()),
            'fileName',
            `application/${format}`,
            AdfiUtil.getNameFile(this.canSearchConsolidated ? 'Ejecucion_Consolidada' : 'Ejecucion', this.selectedDates,
                this.canSearchConsolidated ? undefined : this.service.user.centroCosto.deDescripcion ) + '.xlsx',
            this.canSearchConsolidated ? '' : 'thisCenter'
        );
      }
    }
  }

  loadData(filter: string = '') {
    if (this.selectedDates) {
      let next;
      switch (filter) {
        case 'I':
          this.loadingIng = true;
          next = (d) => {
            if (d && d.fileName) {
              this.loadingIng = false;
              this.revenues = JSON.parse(d.fileName);
            } else {
              this.adfiGrowlService.error('Reporte', 'Se ha presentado un error al obtener la información, por favor contacta a soporte técnico');
            }
            this.loading.hide();
          };
          break;
        case 'E':
          this.loadingEgr = true;
          next = (d) => {
            if (d && d.fileName) {
              this.loadingEgr = false;
              this.expenses = JSON.parse(d.fileName);
            } else {
              this.adfiGrowlService.error('Reporte', 'Se ha presentado un error al obtener la información, por favor contacta a soporte técnico');
            }
            this.loading.hide();
          };
          break;
        case '':
          this.loadData('I');
          this.loadData('E');
          break;
      }
      if (next) {
        this.loading.show();
        this.service.getJson(
            'BudgetExecution',
            JSON.stringify(this.getAllFilters(filter)),
            'fileName',
            next
        );
      }
    } else  {
      this.adfiGrowlService.warning('Advertencia', 'No hay un rango de fechas seleccionado');
    }
  }

  resetFilters() {
    this.selectedDates = null;
    this.level = this.level ? this.level : 4;
    this.type = this.type ? this.type : '';
    this.parameters = {
      levels: this.parameters ? this.parameters.levels : [],
      sources: this.parameters ? this.parameters.sources : []
    };
    this.townOrIe = '';
    this.filters = {
      sources: []
    };
    this.itemKey = '';
  }

  openCenterSearch() {
    const data = {entityName: 'PrCentroCosto', module: '', enableAdd: false} as DataEntity;
    const c = UIHelper.openDialog(SelectItemComponent, this.matDialog, data, ENUM_ACTIONS.SELECT);
    const select = (row) => {
      if (row) {
        if (!this.townOrIe || this.townOrIe.trim() === '') {
          this.townOrIe = '' + row._id;
        } else {
          this.townOrIe += ',' + row._id;
        }
      }
      subs.unsubscribe();
    };
    const subs = c.componentInstance.dialogRef.afterClosed().subscribe(select);
  }

  checkRowColor(ctx, row, table){
    return 'without-highlight without-background budget-lvl-' + (row.BUDGET_CODE ? row.BUDGET_CODE.length : 1);
  }

  omitKey(event){
    const k = event.charCode;
    if (k === 37) {
      const count = (event.currentTarget.value.match(/%/g) || []).length;
      return count <= 1;
    }
    return((k >= 48 && k <= 57) || k === 46);
  }
}

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NavComponent} from '@Components/nav/nav.component';
import {AdfibGuard, ServiwRoutes} from './public/adfib.guard';
import {PATH_HOME, PATH_OUT_OF_SERVICE} from './config/constants';
import {MenuRoutes} from './utils/menu';
import { OutOfServiceComponent } from '@pages/out-of-service/out-of-service.component';

const routes: ServiwRoutes<any> = [
     {
       path: 'login',
       loadChildren: () => import('@pages/login-page/login-page.module').then(m => m.LoginPageModule),
     }, {
       path: PATH_HOME,
       component: NavComponent,
       children: MenuRoutes, // Menu
       canActivate: [AdfibGuard],
       data: {home: true, icon: 'home'}
     },
     {
         path: 'capacitacion',
         loadChildren: () => import('@pages/public/public.module').then(m => m.PublicModule),
     },
     {
        path: 'reviewer-statistics',
        loadChildren: () => import('@pages/reviewer-statistics/reviewer-statistics.module').then(m => m.ReviewerStatisticsModule),
        canActivate: [AdfibGuard],
    },
    //  {
    //      path: PATH_OUT_OF_SERVICE,
    //      component: OutOfServiceComponent
    //  }, 
    {
        path: '**',
        redirectTo: PATH_HOME,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NavRoute} from '../../utils/menu';
import {Entities, Pages, PeriodState, Permission} from '@Interfaces/index';
import {ChangePasswordComponent} from '@pages/user/change-password/change-password.component';
import {DocumentationComponent} from '@pages/user/documentation/documentation.component';


export const RoutesUser: NavRoute[] = [
  {
    path: Pages.CHANGE_DOCUMENTS,
    nav: { title: 'Información', icon: 'admin_panel_settings' },
    component: DocumentationComponent,
    entity: Entities.ROLES_BY_USER,
    permission: Permission.CHANGE_MY_DOCS,
    periodSate: PeriodState.NO_STATUS
  },
  {
    path: Pages.CHANGE_PASSWORD,
    nav: { title: 'Cambio de Contraseña', icon: 'vpn_key' },
    component: ChangePasswordComponent,
    entity: Entities.EMPLOYEE,
    permission: Permission.CHANGE_MY_PASSWORD,
    periodSate: PeriodState.NO_STATUS
  }
];
@NgModule({
  imports: [RouterModule.forChild(RoutesUser)],
  exports: [RouterModule]
})
export class UserRoutingModule { }

import {Component, OnInit} from '@angular/core';
import {AdjustComponent} from '@pages/adjustment/adjust/adjust.component';
import {UIHelper} from '@Components/util/UIHelper';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent extends AdjustComponent implements OnInit{

  typeDoc: any;
  documentTypes: any[];
  centers: any[];
  currentCenter: any;

  get getModule() {
    return 'AG';
  }

  get filterByDocuments() {
    return (this.currentCenter && this.currentCenter._id ? `cencos: "${this.currentCenter._id}", ` : '') + `module: "${this.getModule}", type_id: ${this.typeDoc ? this.typeDoc._id : 100}, order: {  numberDoc: "ASC" }`;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadTypes();
  }

  async loadTypes() {
    this.loading.show();
    const next = (types: any[]) => {
      this.documentTypes = types;
      this.loading.hide();
    };
    const error = (e) => {
      this.loading.hide();
      this.adfiGrowlService.errorMessage(e, 'Error al cargar tipos de documentos');
    };
    const filter = `year: ${this.adfiService.user.period}, proof_year: ${this.adfiService.user.period}, proof_id: 2`;
    const count  = await this.adfiGraphql.countEntity('documentTypeByProofAdjustments', filter);
    this.adfiGraphql.getEntityWithOutIds('documentTypeByProofAdjustments',
        'type {id _id name docDescription}',
        count,
        filter,
        {next, error},
        'type');
  }

  async searchCenters(key: string) {
    const params = `deDescripcion: "${key}"`;
    const total = await this.adfiGraphql.countEntity('prCentroCostos', params);
    this.adfiGraphql.getEntity('prCentroCostos', 'deDescripcion municipio {nombre}',
        total, params, { next: (items) => this.centers = items });
  }

  public newDocument() {
    const refDialog = UIHelper.createItemEntity(this.matDialog, { entityName: 'Document', module: 'Adjustments',
      keepDataObjects: true,
      customReturnAfterSave: ['numberDoc', 'proof {id}', 'type {id}'],
      customData: {
        module: this.getModule
      }});
    refDialog.componentInstance.preSave.subscribe( variables => {
      variables.type = variables.types.type.id;
      delete variables.types;
      variables.cencos = variables.cencos.id;
      variables.proof = variables.proof.id;
    });
    refDialog.componentInstance.postSave.subscribe(d => {
      if (d) {
        this.afterCreateDocument(d);
      }
    });
  }
  displayCenters(item) {
    if (!item) {return null; }
    return item._id + ' - ' + item.deDescripcion;
  }
}

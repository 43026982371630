<section class="videos-section" *ngFor="let section of sections">
    <div class="title-section">
      <img src="{{section.image}}"><h1>{{section.label | uppercase}}</h1>
      <button (click)="hiddeContent(section)">
        <mat-icon [ngClass]="{'arrow-icon': true, 'rotate': !isHiddenStates[section.moduleKey]}">keyboard_arrow_down</mat-icon>
      </button>
    </div>
    <div [ngClass]="{'miniatures-section': true}" id="{{section.moduleKey}}">
      <div class="miniature-container" *ngFor="let video of section.videos">
        <a (click)="openViewVideo(video)">
          <div class="miniature">
            <img src="/assets/images/sticker.png" class="sticker" *ngIf="video.new">
            <img src="https://drive.google.com/thumbnail?id={{ video.imageKey }}" alt="Miniatura del video" class="front-page">
          </div>
        </a>
      </div>
    </div>
</section>
<footer class="footer-form-r">
  <div class="footer-form row justify-content-end mr-2">
      <p style="color: gray;  font-size: 9px">
          Icons made by
          <a class="d-inline" target="_blank" href="https://www.freepik.com" title="Freepik" style="color: dimgray">Freepik</a> from <a class="d-inline" target="_blank" href="https://www.flaticon.com/" title="Flaticon" style="color: dimgray"> Flaticon </a>
      </p>
  </div>
</footer>

import { Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {AdfiService} from '@services/adfi-service';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {DataEntity, Entities, ENUM_ACTIONS, Permission} from '@Interfaces/index';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiRest} from '@services/adfi-rest';
import {map} from 'rxjs/operators';
import {FormComponent} from '@Components/ui/form/form.component';
import {AdfiGrowlService} from '@services/adfi-growl.service';

@Component({
    selector: 'app-view-presupuesto-inicial',
    templateUrl: './view-presupuesto-inicial.component.html',
    styleUrls: ['./view-presupuesto-inicial.component.css']
})
export class ViewPresupuestoInicialComponent implements OnInit {

    ENTITY = 'vPresupuestoInicials';
    /*
     * View btn aprobar of view presupuesto inicial component
     */
    public viewBtnAprobar: boolean;
    /*
     * Data  of presupuesto inicial component
     */
    public readonly data: {
        ingresos: number,
        egresos: number,
        total?: number
    };
    /*
     * Determines whether save on
     */
    @Output() saved = new EventEmitter<any>();

    /*
     * Creates an instance of presupuesto inicial component.
     * @param jsonStorage
     * @param adfiGrowlService
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public presupuestoInicial: any,
        private loading: LoadingService,
        private adfiService: AdfiService,
        private adfiGraphqlService: AdfiGraphqlService,
        private adfiGrowlService: AdfiGrowlService,
        private matDialog: MatDialog) {
        this.data = {ingresos: 0, egresos: 0};
    }

    /**
     * get
     */
    public get dataAprobar(): any {
        return {
            id: this.presupuestoInicial.id,
            fechaAcuerdo: this.presupuestoInicial.fechaAcuerdo,
            numeroAcuerdo: this.presupuestoInicial.numeroAcuerdo
        };
    }

    ngOnInit() {
        this.updateData();
        this.loadTotales();
    }

    /**
     * Updates data
     */
    public updateData() {
        this.viewBtnAprobar = this.adfiService.validPermission(Entities.INITIAL_BUDGET, Permission.APPROVE)
            && (!this.presupuestoInicial.id || this.presupuestoInicial.estado === 'P');
    }

    async loadTotales() {
        this.data.total = undefined;
        this.data.ingresos = await this.sumValueBudget('I');
        this.data.egresos = await this.sumValueBudget('E');
        this.data.total = Math.abs(this.data.ingresos - this.data.egresos);
    }

    sumValueBudget(type: 'I' | 'E'): Promise<number> {
        return this.adfiService.get<number>(AdfiRest.sumValorPresupuesto(type))
            .pipe(
                map((response) =>  {
                    if (response) {
                        return response;
                    }
                    return 0;
                })
            ).toPromise();
    }

    /*
     * Determines whether change data on
     * @param vals
     */
    public onChangeData(list: any[]) {
        const rows = document.getElementsByClassName('adfi-row-mat');
        for (let i = 0; i < list.length; i++) {
            const val = list[i];
            if (val.ingreso !== val.egreso) {
                rows[i].classList.add('val-error');
            } else {
                rows[i].classList.add('val-success');
            }
        }
    }

    /**
     * Aprobars presupuesto inicial component
     */
    aprobar() {
        const dialog = this.matDialog.open(FormComponent, {
            data: { entityName: 'PresupuestoInicial',
                module: 'Presupuesto',
                group: 'approve',
                item: this.presupuestoInicial.id,
                customTitle: 'Aprobar Presupuesto Inicial',
                customActionBtnText: 'Aprobar',
                action: ENUM_ACTIONS.EDIT
            }as DataEntity
        });
        const subs = dialog.beforeClosed().subscribe((event) => {
            if (event) {
                this.loading.show();
                const next = (f) => {
                    this.saved.emit(f);
                    this.updateData();
                    this.adfiGrowlService.success('Presupuesto', 'Aprobado correctamente para la vigencia');
                    subs.unsubscribe();
                    this.loading.hide();
                };
                const error = (e) => {
                    this.loading.hide();
                    this.adfiGrowlService.errorMessage(e);
                };
                this.adfiGraphqlService.getFirstEntity('presupuestoInicials',
                    'fechaAcuerdo numeroAcuerdo estado archivo fileName',
                    `centroCosto_id: ${this.adfiService.user.centroCosto._id}, vigencia: "${this.presupuestoInicial.vigencia}"`, {next, error});
            }
        });
    }
}

<div class="row">
    <div class="col" *ngIf="configTable && displayAlerts">
        <mat-chip-list [selectable]="false">
            <app-alert *ngFor="let alert of configTable.alerts" [alert]="alert" [module]="this.module"
                       [group]="dataEntity?.displayGroup"
                       [entity]="this.entityName"></app-alert>
        </mat-chip-list>
    </div>
</div>
<mat-card>
    <mat-card-title>
        <div class="d-flex justify-content-between">
             <span style="padding-left: 16px" *ngIf="configTable">
                {{customTitle ? customTitle : configTable.name}}
            </span>
            <div class="row justify-content-end">
                <div class="col-auto no-padding">
                    <div class="d-flex justify-content-end general-actions" *ngIf="!selectable || dataEntity.enableAdd">
                        <ng-container *ngIf="!selectable">
                            <ng-container *ngFor="let item of generalActions">
                                <button mat-raised-button class="btn-actions" color="primary" (click)="handleCustomActions(item, mp, rp)"
                                        [disabled]="this.loadingData">
                                    <ng-container *ngIf="item.icon">
                                        <mat-icon>{{item.icon}}</mat-icon>
                                    </ng-container>
                                    {{item.text}}
                                    <app-month-picker #mp></app-month-picker>
                                    <app-date-range-picker #rp stylePicker="Advanced"></app-date-range-picker>
                                </button>
                            </ng-container>
                        </ng-container>
                        <ng-content select=".actionsTable">
                        </ng-content>
                        <button mat-raised-button class="btn-actions"
                                matTooltip="Agregar {{customTitle ? customTitle : configTable.name}}"
                                color="primary"
                                (click)="addItem()"
                                *ngIf="this.canAdd && (!dataEntity || (dataEntity.enableAdd === undefined) || dataEntity.enableAdd)"
                                [disabled]="this.loadingData">
                            <mat-icon>add</mat-icon>
                            Agregar Nuevo
                        </button>
                    </div>
                </div>
                <div class="col-auto pl-0">
                    <button mat-icon-button matTooltip="Actualizar" (click)="reload()" [disabled]="this.loadingData">
                        <mat-icon>update</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-card-title>
    <mat-card-content>
        <mat-progress-bar class="col-12" *ngIf="loadingData || !configTable" mode="indeterminate"></mat-progress-bar>
        <ng-content></ng-content>
        <mat-table [dataSource]="data">
            <ng-container matColumnDef="select" *ngIf="this.dataEntity && this.dataEntity.multiSelect !== undefined">
                <mat-header-cell *matHeaderCellDef class="mat-header-cell expand-3-p justify-content-center">
                    <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                  [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="mat-cell expand-3-p justify-content-center">
                    <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)"
                                  [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef class="mat-footer-cell expand-3-p justify-content-center"></mat-footer-cell>
            </ng-container>
            <ng-container *ngFor="let property of columns">
                <ng-container matColumnDef="{{property.fieldName}}">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-header-cell {{property.options[OPTION.CELL_EXPAND] ? property.options[OPTION.CELL_EXPAND] : ''}}">
                        <div class="col-12" style=" display: flex; flex-direction: column; justify-content: center; height: 100%">
                            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%; min-height: 2.5rem">
                                {{property.label ? property.label : property.fieldName }}
                                <mat-form-field class="inline-filter" appearance="none"
                                                *ngIf="property.hasFilter && property.typeFilter == FILTER.NUMERIC && property.options[TYPES.DROPDOWN]">
                                    <mat-select multiple
                                                disabled="{{property.hasFilter && filters.get(property.nameFilter) &&  filters.get(property.nameFilter).blocked}}"
                                                (selectionChange)="updateFilters(property.nameFilter, $event)">
                                        <mat-option
                                                *ngFor="let item of property.options[TYPES.DROPDOWN]"
                                                [value]="item.key">{{ item.value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <span class="order cursor-pointer" *ngIf="findColumnSort(property.fieldName); let c"
                                      [ngClass]="c.active ? 'active': 'hidden'"
                                      (click)="activateSort(c)">
                                    <mat-icon>{{c.order === ORDER.DESC ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                                </span>
                            </div>
                            <div style="display: flex; flex-direction: column; width: 100%"
                                 *ngIf="filters.get(property.nameFilter); let _f">
                                <ng-container [ngSwitch]="_f.type">
                                    <ng-container *ngSwitchCase="FILTER.SEARCH">
                                        <mat-form-field class="custom-input-filter" appearance="legacy"
                                                        *ngIf="property.options[TYPES.DROPDOWN] else defaultT">
                                            <mat-select [multiple]="_f.strategy === 'exact'" disabled="{{_f.blocked}}" [value]="_f.data"
                                                        (selectionChange)="updateFilters(property.nameFilter, $event); _f.strategy !== 'exact' ? reload(): ''">
                                                <mat-option *ngIf="_f.strategy !== 'exact'">TODOS</mat-option>
                                                <mat-option
                                                        *ngFor="let item of property.options[TYPES.DROPDOWN]"
                                                        [value]="item.key">{{ item.value }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="FILTER.EXISTS_FILTER">
                                        <div class="full-width header-filter d-flex align-items-center justify-content-center">
                                            <mat-slide-toggle [checked]="_f.data" color="primary" matTooltip="¿Existe?"
                                                    (change)="updateFilters(property.nameFilter, {value: $event.checked}); reload()">
                                            </mat-slide-toggle>
                                            <mat-icon matTooltip="Remover valor del filtro" class="small-icon cancel-icon" (click)="_f.data = undefined; reload()">highlight_off</mat-icon>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="FILTER.NUMERIC">
                                        <ng-container
                                                *ngIf="property.options[TYPES.DROPDOWN] else defaultT"></ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="FILTER.RANGE">
                                        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between">
                                            <div style="display: flex; flex-direction: column; width: 80%; max-width: 80%">
                                                <ng-container [ngTemplateOutlet]="defaultT"
                                                              [ngTemplateOutletContext]="{index: 0, placeholder: _f.option && _f.option[0] === 'between' ? 'Desde' : 'Buscar'}"></ng-container>
                                                <ng-container
                                                        *ngIf="_f.option && _f.option[0] === 'between'"
                                                        [ngTemplateOutlet]="defaultT"
                                                        [ngTemplateOutletContext]="{index: 1, placeholder: 'Hasta'}"></ng-container>
                                            </div>
                                            <div style="width: 20%; max-width: 18px; display: flex; align-items: center">
                                                <mat-form-field class="custom-input-filter full-width"
                                                                appearance="none">
                                                    <mat-select disabled="{{_f.blocked}}"
                                                                (selectionChange)="updateOptionFilters(property.nameFilter, $event, _f.type)">
                                                        <mat-option *ngFor="let item of rangeOptions"
                                                                    [value]="item.key">{{ item.label }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="FILTER.DATE">
                                        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between">
                                            <div style="display: flex; flex-direction: column; width: 80%; max-width: 80%">
                                                <ng-container [ngTemplateOutlet]="defaultTD"
                                                              [ngTemplateOutletContext]="{index: 0, placeholder: 'Despues'}"></ng-container>
                                                <ng-container [ngTemplateOutlet]="defaultTD"
                                                              [ngTemplateOutletContext]="{index: 1, placeholder: 'Antes'}"></ng-container>
                                            </div>
                                            <div style="width: 20%; max-width: 18px; display: flex; flex-direction: column; justify-content: space-around">
                                                <mat-form-field class="custom-input-filter full-width"
                                                                appearance="none">
                                                    <mat-select
                                                            (selectionChange)="updateOptionFilters(property.nameFilter, $event, _f.type, 0)">
                                                        <mat-option *ngFor="let item of dateOptions1"
                                                                    [value]="item.key">{{ item.label }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field class="custom-input-filter full-width"
                                                                appearance="none">
                                                    <mat-select
                                                            (selectionChange)="updateOptionFilters(property.nameFilter, $event, _f.type, 1)">
                                                        <mat-option *ngFor="let item of dateOptions2"
                                                                    [value]="item.key">{{ item.label }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #defaultT let-index="index" let-placeholder="placeholder">
                                    <mat-form-field class="custom-input-filter full-width" appearance="legacy">
                                        <label>
                                            <ng-container
                                                    *ngIf="_f.type === FILTER.NUMERIC else text">
                                                <input placeholder="{{placeholder ? placeholder: 'Buscar'}}"
                                                       autocomplete="off" matInput
                                                       value="{{index >= 0 && _f.data ? _f.data[index] : _f.data}}"
                                                       disabled="{{_f.blocked}}"
                                                       appNumberDirective
                                                       (keyup.enter)="updateFilters(property.nameFilter, $event.target, index >= 0 ? index : -1); this.reload()"
                                                       (focusout)="updateFilters(property.nameFilter, $event.target, index >= 0 ? index : -1)">
                                            </ng-container>
                                            <ng-template #text>
                                                <input placeholder="{{placeholder ? placeholder: 'Buscar'}}"
                                                       autocomplete="off" matInput
                                                       value="{{index >= 0 && _f.data ? _f.data[index] : _f.data}}"
                                                       disabled="{{_f.blocked}}"
                                                       (keyup.enter)="updateFilters(property.nameFilter, $event.target, index >= 0 ? index : -1); this.reload()"
                                                       (focusout)="updateFilters(property.nameFilter, $event.target, index >= 0 ? index : -1)">
                                            </ng-template>
                                        </label>
                                    </mat-form-field>
                                </ng-template>
                                <ng-template #defaultTD let-index="index" let-placeholder="placeholder">
                                    <mat-form-field class="custom-input-filter full-width" appearance="legacy">
                                        <label>
                                            <input placeholder="{{placeholder ? placeholder: 'Seleccionar'}}"
                                                   autocomplete="off"
                                                   [min]="_f.blocked ? (index === 0 && _f.first_data ? _f.first_data[index]: (_f.data ? _f.data[0]: null)): null"
                                                   [max]="_f.blocked ? (index === 0 && _f.data ? _f.data[1] : (_f.first_data ? _f.first_data[index] : null) ) : null"
                                                   [value]="_f.data ? _f.data[index]: null"
                                                   readonly
                                                   (click)="picker.open()"
                                                   (dateChange)="updateFilters(property.nameFilter, $event.target, index)"
                                                   matInput [matDatepicker]="picker">
                                        </label>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </ng-template>
                            </div>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row"
                              [attr.data-label]="property.label ? property.label : property.fieldName"
                              matTooltip="{{ property.options[OPTION.TOOLTIP] ? getColumnValue(row, property) : ''}}"
                              class="mat-cell {{property.options[OPTION.CELL_CLASS] ? property.options[OPTION.CELL_CLASS] : ''}} {{property.options[OPTION.CELL_EXPAND] ? property.options[OPTION.CELL_EXPAND] : ''}}">
                        <ng-container
                                *ngIf="getTemplate(property.fieldName) else def; let template"
                                [ngTemplateOutlet]="template"
                                [ngTemplateOutletContext]="{ row: row, property: property}">
                        </ng-container>
                        <ng-template #def>
                            <ng-container *ngIf="property.formType === 'ManyToMany'">
                                <ng-container *ngIf="getColumnValue(row, property); let list">
                                    <button *ngIf="list && list.length && list.length >= 1" mat-button [matMenuTriggerFor]="many">
                                        <mat-icon color="primary" matSuffix>visibility</mat-icon>
                                    </button>
                                    <mat-menu #many="matMenu" yPosition="above">
                                        <mat-list>
                                            <mat-list-item *ngFor="let item of list">
                                                <mat-icon
                                                        mat-list-icon>{{property.options[OPTION.ICON] ? property.options[OPTION.ICON] : 'double_arrow'}}</mat-icon>
                                                <div mat-line class="wrap-content"
                                                     *ngFor="let p of property.display">{{item[p]}}</div>
                                            </mat-list-item>
                                        </mat-list>
                                    </mat-menu>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="property.formType === 'OneToMany'">
                                <ng-container *ngIf="getDataOneToMany(row, property); let list">
                                    <button *ngIf="list && list.length && list.length > 1" mat-button [matMenuTriggerFor]="many">
                                        <mat-icon color="primary" matSuffix>visibility</mat-icon>
                                    </button>
                                    <button *ngIf="list && list.length && list.length === 1" mat-button (click)="showItem(list[0], property)">
                                        <mat-icon color="primary" matSuffix>visibility</mat-icon>
                                    </button>
                                    <mat-menu #many="matMenu" yPosition="above">
                                        <mat-list>
                                            <mat-list-item *ngFor="let item of list" class="cursor-pointer">
                                                <mat-icon mat-list-icon class="cursor-pointer" (click)="showItem(item, property)">
                                                    {{property.options[OPTION.ICON] ? property.options[OPTION.ICON] : 'double_arrow'}}
                                                </mat-icon>
                                                <div mat-line class="wrap-content" *ngFor="let p of property.display; let i = index">
                                                    <mat-icon class="cursor-pointer" *ngIf="property.customFormatDisplay && property.customFormatDisplay[i]  ===  TYPES.FILE"
                                                              (click)="downloadMediaObject(item[p], property)"
                                                        mat-list-icon>arrow_downward</mat-icon>
                                                    {{transformColumnValue(p, item, property, i)}}
                                                </div>
                                            </mat-list-item>
                                        </mat-list>
                                    </mat-menu>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="row[property.fieldName] && property.formType === TYPES.CELLPHONE">
                                <ng-container *ngTemplateOutlet="memberTemplate; context:{o: getValueCellphone(row[property.fieldName]) }"></ng-container>
                                <ng-template #memberTemplate let-arr="o" [ngIf]="arr">
                                    <section>
                                        <p class="font-weight-bold d-inline">+{{arr[0]}}&nbsp;&nbsp;</p>
                                        <p class="d-inline">{{arr[1]}}{{arr[2]}}{{arr[3]}}</p>
                                    </section>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="(row[property.fieldName] || row[property.fieldName] === 0  ) && property.formType !== TYPES.MANY_TO_MANY && property.formType !== TYPES.ONE_TO_MANY && property.formType !== TYPES.CELLPHONE">
                                <a *ngIf="row && property && property.fieldName && property.options.displayOnClick; else wthClick" class="cursor-pointer" matTooltip="Ver detalles {{ property.label }}" (click)="showItem(row[property.fieldName], property)">
                                    {{ getColumnValue(row, property)}}
                                </a>
                                <ng-template #wthClick>
                                    {{ getColumnValue(row, property)}}
                                </ng-template>
                            </ng-container>
                            <button mat-icon-button color="primary"
                                    *ngIf="!property.options[OPTION.MULTIPLE_FILE]  && property.formType === TYPES.FILE && row[property.fieldName]"
                                    matTooltip="Descargar" (click)="downloadMediaObject(row, property)">
                                <mat-icon>arrow_downward</mat-icon>
                            </button>
                            <ng-container
                                    *ngIf="property.options[OPTION.MULTIPLE_FILE]  && property.formType === TYPES.FILE && row[property.fieldName]">
                                <ng-container *ngIf="row[property.fieldName]; let list">
                                    <button mat-button [matMenuTriggerFor]="manyFiles">
                                        <mat-icon class="pl-1" color="primary" matSuffix>arrow_downward</mat-icon>
                                    </button>
                                    <mat-menu #manyFiles="matMenu" yPosition="above">
                                        <mat-list>
                                            <mat-list-item *ngFor="let item of list.split(','); let i = index;">
                                                <button mat-icon-button (click)="downloadMediaObject(item, property)">
                                                    <mat-icon>{{property.options[OPTION.ICON] ? property.options[OPTION.ICON] : 'arrow_downward'}}</mat-icon>
                                                </button>
                                                <div mat-line class="wrap-content cursor-pointer"
                                                     (click)="downloadMediaObject(item, property)">{{row[property.display][i]}}</div>
                                            </mat-list-item>
                                        </mat-list>
                                    </mat-menu>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef
                                     class="mat-footer-cell {{property.options[OPTION.CELL_CLASS] ? property.options[OPTION.CELL_CLASS] : ''}} {{property.options[OPTION.CELL_EXPAND] ? property.options[OPTION.CELL_EXPAND] : ''}}">
                        <ng-container *ngIf="property?.options?.total">
                            {{ listTotals[property.fieldName] | currency }}
                        </ng-container>
                        <ng-container
                                *ngIf="getTemplate('footer_'+property.fieldName) else def; let template"
                                [ngTemplateOutlet]="template"
                                [ngTemplateOutletContext]="{ property: property}">
                        </ng-container>
                        <ng-template #def></ng-template>
                    </mat-footer-cell>
                </ng-container>
            </ng-container>
            <ng-container [matColumnDef]="OPTION.ACTIONS_TABLE">
                <mat-header-cell *matHeaderCellDef
                                 class="mat-header-cell font-weight-bold {{configTable.table.options[OPTION.ACTIONS_EXPAND] ? configTable.table.options[OPTION.ACTIONS_EXPAND] : 'expand-4-p'}}">
                    <div class="col-12"
                         style="display: flex; flex-direction: column; justify-content: center; height: 100%">
                        <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%; min-height: 2.5rem">
                            <button mat-icon-button color="primary"
                                    class="small-icon-button" *ngIf="!selectable"
                                    matTooltip="Acciones">
                                <mat-icon>all_out</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="actions-column mat-cell d-flex flex-wrap justify-content-center {{configTable.table.options[OPTION.ACTIONS_EXPAND] ? configTable.table.options[OPTION.ACTIONS_EXPAND] : 'expand-4-p'}}">
                    <button mat-icon-button color="primary"
                            *ngIf="!selectable && canView"
                            matTooltip="Ver"
                            (click)="viewItem(row)"
                    >
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <button mat-icon-button color="primary"
                            *ngIf="!selectable && canPrint"
                            matTooltip="Generar PDF"
                            (click)="printItem(row)"
                    >
                        <mat-icon>print</mat-icon>
                    </button>
                    <button mat-icon-button color="primary"
                            *ngIf="!selectable && canEdit && ((configTable.table.options[OPTION.CAN_EDIT] === undefined || configTable.table.options[OPTION.CAN_EDIT] === true) || (configTable.table.options[OPTION.CAN_EDIT] && row[configTable.table.options[OPTION.CAN_EDIT]]))"
                            matTooltip="Editar"
                            (click)="editItem(row)"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn"
                            *ngIf="!selectable && canDelete && ((configTable.table.options[OPTION.CAN_DELETE] === undefined || configTable.table.options[OPTION.CAN_DELETE] === true) || (configTable.table.options[OPTION.CAN_DELETE] && row[configTable.table.options[OPTION.CAN_DELETE]]))"
                            matTooltip="Eliminar"
                            (click)="deleteItem(row)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button color="primary"
                            matTooltip="Seleccionar"
                            *ngIf="selectable"
                            (click)="suggestionWasClicked(row)">
                            <mat-icon>touch_app</mat-icon>
                    </button>
                    <ng-container
                            *ngIf="getTemplate('actions') && !selectable"
                            [ngTemplateOutlet]="getTemplate('actions')"
                            [ngTemplateOutletContext]="{ row: row }">
                    </ng-container>
                    <ng-container *ngIf="!selectable">
                        <ng-container *ngFor="let item of specificActions">
                            <button mat-icon-button color="primary" (click)="handleCustomActions(item, smp, rp, row)"
                                    [disabled]="this.loadingData" matTooltip="{{item.text}}" *ngIf="item.canDisplay === undefined || item.canDisplay === null || row[item.canDisplay]">
                                <mat-icon>{{item.icon}}</mat-icon>
                                <app-month-picker #smp></app-month-picker>
                                <app-date-range-picker #rp stylePicker="Advanced"></app-date-range-picker>
                            </button>
                        </ng-container>
                    </ng-container>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef
                                 class="mat-footer-cell {{configTable.table.options[OPTION.ACTIONS_EXPAND] ? configTable.table.options[OPTION.ACTIONS_EXPAND] : 'expand-4-p'}}">
                    <ng-container
                            *ngIf="getTemplate('footer_actions') else def; let template"
                            [ngTemplateOutlet]="template">
                    </ng-container>
                    <ng-template #def></ng-template>
                </mat-footer-cell>
            </ng-container>
            <mat-header-row class="mat-header-row" *matHeaderRowDef="displayColumns"></mat-header-row>
            <mat-row (click)="selectable ? suggestionWasClicked(row): ''" [class.cursor-pointer]="selectable"  class="mat-row {{row[OPTION.ROW_CLASS]}} {{customRowClass}}" *matRowDef="let row; columns: displayColumns"></mat-row>
            <mat-footer-row class="mat-footer-row" *matFooterRowDef="displayColumns"></mat-footer-row>
        </mat-table>
        <ng-container *ngIf="withPaginator">
            <mat-paginator [pageSize]="resultsLength" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
        </ng-container>
        <div class="row justify-content-end">
            <button *ngIf="this.dataEntity && this.dataEntity.multiSelect !== undefined" class="pr-3 pl-1"
                    mat-raised-button color="primary" (click)="suggestionWasClicked()">
                <mat-icon class="pr-1">list</mat-icon>
                Finalizar selección
            </button>
        </div>
        <div class="row" *ngIf="!selectable && exportableDocs">
            <ng-container
                    *ngIf="!loadingData && configTable.table.options && configTable.table.options['export']">
                <button *ngFor="let format  of configTable.table.options.export"
                        matTooltip="Al exportar el archivo se tentrán en cuenta los filtros aplicados en las consulta"
                        class="col-auto ml-3 btn-actions" mat-raised-button color="primary" (click)="exportReport(format)"
                        [disabled]="loadingData">Exportar {{format | uppercase}}
                </button>
            </ng-container>
            <ng-container *ngFor="let property of columns">
                <button mat-raised-button class="btn-actions ml-3" color="primary" [matTooltip]="property.label" (click)="download(property)" *ngIf="property.formType === TYPES.FILE && property.options[OPTION.BULK_DOWNLOAD]">Descargar ZIP {{ property.label.length < 20 ?  property.label : property.label.substr(0, 17)+'...'}}</button>
            </ng-container>
            <ng-container *ngFor="let item of footerActions">
                <button mat-raised-button class="btn-actions ml-3" color="primary" (click)="handleCustomActions(item, fmp, rp)"
                        [disabled]="this.loadingData">
                    <ng-container *ngIf="item.icon">
                        <mat-icon>{{item.icon}}</mat-icon>
                    </ng-container>
                    {{item.text}}
                    <app-month-picker #fmp></app-month-picker>
                    <app-date-range-picker #rp stylePicker="Advanced"></app-date-range-picker>
                </button>
            </ng-container>
            <ng-content select=".footer"></ng-content>
        </div>
    </mat-card-content>
</mat-card>
<div class="over-message" *ngIf="downloadedFiles">
    Archivos descargados {{downloadedFiles}} - {{total}}
</div>
<div class="over-message" *ngIf="generatingZip">
    Comprimiendo archivos {{percentageGenZip}}%
</div>

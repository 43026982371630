<div *ngIf="counterStart > 0 || counterEnd < 0; else open" class="d-flex flex-column justify-content-center align-items-center" style="height: 60vh">
    <mat-card class="w-75 mat-elevation-z4" style="padding: 3rem; border-radius: 2rem">
        <mat-card-content class="d-flex flex-column justify-content-around align-items-center">
            <img class="w-50 m-4" src="assets/images/adfib.png" alt="">
            <h2 class="mt-4">{{counterStart > 0 ? 'El Formulario se habilitará en: ': counterEnd < 0 ? 'El formulario ya no se encuentra habilitado para el registro de información' : ''}}</h2>
            <h4 *ngIf="counterStart > 0" class="m-1">{{ counterStart | formatTime }}</h4>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #open>
    <div style="background-color: #fff">
        <h2 class="px-2 text-center" style="color: #3f51b5; padding-top: 1em">INFORMACIÓN COMPLEMENTARIA CIERRE VIGENCIA {{adfiService.user.period}}</h2>
        <div class="d-flex justify-content-center align-items-center" *ngIf="counterEnd > 0 && canEditForm">
            <h4 class="mx-1">El formulario se cierra en: </h4>
            <mat-chip-list>
                <mat-chip matTooltip="Plazo para el registro de registro de información" selectable="false" [ngClass]="getColorTime()">{{ counterEnd | formatTime }}</mat-chip>
            </mat-chip-list>
        </div>
        <template #tem_ieForm></template>
        <mat-vertical-stepper linear #stepper [selectedIndex]="indexStep" *ngIf="closingForm">
            <mat-step [completed]="closingForm && closingForm['generalBox']">
                <ng-template matStepLabel>Caja general</ng-template>
                <template #tem_generalCashForm></template>
                <p *ngIf="generalCashForm?.entityForm?.get('collectedCash').value">1.4 De acuerdo con el saldo indicado en el numeral anterior diligencié los conceptos a los que
                    corresponden los dineros en caja al cierre de la vigencia</p>
                <app-list *ngIf="generalCashForm?.entityForm?.get('collectedCash').value" #listCashBox entityName="CashBox"
                          [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'originData', value: 'FC_PERIOD'}]"
                          [dataEntity]="{enableAdd: canEditForm}"
                          [noActions]="!canEditForm"
                          [exportableDocs]="false"
                          module="ClosingForm" (loadedData)="postLoadCashBox()">
                    <ng-template tableColumn="footer_description">
                        <strong>Total</strong>
                    </ng-template>
                    <ng-template tableColumn="footer_value">
                        <strong> {{ totalCashBox | currency }}</strong>
                    </ng-template>
                    <ng-container class="footer">
                        <p class="px-4">Tenga en cuenta que para poder continuar la suma del valor total de la tabla debe ser
                            igual al valor de los recursos en efectivo no consignados en las cuentas bancarias de la
                            Institución</p>
                    </ng-container>
                </app-list>
                <div class="d-flex justify-content-center">
                    <div class="px-4 my-2">
                        <button mat-raised-button color="primary" *ngIf="canEditForm else nextToCash"
                                [disabled]="((generalCashForm?.entityForm?.get('collectedCash').value !== false) && (generalCashForm?.entityForm?.get('collectedCash').value && totalCashBox !== generalCashForm?.currencies?.get('value')))"
                                (click)="saveGeneralBox()">Continuar
                        </button>
                        <ng-template #nextToCash>
                            <button mat-raised-button color="primary" (click)="stepper.next()">Continuar</button>
                        </ng-template>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="closingForm && closingForm['accounts']">
                <ng-template matStepLabel>Cuentas bancarias</ng-template>
                <div class="row">
                    <div class="col my-2">
                        De acuerdo con la conciliación bancaria efectuada a las cuentas en 31 de diciembre {{adfiService.user.period}} por favor
                        diligencié la siguiente información, estableciendo las partidas conciliatorias determinadas al cierre de
                        la vigencia por cada cuenta bancaria.
                    </div>
                </div>
                <div class="row m-2">
                    <mat-form-field class="col-12 col-md-8">
                        <mat-label>Cuenta Bancaria</mat-label>
                        <mat-select placeholder="Seleccionar" (selectionChange)="selectAllConciliation($event.value)" #accountSelect>
                            <mat-select-trigger>
                                <ng-container *ngIf="accountSelect.value">
                                    {{ accountSelect.value.nombre }} {{accountSelect.value.numero }} <mat-icon *ngIf="accountSelect.value.state === 'Y'" color="primary">check_circle_outline</mat-icon>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option *ngFor="let cuenta of bankAccounts" [value]="cuenta">
                                {{ cuenta.nombre }} {{ cuenta.numero }}  <mat-icon *ngIf="cuenta.state === 'Y'" color="primary">check_circle_outline</mat-icon>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-12 col-md-4 d-flex align-items-center justify-content-center" *ngIf="accountBalance && canEditForm">
                        <button mat-icon-button color="primary" matTooltip="Editar Cuenta" (click)="editExtract()">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-raised-button color="primary"
                                (click)="createItemConciliation()">AÑADIR PARTIDA CONCILIATORIA
                        </button>
                    </div>
                    <div class="col-md-3 col-6" *ngIf="accountBalance">
                        <p>Saldo en extracto al 31 de dic. {{adfiService.user.period}}: <strong>{{ accountBalance.value | currency }}</strong></p>
                    </div>
                    <div class="col-md-3 col-6" *ngIf="accountBalance">
                        <p>Entidad Bancaria: <strong>{{ accountBalance.banco.nombre }}</strong></p>
                    </div>
                    <div class="col-md-3 col-6" *ngIf="accountBalance">
                        <p>Tipo de cuenta: <strong>{{ accountBalance.tipo == 'CC' ? 'Corriente' : 'Ahorros' }}</strong></p>
                    </div>
                    <!--div class="col-6" *ngIf="accountBalance">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <button mat-stroked-button color="primary" (click)="downloadExtract()">Descargar Extracto</button>
                            </div>
                            <div class="col-12 col-md-6">
                                <button mat-stroked-button color="primary" (click)="editFileExtract()">Editar Archivo Extracto</button>
                            </div>
                        </div>
                    </div-->
                </div>
                <div class="row" *ngIf="account">
                    <div class="col">
                        <mat-tab-group #tabsPc>
                            <mat-tab label="CHEQUE GIRADO Y NO COBRADO">
                                <ng-template matTabContent>
                                    <app-list #cncList  entityName="ClosingConciliation" module="ClosingForm"
                                              [dataEntity]="{enableAdd: false}"
                                              group="CNC"
                                              [noActions]="!canEditForm"
                                              [exportableDocs]="false"
                                              [defaultQuery]="[{column: 'type', value: 'CNC'}, {column :'account', value: account._id}]"
                                              customTitle="CONCILIACIÓN BANCARIA"></app-list>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="CONSIGNACIÓN SIN IDENTIFICAR">
                                <ng-template matTabContent>
                                    <app-list #csiList entityName="ClosingConciliation" module="ClosingForm"
                                              [dataEntity]="{enableAdd: false}"
                                              group="CSI"
                                              [noActions]="!canEditForm"
                                              [exportableDocs]="false"
                                              [defaultQuery]="[{column: 'type', value: 'CSI'}, {column :'account', value: account._id}]"
                                              customTitle="CONCILIACIÓN BANCARIA"></app-list>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="CONSIGNACIÓN EN TRÁNSITO">
                                <ng-template matTabContent>
                                    <app-list #cetList entityName="ClosingConciliation" module="ClosingForm"
                                              [dataEntity]="{enableAdd: false}"
                                              group="CET"
                                              [noActions]="!canEditForm"
                                              [exportableDocs]="false"
                                              [defaultQuery]="[{column: 'type', value: 'CET'}, {column :'account', value: account._id}]"
                                              customTitle="CONCILIACIÓN BANCARIA"></app-list>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="NOTA DÉBITO">
                                <ng-template matTabContent>
                                    <app-list #ndeList entityName="ClosingConciliation" module="ClosingForm"
                                              [dataEntity]="{enableAdd: false}"
                                              group="DEB"
                                              [noActions]="!canEditForm"
                                              [exportableDocs]="false"
                                              [defaultQuery]="[{column: 'type', value: 'DEB'}, {column :'account', value: account._id}]"
                                              customTitle="CONCILIACIÓN BANCARIA"></app-list>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="NOTA CRÉDITO">
                                <ng-template matTabContent>
                                    <app-list #ncrList entityName="ClosingConciliation" module="ClosingForm"
                                              [dataEntity]="{enableAdd: false}"
                                              group="CRE"
                                              [noActions]="!canEditForm"
                                              [exportableDocs]="false"
                                              [defaultQuery]="[{column: 'type', value: 'CRE'}, {column :'account', value: account._id}]"
                                              customTitle="CONCILIACIÓN BANCARIA"></app-list>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-12 p-2">
                        <p>¿De la lista desplegable presentada anteriormente la Institución evidencia que faltan cuentas bancarias por registrar?</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select [disabled]="!canEditForm"
                                        placeholder="Seleccionar" [value]="closingForm.hasNewAccount"
                                        #yesAccount>
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-2">
                        <p *ngIf="yesAccount.value">Por favor diligéncielas a continuación dando clic en agregar nuevo:</p>
                        <app-list *ngIf="yesAccount.value" module="ClosingForm" entityName="NewAccount"
                                  [dataEntity]="{enableAdd: canEditForm}"
                                  [noActions]="!canEditForm"
                                  [exportableDocs]="false"
                                  [defaultQuery]="[{column:'cencos', value:''}]"></app-list>
                    </div>

                </div>
                <div class="row-p4 mt-2">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button *ngIf="canEditForm else btnNextBank" [disabled]="yesAccount.value === undefined || yesAccount.value === null" mat-raised-button color="primary" (click)="validStep('SaveConciliationsClosingForm', {accounts: true, hasNewAccount: yesAccount.value})">
                                Continuar
                            </button>
                            <ng-template #btnNextBank>
                                <button mat-raised-button color="primary" (click)="stepper.next()">
                                    Continuar
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="closingForm && closingForm['accountsReceivable']">
                <ng-template matStepLabel>Cuentas por Cobrar</ng-template>
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h2>¿Al cierre de la vigencia la Institución Educativa presenta cuentas por cobrar?</h2>
                        </div>
                        <div class="col-12 text-center">
                            <h4>Por favor diligéncielos a continuación dando clic en agregar nuevo:</h4>
                        </div>
                        <div class="col-12">
                            <app-list entityName="AccountsCollectable" module="ClosingForm"
                                      [dataEntity]="{enableAdd: canEditForm}"
                                      [noActions]="!canEditForm"
                                      [exportableDocs]="false"
                                      [defaultQuery]="[{column: 'cencos', value: ''}]"></app-list>
                        </div>
                    </div>
                    <div class="row-p4 mt-2">
                        <div class="d-flex justify-content-center">
                            <div class="px-4">
                                <button *ngIf="canEditForm else nextToCob" mat-raised-button color="primary" (click)="saveStep({accountsReceivable: true})">
                                    Continuar
                                </button>
                                <ng-template #nextToCob>
                                    <button mat-raised-button color="primary" (click)="stepper.next()">Continuar</button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="closingForm && closingForm['accountsPayable']">
                <ng-template matStepLabel>Cuentas por Pagar</ng-template>
                <div class="container">
                    <div class="row">
                        <div class="col-12 pb-2">
                            <p>Al cierre de la vigencia {{adfiService.user.period}} la Institución Educativa presenta cuentas por pagar en la adquisición de bienes y servicios por</p>
                        </div>
                        <div class="col-12">
                            <app-list #pO entityName="PaymentOrder" module="Egresos" group="not_paid"
                                      [dataEntity]="{
                                    customTitle: 'A continuación, se presentan únicamente las órdenes de pago que en plataforma no se encuentran asociadas a pago de factura (egreso)',
                                    enableAdd: false
                                  }"
                                      [noActions]="true"
                                      [exportableDocs]="false"
                                      [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'orders', value: ['wps']}]">
                                <ng-template tableColumn="isAccountPayable" let-row="row">
                                    <mat-slide-toggle [disabled]="!canEditForm" checked="{{row.isAccountPayable}}" color="primary" (change)="updatePaymentIncomplete($event.checked, row, pO)">
                                        {{row.isAccountPayable ? 'Si' : 'No' }}
                                    </mat-slide-toggle>
                                </ng-template>
                                <ng-template tableColumn="reasonForNonPayment" let-row="row">
                                    <ng-container *ngIf="row.reasonForNonPayment else act">
                                        {{row.reasonForNonPayment}}
                                        <button mat-icon-button
                                                matTooltip="Editar Razón"
                                                color="primary"
                                                [disabled]="!canEditForm"
                                                (click)="editReasonPaymentIncomplete(row, pO)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </ng-container>
                                    <ng-template #act>
                                        <button mat-icon-button
                                                matTooltip="Especificar Razón"
                                                [disabled]="!canEditForm"
                                                color="primary"
                                                (click)="editReasonPaymentIncomplete(row, pO)"
                                        >
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </ng-template>
                                </ng-template>
                            </app-list>
                        </div>
                    </div>
                    <div class="row-p4 mt-2">
                        <div class="d-flex justify-content-center">
                            <div class="px-4">
                                <button *ngIf="canEditForm else nextToCxp" mat-raised-button color="primary" (click)="validStep( 'FCVSaveAccountsPayable',{accountsPayable: true})">
                                    Continuar
                                </button>
                                <ng-template #nextToCxp>
                                    <button mat-raised-button color="primary" (click)="stepper.next()">Continuar</button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="closingForm && closingForm['responsibilityIca'] != null">
                <ng-template matStepLabel>Responsabilidad declarantes de ICA</ng-template>
                <div class="container">
                    <div class="row">
                        <div class="col-12 pb-2">
                            <p>5. ¿La Institución Educativa es responsable de la declaración de conceptos ICA?</p>
                            <mat-form-field class="col-12 col-md-8 m-2">
                                <mat-label>Seleccione SI o NO</mat-label>
                                <mat-select [disabled]="!canEditForm"
                                            placeholder="Seleccionar" [value]="closingForm.responsibilityIca"
                                            (selectionChange)="declarantConceptForm.disableEditInForm = !yesResponsible.value"
                                            #yesResponsible>
                                    <mat-option [value]="true">SI</mat-option>
                                    <mat-option [value]="false">NO</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <div class="row justify-content-center">
                                <div class="col-11">
                                    <template #tem_declarantConceptForm></template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-p4 mt-2">
                        <div class="d-flex justify-content-center">
                            <div class="px-4">
                                <button *ngIf="canEditForm else nextToIca" mat-raised-button color="primary" [disabled]="yesResponsible.value === null" (click)="saveDeclIca(yesResponsible.value)">
                                    Continuar
                                </button>
                                <ng-template #nextToIca>
                                    <button mat-raised-button color="primary" (click)="stepper.next()">Continuar</button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="closingForm && closingForm['withholdingSource']">
                <ng-template matStepLabel>Retenciones en la fuente a título de Renta, IVA e ICA</ng-template>
                <div class="container">
                    <div class="row">
                        <h3 class="col-12">6.1. De acuerdo con las retenciones en la fuente efectuadas en la vigencia {{adfiService.user.period}} se realizó el pago total de estas?</h3>
                        <mat-form-field class="col-12">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select [disabled]="!canEditForm"
                                        placeholder="Seleccionar" #yesReten [value]="closingForm.hasReteIca" [(ngModel)]="closingForm.hasReteIca">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row" *ngIf="closingForm.hasReteIca === false">
                        <h3 class="col-12">6.2. Por favor diligéncielos a continuación dando clic en agregar nuevo</h3>
                        <div class="col-12">
                            <app-list entityName="TreasuryPaymetOrderForm" module="ClosingForm"
                                      [dataEntity]="{
                                    enableAdd: canEditForm
                                  }"
                                      [noActions]="!canEditForm"
                                      [exportableDocs]="false"
                                      [defaultQuery]="[{column: 'cencos', value: ''}]">
                            </app-list>
                        </div>
                    </div>
                    <div class="row-p4 mt-2">
                        <div class="d-flex justify-content-center">
                            <div class="px-4">
                                <button *ngIf="canEditForm else nextToImp" mat-raised-button color="primary" (click)="saveStep({withholdingSource: true, hasReteIca: yesReten.value})">
                                    Continuar
                                </button>
                                <ng-template #nextToImp>
                                    <button mat-raised-button color="primary" (click)="stepper.next()">Continuar</button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="closingForm && closingForm['incomes']">
                <ng-template matStepLabel>Ingresos</ng-template>
                <div class="container">
                    <div class="row">
                        <h3 class="col-12">7.1. ¿Durante la vigencia {{adfiService.user.period}} la Institución Educativa registró la totalidad de ingresos percibidos?</h3>
                        <mat-form-field class="col-12">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select [disabled]="!canEditForm"
                                        placeholder="Seleccionar" #yesIncomes [value]="closingForm.hasNewIncome" [(ngModel)]="closingForm.hasNewIncome">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" *ngIf="yesIncomes.value === false">
                    <h3 class="col-12">7.2. Por favor diligéncielos a continuación dando clic en agregar nuevo</h3>
                    <div class="col-12">
                        <app-list entityName="UnrecordIncome" module="ClosingForm"
                                  [dataEntity]="{
                                    enableAdd: canEditForm
                                  }"
                                  [noActions]="!canEditForm"
                                  [exportableDocs]="false"
                                  [defaultQuery]="[{column: 'cencos', value: ''}]">
                        </app-list>
                    </div>
                </div>

                <div class="row-p4 mt-2">
                    <div class="d-flex justify-content-center">
                        <div class="px-4">
                            <button *ngIf="canEditForm else nextToIng" [disabled]="yesIncomes.value === undefined || yesIncomes.value === null" mat-raised-button color="primary" (click)="saveStep({incomes: true, hasNewIncome: yesIncomes.value})">
                                Continuar
                            </button>
                            <ng-template #nextToIng>
                                <button mat-raised-button color="primary" (click)="stepper.next()">Continuar</button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="closingForm && closingForm['egress']">
                <ng-template matStepLabel>Gastos</ng-template>
                <div class="container">
                    <div class="row">
                        <h3 class="col-12">8.1. ¿Durante la vigencia {{adfiService.user.period}} la Institución Educativa registró en Plataforma ADFI la totalidad de gastos de administración, operación y otros gastos (no incluye depreciación)?</h3>
                        <mat-form-field class="col-12">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select [disabled]="!canEditForm"
                                        placeholder="Seleccionar" #yesPend [value]="closingForm.hasExpenses" [(ngModel)]="closingForm.hasExpenses">
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row" *ngIf="closingForm.hasExpenses === false">
                        <h3 class="col-12">8.2. Por favor diligéncielos a continuación dando clic en agregar nuevo</h3>
                        <div class="col-12">
                            <app-list entityName="PaymentOrderWP" module="ClosingForm" group="not_paid"
                                      [dataEntity]="{customTitle: 'Gastos no registrados en el sistema', enableAdd: canEditForm}"
                                      [noActions]="!canEditForm"
                                      [exportableDocs]="false"
                                      [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'typePayment', value: 'G'}, {column: 'vigencia', value: 0}]" [customData]="{typePayment: 'G'}">
                            </app-list>
                        </div>
                    </div>
                    <div class="row">
                        <h3 class="col-12">8.3. Durante la vigencia la institución cuenta con pasivos sin registrar en plataforma</h3>
                        <div class="col-12">
                            <app-list entityName="PaymentOrderWP"
                                      module="ClosingForm" group="not_paid_fr"
                                      [noActions]="!canEditForm"
                                      [exportableDocs]="false"
                                      [dataEntity]="{customTitle: 'Pasivos no registrados en el sistema', enableAdd: canEditForm, group: 'not_paid_fr', editGroup: 'not_paid_fr'}"
                                      [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'typePayment', value: 'R'}, {column: 'vigencia', value: 0}]" [customData]="{typePayment: 'R'}">
                            </app-list>
                        </div>
                    </div>
                    <div class="row-p4 mt-2">
                        <div class="d-flex justify-content-center">
                            <div class="px-4">
                                <button *ngIf="canEditForm else nextToExp" [disabled]="yesPend.value === undefined || yesPend.value === null" mat-raised-button color="primary" (click)="saveStep({egress: true, hasExpenses: yesPend.value})">
                                    Continuar
                                </button>
                                <ng-template #nextToExp>
                                    <button mat-raised-button color="primary" (click)="stepper.next()">Continuar</button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Certificado</ng-template>
                <div class="row p-2">
                    <div class="col-12 p-2">
                        <p>9. ¿La Institución en la vigencia {{adfiService.user.period}} adquirió los servicios de un profesional contable?</p>
                        <mat-form-field class="col-12 col-md-8 m-2">
                            <mat-label>Seleccione SI o NO</mat-label>
                            <mat-select [disabled]="!canEditForm" (valueChange)="selectAccountMan($event)"
                                        [value]="!!this.closingForm.accountantName"
                                        placeholder="Seleccionar" #yesAccountMan>
                                <mat-option [value]="true">SI</mat-option>
                                <mat-option [value]="false">NO</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="closingForm?.accountantName">
                            <div class="col-12">
                                <p>Nombre: {{closingForm.accountantName}}</p>
                            </div>
                            <div class="col-12">
                                <p>Número de Identificación de Contador: {{closingForm.accountantDni}}</p>
                            </div>
                            <div class="col-12">
                                <p>Número tarjeta profesional Contador: {{closingForm.profesionalCounterCard}}</p>
                            </div>
                        </div>
                        <template #tem_employeesForm></template>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex justify-content-around">
                        <button mat-raised-button color="primary" *ngIf="canEditForm && closingForm.status === 'INI'"
                                (click)="editSignature()"
                                [disabled]="!employeesForm || !employeesForm.form?.valid"
                        >
                            Generar Archivo Borrador
                        </button>
                        <button mat-raised-button color="primary" *ngIf="canEditForm && closingForm.status === 'INI'" (click)="closForm()">Finalizar diligenciamiento</button>
                        <button mat-raised-button color="primary" *ngIf="canUploadFiles" (click)="generateReport()">Generar Archivo</button>
                        <button mat-raised-button color="primary" *ngIf="canUploadFiles" (click)="uploadFile()">Subir Archivo</button>
                        <button mat-raised-button color="primary" *ngIf="closingForm.status === 'UPL'" (click)="downloadFile()">Descargar Archivo Firmado</button>
                    </div>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </div>
</ng-template>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable, isObservable } from 'rxjs';
import {DatePipe, TitleCasePipe} from '@angular/common';
import {CurrencyPipe} from '../util/currency.pipe';
export const SUM = 'adfi_sum';
export interface Attribute {
  classHeader: string;
  classCell: string;
  tooltip: boolean;
  options?: any;
}
export interface Column {
  name: string;
  dynamicName?: (ctx: any, table: TableComponent) => string;
  column: string;
  type: Type;
  group?: string|string[];
  attr: Attribute;
}
export interface ColumnGroup {
  name: string;
  column: string;
  sum?: boolean;
  sumName?: string;
  hideGroupName?: boolean;
  includeValInFooter?: { posCol: number, nameColItem: string };
  type: Type;
  valueFn?: (item: any, c: ColumnGroup) => any;
}
export enum Type {
  String,
  Number,
  Date,
  Currency,
  Option
}
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @Input() title = '';
  @Input() identifier = '';
  @Input() externalCondition: boolean;
  @Output() error = new EventEmitter<any>();
  @Output() loaded = new EventEmitter<any>();
  @Input() locale = 'es-CO';
  @Input() format = 'mediumDate';
  data: any[];
  @Input() context: any;
  count = 0;
  @Input() headerGroupBy: ColumnGroup[];
  @Input() footerGroupBy: ColumnGroup[];
  @Input() set items( data: Observable<any[]> | any[]) {
    if (isObservable(data)) {
      this.loadingData = true;
      data.subscribe( {
        next: (d) => {this.loadingData = false; this.checkData(d); this.loaded.emit(d); },
        error: (e) => {this.loadingData = false; this.error.emit(e); }
      });
    } else if (Array.isArray(data)) {
      this.checkData(data);
      this.loaded.emit(data);
    } else {
      this.loadingData = false;
      this.data = null;
      this.count = 0;
      this.loaded.emit(null);
    }
  }
  columnsConf: Column[];
  displayCol: string[];
  @Input() dynamicRowClass: (ctx: any, row, table: TableComponent) => string;
  @Input() set columns(c: Column[]) {
    this.columnsConf = c;
    this.displayCol = this.columnsConf.map(cl => cl.column);
  }
  @Output() reload = new EventEmitter<void>();
  @Input() loadingData = false;
  CELL_ATTR = 'adfi_cell';
  HIDE_CELL = 'adfi_hide_cell';
  ROWSPAN = 'adfi_rowspan';
  ROW_DYNAMIC_CLASS = 'adfi_cell_dynamic';
  currentColSelected: {left: string, width: string} = {left: '0', width: '0'};
  constructor(public currency: CurrencyPipe, public tr: TitleCasePipe, public datePipe: DatePipe) { }

  getOffset( el ) {
    let _x = 0;
    el = el.parentNode;
    while (el) {
      _x += (el.offsetLeft || 0) - (el.scrollLeft || 0);
      el = el.offsetParent;
    }
    return _x;
  }
  checkColumnOver(event){
    const rect = event.target.getBoundingClientRect();
    const offsetCalc = this.getOffset(event.target);
    this.currentColSelected.left =  rect.left - offsetCalc + 'px';
    this.currentColSelected.width = rect.width + 'px';
  }

  isGroup(index, item) {
    return item.grouped;
  }

  checkData(data: any[]) {
    this.count = data.length;
    let indexBef = 0;
    let indexBefR;
    let mustGroup;
    let valueGroup;
    let valueGroupSum;
    let max = data.length;
    let fillColumns;
    if (!this.headerGroupBy) {
      this.headerGroupBy = [];
    }
    for (let i = 0; i < max; i++) {
      mustGroup = false;
      fillColumns = true;
      valueGroup = '';
      if (this.dynamicRowClass) {
        data[i][this.ROW_DYNAMIC_CLASS] = this.dynamicRowClass(this.context, data[i], this);
      }
      for (let j = 0; j < this.headerGroupBy.length && fillColumns; j++) {
        if (i > 0 && mustGroup === false) {
          mustGroup = data[indexBef][this.headerGroupBy[j].column] === data[i][this.headerGroupBy[j].column];
          fillColumns = !mustGroup;
        }
        if (fillColumns) {
          mustGroup = undefined;
          valueGroup += this.extractGroupColumnVal(data[i], this.headerGroupBy[j]);
          if (this.headerGroupBy[j].includeValInFooter && this.footerGroupBy) {
            const valInfo = this.headerGroupBy[j].includeValInFooter;
            if (this.footerGroupBy[valInfo.posCol][data[i][valInfo.nameColItem]] === undefined) {
              this.footerGroupBy[valInfo.posCol][data[i][valInfo.nameColItem]] = data[i][this.headerGroupBy[j].column];
            }
          }
          valueGroup += (j + 1 === this.headerGroupBy.length) ? '' : ' - ';
        }
      }
      if (this.footerGroupBy) {
        for (const c of this.footerGroupBy) {
          if (c.sum) {
            if (c[SUM] === undefined || i === 0) {
              c[SUM] = 0.0;
            }
            c[SUM] += parseFloat(data[i][c.column]);
          }
        }
      }
      for (let ind = 0; ind < this.columnsConf.length; ind++) {
        const cc  = this.columnsConf[ind];
        if (cc.group) {
          if (indexBefR && indexBefR !== data[i]) {
            const evalColumn = (nameCol: string) => {
              if (data[i][nameCol] && data[i][nameCol] === indexBefR[nameCol]) {
                if (indexBefR[this.CELL_ATTR][cc.column] && indexBefR[this.CELL_ATTR][cc.column][this.ROWSPAN]) {
                  indexBefR[this.CELL_ATTR][cc.column][this.ROWSPAN] += 1;
                } else {
                  indexBefR[this.CELL_ATTR][cc.column] = {[this.ROWSPAN]: 2};
                }
                if (!data[i][this.CELL_ATTR]) {
                  data[i][this.CELL_ATTR] = {};
                }
                data[i][this.CELL_ATTR][cc.column] = {[this.HIDE_CELL]: true};
                return true;
              }
              return false;
            };
            let matchColumn = true;
            if (typeof cc.group === 'string') {
              matchColumn = evalColumn(cc.group);
            } else {
              for (let k = 0; k < cc.group.length && matchColumn; k++) {
                matchColumn = evalColumn(cc.group[k]);
              }
            }
            if (!matchColumn) {
              indexBefR = data[i];
              if (!indexBefR[this.CELL_ATTR]) {
                indexBefR[this.CELL_ATTR] = {[cc.column]: {}};
              }
              break;
            }
          } else {
            if (data[i][this.CELL_ATTR] === undefined || i === 0) {
              data[i][this.CELL_ATTR] = {[cc.column]: {[this.ROWSPAN]: 1}};
              if (i === 0) {
                indexBefR = data[i];
              }
            }
          }
        }
      }
      if (!mustGroup) {
        const newsItems = [];
        if (i !== 0) {
          valueGroupSum = this.checkFooterCells(data, i);
          if (valueGroupSum) {
            newsItems.push({
              value: valueGroupSum,
              grouped: true
            });
          }
        }
        if ( mustGroup === undefined) {
          newsItems.push({
            value: valueGroup,
            grouped: true
          });
        }
        data.splice(i, 0, ...newsItems);
        max = data.length;
        i += newsItems.length;
        indexBef = i;
      }
    }
    valueGroupSum = this.checkFooterCells(data, data.length - 1);
    if (valueGroupSum) {
      data.splice(data.length, 0, {
        value: valueGroupSum,
        grouped: true
      });
    }
    this.data = data;
    this.loadingData = false;
  }

  extractGroupColumnVal(item: any, c: ColumnGroup) {
    if (c.hideGroupName) {
      return c.valueFn ? c.valueFn(item, c) : this.transformColumn(item, c);
    } else {
      return c.valueFn ? `${c.name}: ${this.transformColumn({ [c.column]: c.valueFn(item, c)}, c)}` : `${c.name}: ${this.transformColumn(item, c)}`;
    }
  }

  transformColumn(item: any, c: Column | ColumnGroup) {
    switch (c.type) {
      case Type.Currency:
        return this.currency.transform(item[c.column]);
      case Type.Date:
        return this.datePipe.transform(item[c.column], this.format, null, this.locale);
      case Type.String:
        return this.tr.transform(item[c.column]);
      case Type.Option:
        if ('attr' in c) {
          return c.attr.options[item[c.column]];
        }
    }
    return item[c.column];
  }

  private checkFooterCells(data: any[], i: number) {
    let valueGroupSum = '';
    if (this.footerGroupBy && data && data.length) {
      this.footerGroupBy.forEach((c, j) => {
        if (c.sum) {
          if (i + 1 !== data.length) {
            c[SUM] -= data[i][c.column];
          }
          valueGroupSum += (valueGroupSum === '') ? '' : ' - ';
          valueGroupSum += `${c.sumName}: ${this.transformColumn({[c.column]: c[SUM].toFixed(2)}, c)}`;
        }
        if (c.name) {
          valueGroupSum += (valueGroupSum === '') ? '' : ' - ';
          valueGroupSum += this.extractGroupColumnVal(data[i + 1 !== data.length ? i - 1 : i], c);
          valueGroupSum += (j + 1 === this.footerGroupBy.length) ? '' : ' - ';
        }
        c[SUM] = parseFloat(data[i][c.column]);
      });
    }
    return valueGroupSum;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import {Entities, Pages, Permission} from '@Interfaces/index';
import {NavRoute} from '../../utils/menu';
import {ConciliationSebComponent} from '@pages/bank-conciliation/conciliation-seb/conciliation-seb.component';

export const BankConciliationRoutes: NavRoute[] = [
  {
    path: Pages.CONSILIATION_REGISTER,
    component: ConciliationSebComponent,
    entity: Entities.CONCILIATION,
    permission: Permission.LIST
  }
];

@NgModule({
  imports: [RouterModule.forChild(BankConciliationRoutes)],
  exports: [RouterModule]
})
export class BankConciliationRoutingModule { }

<h1 mat-dialog-title>Tipo de Conciliación</h1>
<div mat-dialog-content>
    <mat-form-field class="w-100">
        <mat-label>Tipo de Conciliación</mat-label>
        <mat-select [(ngModel)]="data.type" (selectionChange)="dialogRef.close(data.type)">
            <mat-option *ngFor="let type of types" [value]="type">
                {{type.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<app-list #table entityName="VPresupuestoInicial" module="Presupuesto"
          (loadedData)="onChangeData($event)"  customRowClass="adfi-row-mat" [defaultQuery]="[{column: 'cencos', value: ''}, {column: 'vigencia', value: presupuestoInicial.vigencia}]">
    <ng-template tableColumn="ingreso" let-row="row" let-property="property">
        {{ row[property.fieldName] | currency }}
    </ng-template>
    <ng-template tableColumn="egreso" let-row="row" let-property="property">
        {{ row[property.fieldName] | currency }}
    </ng-template>

    <div class="footer row py-2">
        <div class="col-md-6 col-12">
            <div class="row justify-content-center">
                <mat-chip-list class="col-12" *ngIf="data.total != undefined">
                    <mat-chip class="col-10 justify-content-center mx-5 px-5" color="{{data.total > 0 ? 'warn' : 'primary'}}" selected><strong>Diferencia: </strong> {{ data.total | currency }} </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="offset-md-1 col-md-5 col-12" *ngIf="viewBtnAprobar && !table.loadingData && presupuestoInicial.id">
            <div class="row justify-content-center">
                <button mat-raised-button color="primary"
                        [disabled]="data.ingresos === 0 || data.ingresos === 0 ||  data.total !== 0" (click)="aprobar()">
                    Aprobar Presupuesto
                </button>
            </div>
        </div>
        <div class="col-md-5 col-12">
            <div class="row justify-content-center">
                <span class="svw-message info" *ngIf="viewBtnAprobar && !presupuestoInicial.id">
                    <strong>No se ha solicitado una aprobación</strong>
                </span>
                <span class="svw-message success" *ngIf="presupuestoInicial.estado=='A'">
                    <strong>Presupuesto aprobado</strong>
                </span>
            </div>
        </div>
    </div>

    <!-- footer table -->

    <!-- vigencia -->
    <ng-template tableColumn="footer_descripcion">
        <strong>Total</strong>
    </ng-template>
    <!-- ingreso -->
    <ng-template tableColumn="footer_ingreso">
        {{ data.ingresos |currency }}
    </ng-template>
    <!-- egreso -->
    <ng-template tableColumn="footer_egreso">
        {{ data.egresos |currency }}
    </ng-template>
</app-list>


  <mat-card-title class="d-flex full-width align-items-center">
    <h4>Plan Anual de Adquiciones </h4>
    <button class="ml-4" mat-raised-button color="primary" (click)="openVideoPAA()">Ver video</button>
  </mat-card-title>
  <div class="row justify-content-end pr-4">
    <mat-chip-list>
      <mat-chip *ngIf="counter.counterEnd > 0 && (!paa?.state || paa?.state!='C')"
                matTooltip="Plazo para el registro de plan anual de adquisiciones"
                selectable="false" [ngClass]="getColorTime()">
        {{ counter.counterEnd | formatTime }}
      </mat-chip>
      <mat-chip *ngIf="counter.counterEnd < 0"
      selectable="false" class="green">Plan Anual de Adquiciones en modo solo lectura
  </mat-chip>
    </mat-chip-list>
  </div>
  <template #tem_paa></template>
  <ng-container *ngIf="paa">
    <app-list #listPAA
      entityName="PAAAdquisicion"
      module="PAA"
      [group]="'LP'"
      [noActions]="paa?.state!='I' || counter.counterEnd < 0"
      [dataEntity]="{ enableAdd: (!paa?.state || paa?.state=='I') && counter.counterEnd > 0, group: 'AP', editGroup: 'EP'}"
      [defaultQuery]="[{column:'cencos', value: ''}, {column:'vigencia', value: period}]"
    >
      <ng-template tableColumn="unspscAccounts" let-row="row" let-property="property">
        <div class="d-flex align-items-center justify-content-center full-width full-height">
          <ng-container *ngIf="getDataMenu(row, property); let list">
            <ng-container *ngIf="list && list.length">
              <button mat-icon-button [matMenuTriggerFor]="many">
                <mat-icon color="primary">visibility</mat-icon>
              </button>
              <mat-menu #many="matMenu" yPosition="above">
                <mat-list>
                  <mat-list-item *ngFor="let item of list" class="cursor-pointer">
                    <mat-icon mat-list-icon class="cursor-pointer">double_arrow</mat-icon>
                    <div mat-line class="wrap-content" *ngFor="let p of property.display; let i = index">
                      {{transformValue(item, property, i)}}
                    </div>
                  </mat-list-item>
                </mat-list>
              </mat-menu>
              <button mat-icon-button matTooltip="Editar" *ngIf="paa?.state=='I' && counter.counterEnd > 0" (click)="addUnspscAccount(row, property, listPAA)">
                <mat-icon color="primary">edit</mat-icon>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <div class="footer col-12 py-2">
        <div class="col-12">
          <div class="row justify-content-center">
            <button mat-raised-button color="primary" class="mr-3" *ngIf="(paa?.state == 'A') && counter.counterEnd > 0"
                    (click)="uploadFile()">
              <mat-icon>file_upload</mat-icon>
              Archivo Plan Anual Adquisiciones
            </button>
            <button mat-raised-button color="primary" class="mr-3" *ngIf="(paa?.state == 'C')"
                    (click)="downloadPaa()">
              <mat-icon>file_download</mat-icon>
              Archivo Plan Anual Adquisiciones
            </button>
            <button mat-raised-button color="primary" (click)="downloadFile('pdf')">
              <mat-icon>picture_as_pdf</mat-icon>
              Descargar PDF
            </button>
            <button mat-raised-button color="primary" class="ml-3" (click)="downloadFile('xlsx')">
              <mat-icon>summarize</mat-icon>
              Descargar XLSX
            </button>
          </div>
        </div>
      </div>
      <div class="actionsTable pr-3">
        <button mat-raised-button color="primary" class="ml-3" (click)="openVerification()">
          Verificación
        </button>
      </div>
    </app-list>
  </ng-container>

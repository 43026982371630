<div class="col-12">
  <div class="row justify-content-center">
    <mat-chip-list aria-label="account" class="fixed-account">
      <mat-chip color="primary" selected>
        Cuenta Número:
        <ng-container *ngIf="currentAccount">{{currentAccount.minicode}}</ng-container>
        <ng-container *ngIf="!currentAccount && documentForm.get('group').valid">
          {{documentForm.get('group').value}}
        </ng-container>
        <ng-container *ngIf="!currentAccount && documentForm.get('subGroup').valid">
          {{documentForm.get('subGroup').value}}
        </ng-container>
        <ng-container *ngIf="!currentAccount && documentForm.get('major').valid">
          {{documentForm.get('major').value}}
        </ng-container>
        <ng-container *ngIf="!currentAccount && documentForm.get('subAccount').valid">
          {{documentForm.get('subAccount').value}}
        </ng-container>
        <ng-container *ngIf="!currentAccount && documentForm.get('aux1').valid">
          {{documentForm.get('aux1').value}}
        </ng-container>
        <ng-container *ngIf="!currentAccount && documentForm.get('aux2').valid">
          {{documentForm.get('aux2').value}}
        </ng-container>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
<div class="col-12 pt-5">
  <mat-progress-bar mode="indeterminate" *ngIf="loadSources"></mat-progress-bar>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="rubro">
      <button mat-icon-button disabled></button>
      {{node.description | titlecase}}
      <button class="ml-2" mat-stroked-button color="primary" (mouseenter)="showAccount(node)" (mouseleave)="currentAccount = undefined"  (click)="onSubmit(node)">Seleccionar</button>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="rubro">
      <button mat-icon-button [attr.aria-label]="'toggle ' + node.filename" matTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-chip-list [attr.aria-label]="'item-chip-' + node.filename" *ngIf="treeControl.isExpanded(node)">
        <mat-chip color="primary" selected>
          {{node.description | titlecase}}
        </mat-chip>
      </mat-chip-list>
      <ng-container *ngIf="!treeControl.isExpanded(node)">
        {{node.description | titlecase}}
      </ng-container>
      <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="over-head-load"></mat-progress-bar>
    </mat-tree-node>
  </mat-tree>
</div>
<div class="col-12 mt-3">
  <div class="row justify-content-end">
    <button mat-raised-button color="primary" mat-dialog-close>Cancelar</button>
  </div>
</div>

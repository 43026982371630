import {Component, OnInit, ViewChild} from '@angular/core';
import {TreePresupuestoComponent} from '@Components/presupuesto/tree-presupuesto/tree-presupuesto.component';
import {ICustomComponent} from '@Components/util/ICustomComponent';
import {DataEntity, Property} from '@Interfaces/index';

@Component({
  selector: 'app-detail-modification',
  templateUrl: './detail-modification.component.html',
  styleUrls: ['./detail-modification.component.scss']
})
export class DetailModificationComponent implements OnInit, ICustomComponent {

  @ViewChild('incomes', {static: true}) incomes: TreePresupuestoComponent;
  @ViewChild('expenses', {static: true}) expenses: TreePresupuestoComponent;

  dataEntity: DataEntity;
  field: Property;
  group: string;
  item: any;
  titleL = 'INGRESOS';
  titleR = 'GASTOS';
  showHistory = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  setConfig(field: Property, dataEntity: DataEntity, group: string, item: any) {
    this.field = field;
    this.dataEntity = dataEntity;
    this.group = group;
    this.item = item;
    this.loadData();
  }

  loadData(){
    if (this.field.column.entity === 'DetailModification'){
      this.incomes.customFilter = `typePresu: "I", modificationId: ${this.item._id}`;
      this.expenses.customFilter = `typePresu: "E", modificationId: ${this.item._id}`;
    } else {
      this.expenses.customFilter = `typePresu: "E", modificationId: ${this.item._id}, typeTransfer: "CR"`;
      this.expenses.typeTransfer = 'CR';

      this.incomes.customFilter = `typePresu: "E", modificationId: ${this.item._id}, typeTransfer: "CC"`;
      this.incomes.typeTransfer = 'CC';

      this.titleR = 'CRÉDITOS';
      this.titleL = 'CONTRACRÉDITOS';
    }
    this.incomes.update();
    this.expenses.update();
  }


  save(node: any, tree: TreePresupuestoComponent, item: any, field: Property) {
    tree.sLoading.show();
    const next = () => {
      tree.sLoading.hide();
      tree.alert.success('Correcto', 'Rubro correctamente guardado');
      tree.update();
    };
    const error = (e) => {
      tree.sLoading.hide();
      tree.alert.errorMessage(e);
      node.valor = node.valorIn;
    };
    if (node.detailModificationId) {
      if (node.valor === 0){
        tree.graphqlService.deleteEntity(field.column.entity, (field.column.entity === 'DetailModification' ?
            '/api/detail_modifications/' : '/api/detalle_traslados/') + node.detailModificationId,
            {next, error}, true);
      } else {
        if (field.column.entity === 'DetailModification') {
          tree.graphqlService.updateEntity(field.column.entity, {
            id: `/api/detail_modifications/${node.detailModificationId}`,
            value: node.valor,
            state: 'T'
          }, undefined, {next, error});
        } else {
          tree.graphqlService.updateEntity(field.column.entity, {
            id: `/api/detalle_traslados/${node.detailModificationId}`,
            valor: node.valor,
            type: 'T'
          }, undefined, {next, error});
        }
      }
    } else {
      if (field.column.entity === 'DetailModification') {
        tree.graphqlService.createEntity(field.column.entity, {value: node.valor, modification: item.id, vCodPresu: node.minipresu},
            undefined, {next, error});
      } else {
        tree.graphqlService.createEntity(field.column.entity, {valor: node.valor, traslado: item.id, vCodPresu: node.minipresu, type: tree.typeTransfer},
            undefined, {next, error});
      }
    }
  }

}

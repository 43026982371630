<h3 mat-dialog-title>Imprimir</h3>
<div mat-dialog-content>
    <p>¿Desea incluir la firma de su Contador?</p>
    <div class="container">

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Seleccione</mat-label>
                    <mat-select [(ngModel)]="addSing" (selectionChange)="changeSelect($event)">
                        <mat-option [value]="true">Si</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="addSing" class="row">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Nombres y Apellidos</mat-label>
                    <input matInput [(ngModel)]="data.name">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                    <mat-label>Número documento de Identificación</mat-label>
                    <input matInput [(ngModel)]="data.dni">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                    <mat-label>Número tarjeta profesional Contador</mat-label>
                    <input matInput [(ngModel)]="data.profesionalCounterCard">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cancelar</button>
    <button mat-raised-button [disabled]="addSing && (data.name == undefined || data.dni === undefined || data.profesionalCounterCard === undefined )" [mat-dialog-close]="data" color="primary" cdkFocusInitial>Imprimir</button>
</div>

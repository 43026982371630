<div>
    <h3> Resumen Procesos Plataforma - ADFI</h3>
    <ng-container *ngIf="listPendingProcess && listPendingProcess.length else loading">
        <div *ngFor="let item of listPendingProcess">
            <ng-container *ngIf="!item.loading else loading">
                <mat-expansion-panel [hideToggle]="item.list.length === 0 || item.valueTransfer == 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                           {{ item.name }}
                        </mat-panel-title>
                        <mat-panel-description class="d-flex justify-content-end">
                            {{ item.valueTransfer | currency}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div *ngFor="let detail of item.list" class="d-flex justify-content-between px-2">
                        <p>{{detail.consecutive}} - {{detail.description}}</p>  <p>{{detail.valueTransfer | currency}}</p>
                    </div>
                    <p class="text-center" *ngIf="! item.list.length">{{item.messageEmpty}}</p>
                </mat-expansion-panel>
            </ng-container>
        </div>
    </ng-container>

    <ng-template #loading>
        <div class='container-hint loading' style="max-width: 600px">
            <div class='content'>
                <div class='stripe small-stripe'>
                </div>
                <div class='stripe medium-stripe'>
                </div>
                <div class='stripe long-stripe'>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<div class="row" mat-dialog-title>
    <div class="col-12 pt-1">
        <h2 class="text-center">{{data.title}}</h2>
    </div>
</div>
<div class="d-flex" mat-dialog-content>
    <div class="row">
    <div class="col-12 pt-1 pb-4 px-4 text-center" [innerHTML]="data.content">
    </div>
    <div class="col-12" *ngIf="data.warnings && data.warnings.length > 0">
        <div class="d-flex align-items-center" *ngFor="let warning of data.warnings; let lastIndex = last">
            <ng-container *ngIf="!lastIndex">
                <button mat-icon-button type="button" (click)="resolveWarning(warning)">
                    <mat-icon style="color: #ffbe42">warning</mat-icon>
                </button> {{warning.warning}}
            </ng-container>
            <ng-container *ngIf="lastIndex">
                <button mat-icon-button type="button" (click)="resolveWarning(warning)">
                    <mat-icon class="warning-icon">warning</mat-icon>
                </button> 
                <span class="warning-text">{{warning.warning}}</span>
            </ng-container>
        </div>
        <span class="px-3">
        </span>
    </div>
    </div>
</div>
<div class="row justify-content-center" *ngIf="data.hideActions === undefined || !data.hideActions">
    <div class="pb-2 pt-1">
        <ng-container [ngSwitch]="data.action">
            <div mat-dialog-actions *ngSwitchCase="ACTIONS.CREATE">
                <button mat-stroked-button color="primary" mat-dialog-close>Revisar</button>
                <button mat-flat-button color="primary" cdkFocusInitial (click)="save()">GUARDAR</button>
            </div>
            <div mat-dialog-actions *ngSwitchCase="ACTIONS.DELETE">
                <button mat-stroked-button color="primary" mat-dialog-close>No</button>
                <button mat-stroked-button color="warn" cdkFocusInitial (click)="save()">SI</button>
            </div>
            <div mat-dialog-actions *ngSwitchDefault>
                <button mat-stroked-button color="primary" mat-dialog-close>Cancelar</button>
                <button mat-flat-button color="primary" cdkFocusInitial (click)="save()">CONTINUAR</button>
            </div>
        </ng-container>
    </div>
</div>

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiService} from '@services/adfi-service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {first} from 'rxjs/operators';
import {LoadingService} from '@services/loading.service';
import {generateRandomLetter} from '../../../utils/functions';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

    form1: FormGroup;

    constructor(private adfiGraphqlService: AdfiGraphqlService, private adfiService: AdfiService,
                private adfiGrowlService: AdfiGrowlService, private loading: LoadingService) {
    }

    ngOnInit() {
        this.form1 = new FormGroup({
            oldPwd: new FormControl('', Validators.required),
            newPwd: new FormControl('', [Validators.required, Validators.minLength(8)]),
            confirmPwd: new FormControl('', [Validators.required])
        });
    }

    get oldPwd() {
        return this.form1.get('oldPwd');
    }

    get newPwd() {
        return this.form1.get('newPwd');
    }

    get confirmPwd() {
        return this.form1.get('confirmPwd');
    }

    customValidators() {
        const newPwd = this.form1.get('newPwd');
        const confirmPwd = this.form1.get('confirmPwd');
        if (newPwd.value !== confirmPwd.value) {
            newPwd.setErrors({notEquivalent: true});
            confirmPwd.setErrors({notEquivalent: true});
        } else {
            newPwd.setErrors(null);
            confirmPwd.setErrors(null);
        }
    }

    valid() {
        this.form1.markAllAsTouched();
        this.customValidators();
    }

    changePassword() {
        this.loading.show();
        this.adfiService.post('/api/session/check', generateRandomLetter() + generateRandomLetter() + btoa(generateRandomLetter() +
            JSON.stringify({psw: this.oldPwd.value, [generateRandomLetter()]: generateRandomLetter(), nw: this.newPwd.value}))).pipe(first())
            .subscribe((ok) => {
                    if (ok) {
                        this.loading.hide();
                        this.adfiGrowlService.success('Contraseña', 'Actualizada correctamente');
                    } else {
                        this.loading.hide();
                        this.adfiGrowlService.error('Contraseña anterior', 'Error en la conexión');
                    }
                },
                err => {
                    this.adfiGrowlService.error('Error', err.error);
                    this.loading.hide();
                }
            );

    }
}

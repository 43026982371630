import {Directive, ElementRef, OnInit, Optional, Renderer2} from '@angular/core';
import {NgControl, NgModel} from '@angular/forms';
import {MatDatepickerInput} from '@angular/material/datepicker';
import {FormControl} from '@Components/ui/util/FormControl';

@Directive({
  selector: '[ngModel], [formControl], [formControlName]'
})
export class NativeElementInjectorDirective implements OnInit {

  private name = 'nativeElement';
  private rendererName = 'renderer';
  private pickerName = 'picker';

  constructor(private el: ElementRef, private renderer: Renderer2,
              private control: NgControl,
              @Optional() private model: NgModel,
              @Optional() private picker: MatDatepickerInput<any>) {
  }

  ngOnInit() {
    const control = this.model ? this.model.control : this.control.control;
    control[this.name] = this.el.nativeElement;
    control[this.rendererName] = this.renderer;
    control[this.pickerName] = this.picker;
    if (control instanceof FormControl) {
      control.assignedNatives.emit();
    }
  }

}
